import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Icon, Text} from 'react-native-elements'
import {GetOrderLineAmountFormatted} from '../../../Helpers/OrderHelpers'
import {IOrderRequestLine} from '../../../Models/InzziiApi'
import Style, {colors, sizes} from '../../../Style'

interface IProps {
  orderRequestLine: IOrderRequestLine
}

const OrderRow: React.FC<IProps> = ({orderRequestLine}) => {
  const {
    container,
    row,
    supplimentsText,
    contentView,
    quantityText,

    amountText,
    productText,
    separator,
  } = styles

  const renderSuppliments = () => {
    if (
      orderRequestLine.linkedOrderLines &&
      orderRequestLine.linkedOrderLines.length > 0
    ) {
      const text = orderRequestLine.linkedOrderLines
        .map((x) => x.name)
        .join(', ')
      return <Text style={supplimentsText}>{text}</Text>
    }

    return null
  }
  return (
    <View style={container}>
      <View style={row}>
        <Text style={quantityText}>{orderRequestLine.quantity}</Text>
        <View style={contentView}>
          <Text style={productText}>{orderRequestLine.name}</Text>
          {renderSuppliments()}
        </View>
        <Text style={amountText}>
          {GetOrderLineAmountFormatted(orderRequestLine)}
        </Text>
      </View>

      <View style={separator}></View>
    </View>
  )
}

export default OrderRow

const styles = StyleSheet.create({
  separator: {
    borderBottomColor: colors.lightGrey,
    borderBottomWidth: 1,
    marginTop: sizes.padding.md,
  },
  container: {
    paddingTop: sizes.padding.md,
  },
  row: {
    flexDirection: 'row',
  },
  contentView: {
    flex: 8,
  },
  productText: {
    fontWeight: 'bold',
  },
  quantityText: {
    flex: 1,

    fontWeight: 'bold',
  },
  amountText: {
    width: 70,
    textAlign: 'right',
  },
  supplimentsText: {
    color: colors.grey,
    fontSize: 12,
  },
})
