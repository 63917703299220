import React, {useRef} from 'react'

interface IProps {}

const HorizontalScroll: React.FC<IProps> = ({children}) => {
  const myDiv = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={myDiv}
      onWheel={(e) => {
        //console.log('wheel', e.deltaY)
        if (myDiv && myDiv.current) {
          myDiv.current.scrollTo({left: myDiv.current.scrollLeft + e.deltaY})
        }
      }}
      onScroll={(e) => {
        //   console.log('scroll', myDiv?.current?.scrollLeft)
        e.stopPropagation()
      }}
      className="h-scroll pb-4">
      {children}
    </div>
  )
}

export default HorizontalScroll
