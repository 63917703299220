import NetInfo from '@react-native-community/netinfo'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {ThemeProvider} from 'react-native-elements'
import {ToastProvider} from 'react-native-fast-toast'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import {
  AlertModal,
  AuthenticateModal,
  ConfirmModal,
  MyToast,
} from './src/Components'
import CodePushUpdatingModal from './src/Components/Modal/CodePushUpdatingModal'
import {WebIncludes} from './src/Components/Web'
import {persistedStore} from './src/Mobx'
import {appUiStore} from './src/Mobx/AppUiStore'
import Navigation from './src/Navigation'
import Test from './src/Screens/Test/Test'

import Theme from './src/Style/Theme'

console.ignoredYellowBox = ['Setting a timer']

const App = () => {
  useEffect(() => {
    const netInfoUnsubcribe = NetInfo.addEventListener((state) => {
      appUiStore.SetIsConnected(state.isConnected)
      return () => {
        netInfoUnsubcribe()
      }
    })
  }, [null])

  const {NetworkStatefinished, ToastMessage} = appUiStore
  if (!NetworkStatefinished || !persistedStore.IsHydrated) {
    return null
  }

  //return <Test />

  return (
    <SafeAreaProvider>
      <ToastProvider placement="top" offset={10}>
        <ThemeProvider theme={Theme}>
          <WebIncludes />
          <Navigation />
          <MyToast toastMessage={ToastMessage} />
          <ConfirmModal />
          <AuthenticateModal />
          <AlertModal />
          <CodePushUpdatingModal />
        </ThemeProvider>
      </ToastProvider>
    </SafeAreaProvider>
  )
}

export default observer(App)
