import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {Text, View} from 'react-native'
import {ResultCard} from '../../../Components'
import I18n from '../../../i18n'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import {colors, sizes} from '../../../Style'

interface IProps
  extends StackScreenProps<MainScreensList, 'changeEmailConfirmationSent'> {}

const ChangeEmailConfirmationSent: React.FC<IProps> = ({
  navigation: {navigate},
  route,
}) => {
  useEffect(() => {
    myLog.info('ChangeEmailConfirmationSent')
  }, [null])

  return (
    <ResultCard
      title={I18n.t('ChangeEmail.ConfirmationSentStep.Header')}
      button={{
        text: I18n.t('Common.BackToDashboard'),
        action: () => navigate('dashboard'),
      }}>
      <Text>
        {I18n.t('ChangeEmail.ConfirmationSentStep.TextPart1')}
        <Text style={{color: colors.main}}>{route.params.email}</Text>.
        {I18n.t('ChangeEmail.ConfirmationSentStep.TextPart2')}
      </Text>
    </ResultCard>
  )
}

export default observer(ChangeEmailConfirmationSent)
