import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Image, View} from 'react-native'
import {Text} from 'react-native-elements'
import {
  Container,
  Content,
  EuroTextInput,
  FormInfo,
  MySelectList,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import {
  FormatCentsToEuro,
  GetWalletPagetitle,
} from '../../../Helpers/TextHelpers'
import {GetFileUrl} from '../../../Helpers/UiHelpers'
import I18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {
  AppLinkTypes,
  PaymentOrderStatusTypes,
} from '../../../Models/Client/Enums'
import {ICurrencyChargeMethodSTO} from '../../../Models/Server'
import {getPaymentMethodTransactionCosts} from '../../../Models/Server/Wallet/ICurrencyChargeMethodSTO'

import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style, {colors} from '../../../Style'
import {ChargeFormData, HbChargeValue, chargeStore} from './Store'
import {CreateAppLinkUrl} from '../../../Helpers/UrlHelpers'

interface IProps extends StackScreenProps<MainScreensList, 'charge'> {}

const Charge: React.FC<IProps> = ({
  navigation: {goBack, setOptions},
  route,
}) => {
  const wallet = route.params.wallet
  const initialAmount = route.params.initialAmount
  const {noteText, flexRow, pickerItemImage} = Style
  useEffect(() => {
    console.log('Charge', wallet.Currency.CurrencyGuid)

    chargeStore.Initialize(
      wallet.Currency.CurrencyGuid,
      route.params.chargeSuccessReturn,
    )
  }, [null])

  useEffect(() => {
    if (wallet) {
      setOptions({
        title: GetWalletPagetitle(
          I18n.t('Charge.Form.Action'),
          wallet.Currency.Name,
        ),
      })
    }
  }, [wallet])

  const {control, handleSubmit, errors, setError} = useForm<ChargeFormData>({
    mode: 'onChange',
    defaultValues: {
      amount: initialAmount,
    },
  })
  const {
    IsSubmitting,
    SelectedChargeMethod,
    ChargeMethods,
    IsChargeMethodsLoading,
    HBChargeValues,
    SelectedHBChargeValue,
  } = chargeStore

  const onSubmit = (data: ChargeFormData) => {
    const amount =
      wallet.Currency.Code === 'HBC'
        ? SelectedHBChargeValue?.Text
        : FormatCentsToEuro(data.amount)
    if (SelectedChargeMethod) {
      appUiStore.ShowAuthenticateConfirm({
        OnOk: () => {
          chargeStore.Topup(
            SelectedChargeMethod.Guid,
            wallet.Currency.Code === 'HBC'
              ? SelectedHBChargeValue?.Amount * 300
              : data.amount,
            (errorMessage: string) => {
              appUiStore.ShowAlert({
                title: 'Error',
                message: errorMessage,
              })

              // setError('amount', {
              //   type: 'manual',
              //   message: errorMessage,
              // })
            },
          )
        },
        Message: I18n.t('Charge.Form.AuthenticateAction', {
          amount,
        }),
      })
    }
  }

  const maxChargeAmount =
    wallet.Currency.MaxBalance !== null
      ? wallet.Currency.MaxBalance - wallet.Balance
      : null

  const renderMaxBalanceMessage = () => {
    if (maxChargeAmount) {
      return (
        <FormInfo>
          {I18n.t('Charge.MaxBalance0')}
          <Text style={{color: colors.main}}>
            {FormatCentsToEuro(maxChargeAmount)}
          </Text>
          {I18n.t('Charge.MaxBalance1')}
        </FormInfo>
      )
    }

    return null
  }

  const renderSubmit = () => {
    if (IsChargeMethodsLoading || SelectedChargeMethod === undefined) {
      return null
    }

    return (
      <SubmitHeaderButton
        isLoading={IsSubmitting}
        title={I18n.t('Charge.Form.Action')}
        onPress={handleSubmit(onSubmit)}
      />
    )
  }

  const renderTransactionCosts = () => {
    const {SelectedChargeMethod} = chargeStore
    if (SelectedChargeMethod === undefined) {
      return null
    }

    const calculatedTransactionCosts =
      getPaymentMethodTransactionCosts(SelectedChargeMethod)

    if (calculatedTransactionCosts === 0) {
      return null
    }

    const renderCostMessage = (transactionCosts: number) => {
      return (
        <Text style={noteText}>
          {I18n.t('Charge.Form.ChargeCostsMessage')}
          <Text style={{color: colors.main, fontSize: 12}}>
            {FormatCentsToEuro(transactionCosts)}
          </Text>
        </Text>
      )
    }

    if (SelectedChargeMethod.TransactionCostsFixed > 0) {
      return renderCostMessage(calculatedTransactionCosts)
    }

    if (SelectedChargeMethod.TransactionCostsPercentage > 0) {
      if (calculatedTransactionCosts > 0) {
        return renderCostMessage(calculatedTransactionCosts)
      }
    }
  }

  const max =
    maxChargeAmount === null
      ? undefined
      : {
          value: maxChargeAmount,
          message: I18n.t('Charge.Form.Fields.Amount.Errors.Max', {
            max: FormatCentsToEuro(maxChargeAmount),
          }),
        }

  const renderAmountInput = () => {
    //other

    if (wallet.Currency.Code === 'HBC') {
      return (
        <>
          <MySelectList
            label={I18n.t('Charge.Form.Fields.AmountHb.Label')}
            options={HBChargeValues}
            selectedOption={SelectedHBChargeValue}
            onSelect={(option: HbChargeValue) =>
              chargeStore.SetSelectedHBChargeValue(option)
            }
            isItemSelected={(a: HbChargeValue, b: HbChargeValue) => {
              return a?.Amount === b?.Amount
            }}
            renderListItem={(item: HbChargeValue, isHighLighted: boolean) => {
              return (
                <View style={flexRow}>
                  <View style={{justifyContent: 'center'}}>
                    <Text style={[isHighLighted && {color: colors.main}]}>
                      {item.Text}
                    </Text>
                  </View>
                </View>
              )
            }}
          />
        </>
      )
    }

    return (
      <EuroTextInput
        formObject={{} as ChargeFormData}
        name="amount"
        label={I18n.t('Charge.Form.Fields.Amount.Label')}
        control={control}
        onSubmitEditing={() => {
          handleSubmit((data) => onSubmit(data))()
        }}
        validation={{
          errors,
          rules: {
            required: I18n.t('Charge.Form.Fields.Amount.Errors.Required'),
            min: {
              value: 100,
              message: I18n.t('Charge.Form.Fields.Amount.Errors.Min', {
                min: FormatCentsToEuro(100),
              }),
            },
            max,
          },
        }}
      />
    )
  }

  return (
    <>
      <Container keyboardAware={true}>
        <StockImageLayout>
          <Content headerMargin>
            {renderMaxBalanceMessage()}
            <MySelectList
              label={I18n.t('Charge.Form.Fields.ChargeMethod.Label')}
              options={ChargeMethods}
              selectedOption={SelectedChargeMethod}
              onSelect={(option: ICurrencyChargeMethodSTO) =>
                chargeStore.SetSelectedChargeMethod(option)
              }
              isItemSelected={(
                a: ICurrencyChargeMethodSTO,
                b: ICurrencyChargeMethodSTO,
              ) => {
                return a.Guid === b.Guid
              }}
              renderListItem={(
                chargeMethod: ICurrencyChargeMethodSTO,
                isHighLighted: boolean,
              ) => {
                return (
                  <View style={flexRow}>
                    <Image
                      style={pickerItemImage}
                      source={{uri: GetFileUrl(chargeMethod.IconFile)}}
                    />
                    <View style={{justifyContent: 'center'}}>
                      <Text style={[isHighLighted && {color: colors.main}]}>
                        {chargeMethod.Name}
                      </Text>
                    </View>
                  </View>
                )
              }}
            />
            {renderAmountInput()}
            {renderTransactionCosts()}
          </Content>
          <SubmitHeader onBackPress={goBack}>
            <Text>{wallet.Currency.Name}</Text>
            {renderSubmit()}
          </SubmitHeader>
        </StockImageLayout>
      </Container>
    </>
  )
}
export default observer(Charge)
