import {StackNavigationProp} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Keyboard} from 'react-native'
import {
  Container,
  Content,
  FormHeader,
  FormInfo,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import AppConfig from '../../../Config/AppConfig'
import {goBack} from '../../../Helpers/NavigationHelper'
import i18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {myLog} from '../../../Services/Client/LogService'
import {RegisterSteps} from '../Register'
import {RegisterFormData, store} from '../Store'

interface IProps {
  navigation: StackNavigationProp<RegisterSteps>
}

const EmailStep: React.FC<IProps> = ({navigation: {navigate}}) => {
  const {FormData, IsSubmitting} = store

  const {control, handleSubmit, errors, setValue, setError, formState} =
    useForm<RegisterFormData>({mode: 'onChange'})

  useEffect(() => {
    myLog.info('EmailStep')
    if (AppConfig.IsDebugMode) {
      setValue('email', 'r.de.ruijter@mybility.nl')
      //setValue('email', 'superdopey@live.nl')
    }
  }, [store])

  const onSubmit = (data: RegisterFormData) => {
    store.SetFormData('email', data.email)
    store.CheckEmail(
      () => navigate('password'),
      (errorMessage) => {
        setError('email', {
          type: 'manual',
          message: errorMessage,
        })
      },
    )
  }

  return (
    <>
      <Container>
        <StockImageLayout>
          <Content headerMargin>
            <FormHeader>
              {i18n.t('Register.EmailStep.Header', {name: FormData.name})}
            </FormHeader>
            <FormInfo>{i18n.t('Register.EmailStep.Info')}</FormInfo>
            <MyInput
              formObject={{} as RegisterFormData}
              name="email"
              label={i18n.t('Register.EmailStep.Field.Label')}
              control={control}
              autofocus={true}
              inputProps={{
                autoCapitalize: 'none',
                keyboardType: 'email-address',
                onSubmitEditing: () => {
                  handleSubmit((data) => onSubmit(data))()
                },
              }}
              validation={{
                errors,
                rules: {
                  required: i18n.t('Register.EmailStep.Field.Errors.Required'),
                  pattern: {
                    value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
                    message: i18n.t('Register.EmailStep.Field.Errors.Regex'),
                  },
                },
              }}
            />
          </Content>
          <SubmitHeader title={i18n.t('Register.Header')} onBackPress={goBack}>
            <SubmitHeaderButton
              disabled={!formState.isValid}
              isLoading={IsSubmitting}
              title={i18n.t('Common.Continue')}
              onPress={handleSubmit(onSubmit)}
            />
          </SubmitHeader>
        </StockImageLayout>
      </Container>
    </>
  )
}

export default observer(EmailStep)
//simple email regex
//https://stackoverflow.com/questions/742451/what-is-the-simplest-regular-expression-to-validate-emails-to-not-accept-them-bl
