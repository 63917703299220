import DebugConfig from './Service/DebugConfig'
import ProductionConfig from './Service/ProductionConfig'
import TestConfig from './Service/TestConfig'
import ToprConfig from './Tenants/Topr/ToprConfig'

export default class AppConfig {
  //Constants
  static Tenant = ToprConfig
  //static Service = DebugConfig
  //static Service = ProductionConfig
  static Service = TestConfig

  public static get IsDebugMode() {
    return AppConfig.Service === DebugConfig // || AppConfig.Service === TestConfig
  }
}
