import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {
  Container,
  Content,
  MyInput,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import AppConfig from '../../../Config/AppConfig'
import i18n from '../../../i18n'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import {resetPasswordStore} from './Store'
import {navigate} from '../../../Helpers/NavigationHelper'

interface IProps extends StackScreenProps<MainScreensList, 'resetPassword'> {}
{
}

export type MyFormData = {
  password: string
  confirmPassword: string
}

const ResetPassword: React.FC<IProps> = ({navigation: {goBack}, route}) => {
  useEffect(() => {
    const {requestIdentifier} = route.params
    myLog.info(`ResetPassword ${requestIdentifier}`)

    //get deeplink

    if (AppConfig.IsDebugMode) {
      setValue('password', 'sdopey01')
      setValue('confirmPassword', 'sdopey01')
    }

    resetPasswordStore.Initialize(requestIdentifier)
  }, [resetPasswordStore])

  const {control, handleSubmit, errors, setValue, getValues, formState} =
    useForm<MyFormData>({mode: 'onChange'})

  const {IsSubmitting} = resetPasswordStore

  const onSubmit = (data: MyFormData) => {
    resetPasswordStore.ResetPassword(data.password)
  }
  return (
    <>
      <Container>
        <Content headerMargin>
          <MyInput
            formObject={{} as MyFormData}
            name="password"
            control={control}
            inputProps={{
              autoCapitalize: 'none',
              secureTextEntry: true,
            }}
            validation={{
              errors,
              rules: {
                required: i18n.t(
                  'ResetPassword.Form.Fields.Password.Errors.Required',
                ),
                minLength: {
                  value: 6,
                  message: i18n.t(
                    'ResetPassword.Form.Fields.Password.Errors.MinLength',
                  ),
                },
              },
            }}
            label={i18n.t('ResetPassword.Form.Fields.Password.Label')}
          />
          <MyInput
            formObject={{} as MyFormData}
            name="confirmPassword"
            control={control}
            inputProps={{
              autoCapitalize: 'none',
              secureTextEntry: true,
              onSubmitEditing: () => {
                handleSubmit((data) => onSubmit(data))()
              },
            }}
            validation={{
              errors,
              rules: {
                required: i18n.t(
                  'ResetPassword.Form.Fields.ConfirmPassword.Errors.Required',
                ),
                validate: {
                  checkPincode: (confirmPassword: string) => {
                    const values = getValues()
                    return (
                      values.password === confirmPassword ||
                      i18n.t(
                        'ResetPassword.Form.Fields.ConfirmPassword.Errors.Equality',
                      )
                    )
                  },
                },
              },
            }}
            label={i18n.t('ResetPassword.Form.Fields.ConfirmPassword.Label')}
          />
        </Content>
      </Container>
      <SubmitHeader onBackPress={() => navigate('login')}>
        <SubmitHeaderButton
          disabled={!formState.isValid}
          isLoading={IsSubmitting}
          title={i18n.t('ChangePassword.Form.Action')}
          onPress={handleSubmit(onSubmit)}
        />
      </SubmitHeader>
    </>
  )
}
export default observer(ResetPassword)
