import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {IUserEventSto} from '../../Models/Server'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  GetUserEvents: () => {
    return new Promise<IUserEventSto[]>((resolve, reject) => {
      const url = 'api/v1/user/tickets'
      axiosAuthUser
        .get(url)
        .then((response: AxiosResponse<IUserEventSto[]>) => {
          if (response.data) {
          }
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
