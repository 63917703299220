import {StackNavigationProp} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Text, Keyboard, Platform, PlatformWebStatic} from 'react-native'
import {
  FormHeader,
  TextButton,
  MyButton,
  MyCheckBox,
  Content,
  MyFooter,
  MyFooterTile,
  Container,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import AppConfig from '../../../Config/AppConfig'
import {goBack} from '../../../Helpers/NavigationHelper'

import {store} from '../Store'
import {myLog} from '../../../Services/Client/LogService'
import {RegisterSteps} from '../Register'
import {RegisterFormData} from '../Store'
import * as Linking from 'expo-linking'
import {appUiStore} from '../../../Mobx/AppUiStore'
import i18n from '../../../i18n'
import {StockImageLayout} from '../../../Components/Web'
import {GetTenantPdfWeb} from '../../../Helpers/UrlHelpers'

interface IProps {
  navigation: StackNavigationProp<RegisterSteps>
}

const TermsStep: React.FC<IProps> = ({navigation: {navigate}}) => {
  const {IsSubmitting} = store

  const {control, handleSubmit, errors, setError, formState} =
    useForm<RegisterFormData>({
      mode: 'onChange',
    })

  useEffect(() => {
    myLog.info('TermsStep')
  }, [store])

  const onSubmit = (data: RegisterFormData) => {
    store.SetFormData('acceptTerms', data.acceptTerms)
    store.RegisterConsumer((errorMessage) => {
      setError('acceptTerms', {
        type: 'manual',
        message: errorMessage,
      })
    })
  }

  const renderPdfLink = () => {
    if (Platform.OS === 'web') {
      return (
        <a
          href={GetTenantPdfWeb()}
          target="_blank"
          style={{color: AppConfig.Tenant.MainColor}}>
          {i18n.t('Register.TermsStep.Field.LinkText', {
            appName: AppConfig.Tenant.AppName,
          })}
        </a>
      )
    }

    return (
      <TextButton
        onPress={() => {
          navigate('termsPdf')
        }}>
        {i18n.t('Register.TermsStep.Field.LinkText', {
          appName: AppConfig.Tenant.AppName,
        })}
      </TextButton>
    )
  }

  return (
    <>
      <Container>
        <StockImageLayout>
          <Content headerMargin>
            <FormHeader>{i18n.t('Register.TermsStep.Header')}</FormHeader>
            <MyCheckBox
              formObject={{} as RegisterFormData}
              name="acceptTerms"
              control={control}
              validation={{
                errors,
                rules: {
                  required: i18n.t('Register.TermsStep.Field.Errors.Required'),
                },
                defaultValue: false,
              }}>
              <Text>
                {i18n.t('Register.TermsStep.Field.Label')}
                {renderPdfLink()}
              </Text>
            </MyCheckBox>
          </Content>
          <SubmitHeader title={i18n.t('Register.Header')} onBackPress={goBack}>
            <SubmitHeaderButton
              disabled={!formState.isValid}
              title={i18n.t('Common.Continue')}
              onPress={handleSubmit(onSubmit)}
            />
          </SubmitHeader>
        </StockImageLayout>
      </Container>
    </>
  )
}

export default observer(TermsStep)
// //simple email regex
// //https://stackoverflow.com/questions/742451/what-is-the-simplest-regular-expression-to-validate-emails-to-not-accept-them-bl
