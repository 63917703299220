import React, {PropsWithChildren, ReactNode, useState} from 'react'
import {TouchableOpacity, View} from 'react-native'
import Style, {colors, sizes} from '../../Style'

import {ErrorMessage} from '@hookform/error-message'
import {Controller} from 'react-hook-form'
import {Icon, ListItem, Text} from 'react-native-elements'
import MyModal from '../Modal/MyModal'
import {MyListItem} from '..'

interface IProps<T> {
  name?: keyof T
  control?: any
  validation?: {
    errors: any
    rules: any
    defaultValue?: string
  }
  formObject?: T

  label: string
  noItemSelectedText?: string
  onSelect: (option: any) => void
  selectedOption?: any
  options: any[]
  isItemSelected: (a: any, b: any) => boolean
  renderListItem: (rowData: any, isHighLighted: boolean) => ReactNode
  modalPosition?: 'top' | 'center' | 'bottom'
}

const MySelectList = <ObjectType,>(
  props: PropsWithChildren<IProps<ObjectType>>,
) => {
  const {
    control,
    children,
    label,
    options,
    onSelect,
    renderListItem,
    selectedOption,
    isItemSelected,
    noItemSelectedText,
    validation,
    name,
    modalPosition,
    ...otherProps
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)

  const {labelStyle, errorMessage} = Style

  const renderRows = (onChange?: (...event: any[]) => void) => {
    if (options.length === 0) {
      return null
    }

    return options.map((option, key) => {
      return (
        <ListItem
          key={key}
          onPress={() => {
            onSelect(option)
            setIsModalVisible(false)
            if (onChange) {
              onChange(option)
            }
          }}
          bottomDivider>
          {renderListItem(option, isItemSelected(selectedOption, option))}
        </ListItem>
      )
    })
  }

  const renderNoSelectedItem = () => {
    if (noItemSelectedText === undefined) {
      return null
    }

    return (
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          paddingVertical: sizes.padding.md,
          borderBottomColor: colors.lightGrey,
          borderBottomWidth: 1,
          marginBottom: sizes.padding.sm,
          alignItems: 'center',
        }}
        onPress={() => {
          setIsModalVisible(true)
        }}>
        <Icon
          name="select-arrows"
          type="entypo"
          iconStyle={{marginRight: sizes.padding.md}}
        />
        <Text>{noItemSelectedText}</Text>
      </TouchableOpacity>
    )
  }

  const renderSelectedItem = () => {
    if (selectedOption === undefined) {
      return renderNoSelectedItem()
    }

    return (
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          paddingVertical: sizes.padding.md,
          borderBottomColor: colors.lightGrey,
          borderBottomWidth: 1,
          marginBottom: sizes.padding.md,
          alignItems: 'center',
        }}
        onPress={() => {
          setIsModalVisible(true)
        }}>
        <Icon
          name="select-arrows"
          type="entypo"
          iconStyle={{marginRight: sizes.padding.md}}
        />
        {renderListItem(selectedOption, false)}
      </TouchableOpacity>
    )
  }

  const renderSelectItemsModal = (onChange?: any) => {
    return (
      <MyModal
        onClose={() => setIsModalVisible(false)}
        closeOnBackPress
        visible={isModalVisible}
        position={modalPosition}
        useParentWidth
        {...otherProps}>
        {renderRows(onChange)}
      </MyModal>
    )
  }

  const renderWithValidation = () => {
    const rules = validation !== undefined ? validation.rules : {}
    const errors = validation !== undefined ? validation.errors : {}

    const myName = name === undefined ? '' : name.toString()

    return (
      <Controller
        control={control}
        name={myName}
        rules={rules}
        //defaultValue={selectedOption}
        render={(
          {onChange, onBlur, value, name, ref},
          {invalid, isTouched, isDirty},
        ) => {
          return (
            <>
              <View style={{paddingTop: sizes.padding.sm}}>
                <Text style={labelStyle}>{label}</Text>
                {renderSelectedItem()}
                <ErrorMessage
                  errors={errors}
                  name={myName}
                  render={({message}) => (
                    <Text
                      style={[errorMessage, {marginLeft: sizes.padding.sm}]}>
                      {message}
                    </Text>
                  )}
                />
              </View>
              {renderSelectItemsModal(onChange)}
              {/* <SelectItemModal
                onClose={() => setIsModalVisible(false)}
                renderRows={() => {
                  return renderRows(onChange)
                }}
                isVisible={isModalVisible}
                {...otherProps}
              /> */}
            </>
          )
        }}
      />
    )
  }

  if (control !== undefined) {
    return renderWithValidation()
  }

  //no validation
  return (
    <>
      <View style={{paddingTop: sizes.padding.sm}}>
        <Text style={labelStyle}>{label}</Text>
        {renderSelectedItem()}
      </View>
      {renderSelectItemsModal()}
    </>
  )
}

export default MySelectList
