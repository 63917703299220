import React from 'react'
import {View} from 'react-native'
import Style from '../../Style'
import {Text, TextProps} from 'react-native-elements'
interface IProps extends TextProps {}

const FormHeader: React.FC<IProps> = ({children, ...otherProps}) => {
  const {formHeader} = Style
  return (
    <Text h1 h1Style={formHeader} {...otherProps}>
      {children}
    </Text>
  )
}

export default FormHeader
