import {
  Dimensions,
  ImageStyle,
  Platform,
  TextStyle,
  ViewStyle,
} from 'react-native'
import AppConfig from '../Config/AppConfig'

export const deviceHeight = Dimensions.get('window').height
export const deviceWidth = Dimensions.get('window').width
//export {default as theme} from './Theme'

export const tabViewLayout = {width: deviceWidth}
export const sizes = {
  padding: {
    sm: 5,
    md: 15,
    lg: 40,
  },
  image: {
    tileImageHeight: 150,
    headerHeight: 250,
  },
  icon: {
    md: 20,
    lg: 40,
    xl: 70,
    xxl: 140,
  },
}

export const defaultFontWeight: TextStyle = {
  fontWeight: '300',
}

export const boldFontWeight: TextStyle = {
  fontWeight: '600',
}

export const openSansLight: TextStyle = {
  fontFamily: Platform.OS === 'ios' ? 'Open Sans' : 'OpenSans-Light',
  fontWeight: Platform.OS === 'ios' ? '300' : 'normal',
}

export const colors = {
  main: AppConfig.Tenant.MainColor,
  containerBg: '#EEEEEC',
  darkGrey: '#555', //'#7B7B7B',
  darkerGrey: '#393939',
  grey: '#808080' /* Item.Label */,
  lighterGrey: '#F8F5F2',
  lightGrey: '#999', //'#cecece' /* password icon */,
  darkBlue: '#122C45' /* topr blue */,
  darkRed: '#B90504',
  lightRed: '#feecf0',
  green: '#cedb00',
  orange: '#f28b00',
  yellow: '#F4E2A0',
}

export const fullWidthHeightAbs: ViewStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
}

const alertIcon: TextStyle = {
  fontSize: 50,
  marginBottom: sizes.padding.md,
}

interface IStyles {
  alignItemsCenter: ViewStyle

  amountStyle: TextStyle
  backgroundImageStyle: ViewStyle
  badgeStyle: ViewStyle

  cardFooter: ImageStyle
  cardFooterColumn: ViewStyle
  centerXY: ViewStyle
  centerXYContainer: ViewStyle
  centerRowY: ViewStyle
  container: ViewStyle
  containerBg: ViewStyle
  content: ViewStyle
  defaultFlatList: ViewStyle
  defaultFlatListWhite: ViewStyle
  dot: ViewStyle
  dotSmall: ViewStyle
  dropShadow: ViewStyle
  errorMessage: TextStyle
  flatListContentStyle: ViewStyle
  flatListContentStyleStock: ViewStyle
  flatListLoader: ViewStyle
  flatListHeader: ViewStyle
  flex1: ViewStyle
  flexEnd: ViewStyle
  flexRow: ViewStyle
  footerBar: ViewStyle
  formFooter: ViewStyle
  formHeader: TextStyle
  formIntro: TextStyle
  fullFooterText: TextStyle
  fullWidthHeightAbs: ViewStyle
  highlightText: TextStyle
  justifySpaceBetween: ViewStyle
  labelStyle: TextStyle
  inputContainer: ViewStyle
  imageHeaderBackdrop: ViewStyle
  imageHeaderTitle: TextStyle
  imageHeaderSubTitle: TextStyle
  modalBackdrop: ViewStyle
  modalCard: ViewStyle
  modalMessage: TextStyle
  myLoader: ViewStyle
  noteText: TextStyle
  pickerItemImage: ImageStyle
  pickerRow: ViewStyle
  pickerText: TextStyle
  pincodeStyle: TextStyle
  price: TextStyle
  profileColorButton: ViewStyle
  profilePictureImage: ImageStyle
  rightContainer: ViewStyle
  stampIcon: TextStyle
  stampsContainer: ViewStyle
  subTitle: TextStyle
  tabButtonStyle: ViewStyle
  tabBarLabelStyle: TextStyle
  textWhite: TextStyle
  textNote: TextStyle
  textCenter: TextStyle
  textRowContainer: ViewStyle
  title: TextStyle
  toprLoader: ViewStyle
  transactionRow: ViewStyle
}

const styles: IStyles = {
  alignItemsCenter: {
    alignItems: 'center',
  },

  amountStyle: {
    color: colors.main,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  backgroundImageStyle: {...fullWidthHeightAbs, opacity: 0.2},
  badgeStyle: {
    backgroundColor: colors.main,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 5,
    height: 'auto',
    borderWidth: 0,
  },
  tabButtonStyle: {
    backgroundColor: '#fff',
    borderBottomColor: colors.lightGrey,
    borderBottomWidth: 1,
  },
  cardFooter: {
    flexDirection: 'row',
    paddingHorizontal: sizes.padding.md,
    //paddingBottom: sizes.padding.md,
    alignItems: 'center',
  },
  cardFooterColumn: {
    //flexDirection: 'column',
    padding: sizes.padding.md,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  centerXY: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerXYContainer: {flex: 1, alignItems: 'center', justifyContent: 'center'},
  centerRowY: {
    alignItems: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  containerBg: {
    backgroundColor: colors.containerBg,
  },
  content: {
    flex: Platform.OS === 'web' ? 1 : undefined,
    paddingHorizontal: sizes.padding.md,
    paddingTop: sizes.padding.md,
    backgroundColor: '#fff',
  },
  dropShadow: {
    elevation: 5, // Android
    shadowColor: '#030002', // Android, iOS & Web
    shadowOpacity: 0.25, // iOS & Web
    shadowRadius: 5, // iOS & web
  },
  defaultFlatList: {
    flex: 1,
    backgroundColor: colors.containerBg,
    //padding: sizes.padding.sm,
  },
  defaultFlatListWhite: {
    flex: 1,
    backgroundColor: '#fff',
  },
  dot: {
    backgroundColor: '#fff',
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  dotSmall: {
    backgroundColor: '#fff',
    width: 15,
    height: 15,
    borderRadius: 10,
    marginHorizontal: 10,
  },
  errorMessage: {
    fontSize: 12,
    color: 'red',
  },

  flatListContentStyle: {
    paddingBottom: 120,
  },
  flatListContentStyleStock: {
    paddingBottom: 20,
    height: Platform.OS === 'web' ? 10 : 'auto', //token, locations,couponsummary,savingcardsummary
  },
  flatListLoader: {
    alignItems: 'center',
    paddingVertical: sizes.padding.md,
  },
  flatListHeader: {
    backgroundColor: colors.main,
    paddingHorizontal: sizes.padding.md,
    paddingVertical: sizes.padding.md,
    borderBottomColor: colors.lightGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flex1: {
    flex: 1,
  },

  flexEnd: {alignItems: 'flex-end', flex: 1},
  flexRow: {
    flexDirection: 'row',
  },
  footerBar: {
    borderTopColor: '#000',
    borderTopWidth: 1,
    backgroundColor: '#fff',
    flexDirection: 'row',
    paddingVertical: sizes.padding.sm,
  },

  formFooter: {
    marginTop: sizes.padding.lg,
  },
  formHeader: {
    color: colors.main,
    fontWeight: '300',
    marginTop: sizes.padding.md,
    marginBottom: sizes.padding.lg,
    ...openSansLight,
  },
  formIntro: {
    marginBottom: sizes.padding.md,
    fontSize: 20,
    color: colors.darkerGrey,
    ...openSansLight,
  },
  fullFooterText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  fullWidthHeightAbs: {
    ...fullWidthHeightAbs,
  },
  highlightText: {
    fontSize: 16,
    color: colors.main,
    ...openSansLight,
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  labelStyle: {
    color: colors.darkGrey,
    fontWeight: '300',
    marginBottom: sizes.padding.sm,
  },
  inputContainer: {
    borderBottomColor: colors.lightGrey,
    paddingHorizontal: 0,
  },
  imageHeaderBackdrop: {
    ...fullWidthHeightAbs,
    backgroundColor: 'rgba(0,0,0,0.4)',
    //backgroundColor:'red',
  },
  imageHeaderTitle: {
    //marginVertical: 20,
    color: '#fff',
    fontSize: 32,
    paddingHorizontal: sizes.padding.md,
    marginBottom: sizes.padding.sm,
    textAlign: 'center',
    //backgroundColor: 'red',
    ...openSansLight,
  },
  imageHeaderSubTitle: {
    color: '#fff',
    fontSize: 24,
    ...openSansLight,
  },
  modalBackdrop: {
    ...fullWidthHeightAbs,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  modalCard: {
    //select width wrong, automatic charge
    //width: Platform.OS === 'web' ? 640 : deviceWidth - 2 * sizes.padding.md,
    width: Platform.OS === 'web' ? 640 : deviceWidth - 2 * sizes.padding.md,
    //maxWidth: deviceWidth - 2 * sizes.padding.md,
    //minWidth: '80%',
    marginBottom: sizes.padding.md,
    padding: sizes.padding.md,
  },
  modalMessage: {
    textAlign: 'center',
    //marginBottom: sizes.padding.md,
  },

  myLoader: {
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  noteText: {
    color: '#aaa',
    fontSize: 12,
  },
  pickerItemImage: {
    resizeMode: 'contain',
    height: 40,
    width: 40,
  },
  pickerRow: {flexDirection: 'row', alignItems: 'center'},
  pickerText: {marginLeft: sizes.padding.md},
  pincodeStyle: {
    fontSize: 50,
    height: 100,
  },
  price: {
    fontWeight: 'bold',
    fontSize: 20,
    color: colors.main,
  },
  profileColorButton: {
    backgroundColor: colors.main,
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
  },
  profilePictureImage: {
    backgroundColor: colors.main,
    height: 40,
    width: 40,
    borderRadius: 30,
  },
  rightContainer: {
    position: 'absolute',
    top: sizes.padding.md,
    right: sizes.padding.md,
    bottom: sizes.padding.md,
  },
  stampsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  stampIcon: {
    fontSize: 40,
    //marginBottom: sizes.padding.md,
  },
  subTitle: {
    fontStyle: 'italic',
  },
  tabBarLabelStyle: defaultFontWeight,
  textNote: {
    color: colors.lightGrey,
  },
  textWhite: {
    color: '#fff',
  },
  textCenter: {textAlign: 'center'},
  textRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 17,
  },

  toprLoader: {
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingBottom: 8,
  },
  transactionRow: {
    flexDirection: 'row',
    paddingBottom: sizes.padding.md,
    paddingHorizontal: sizes.padding.md,
    marginTop: sizes.padding.md,

    borderBottomColor: colors.lightGrey,
    borderBottomWidth: 1,
  },
}

export default styles
