import {observer} from 'mobx-react'
import React from 'react'
import {Text} from 'react-native'
import {SubmitHeader, SubmitHeaderButton} from '../../../../Components'
import {goBack} from '../../../../Helpers/NavigationHelper'
import i18n from '../../../../i18n'
import {sendMoneyStore} from '../Store'

interface IProps {
  currencyName: string
}

const SendMoneyHeader: React.FC<IProps> = ({currencyName}) => {
  const {SelectedTabIndex, IsSubmitting, FoundUser} = sendMoneyStore

  const backAction = () => {
    if (SelectedTabIndex === 1 && FoundUser !== undefined) {
      sendMoneyStore.ClearFoundUser()
      return
    }

    goBack()
    return
  }

  // const renderSubmitButton = () => {
  //   //TODO:fix
  //   if (SelectedTabIndex === 0 || FoundUser !== undefined) {
  //     return (
  //       <SubmitHeaderButton
  //         isLoading={IsSubmitting}
  //         //disabled={Amount === 0}
  //         onPress={() => sendMoneyStore.ConfirmSubmit(-1)}
  //         title={i18n.t('SendMoney.Form.Action')}
  //       />
  //     )
  //   }

  //   return null
  // }

  return (
    <SubmitHeader onBackPress={backAction}>
      <Text>{currencyName}</Text>
      {/* {renderSubmitButton()} */}
    </SubmitHeader>
  )
}

export default observer(SendMoneyHeader)
