import React from 'react'
import {TouchableOpacity, View, Image} from 'react-native'
import {Badge, Card, Icon, Text} from 'react-native-elements'

import {FormatCurrency, FormatDistance} from '../../Helpers/TextHelpers'
import {GetFileUrl, GetLocalizedImageUrl} from '../../Helpers/UiHelpers'
import {IGroupedCoupon, ILocationSTO, IWalletSTO} from '../../Models/Server'
import Style, {sizes} from '../../Style'
import {colors} from '../../Style/index'

interface IProps {
  imageUrl: string
  onPress?: () => void
}
const ImageButton: React.FC<IProps> = ({imageUrl, onPress, children}) => {
  const {cardFooter, flexRow, flex1} = Style

  const renderCard = () => {
    return (
      <View
        style={{
          marginTop: sizes.padding.md,
          marginHorizontal: sizes.padding.md,
          flexDirection: 'row',
          flex: 1,
          backgroundColor: '#fff',
          borderWidth: 1,
          borderColor: '#E5E5E5',
        }}>
        <Image
          source={{
            uri: imageUrl,
          }}
          style={{width: 100, height: 100}}
        />
        <Card.Divider />
        <View style={[flexRow, {flex: 1}]}>
          <View style={[cardFooter, flex1]}>
            <Text>{children}</Text>
          </View>
          {onPress && (
            <View style={[cardFooter]}>
              <Icon name="chevron-thin-right" type="entypo" />
            </View>
          )}
        </View>
      </View>
    )
  }

  if (onPress) {
    return <TouchableOpacity onPress={onPress}>{renderCard()}</TouchableOpacity>
  }

  return renderCard()
}

export default ImageButton
