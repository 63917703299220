import React from 'react'
import {IsWebAndMobileOrApp} from '../../../Helpers/WebHelpers'
import Dashboard from './Dashboard'
import DashboardWeb from './DashboardWeb'

interface IProps {}

const index: React.FC<IProps> = () => {
  if (IsWebAndMobileOrApp()) {
    return <Dashboard />
  }
  return <DashboardWeb />
}

export default index
