import React from 'react'
import {StyleSheet, TouchableOpacity} from 'react-native'
import {Icon, Text} from 'react-native-elements'
import ToprConfig from '../../Config/Tenants/Topr/ToprConfig'
import Style from '../../Style'
interface IProps {
  icon: {name: string; type: string}
  onPress: () => void
  text?: string
}

const FooterBarButton: React.FC<IProps> = ({onPress, icon, text}) => {
  const {dropShadow} = Style
  return (
    <TouchableOpacity
      style={[
        dropShadow,
        {
          width: 85,
          height: 85,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fff',
          borderRadius: 50,
          borderColor: ToprConfig.MainColor,
          borderWidth: 1,
          elevation: 7,
        },
      ]}
      onPress={onPress}>
      <Icon {...icon} color={ToprConfig.MainColorDark} />
      {text && (
        <Text style={{color: ToprConfig.MainColorDark, fontSize: 11}}>
          {text}
        </Text>
      )}
    </TouchableOpacity>
  )
}

export default FooterBarButton
