import {
  CardStyleInterpolators,
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {GetTenantPagetitle} from '../../Helpers/TextHelpers'
import i18n from '../../i18n'
import {MainScreensList} from '../../Navigation'
import {myLog} from '../../Services/Client/LogService'
import {
  ConfirmationSentStep,
  EmailStep,
  NameStep,
  PasswordStep,
  TermsPdf,
  TermsStep,
} from './Components'
import {store} from './Store'

interface IProps extends StackScreenProps<MainScreensList> {}

export type RegisterSteps = {
  name: undefined
  email: undefined
  password: undefined
  terms: undefined
  termsPdf: undefined
  confirmationSentStep: undefined
}

const RegisterStack = createStackNavigator<RegisterSteps>()

const Register: React.FC<IProps> = ({navigation: {goBack}}) => {
  useEffect(() => {
    myLog.info('Register')
    store.Initialize()
  }, [store])
  const {ConfirmationSent} = store

  return (
    <RegisterStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        headerShown: false,
      }}>
      {ConfirmationSent ? (
        <RegisterStack.Screen
          name="confirmationSentStep"
          component={ConfirmationSentStep}
        />
      ) : (
        <>
          <RegisterStack.Screen
            name="name"
            component={NameStep}
            options={{
              title: GetTenantPagetitle(i18n.t('Register.Form.Action')),
            }}
          />
          <RegisterStack.Screen
            name="email"
            component={EmailStep}
            options={{
              title: GetTenantPagetitle(i18n.t('Register.Form.Action')),
            }}
          />
          <RegisterStack.Screen
            name="password"
            component={PasswordStep}
            options={{
              title: GetTenantPagetitle(i18n.t('Register.Form.Action')),
            }}
          />
          <RegisterStack.Screen
            name="terms"
            component={TermsStep}
            options={{
              title: GetTenantPagetitle(i18n.t('Register.Form.Action')),
            }}
          />
          <RegisterStack.Screen
            name="termsPdf"
            component={TermsPdf}
            options={{
              title: GetTenantPagetitle(i18n.t('Register.Form.Action')),
            }}
          />
        </>
      )}
    </RegisterStack.Navigator>
  )
}

export default observer(Register)
