import {observer} from 'mobx-react'
import React from 'react'
import {
  Image,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  View,
} from 'react-native'
import {GetFileUrl} from '../../Helpers/UiHelpers'
import {IUserSTO} from '../../Models/Server'
import {dashboardStore} from '../../Screens/Dashboard/Dashboard/Store'
import Style from '../../Style'
import {GetFirstLetterUser} from '../../Helpers/TextHelpers'
interface IProps extends TouchableWithoutFeedbackProps {
  user: IUserSTO | undefined
}

const ProfileButton: React.FC<IProps> = ({user, ...otherProps}) => {
  const {profileColorButton, profilePictureImage} = Style

  const renderProfile = () => {
    if (user === undefined) {
      return null
    }

    if (user.UserPictureFileGuid) {
      return renderImage()
    } else {
      return renderFirstLetter()
    }
  }

  const renderImage = () => {
    if (user !== undefined && user.UserPictureFileGuid === null) {
      return null
    }

    return (
      <img
        src={GetFileUrl(user?.UserPictureFileGuid?.toString(), true)}
        className="profilePictureImage"
      />
    )
  }

  const renderFirstLetter = () => {
    const letter =
      user?.Name === undefined || user?.Name === null || user?.Name === ''
        ? 'P'
        : user?.Name[0]
    return (
      <View style={profileColorButton}>
        <Text style={{color: '#fff', textTransform: 'uppercase'}}>
          {GetFirstLetterUser(user)}
        </Text>
      </View>
    )
  }

  return <TouchableOpacity {...otherProps}>{renderProfile()}</TouchableOpacity>
}

export default observer(ProfileButton)
