import axios, {AxiosError} from 'axios'
import AppConfig from '../../Config/AppConfig'
import {HandleAxiosCatch, Json2Url} from '../../Helpers/ServiceHelpers'
import {primaryLanguage} from '../../i18n'
import {persistedStore} from '../../Mobx'
import {ToprError} from '../../Models/Client'
import AuthenticationController from './AuthenticationController'
import * as Linking from 'expo-linking'
import {AppLinkTypes} from '../../Models/Client/Enums'
import {CreateAppLinkUrl} from '../../Helpers/UrlHelpers'
import {axiosAuthClient} from './Axios/AxiosAuthClient'

export default {
  RegisterConsumer: (
    name: string,
    email: string,
    password: string,
    confirmPassword: string,
  ) => {
    const data = {
      Name: name,
      Email: email,
      Password: password,
      ConfirmPassword: confirmPassword,
      RegisterConfirmUrl: CreateAppLinkUrl(AppLinkTypes.RegisterConfirm),
    }

    return new Promise<void>((resolve, reject) => {
      axiosAuthClient
        .post(`api/v2/register/consumer`, data)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  RegisterConfirmConsumer: (userGuid: string) => {
    const data = {
      UserGuid: userGuid,
    }
    const queryStringParams = Json2Url(data)

    return new Promise<void>((resolve, reject) => {
      axiosAuthClient
        .post(`api/v1/register/consumer/confirm?${queryStringParams}`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  EmailExists: (email: string) => {
    const data = {
      Email: email,
    }
    const queryStringParams = Json2Url(data)

    return new Promise<void>((resolve, reject) => {
      const url = `api/v1/register/emailexists?${queryStringParams}`
      axiosAuthClient
        .get(url)
        .then(() => {
          reject(new ToprError('USER_EMAIL_NOT_UNIQUE'))
        })
        .catch((error: AxiosError) => {
          const status =
            error.response !== undefined ? error.response.status : 400

          switch (status) {
            case 404:
              //not found, so it is valid email
              resolve()
              break
            default:
              HandleAxiosCatch(error, reject)
              break
          }
        })
    })
  },
}
