import {Theme} from 'react-native-elements'
import AppConfig from '../Config/AppConfig'
import {defaultFontWeight} from './index'

const theme: Theme = {
  colors: {
    primary: AppConfig.Tenant.MainColor,
  },
  Button: {
    titleStyle: {...defaultFontWeight},
  },
  Text: {
    style: defaultFontWeight,
  },
  Badge: {
    textStyle: defaultFontWeight,
  },
  Input: {
    inputStyle: defaultFontWeight,
  },
}

export default theme
