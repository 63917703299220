import {observer} from 'mobx-react'
import React from 'react'
import {ImageCard} from '../../../../Components'
import UserSavingsCardWeb from '../../../../Components/Cards/UserSavingsCardWeb'
import {HorizontalScroll} from '../../../../Components/Web'
import {navigate} from '../../../../Helpers/NavigationHelper'
import i18n from '../../../../i18n'
import {dashboardStore} from '../../Dashboard/Store.web'
import ToprRowLoader from './ToprRowLoader.web'

interface IProps {}

const SavingCardsScroll: React.FC<IProps> = ({children}) => {
  const {UserSavingCards, IsSavingcardsLoading} = dashboardStore

  const renderNoSavingCards = () => {
    if (UserSavingCards.length > 0) {
      return null
    }

    return (
      <ImageCard
        imageUrl="https://images.unsplash.com/photo-1565165928601-867958a20fdd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
        text={i18n.t('Dashboard.NoSavingCards')}
      />
    )
  }

  const renderCards = () => {
    return UserSavingCards.map((userSavingCard, key) => (
      <UserSavingsCardWeb
        key={key}
        onPress={() => {
          navigate('savingCardDetails', {
            userSavingCardGuid: userSavingCard.UserSavingCardGuid,
          })
        }}
        userSavingCard={userSavingCard}
      />
    ))
  }

  if (IsSavingcardsLoading) {
    return <ToprRowLoader />
  }

  return (
    <>
      <h1 className="title is-4 pt-4">{i18n.t('Common.SavingCards')}</h1>
      <HorizontalScroll>
        {renderCards()}
        {renderNoSavingCards()}
      </HorizontalScroll>
    </>
  )
}

export default observer(SavingCardsScroll)
