import React from 'react'
import {Image, ImageURISource, View} from 'react-native'
import {Icon, Text} from 'react-native-elements'
import Style, {sizes, colors} from '../../Style'
interface IProps {
  //imageUrl: string
  imageSource: ImageURISource
  onBackPress: () => void
}

const ImageHeader: React.FC<IProps> = ({
  imageSource,
  onBackPress,
  children,
}) => {
  const {imageHeaderBackdrop} = Style
  return (
    <View>
      <Image
        source={imageSource}
        style={{height: sizes.image.headerHeight, width: 'auto'}}
      />
      <View style={[imageHeaderBackdrop]}>
        <View
          style={{
            paddingTop: sizes.padding.md,
          }}>
          <Icon
            name="arrow-left"
            type="feather"
            color="#fff"
            containerStyle={{
              width: 50,
              height: 50,
            }}
            onPress={onBackPress}
          />
        </View>
        <View
          style={{
            flex: 1,
            //justifyContent: 'space-evenly',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {children}
        </View>
      </View>
    </View>
  )
}

export default ImageHeader
