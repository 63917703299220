import {observer} from 'mobx-react'
import React from 'react'
import {View, TouchableOpacity} from 'react-native'
import {
  FooterBar,
  FooterBarButton,
  FooterBarIconButton,
} from '../../../Components'
import {navigate} from '../../../Helpers/NavigationHelper'
import i18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {DeliveryType} from '../../../Models/InzziiApi/Enums'
import {orderOnlineStore} from '../../Location/OrderOnline/Store'
import {dashboardStore} from '../Dashboard/Store'
import ToprConfig from '../../../Config/Tenants/Topr/ToprConfig'
import {sizes} from '../../../Style'
import {Text, Icon} from 'react-native-elements'
interface IProps {}

const DashboardFooter: React.FC<IProps> = ({children}) => {
  return (
    <FooterBar iconButtonLeft={false}>
      <FooterBarButton
        icon={{name: 'qrcode', type: 'font-awesome'}}
        text={i18n.t('Common.Pay')}
        onPress={() => navigate('qrCode')}
      />
      <FooterBarIconButton
        icon={{name: 'plus', type: 'ant-design'}}
        onPress={() => {
          dashboardStore.ToggleAddMenu(true)
        }}
      />
      <FooterBarButton
        icon={{name: 'scan', type: 'ionicon'}}
        text={i18n.t('Common.ToOrder')}
        onPress={() => {
          navigate('scanner' as any)
        }}
      />
    </FooterBar>
  )
}

export default observer(DashboardFooter)
