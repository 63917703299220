import {observer} from 'mobx-react'
import React, {useState, useRef, useEffect} from 'react'

import {
  Animated,
  ScrollView,
  SectionList,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from 'react-native'
import {Icon, Input, Text} from 'react-native-elements'
import {TouchableOpacity} from 'react-native-gesture-handler'
import i18n from '../../../../../i18n'
import {colors, sizes} from '../../../../../Style'
import {orderOnlineStore} from '../../Store'

interface IProps {
  departments: string[]
  sectionListRef: React.RefObject<SectionList>
  departmentScrollViewRef: React.RefObject<ScrollView>
}

const CategorySelector: React.FC<IProps> = ({
  departments,
  sectionListRef,
  departmentScrollViewRef,
}) => {
  const {
    ScrollY,
    HEADER_MAX_HEIGHT,
    TABBAR_HEIGHT,
    VisibleDepartmentName,
    Search,
    SearchMode,
  } = orderOnlineStore

  const textInputRef = useRef<TextInput>(null)

  useEffect(() => {
    //console.log('effect', textInputRef.current === null)
  }, [textInputRef])

  // const [searchMode, toggleSearchMode] = useState(false)

  const headerTranslateY = SearchMode
    ? 0
    : ScrollY.interpolate({
        inputRange: [0, HEADER_MAX_HEIGHT],
        outputRange: [0, -HEADER_MAX_HEIGHT],
        extrapolate: 'clamp',
      })
  const {
    container,
    defaultContainer,
    activeContainer,
    searchBarContainer,
    searchButton,
    scrollViewBar,
    textInput,
  } = styles

  const scrollSectionList = (sectionIndex: number) => {
    if (sectionListRef.current) {
      sectionListRef.current.scrollToLocation({
        animated: false,
        sectionIndex,
        itemIndex: 0,
        viewOffset: 55,
      })
    }
  }

  const renderButtons = () => {
    return departments.map((departmentName, key) => {
      let isActive = departmentName === VisibleDepartmentName

      const containerStyle: ViewStyle[] = [defaultContainer]
      if (isActive) {
        containerStyle.push(activeContainer)
      }

      return (
        <View
          key={key}
          onLayout={(event) => {
            orderOnlineStore.SetDepartmentScroll(
              departmentName,
              event.nativeEvent.layout.x,
            )
          }}>
          <TouchableOpacity
            style={containerStyle}
            onPress={() => {
              scrollSectionList(key)
            }}>
            <Text style={{color: isActive ? '#fff' : '#000'}}>
              {departmentName}
            </Text>
          </TouchableOpacity>
        </View>
      )
    })
  }

  const renderScrollViewBar = () => {
    if (SearchMode) {
      return null
    }

    return (
      <View style={scrollViewBar}>
        <Icon
          name="magnifying-glass"
          type="entypo"
          iconStyle={searchButton}
          onPress={() => {
            scrollSectionList(0)
            orderOnlineStore.ToggleSearchMode(true)

            //rist render textinput, after 100 ms set focus, otherwise textInputRef.current === null
            setTimeout(() => {
              textInputRef.current?.focus()
            }, 100)
          }}
        />
        <ScrollView ref={departmentScrollViewRef} horizontal={true}>
          {renderButtons()}
        </ScrollView>
      </View>
    )
  }

  const renderSearchBar = () => {
    if (!SearchMode) {
      return null
    }

    return (
      <View style={searchBarContainer}>
        <Icon
          name="arrow-left"
          type="feather"
          iconStyle={searchButton}
          onPress={() => {
            orderOnlineStore.ToggleSearchMode(false)
            orderOnlineStore.SetSearch('')
            //clear search
          }}
        />
        <TextInput
          ref={textInputRef}
          placeholder={i18n.t('OrderOnline.SearchProduct')}
          onChangeText={(e) => orderOnlineStore.SetSearch(e)}
          value={Search}
          style={textInput}
        />
      </View>
    )
  }

  return (
    <Animated.View
      style={[
        container,
        {
          transform: [{translateY: headerTranslateY}],
          top: SearchMode ? 0 : HEADER_MAX_HEIGHT,
        },
      ]}>
      {renderScrollViewBar()}
      {renderSearchBar()}
    </Animated.View>
  )
}

export default observer(CategorySelector)

const styles = StyleSheet.create({
  defaultContainer: {
    marginRight: sizes.padding.sm,
    paddingVertical: sizes.padding.sm,
    paddingHorizontal: sizes.padding.md,
    marginVertical: sizes.padding.sm,
  },
  activeContainer: {
    backgroundColor: colors.main,
    borderRadius: 10,
  },
  searchBarContainer: {
    height: 55,
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: 7,
    backgroundColor: '#fff',
  },
  scrollViewBar: {
    backgroundColor: colors.lighterGrey,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 7,
    paddingRight: 7,
  },
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  searchButton: {
    paddingHorizontal: sizes.padding.md,
  },
  textInput: {
    fontSize: 20,
    //color: colors.grey,
  },
})
