import axios, {AxiosError, AxiosResponse} from 'axios'
import {toJS} from 'mobx'
import AppConfig from '../../Config/AppConfig'
import {GetTotalOrderAmount} from '../../Helpers/OrderHelpers'
import {HandleAxiosCatch, HandleInziiCatch} from '../../Helpers/ServiceHelpers'
import {
  IIPaymentMethod,
  IMyOrderRequestLine,
  IOrderResult,
  IRestaurant,
} from '../../Models/InzziiApi'
import {DeliveryType} from '../../Models/InzziiApi/Enums'

export default {
  GetRestaurant: (branchofficeId: string) => {
    return new Promise<IRestaurant>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/Restaurant/restaurant/${branchofficeId}`
      axios
        .get(url, {})
        .then((response: AxiosResponse<IRestaurant>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleInziiCatch(error, reject)
        })
    })
  },
  GetPaymentMethods: (branchofficeId: string, type: DeliveryType) => {
    return new Promise<IIPaymentMethod[]>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/Restaurant/${branchofficeId}/paymentmethods?type=${type}`
      axios
        .get(url, {})
        .then((response: AxiosResponse<IIPaymentMethod[]>) => {
          //hack: so we don;t have to change the Topr/SiedlerKarte name in myarantek
          response.data.forEach((x) => {
            if (x.description === 'SiedlerKarte') {
              x.description = 'Topr Wallet'
            }
          })
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleInziiCatch(error, reject)
        })
    })
  },
  ///Order/{branchofficeId}/TableOrder
  //const bearerAuthorization = `Bearer ${userToken.AccessToken}`
  TableOrder: (
    accessToken: string | undefined,
    branchofficeId: string,
    tableNumber: string,
    orderLines: IMyOrderRequestLine[],
    paymentGateway: number,
    comment: string,
  ) => {
    return new Promise<IOrderResult>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/Order/${branchofficeId}/TableOrder`

      const bearerAuthorization = `Bearer ${accessToken}`
      const headers = {
        'Content-type': 'application/json',
        Authorization: bearerAuthorization,
      }
      const data = {
        orderDateTime: new Date().toISOString(),
        orderLines,
        comment,
        totalAmount: GetTotalOrderAmount(orderLines),
        paymentGateway,
        tableNumber,
      }

      console.log('orderLines', toJS(orderLines))
      console.log('tableOrderData', toJS(data))

      axios
        .post(url, data, {
          headers,
        })
        .then((response: AxiosResponse<IOrderResult>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleInziiCatch(error, reject)
        })
    })
  },

  CompletePayment: (accessToken: string, orderId: string) => {
    return new Promise<IOrderResult>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/TopR/CompletePayment/${orderId}`

      const bearerAuthorization = `Bearer ${accessToken}`
      const headers = {
        'Content-type': 'application/json',
        Authorization: bearerAuthorization,
      }

      axios
        .post(url, null, {
          headers,
        })
        .then((response: AxiosResponse<IOrderResult>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleInziiCatch(error, reject)
        })
    })
  },

  ///
}
