import React from 'react'
import { navigate } from '../../../Helpers/NavigationHelper'
import { IRegisterLoginViewModel } from '../../../Services/Topr.Umbraco/Models'
interface IProps {
  data: IRegisterLoginViewModel
}

const RegisterLogin: React.FC<IProps> = ({data}) => {
  return (
    <section className="section register-login">
      <h1 className="title">{data.Title}</h1>
      <h5 className="subtitle">{data.Subtitle}</h5>
      <button
        className="button is-primary is-medium"
        onClick={() => navigate('register')}>
        {data.RegisterText}
      </button>

      <button className="button is-text" onClick={() => navigate('login')}>
        {data.LogonText}
      </button>
    </section>
  )
}

export default RegisterLogin
