import {makeAutoObservable, runInAction} from 'mobx'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {ITokenSTO, IUserSTO} from '../../../Models/Server'
import {myLog} from '../../../Services/Client/LogService'
import I18n from '../../../i18n'
import TokenController from '../../../Services/Topr.Core/TokenController'
import {ToprError} from '../../../Models/Client'
import {appUiStore} from '../../../Mobx/AppUiStore'
import i18n from '../../../i18n'
import {sortTokens} from '../../../Helpers/TokenHelpers'

class Store {
  public IsTokensLoading = true
  public TokenMenuIsVisible = false
  public OtherUserModalIsVisible = false
  public SetDescriptionTokenModalIsVisible = false
  public IsSubmitting = false
  public Tokens: ITokenSTO[] = []

  public SelectedToken?: ITokenSTO = undefined
  public LoadingTokenTokenGUID = ''

  constructor() {
    makeAutoObservable(this)
  }

  public GetTokens() {
    this.IsTokensLoading = true
    TokenController.GetTokens()
      .then((tokens) => {
        runInAction(() => {
          this.Tokens = sortTokens(tokens)
          this.IsTokensLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsTokensLoading = false
        })
      })
  }

  public Block() {
    if (this.SelectedToken) {
      this.LoadingTokenTokenGUID = this.SelectedToken.TokenGUID
      const activationCode = this.SelectedToken.ActivationCode
      this.HideTokenMenu()

      TokenController.Block(this.LoadingTokenTokenGUID)
        .then(() => {
          runInAction(() => {
            appUiStore.ShowToast({
              message: i18n.t('Tokens.TokenMenu.BlockTokenAction.Succes', {
                activationCode,
              }),
            })
            const blockedToken = this.Tokens.find(
              (x) => x.TokenGUID === this.LoadingTokenTokenGUID,
            )
            if (blockedToken) {
              blockedToken.IsBlocked = true
            }
            this.ClearSelectedToken()
          })
        })
        .catch((toprError: ToprError) => {
          runInAction(() => {
            this.ClearSelectedToken()
          })

          SetFriendlyMessage(toprError, (message) => {
            appUiStore.ShowToast({message})
          })
        })
    }
  }

  public Unblock() {
    if (this.SelectedToken) {
      this.LoadingTokenTokenGUID = this.SelectedToken.TokenGUID
      const activationCode = this.SelectedToken.ActivationCode
      this.HideTokenMenu()

      TokenController.Unblock(this.LoadingTokenTokenGUID)
        .then(() => {
          runInAction(() => {
            appUiStore.ShowToast({
              message: i18n.t('Tokens.TokenMenu.UnblockTokenAction.Succes', {
                activationCode,
              }),
            })
            const unblockedToken = this.Tokens.find(
              (x) => x.TokenGUID === this.LoadingTokenTokenGUID,
            )
            if (unblockedToken) {
              unblockedToken.IsBlocked = false
            }
            this.ClearSelectedToken()
          })
        })
        .catch((toprError: ToprError) => {
          runInAction(() => {
            this.ClearSelectedToken()
          })

          SetFriendlyMessage(toprError, (message) => {
            appUiStore.ShowToast({message})
          })
        })
    }
  }

  public TokenSwitchUser(user: IUserSTO) {
    if (this.SelectedToken) {
      this.LoadingTokenTokenGUID = this.SelectedToken.TokenGUID
      const activationCode = this.SelectedToken.ActivationCode
      this.HideTokenMenu()

      TokenController.SwitchTokenUser(
        this.LoadingTokenTokenGUID,
        user.MainUserGuid,
      )
        .then(() => {
          appUiStore.ShowToast({
            message: i18n.t('Tokens.TokenMenu.SwitchUserAction.Succes', {
              activationCode,
              name: user.Name,
            }),
          })

          runInAction(() => {
            this.SelectedToken = undefined
            const index = this.Tokens.findIndex(
              (x) => x.TokenGUID === this.LoadingTokenTokenGUID,
            )
            if (index > -1) {
              this.Tokens.splice(index, 1)
            }
            this.ClearSelectedToken()
          })
        })
        .catch((toprError: ToprError) => {
          runInAction(() => {
            this.ClearSelectedToken()
          })

          SetFriendlyMessage(toprError, (message) => {
            appUiStore.ShowToast({message})
          })
        })
    }
  }

  public ShowTokenMenu(token: ITokenSTO) {
    this.SelectedToken = token
    this.TokenMenuIsVisible = true
  }

  public HideTokenMenu() {
    this.TokenMenuIsVisible = false
  }

  private ClearSelectedToken() {
    this.SelectedToken = undefined
    this.LoadingTokenTokenGUID = ''
  }

  public ToggleOtherUserModalIsVisible() {
    if (!this.OtherUserModalIsVisible) {
      this.TokenMenuIsVisible = false
    }
    this.OtherUserModalIsVisible = !this.OtherUserModalIsVisible
  }

  public ToggleSetDescriptionTokenModalIsVisible() {
    if (!this.SetDescriptionTokenModalIsVisible) {
      this.TokenMenuIsVisible = false
    }
    this.SetDescriptionTokenModalIsVisible =
      !this.SetDescriptionTokenModalIsVisible
  }

  public SetDescription(description: string) {
    if (this.SelectedToken) {
      TokenController.SetDescription(this.SelectedToken.TokenGUID, description)
        .then(() => {
          runInAction(() => {
            this.HideTokenMenu()
            const token = this.Tokens.find(
              (x) => x.TokenGUID === this.SelectedToken?.TokenGUID,
            )
            if (token) {
              token.Description = description
            }
            this.ClearSelectedToken()
            this.ToggleSetDescriptionTokenModalIsVisible()
          })
        })
        .catch((toprError: ToprError) => {
          runInAction(() => {
            this.ClearSelectedToken()
          })
          SetFriendlyMessage(toprError, (message) => {
            appUiStore.ShowToast({message})
          })
          this.ToggleSetDescriptionTokenModalIsVisible()
        })
    }
  }
}
export const store = new Store()
