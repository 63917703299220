import axios, {AxiosError, AxiosResponse} from 'axios'
import AppConfig from '../../Config/AppConfig'
import {GetExpirationDateInMs} from '../../Helpers/DateHelpers'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'

import {primaryLanguage} from '../../i18n'
import {persistedStore} from '../../Mobx'
import {
  ClientToken,
  IAuthenticateClientResponse,
  IAuthenticateUserResponse,
} from '../../Models/Server'
import {axiosProxy} from './Axios/AxiosProxy'

const controller = {
  AuthenticateConsumerUser: (username: string, password: string) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        ClientSecret: AppConfig.Service.Secret,
        UserName: username,
        Password: password,
      }

      axiosProxy
        .post(
          `api/v1/authenticate/${AppConfig.Service.ClientId}/consumer/`,
          data,
        )

        .then((response: AxiosResponse<IAuthenticateUserResponse>) => {
          persistedStore.SetParentUserToken(response.data)
          resolve()
        })
        .catch((error: AxiosError) => {
          console.log(
            'axios error',
            error.response?.status,
            error.response?.data,
          )
          HandleAxiosCatch(error, reject)
        })
    })
  },
  AutomaticLogin: (tempAdminGuid: string) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        ClientSecret: AppConfig.Service.Secret,
      }

      axiosProxy
        .post(
          `api/v1/authenticate/${AppConfig.Service.ClientId}/helpdesk/${tempAdminGuid}`,
          data,
        )

        .then((response: AxiosResponse<IAuthenticateUserResponse>) => {
          persistedStore.SetParentUserToken(response.data)
          resolve()
        })
        .catch((error: AxiosError) => {
          console.log(
            'axios error',
            error.response?.status,
            error.response?.data,
          )
          HandleAxiosCatch(error, reject)
        })
    })
  },
  RefreshToken: (refreshToken: string) => {
    const data = {
      ClientSecret: AppConfig.Service.Secret,
      RefreshToken: refreshToken,
    }
    return new Promise<IAuthenticateUserResponse>((resolve, reject) => {
      axiosProxy
        .post(
          `api/v1/authenticate/${AppConfig.Service.ClientId}/refresh/`,
          data,
        )
        .then((response: AxiosResponse<IAuthenticateUserResponse>) => {
          persistedStore.SetUserToken(response.data)
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  AuthenticateClient: () => {
    return new Promise<ClientToken>((resolve, reject) => {
      const url = `api/v1/authenticate/${AppConfig.Service.ClientId}`
      axiosProxy
        .post(url, `"${AppConfig.Service.Secret}"`)
        .then((response: AxiosResponse<IAuthenticateClientResponse>) => {
          const clientToken: ClientToken = new ClientToken(
            response.data.AccessToken,
            GetExpirationDateInMs(response.data.AccessTokenExpires),
          )

          persistedStore.SetClientToken(clientToken)
          resolve(clientToken)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  //api/v1/authenticate/switch/{userGuid}
  SwitchUser: (userGuid: string) => {
    return new Promise<void>((resolve, reject) => {
      //aways user parentUser Token to switch
      axios
        .post(
          `${AppConfig.Service.BaseUrl}api/v1/authenticate/switch/${userGuid}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
              'accept-language': primaryLanguage,
              authorization: `Bearer ${persistedStore.ParentUserToken?.AccessToken}`,
            },
          },
        )
        .then((response: AxiosResponse<IAuthenticateUserResponse>) => {
          persistedStore.SetUserToken(response.data)
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}

export default controller
