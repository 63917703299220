import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Card, Icon, ListItem, Text} from 'react-native-elements'
import {FormatDateTime} from '../../Helpers/DateHelpers'
import i18n from '../../i18n'
import {IUserCouponSTO, IUserSavingCardSTO} from '../../Models/Server'
import Style, {sizes, colors} from '../../Style'
import TextRow from '../Layout/TextRow'
import TransparentCardHeader from './TransparentCardHeader'
import ValidityCard from './ValidityCard'
interface IProps {
  userSavingCard?: IUserSavingCardSTO
}

const SavingCardInfoCard: React.FC<IProps> = ({userSavingCard}) => {
  if (userSavingCard === undefined) {
    return null
  }

  return (
    <>
      <ValidityCard
        validFrom={userSavingCard.SavingCard.ValidFrom}
        validTill={userSavingCard.SavingCard.ValidTill}
      />
      <TransparentCardHeader>
        {i18n.t('Common.Description')}
      </TransparentCardHeader>
      <Card>
        <Text>{userSavingCard.SavingCard.Description}</Text>
      </Card>
    </>
  )
}

export default SavingCardInfoCard

const styles = StyleSheet.create({})
