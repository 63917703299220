import {Text} from 'react-native-elements'
import React from 'react'
import {View, TouchableOpacity} from 'react-native'
import {FormatDateTime} from '../../../../Helpers/DateHelpers'
import {FormatCurrency} from '../../../../Helpers/TextHelpers'
import {ITransactionSTO} from '../../../../Models/Server'
import Style, {colors, sizes} from '../../../../Style'
import {Icon} from 'react-native-elements'
//import {TouchableOpacity} from 'react-native-gesture-handler'
interface IProps {
  transaction: ITransactionSTO
  onPressTransactionItemIcon: () => void
}

const TransactionRow: React.FC<IProps> = ({
  transaction,
  onPressTransactionItemIcon: onPress,
}) => {
  const {transactionRow, noteText, flexRow} = Style
  let activationCodeText =
    transaction.ActivationCode !== null
      ? `${transaction.ActivationCode} - `
      : ''

  const renderTransactionItemsIcon = () => {
    if (!transaction.HasTransactionItems) {
      return null
    }

    return (
      <Icon
        name="receipt"
        type="materialicons"
        onPress={onPress}
        style={{marginLeft: sizes.padding.md}}
      />
    )
  }

  return (
    <View style={transactionRow}>
      <View style={{paddingRight: sizes.padding.md, flex: 1}}>
        <View style={flexRow}>
          <Text>{transaction.Description}</Text>
          {renderTransactionItemsIcon()}
        </View>
        <Text style={noteText}>
          {activationCodeText}
          {FormatDateTime(transaction.RequestDate as string)}
        </Text>
      </View>
      <View>
        <Text>{FormatCurrency(transaction.Amount, transaction.Currency)}</Text>
      </View>
    </View>
  )
  // return (
  //   <TouchableOpacity style={transactionRow} onPress={onPress}>
  //     <View style={{paddingRight: sizes.padding.md, flex: 1}}>
  //       <Text>{transaction.Description}</Text>
  //       <Text style={noteText}>
  //         {activationCodeText}
  //         {FormatDateTime(transaction.RequestDate as string)}
  //       </Text>
  //     </View>
  //     <Text>{FormatCurrency(transaction.Amount, transaction.Currency)}</Text>
  //   </TouchableOpacity>
  // )
}

export default TransactionRow
