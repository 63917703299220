import React, {useState} from 'react'
import {
  Modal as ModalRN,
  Platform,
  Pressable,
  View,
  ViewStyle,
  ScrollView,
  StyleProp,
} from 'react-native'
import {Card, Text} from 'react-native-elements'

import Style, {deviceHeight, sizes} from '../../Style'
import MyWebModal from './MyWebModal'

export interface IMyModalProps {
  visible: boolean
  onClose: () => void
  closeOnBackPress?: boolean
  position?: 'top' | 'center' | 'bottom'
  title?: string
  useParentWidth?: boolean
}

const MyModal: React.FC<IMyModalProps> = ({
  visible,
  closeOnBackPress,
  onClose,
  children,
  position,
  title,
  useParentWidth,
}) => {
  const {modalBackdrop, modalCard, flex1} = Style

  const [width, setWidth] = useState(0)

  if (!visible) {
    return null
  }

  const renderTitle = () => {
    if (title === undefined) {
      return null
    }

    return (
      <>
        <Card.Title>{title}</Card.Title>
        <Card.Divider />
      </>
    )
  }

  const renderModalContent = () => {
    let positionStyle: ViewStyle = {justifyContent: 'flex-end'}
    switch (position) {
      case 'top':
        positionStyle = {justifyContent: 'flex-start'}
        break
      case 'center':
        positionStyle = {justifyContent: 'center'}
        break

      case 'bottom':
      default:
        positionStyle = {
          justifyContent: 'flex-end',
        }
        break
    }

    positionStyle.alignItems = 'center'

    const renderModalCard = () => {
      //when used in Myselect , use parent (form ) width
      const cardStyle: StyleProp<ViewStyle> = useParentWidth
        ? [modalCard, {width}]
        : modalCard

      return (
        <div
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          <Card containerStyle={cardStyle}>
            {renderTitle()}
            {children}
          </Card>
        </div>
      )
    }

    if (closeOnBackPress) {
      return (
        <Pressable
          onPress={() => {
            onClose()
          }}
          style={[modalBackdrop, flex1, positionStyle]}
          onLayout={(e) => {
            setWidth(e.nativeEvent.layout.width - 2 * sizes.padding.md)
          }}>
          {renderModalCard()}
        </Pressable>
      )
    }

    return (
      <View
        style={[modalBackdrop, flex1, positionStyle]}
        onLayout={(e) => {
          setWidth(e.nativeEvent.layout.width - 2 * sizes.padding.md)
        }}>
        {renderModalCard()}
      </View>
    )
  }

  return (
    <MyWebModal transparent={true} visible={visible}>
      {renderModalContent()}
    </MyWebModal>
  )
}

export default MyModal

MyModal.defaultProps = {
  closeOnBackPress: false,
  position: 'bottom',
  useParentWidth: false,
}
