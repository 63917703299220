import {StackScreenProps} from '@react-navigation/stack'
import React, {useEffect} from 'react'
import {View, StyleSheet} from 'react-native'
import {MainScreensListWeb} from '../../../Navigation/index.web'
import {automaticLoginStore} from './Store'

interface IProps
  extends StackScreenProps<MainScreensListWeb, 'automaticLogin'> {}

const AutomaticLogin: React.FC<IProps> = ({navigation: {navigate}, route}) => {
  const {tempAdminGuid} = route.params
  useEffect(() => {
    automaticLoginStore.AutomaticLogin(tempAdminGuid, navigate)
  }, [null])

  return <></>
}

export default AutomaticLogin
