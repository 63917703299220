import React from 'react'
import {ScrollView, View} from 'react-native'
import {ListItem} from 'react-native-elements'
import {MyButton, MyModal, ToprLoader} from '../../../../Components'
import {
  FormatCurrency,
  FormatCurrencyTransactionItems,
} from '../../../../Helpers/TextHelpers'
import i18n from '../../../../i18n'
import {ICurrencySTO} from '../../../../Models/Server'
import {ILoyaltyTransactionItemSTO} from '../../../../Models/Server/Wallet/ILoyaltyTransactionItemSTO'
import Style, {deviceHeight, sizes, boldFontWeight} from '../../../../Style'

export interface IProps {
  transactionItemRows: ILoyaltyTransactionItemSTO[]
  onClose: () => void
  isLoading: boolean
  isVisible: boolean
  currency?: ICurrencySTO
}

const TransactionItemsModal: React.FC<IProps> = ({
  onClose,
  transactionItemRows,
  isLoading,
  isVisible,
  currency,
}) => {
  if (!isVisible) {
    return null
  }

  const renderContent = () => {
    const {centerXY, container} = Style
    if (isLoading) {
      return (
        <View style={[centerXY]}>
          <ToprLoader size="small" isLoading={isLoading} />
        </View>
      )
    }

    if (transactionItemRows.length === 0) {
      return (
        <ListItem>
          <ListItem.Content>
            <ListItem.Title>
              {i18n.t('WalletDetails.NoTransactionItems')}
            </ListItem.Title>
          </ListItem.Content>
        </ListItem>
      )
    }

    const parseItem = (item: ILoyaltyTransactionItemSTO) => {
      if (item.Article !== null && item.Article !== undefined) {
        //when article is set get the name from description
        return item.Description
      }

      //when no article is set parse
      if (
        item.Description !== undefined &&
        item.Description !== null &&
        item.Description.indexOf(';') > -1
      ) {
        const splitted = item.Description.split(';')
        if (splitted.length === 3) {
          return splitted[2]
        }
        return item.Description
      }

      return item.Description
    }

    const renderFooter = () => {
      const totalPrice = transactionItemRows
        .map((x) => x.Quantity * x.UnitPrice)
        .reduce((a, b) => a + b)

      return (
        <ListItem>
          <ListItem.Content>
            <ListItem.Title style={boldFontWeight}>
              {i18n.t('Common.Total')}
            </ListItem.Title>
          </ListItem.Content>
          <ListItem.Title style={boldFontWeight}>
            {FormatCurrencyTransactionItems(totalPrice, currency)}
          </ListItem.Title>
        </ListItem>
      )
    }

    return (
      <>
        <ScrollView style={{maxHeight: deviceHeight - 200}}>
          {transactionItemRows.map((item, key) => {
            const rowTotal = item.UnitPrice * item.Quantity

            return (
              <ListItem bottomDivider key={key}>
                <ListItem.Content>
                  <ListItem.Title>
                    {item.Quantity}x {parseItem(item)}
                  </ListItem.Title>
                </ListItem.Content>
                <ListItem.Title>
                  {FormatCurrency(rowTotal, currency)}
                </ListItem.Title>
              </ListItem>
            )
          })}
        </ScrollView>
        {renderFooter()}
      </>
    )
  }

  return (
    <MyModal onClose={onClose} visible={isVisible} position="bottom">
      {renderContent()}
      <MyButton
        type="outline"
        onPress={onClose}
        buttonStyle={{marginTop: sizes.padding.md}}
        title={i18n.t('Common.Close')}
      />
    </MyModal>
  )
}

export default TransactionItemsModal
