import {makeAutoObservable, runInAction} from 'mobx'
import {ITransactionSTO, IWalletSTO} from '../../../Models/Server'
import {ILoyaltyTransactionItemSTO} from '../../../Models/Server/Wallet/ILoyaltyTransactionItemSTO'
import WalletController from '../../../Services/Topr.Core/WalletController'

export default class Store {
  public IsLoading = true
  public IsTransactionsLoading = true
  public Transactions: ITransactionSTO[] = []

  public get FilteredTransactions(): ITransactionSTO[] {
    if (this.SearchText === '') {
      return this.Transactions
    }

    return this.Transactions.filter((t) => {
      if (t.ActivationCode !== null) {
        if (t.ActivationCode.startsWith(this.SearchText.trim())) {
          return t.ActivationCode.startsWith(this.SearchText.trim())
        }
      }

      //search description
      if (t.Description !== null) {
        return t.Description.toLowerCase()
          .trim()
          .startsWith(this.SearchText.toLocaleLowerCase().trim())
      }

      return false
    })
  }

  public IsPdfLoading = false
  public IsTransactionItemsLoading = false
  public TransactionItems: ILoyaltyTransactionItemSTO[] = []
  public IsTransactionItemsModalVisible = false
  public IsReportModalVisible = false

  public HasMore = true
  public HasMoreLoading = false

  public SearchVisible = false
  public SearchText = ''

  public Wallet?: IWalletSTO = undefined
  public CurrencyGuid?: string = undefined
  private Skip = 0
  public Take = 50

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize(currencyGuid: string) {
    this.Skip = 0
    this.CurrencyGuid = currencyGuid
    this.IsTransactionsLoading = true
    this.IsTransactionItemsLoading = false
    this.IsTransactionItemsModalVisible = false
    this.TransactionItems = []
    this.IsPdfLoading = false
    this.GetWallet(currencyGuid)
    this.GetTransactions(true)
  }

  public ToggleSearch() {
    this.SearchVisible = !this.SearchVisible
  }

  public onSearchTextChange(text: string) {
    this.SearchText = text
  }

  public NextPage() {
    if (!this.HasMoreLoading && this.HasMore) {
      this.Skip += this.Take
      this.GetTransactions(false)
    }
  }

  public GetWallet(currencyGuid: string) {
    this.IsLoading = true

    WalletController.GetWallet(currencyGuid)
      .then((wallet) => {
        runInAction(() => {
          this.IsLoading = false
          this.Wallet = wallet
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public GetTransactions(reload: boolean) {
    this.HasMoreLoading = true

    if (reload) {
      this.Skip = 0
    }

    WalletController.GetTransactions(this.CurrencyGuid, this.Skip, this.Take)
      .then((transactions) => {
        runInAction(() => {
          if (reload) {
            this.Transactions = transactions
          } else {
            this.Transactions.push(...transactions)
          }
          this.IsTransactionsLoading = false
          this.HasMoreLoading = false
          this.HasMore = transactions.length === this.Take
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsTransactionsLoading = false
          this.HasMoreLoading = false
        })
      })
  }

  public SetisPdfLoading(isPdfLoading: boolean) {
    this.IsPdfLoading = isPdfLoading
  }

  public ToggleIsReportModalVisible(isVisible: boolean) {
    this.IsReportModalVisible = isVisible
  }

  public GetTransactionPdfWeb() {
    //used in web
    this.IsPdfLoading = true
    WalletController.GetTransactionsPdfWeb(this.CurrencyGuid)
      .then(() => {
        runInAction(() => {
          this.IsPdfLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsPdfLoading = false
        })
      })
  }

  public GetTransactionPeriodPdfWeb(fromDate: Date, tillDate: Date) {
    //used in web
    this.IsPdfLoading = true
    WalletController.GetTransactionsPeriodPdfWeb(
      this.CurrencyGuid,
      fromDate,
      tillDate,
    )
      .then(() => {
        runInAction(() => {
          this.IsPdfLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsPdfLoading = false
        })
      })
  }

  public ShowTransactionItemsModal(loyaltyTransactionGuid: string) {
    this.IsTransactionItemsLoading = true
    this.IsTransactionItemsModalVisible = true
    this.TransactionItems = []

    WalletController.GetTransactionItems(loyaltyTransactionGuid)
      .then((result) => {
        runInAction(() => {
          this.TransactionItems = result
          this.IsTransactionItemsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsTransactionItemsLoading = false
          this.IsTransactionItemsModalVisible = false
        })
      })
  }

  public HideTransactionItemsModal() {
    runInAction(() => {
      this.IsTransactionItemsModalVisible = false
    })
  }
}

export const walletDetailsStore = new Store()
