import React, {ReactNode} from 'react'
import {View, StyleSheet} from 'react-native'
import {Card, Icon, Text} from 'react-native-elements'
import i18n from '../../i18n'
import {IUserSavingCardSTO} from '../../Models/Server'
import Style, {colors, sizes} from '../../Style'
import TransparentCardHeader from './TransparentCardHeader'
interface IProps {
  userSavingCard?: IUserSavingCardSTO
}

const StampsCard: React.FC<IProps> = ({userSavingCard}) => {
  if (userSavingCard === undefined) {
    return null
  }

  const {stampsContainer, stampIcon} = Style

  const renderStamps = () => {
    const stamps: ReactNode[] = []

    const rowSize = 5
    for (let index = 0; index < userSavingCard.SavingCard.StampCount; index++) {
      const color =
        userSavingCard.StampCount > index ? colors.main : colors.lightGrey
      stampIcon.color = color

      stampIcon.marginLeft = index % rowSize == 0 ? 0 : sizes.padding.md
      stampIcon.marginTop = index > rowSize - 1 ? sizes.padding.md : 0
      const iconStyle = {...stampIcon}

      stamps.push(
        <Icon
          key={index}
          name="checkcircleo"
          type="antdesign"
          iconStyle={iconStyle}
        />,
      )
    }

    return stamps
  }
  return (
    <>
      <TransparentCardHeader>{i18n.t('Common.Stamps')}</TransparentCardHeader>
      <Card containerStyle={{marginTop: 0, marginBottom: sizes.padding.md}}>
        <View style={stampsContainer}>{renderStamps()}</View>
      </Card>
    </>
  )
}

export default StampsCard

const styles = StyleSheet.create({})
