import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Icon} from 'react-native-elements'
import Style from '../../Style'
interface IProps {
  isLoading: boolean
}

const Spinner: React.FC<IProps> = ({children, isLoading}) => {
  if (isLoading) {
    return (
      <span className="fa-spin">
        <Icon name="loading1" type="ant-design" />
      </span>
    )
  }

  return <>{children}</>
}

export default Spinner
