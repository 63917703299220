import {toJS} from 'mobx'
import React from 'react'
// import {
//   IBaseContent,
//   IImageText,
//   IRegisterLogin,

// } from '../../../Models/Strapi/Models'
//import {IDownload} from '../../../Models/Strapi/MyModels'
import {
  IContentElement,
  IImageTextViewModel,
  IRegisterLoginViewModel,
} from '../../../Services/Topr.Umbraco/Models'
import {IDownloadContentViewModel} from '../../../Services/Topr.Umbraco/Models/IDownloadContentViewModel'
import Download from './Download'
import ImageText from './ImageText'

import RegisterLogin from './RegisterLogin'

interface IProps {
  contentElements?: IContentElement[]
}

const ContentElements: React.FC<IProps> = ({contentElements}) => {
  if (contentElements === undefined) {
    return null
  }

  return (
    <div className="my-hero">
      {contentElements.map((contentElement, key) => {
        switch (contentElement.Type) {
          case 'registerLogin':
            return (
              <RegisterLogin
                data={contentElement as IRegisterLoginViewModel}
                key={key}
              />
            )

          case 'imageText':
            return (
              <ImageText
                data={contentElement as IImageTextViewModel}
                key={key}
              />
            )
          case 'downloadContent':
            return (
              <Download
                data={contentElement as IDownloadContentViewModel}
                key={key}
              />
            )

          default:
            return (
              <div key={key} className="tag is-danger is-light">
                UnknownContent - {contentElement.Type}
              </div>
            )
        }
      })}
    </div>
  )
}

export default ContentElements
