import {observer} from 'mobx-react'
import React from 'react'
import {FlatList, RefreshControl} from 'react-native'
import {
  FooterBar,
  FooterBarButton,
  FooterBarIconButton,
  ImageCard,
  ImageTextCard,
  ToprScreenLoader,
} from '../../../../Components'
import {navigate} from '../../../../Helpers/NavigationHelper'
import {GetLocalText} from '../../../../Helpers/TextHelpers'
import {GetFileUrl, GetRandomKey} from '../../../../Helpers/UiHelpers'
import i18n from '../../../../i18n'
import I18n from '../../../../i18n'
import {ConsumerSubscriptionItemTypes} from '../../../../Models/Client/Enums'
import Style from '../../../../Style'
import {dashboardStore} from '../../Dashboard/Store'
import {dashboardHeaderStore} from '../DashboardHeader/Store'
import {LastItem} from '../../../../Helpers/ArrayHelpers'

const SubscriptionsTab: React.FC = () => {
  const {IsUserSubscriptionsLoading, UserSubscriptions} = dashboardStore
  const {defaultFlatList, flatListContentStyle} = Style
  return (
    <ToprScreenLoader isLoading={IsUserSubscriptionsLoading}>
      <FlatList
        ListEmptyComponent={
          <ImageCard
            imageUrl="https://images.unsplash.com/photo-1565165928601-867958a20fdd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
            text={I18n.t('Dashboard.NoSavingCards')}
          />
        }
        bounces={false}
        onScroll={(event) => {
          dashboardHeaderStore.SetScrollY(event.nativeEvent.contentOffset.y)
        }}
        style={[
          defaultFlatList,
          {paddingTop: dashboardHeaderStore.HEADER_MAX_HEIGHT * 2},
        ]}
        refreshControl={
          <RefreshControl
            refreshing={IsUserSubscriptionsLoading}
            progressViewOffset={dashboardHeaderStore.HEADER_MAX_HEIGHT * 2}
            onRefresh={() => {
              dashboardStore.GetUserSubscriptions()
            }}
          />
        }
        contentContainerStyle={[flatListContentStyle]}
        data={UserSubscriptions}
        renderItem={({item}) => {
          const badgeText =
            item.SubscriptionItem.ConsumerSubscriptionItemTypeId !==
            ConsumerSubscriptionItemTypes.Trips
              ? undefined
              : `${item.UsedAmount}/${item.Amount}`
          const lastItem = LastItem(UserSubscriptions)
          return (
            <ImageTextCard
              isLast={lastItem?.UserSubscriptionId === item.UserSubscriptionId}
              key={item.UserSubscriptionId}
              imageUrl={GetFileUrl(item.SubscriptionItem.ImageFileGuid)}
              title={GetLocalText(item.SubscriptionItem.Name)}
              onPress={() => {
                navigate('subscriptionDetails', {
                  userSubscriptionId: item.UserSubscriptionId.toString(),
                })
              }}
              badgeText={badgeText}
            />
          )
        }}
        keyExtractor={() => {
          return GetRandomKey()
        }}
      />
      <FooterBar>
        <FooterBarIconButton
          icon={{name: 'qrcode', type: 'font-awesome'}}
          onPress={() => navigate('qrCode')}
          text={i18n.t('Common.Check')}
        />
      </FooterBar>
    </ToprScreenLoader>
  )
}

export default observer(SubscriptionsTab)
