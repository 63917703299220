import {
  NavigationContainerRef,
  NavigationHelpers,
  CommonActions,
} from '@react-navigation/native'
import React from 'react'
//import {MainScreensList} from '../Navigation'
import {MainScreensListWeb} from '../Navigation/index.web'
import {ILocationSTO, IWalletSTO} from '../Models/Server'
import {OrderOnlineList} from '../Screens/Location/OrderOnline'

export const navigationRef =
  React.createRef<NavigationHelpers<MainScreensListWeb>>()

export function navigate<RouteName extends keyof MainScreensListWeb>(
  ...args: undefined extends MainScreensListWeb[RouteName]
    ? [RouteName] | [RouteName, MainScreensListWeb[RouteName]]
    : [RouteName, MainScreensListWeb[RouteName]]
) {
  if (navigationRef && navigationRef.current) {
    navigationRef?.current?.navigate(...args)
  }
}

export function goBack() {
  navigationRef.current?.goBack()
}

export function goBackSteps(steps: number) {
  for (let index = 0; index < steps; index++) {
    navigationRef.current?.goBack()
  }
}

export class TestUrls {
  static LocationDetails() {
    const location: Partial<ILocationSTO> = {
      ImageFileGuid: '{9db1bcce-eafa-401e-b826-d9a9f9feb4d0}',
      MerchantGuid: '{2281c74b-25d2-4cde-9f69-ec53b9a12c1b}',
      Name: 'Oude Tol',
      City: 'Oud-Beijerland',
      StreetName: 'str 1',
      StreetNumber: '10',
    }
    navigate('locationDetails', {
      location: location as ILocationSTO,
    })
  }
  static wallet = {
    Balance: 1560,
    Currency: {
      AppPhotoLocalizedImageGUID: '{31525902-636a-4f61-9807-d2710ba9659e}',
      BackgroundColor: '#2d9dc2',
      CancelAllowed: false,
      Code: 'QPO',
      ConsumerCharge: true,
      ConsumerSendMoney: true,
      ConsumerWithdraw: false,
      CurrencyGuid: '{e4a6eb11-9b75-4d7f-9bc1-b1e1e469bf19}',
      ForegroundColor: '#FFFFFF',
      IsDecimal: true,
      //MaxBalance: null,
      MaxBalance: 15000,
      Name: "Multi's test",
      StringFormat: '€ {0:n}',
    },
    IsBlocked: false,
    LoyaltyWalletGUID: '{a27c62fa-9f8e-495e-a3c9-b984f78f07ee}',
    Modified: '2019-09-24T13:23:00Z',
    SpendingLimit: 210,
  }
  // static WalletDetails() {
  //   navigate('WalletDetails', {
  //     currencyGuid: '{e4a6eb11-9b75-4d7f-9bc1-b1e1e469bf19}',
  //   })
  // }

  static WalletDetails() {
    navigate('walletDetails', {
      currencyGuid: TestUrls.wallet.Currency.CurrencyGuid,
    })
  }
  static Charge() {
    // navigate('walletDetails', {
    //   wallet: TestUrls.wallet as IWalletSTO,
    // })
  }
  // static Withdraw() {
  //   navigate('WalletDetails', {
  //     currencyGuid: '{e4a6eb11-9b75-4d7f-9bc1-b1e1e469bf19}',
  //   })
  //   setTimeout(() => {
  //     navigate('Withdraw', {
  //       wallet: TestUrls.wallet as IWalletSTO,
  //     })
  //   }, 100)
  // }
  static SendMoney() {
    navigate('sendMoney', {
      currencyGuid: 'e4a6eb11-9b75-4d7f-9bc1-b1e1e469bf19',
    })
  }
}
