import React, {useEffect} from 'react'
import {View, StyleSheet} from 'react-native'
import {helpStore} from '../../../Home/Help/Store'
import {ContentElements} from '../../../../Components/Web/Strapi'
import {ToprScreenLoader} from '../../../../Components'
import {Icon, Input} from 'react-native-elements'
import i18n from '../../../../i18n'
import HelpItemsView from '../../../Home/Components/HelpItemsView'
import {observer} from 'mobx-react'

interface IProps {}

const Help: React.FC<IProps> = ({children}) => {
  useEffect(() => {
    helpStore.GetHelp()
  }, [helpStore])

  const {IsLoading, Page, FilteredHelpItems, Search} = helpStore

  if (IsLoading) {
    //    return null
    return <ToprScreenLoader isLoading={IsLoading} isWhite />
  }

  const renderClearSearchIcon = () => {
    if (Search === '') {
      return undefined
    }
    return (
      <Icon
        name="times"
        type="font-awesome"
        color="black"
        onPress={() => {
          helpStore.SetSearch('')
        }}
      />
    )
  }
  return (
    <>
      <div className="v-scroll">
        <ContentElements contentElements={Page?.Content} />
        <div className="columns is-mobile">
          <div className="column is-4">
            <Input
              placeholder={i18n.t('Common.Search')}
              leftIcon={{type: 'font-awesome', name: 'search'}}
              rightIcon={renderClearSearchIcon()}
              value={Search}
              onChangeText={(value) => helpStore.SetSearch(value)}
            />
          </div>
        </div>

        <HelpItemsView helpItems={FilteredHelpItems} />
      </div>
    </>
  )
}

export default observer(Help)
