import {StackNavigationProp} from '@react-navigation/stack'
import React from 'react'
import {Asset} from 'expo-asset'
import {View} from 'react-native'
import PDFReader from 'rn-pdf-reader-js'
import {SubmitHeader} from '../../../Components'
import AppConfig from '../../../Config/AppConfig'
import i18n, {primaryLanguage} from '../../../i18n'
import {RegisterSteps} from '../Register'

interface IProps {
  navigation: StackNavigationProp<RegisterSteps>
}

const TermsPdf: React.FC<IProps> = ({navigation: {goBack}}) => {
  return (
    <>
      <View style={{flex: 1, paddingTop: 60}}>
        <PDFReader
          source={{uri: AppConfig.Tenant.TermsPdf[primaryLanguage]}}
          withScroll={true}
        />
      </View>
      <SubmitHeader title={i18n.t('Register.Header')} onBackPress={goBack} />
    </>
  )
}

export default TermsPdf
