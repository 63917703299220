import React from 'react'
import {TouchableOpacity, Text, TouchableOpacityProps, View} from 'react-native'
import {Icon} from 'react-native-elements'
import Style, {colors} from '../../Style'
interface IProps extends TouchableOpacityProps {
  text: string
  chevron?: boolean
}

const LinkButton: React.FC<IProps> = ({
  onPress,
  text,
  chevron,
  ...otherProps
}) => {
  const renderChevron = () => {
    if (!chevron) {
      return null
    }

    return (
      <Icon
        color={colors.main}
        style={{marginLeft: -5, fontSize: 22}}
        name="chevron-right"
        type="material"
      />
    )
  }

  return (
    <TouchableOpacity
      style={[{flexDirection: 'row'}, otherProps.style]}
      onPress={onPress}>
      {renderChevron()}
      <View style={{justifyContent: 'center'}}>
        <Text
          style={{
            color: colors.main,
          }}>
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

export default LinkButton

LinkButton.defaultProps = {
  chevron: true,
}
