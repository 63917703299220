import React from 'react'
import {View, StyleSheet} from 'react-native'
import {CheckBox, Text} from 'react-native-elements'
import i18n from '../../../../../i18n'
import {ICondimentGroup} from '../../../../../Models/InzziiApi'
import Style from '../../../../../Style'
import {articleStore} from '../Store'
import CondimentCheckBox from './CondimentCheckBox'
import CondimentRadioButton from './CondimentRadioButton'
interface IProps {
  condimentGroup: ICondimentGroup
}

const CondimentGroup: React.FC<IProps> = ({condimentGroup}) => {
  const renderValidationInfo = () => {
    if (!condimentGroup.isMeetConditions) {
      return null
    }

    if (condimentGroup.isRadioButtonGroup) {
      return <Text>{i18n.t('OrderOnline.RequiredChoice')}</Text>
    }

    let info = ''
    if (condimentGroup.minimumCondiments === 0) {
      info += i18n.t('OrderOnline.OptionalChoice')
    }

    if (condimentGroup.minimumCondiments > 0) {
      info += i18n.t('OrderOnline.Min', {min: condimentGroup.minimumCondiments})
    }

    if (condimentGroup.maximumCondiments) {
      info += i18n.t('OrderOnline.Max', {max: condimentGroup.maximumCondiments})
    }

    return <Text>{info}</Text>
  }

  const renderCheckBoxes = () => {
    if (condimentGroup.isRadioButtonGroup) {
      return null
    }

    return (
      <View>
        {condimentGroup.condiments.map((condiment, key) => (
          <CondimentCheckBox
            key={key}
            condimentGroup={condimentGroup}
            condiment={condiment}
            onCheckChanged={(checked) => {
              if (checked) {
                articleStore.AddCondimentChecked(condiment, condimentGroup)
              } else {
                articleStore.RemoveCondimentChecked(condiment)
              }
            }}
          />
        ))}
      </View>
    )
  }

  const renderRadioButtons = () => {
    if (!condimentGroup.isRadioButtonGroup) {
      return null
    }

    return (
      <View>
        {condimentGroup.condiments.map((condiment, key) => (
          <CondimentRadioButton
            key={key}
            condiment={condiment}
            onRadioPress={() => {
              articleStore.ToggleCondimentRadio(condiment, condimentGroup)
            }}
          />
        ))}
      </View>
    )
  }

  return (
    <>
      <Text h4>{condimentGroup.name}</Text>
      {renderValidationInfo()}
      {renderCheckBoxes()}
      {renderRadioButtons()}
    </>
  )
}

export default CondimentGroup

const styles = StyleSheet.create({})
