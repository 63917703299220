import {makeObservable, observable, action, runInAction} from 'mobx'
import {navigate} from '../../Helpers/NavigationHelper'
import {SetFriendlyMessage} from '../../Helpers/ServiceHelpers'
import {GetRandStockImage} from '../../Helpers/UiHelpers'
import {persistedStore} from '../../Mobx'
import {MyErrorMessage, ToprError} from '../../Models/Client'
import AuthenticationController from '../../Services/Topr.Core/AuthenticationController'
import UserController from '../../Services/Topr.Core/UserController'

export type MyFormData = {
  email: string
  password: string
}

export default class Store {
  public IsSubmitting = false

  constructor() {
    makeObservable(this, {
      IsSubmitting: observable,
      AuthenticateConsumerUser: action,
    })
  }

  public AuthenticateConsumerUser(
    formData: MyFormData,
    onError: (errorMessage: string) => void,
  ) {
    //DoServiceCall(() => {
    this.IsSubmitting = true
    AuthenticationController.AuthenticateConsumerUser(
      formData.email,
      formData.password,
    )
      .then(() => {
        UserController.GetChildUsers()

        UserController.GetConsumerUser().then((mainUser) => {
          persistedStore.SetMainUser(mainUser)
        })

        //reset form
        navigate('dashboard')
      })
      .catch((toprError: ToprError) => {
        console.log('toprError', toprError)
        SetFriendlyMessage(toprError, onError, [
          new MyErrorMessage(
            'USER_PASSWORD_INVALID',
            'Login.Form.ServerErrors.Title',
            'Login.Form.ServerErrors.USER_PASSWORD_INVALID',
            'warning',
          ),
          new MyErrorMessage(
            'USER_LOCKED_SHORT',
            'Login.Form.ServerErrors.Title',
            'Login.Form.ServerErrors.USER_LOCKED_SHORT',
            'warning',
          ),
          new MyErrorMessage(
            'USER_LOCKED_LONG',
            'Login.Form.ServerErrors.Title',
            'Login.Form.ServerErrors.USER_LOCKED_LONG',
            'warning',
          ),
          new MyErrorMessage(
            'USER_NOT_FOUND',
            'Login.Form.ServerErrors.Title',
            'Login.Form.ServerErrors.USER_NOT_FOUND',
            'warning',
          ),
        ])
        runInAction(() => (this.IsSubmitting = false))
      })
      .finally(() => {
        runInAction(() => (this.IsSubmitting = false))
      })
  }
}
