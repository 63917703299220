import axios, {AxiosError, AxiosResponse} from 'axios'
import AppConfig from '../../Config/AppConfig'
import {umbracoApiUrl} from '../../Helpers/UrlHelpers'
import {IPageViewModel, ISystemMessageViewModel, IHelpViewModel} from './Models'

const toprUmbraco = {
  GetPage: (pageName: string) => {
    return new Promise<IPageViewModel>((resolve, reject) => {
      axios
        .get(
          umbracoApiUrl(
            `/page/getpage?tenant=${AppConfig.Tenant.SystemName}&name=${pageName}`,
          ),
        )
        .then((response: AxiosResponse<IPageViewModel>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          console.log(
            'umbraco error',
            error.response?.status,
            error.response?.statusText,
          )
          reject(error.response?.statusText)
        })
    })
  },
  GetHelpPage: () => {
    return new Promise<IHelpViewModel>((resolve, reject) => {
      axios
        .get(
          umbracoApiUrl(
            `/page/gethelppage?tenant=${AppConfig.Tenant.SystemName}`,
          ),
        )
        .then((response: AxiosResponse<IHelpViewModel>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          console.log(
            'umbraco error',
            error.response?.status,
            error.response?.statusText,
          )
          reject(error.response?.statusText)
        })
    })
  },
  GetActiveSystemMessages: () => {
    return new Promise<ISystemMessageViewModel[]>((resolve, reject) => {
      const url = `/SystemMessage/GetActiveSystemMessages?tenant=${AppConfig.Tenant.SystemName}`

      axios
        .get(umbracoApiUrl(url))
        .then((response: AxiosResponse<ISystemMessageViewModel[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          console.log(
            'umbraco error',
            error.response?.status,
            error.response?.statusText,
          )
          reject(error.response?.statusText)
        })
    })
  },
}

export default toprUmbraco
