import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {FlatList, View} from 'react-native'
import {Text} from 'react-native-elements'
import {
  Container,
  Content,
  SubmitHeader,
  SubmitHeaderButton,
  ToprScreenLoader,
} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import {GetRandomKey} from '../../../Helpers/UiHelpers'
import i18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style from '../../../Style'
import OtherUsersModal from './Components/OtherUsersModal'
import TokenCard from './Components/TokenCard'
import TokenMenu from './Components/TokenMenu'
import {store} from './Store'
import SetDescriptionModal from './Components/SetDescriptionModal'

interface IProps extends StackScreenProps<MainScreensList> {}

const Tokens: React.FC<IProps> = ({navigation: {navigate, goBack}, route}) => {
  useEffect(() => {
    myLog.warn('Tokens')
  }, [null])

  const {
    Tokens,
    IsTokensLoading,
    LoadingTokenTokenGUID,
    SelectedToken,
    TokenMenuIsVisible,
    SetDescriptionTokenModalIsVisible,
  } = store
  const {flatListContentStyleStock, transactionRow, defaultFlatListWhite} =
    Style
  return (
    <Container keyboardAware={false}>
      <StockImageLayout padder={false} isLoading={IsTokensLoading}>
        <Content headerMargin padder={false}>
          <FlatList
            contentContainerStyle={[flatListContentStyleStock]}
            ListEmptyComponent={
              <View style={transactionRow}>
                <Text>{i18n.t('Tokens.NoTokens')}</Text>
              </View>
            }
            style={defaultFlatListWhite}
            refreshing={IsTokensLoading}
            onRefresh={() => store.GetTokens()}
            data={Tokens}
            renderItem={({item}) => (
              <TokenCard
                token={item}
                loadingTokenTokenGUID={LoadingTokenTokenGUID}
              />
            )}
            keyExtractor={() => {
              return GetRandomKey()
            }}
          />
        </Content>
        <SubmitHeader onBackPress={goBack} title={i18n.t('Tokens.Header')}>
          <SubmitHeaderButton
            title={i18n.t('Dashboard.AddToken')}
            onPress={() => {
              navigate('addToken')
            }}
          />
        </SubmitHeader>
      </StockImageLayout>

      <TokenMenu
        onClose={() => store.HideTokenMenu()}
        isVisible={TokenMenuIsVisible}
        token={SelectedToken!}
      />

      <OtherUsersModal
        isVisible={store.OtherUserModalIsVisible}
        onClose={() => store.ToggleOtherUserModalIsVisible()}
        onUserSelected={(user) => {
          appUiStore.ShowConfirm(
            i18n.t('Tokens.TokenMenu.SwitchUserAction.Confirm', {
              activationCode: SelectedToken?.ActivationCode,
              name: user.Name,
            }),
            () => {
              store.TokenSwitchUser(user)
            },
          )
        }}
      />

      <SetDescriptionModal />
    </Container>
  )
}
export default observer(Tokens)
