import {ErrorMessage} from '@hookform/error-message'
import React, {PropsWithChildren, useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {Platform, StyleSheet, View} from 'react-native'
import {Input, Text} from 'react-native-elements'
import Style, {colors, sizes} from '../../Style'

interface IProps<T> {
  label: string
  name: keyof T
  control: any
  validation?: {
    errors: any
    rules: any
    defaultValue?: string
  }
  formObject: T

  onSubmitEditing: () => void
}

const EuroTextInput = <ObjectType,>(
  props: PropsWithChildren<IProps<ObjectType>>,
) => {
  const [textAmount, setTextAmount] = useState('')

  const euroTextToCents = (text: string) => {
    text = text.trim()

    let intval = 0

    if (text !== undefined && text != '') {
      intval = parseFloat(text.replace(',', '.'))
    }

    const cents = intval * 100
    //https://stackoverflow.com/questions/34077449/fastest-way-to-cast-a-float-to-an-int-in-javascript
    return ~~cents //remove float
  }
  const {
    name,
    label,
    control,
    validation,

    onSubmitEditing,
  } = props

  useEffect(() => {
    // euroRef.current?.focus()

    const {getValues} = control
    const defaultValue = getValues(name)

    if (defaultValue !== undefined && defaultValue !== '') {
      const euroText = (defaultValue / 100).toString().replace('.', ',')
      setTextAmount(euroText)
    }
  }, [null])

  const rules = validation !== undefined ? validation.rules : {}

  const errorsMessage =
    validation !== undefined && validation.errors[name] !== undefined
      ? validation.errors[name].message
      : undefined
  const defaultValue =
    validation !== undefined && validation.defaultValue !== undefined
      ? validation.defaultValue
      : ''

  const {labelStyle, inputContainer} = Style

  return (
    <>
      <Controller
        control={control}
        render={({onChange, onBlur, value}) => {
          return (
            <>
              <View>
                <Input
                  keyboardType="decimal-pad"
                  selectTextOnFocus
                  autoFocus={true}
                  placeholder="0,00"
                  value={textAmount}
                  onChangeText={(text) => {
                    if (/^([0-9]{1,})*([\,\.]{0,1})(\d{1,2})?$/.test(text)) {
                      //text = text.replace('.', ',')
                      setTextAmount(text)
                      const cents = euroTextToCents(text)
                      onChange(cents)
                    }
                  }}
                  onSubmitEditing={() => {
                    onSubmitEditing()
                  }}
                  maxLength={6}
                  style={styles.euroInput}
                  inputContainerStyle={inputContainer}
                  containerStyle={{paddingHorizontal: 0}}
                  labelStyle={labelStyle}
                  label={label}
                  errorMessage={errorsMessage}
                />
                <Text style={styles.euroIconText}>€</Text>
              </View>
            </>
          )
        }}
        name={name.toString()}
        rules={rules}
        defaultValue={defaultValue}
      />
    </>
  )
}

export default EuroTextInput

const styles = StyleSheet.create({
  euroIconText: {
    position: 'absolute',
    fontSize: 18,
    top: Platform.OS === 'android' ? 38 : 34,
  },
  euroInput: {
    paddingLeft: 20,
  },
})
