import {StackScreenProps} from '@react-navigation/stack'
import I18n from 'i18n-js'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {FlatList, View, Text} from 'react-native'
import {
  Container,
  Content,
  CouponSummaryCard,
  ImageCard,
  SubmitHeader,
  ToprScreenLoader,
} from '../../../Components'
import MyHeader from '../../../Components/Headers/MyHeader'
import {StockImageLayout} from '../../../Components/Web'
import {GetRandomKey} from '../../../Helpers/UiHelpers'
import i18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style from '../../../Style'
import {couponSummaryStore} from './Store'

interface IProps extends StackScreenProps<MainScreensList> {}

const CouponsSummary: React.FC<IProps> = ({
  navigation: {navigate, goBack},
  route,
}) => {
  const {IsLoading, CouponSummaries} = couponSummaryStore

  useEffect(() => {
    myLog.warn('CouponsSummary')
    couponSummaryStore.Initialize()
  }, [null])

  const {defaultFlatListWhite, flatListContentStyleStock} = Style

  return (
    <>
      <StockImageLayout isLoading={IsLoading} padder={false}>
        <Container keyboardAware={false}>
          <Content headerMargin padder={false}>
            <FlatList
              style={[defaultFlatListWhite]}
              ListEmptyComponent={
                <ImageCard
                  imageUrl="https://images.unsplash.com/photo-1594968973184-9040a5a79963?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80"
                  text={I18n.t('Dashboard.NoCoupons')}
                />
              }
              contentContainerStyle={flatListContentStyleStock}
              refreshing={IsLoading}
              onRefresh={() => couponSummaryStore.Initialize()}
              data={CouponSummaries}
              renderItem={({item}) => (
                <CouponSummaryCard
                  couponSummary={item}
                  onPress={() => {
                    navigate('userCouponsSummary', {couponSummary: item})
                  }}
                />
              )}
              keyExtractor={() => {
                return GetRandomKey()
              }}
            />
          </Content>
          <SubmitHeader onBackPress={goBack}>
            {i18n.t('CouponsSummary.Header')}
          </SubmitHeader>
        </Container>
      </StockImageLayout>
    </>
  )
}
export default observer(CouponsSummary)
