import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {View} from 'react-native'
import {CheckBox, Divider, Text} from 'react-native-elements'
import {ScrollView} from 'react-native-gesture-handler'
import {
  Container,
  Content,
  MyButton,
  MyInput,
  MyLabelValueHorizontal,
  MySelectList,
  MySlider,
  SubmitHeader,
  SubmitHeaderButton,
  ToprScreenLoader,
} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import {GetEnumTranslation} from '../../../Helpers/EnumHelpers'
import {navigate} from '../../../Helpers/NavigationHelper'
import {
  FormatCentsToEuro,
  FormatEuro,
  GetWalletPagetitle,
} from '../../../Helpers/TextHelpers'
import {default as i18n, default as I18n} from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {
  AutomaticChargeStatusTypesDTO,
  IDebtorBank,
} from '../../../Models/Server'
import {getPaymentMethodTransactionCosts} from '../../../Models/Server/Wallet/ICurrencyChargeMethodSTO'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style, {colors, sizes} from '../../../Style'
import {automaticChargeStore, ChargeFormData, FormModes} from './Store'

interface IProps extends StackScreenProps<MainScreensList, 'automaticCharge'> {}

const AutomaticCharge: React.FC<IProps> = ({
  navigation: {setOptions},
  route,
}) => {
  const currencyGuid = route.params.currencyGuid
  const {noteText} = Style

  useEffect(() => {
    myLog.info('Charge', currencyGuid)
    automaticChargeStore.Initialize(currencyGuid)
  }, [currencyGuid])

  const {
    IsLoading,
    Wallet,
    IsSubmitting,
    Banks,
    SelectedBank,
    Status,
    DefaultValues,
    PreviousAutomaticCharge,
    AutomaticChargeStatusEnabled,
    FormMode,
  } = automaticChargeStore

  useEffect(() => {
    if (Wallet) {
      setOptions({
        title: GetWalletPagetitle(
          i18n.t('WalletDetails.AutomaticCharge'),
          Wallet.Currency.Name,
        ),
      })
    }
  }, [Wallet])

  const {control, handleSubmit, errors, formState, setError} =
    useForm<ChargeFormData>({
      mode: 'onChange',
      defaultValues: DefaultValues,
    })

  const onSubmit = (data: ChargeFormData) => {
    automaticChargeStore.SaveAutomaticCharge(data, (errorMessage: string) => {
      setError('amount', {
        type: 'manual',
        message: errorMessage,
      })
    })
  }

  if (IsLoading) {
    return <ToprScreenLoader isLoading={IsLoading} />
  }

  if (Wallet === undefined) {
    return null
  }

  const renderSubmitHeader = () => {
    if (FormMode === FormModes.ShowStatus) {
      return null
    }

    const renderSubmit = () => {
      return (
        <SubmitHeaderButton
          isLoading={IsSubmitting}
          title={I18n.t('Common.Save')}
          onPress={handleSubmit(onSubmit)}
        />
      )
    }

    return (
      <SubmitHeader
        onBackPress={() => {
          if (FormMode === FormModes.ShowStatus) {
            navigate('walletDetails', {
              currencyGuid: Wallet.Currency.CurrencyGuid,
            })
          } else {
            automaticChargeStore.SetFormMode(FormModes.ShowStatus)
          }
        }}>
        {renderSubmit()}
      </SubmitHeader>
    )
  }

  const renderTransactionCosts = () => {
    const {AutomaticCharePaymentMethod, Amount} = automaticChargeStore
    if (AutomaticCharePaymentMethod === undefined) {
      return null
    }

    const calculatedTransactionCosts = getPaymentMethodTransactionCosts(
      AutomaticCharePaymentMethod,
      Amount,
    )

    if (calculatedTransactionCosts === 0) {
      return 0
    }

    const renderCostMessage = (transactionCosts: number) => {
      return (
        <Text
          style={[
            noteText,
            {alignSelf: 'flex-end', marginBottom: sizes.padding.md},
          ]}>
          {I18n.t('AutomaticCharge.Form.ChargeCostsMessage')}
          <Text style={{color: colors.main, fontSize: 12}}>
            {FormatCentsToEuro(transactionCosts)}
          </Text>
        </Text>
      )
    }

    if (AutomaticCharePaymentMethod.TransactionCostsFixed > 0) {
      return renderCostMessage(calculatedTransactionCosts)
    }

    if (AutomaticCharePaymentMethod.TransactionCostsPercentage > 0) {
      if (calculatedTransactionCosts > 0) {
        return renderCostMessage(calculatedTransactionCosts)
      }
    }
  }

  const renderForm = () => {
    const {
      AutomaticChargeStepAmount,
      AutomaticChargeStepAmountMax,
      AutomaticChargeStepAmountMin,
      Name: CurrencyName,
    } = Wallet.Currency

    const renderBankform = () => {
      return (
        <>
          <MySelectList
            formObject={{} as ChargeFormData}
            name="selectedBank"
            modalPosition="center"
            validation={{
              errors,
              rules: {
                required: I18n.t(
                  'AutomaticCharge.Form.Fields.SelectedBank.Errors.Required',
                ),
              },
            }}
            control={control}
            label={I18n.t('AutomaticCharge.Form.Fields.SelectedBank.Label')}
            noItemSelectedText={I18n.t(
              'AutomaticCharge.Form.Fields.SelectedBank.EmptyText',
            )}
            options={Banks}
            selectedOption={SelectedBank}
            onSelect={(option: IDebtorBank) => {
              automaticChargeStore.SetSelectedBank(option)
            }}
            isItemSelected={(a: IDebtorBank, b: IDebtorBank) => {
              return a?.DebtorBankId === b?.DebtorBankId
            }}
            renderListItem={(bank: IDebtorBank, isHighLighted: boolean) => {
              return (
                <Text style={[isHighLighted && {color: colors.main}]}>
                  {bank.DebtorBankName}
                </Text>
              )
            }}
          />
          <MyInput
            formObject={{} as ChargeFormData}
            name="initials"
            label={I18n.t('AutomaticCharge.Form.Fields.Initials.Label')}
            control={control}
            inputProps={{
              autoCapitalize: 'none',
            }}
          />
          <MyInput
            formObject={{} as ChargeFormData}
            name="surname"
            label={I18n.t('AutomaticCharge.Form.Fields.Surname.Label')}
            validation={{
              errors,
              rules: {
                required: I18n.t(
                  'AutomaticCharge.Form.Fields.Surname.Errors.Required',
                ),
              },
            }}
            control={control}
            inputProps={{
              autoCapitalize: 'none',
            }}
          />
          <MyInput
            formObject={{} as ChargeFormData}
            name="email"
            label={I18n.t('AutomaticCharge.Form.Fields.Email.Label')}
            validation={{
              errors,
              rules: {
                required: I18n.t(
                  'AutomaticCharge.Form.Fields.Email.Errors.Required',
                ),
              },
            }}
            control={control}
            inputProps={{
              autoCapitalize: 'none',
              keyboardType: 'email-address',
            }}
          />
          <MyInput
            formObject={{} as ChargeFormData}
            name="phone"
            label={I18n.t('AutomaticCharge.Form.Fields.Phone.Label')}
            validation={{
              errors,
              rules: {
                required: I18n.t(
                  'AutomaticCharge.Form.Fields.Phone.Errors.Required',
                ),
              },
            }}
            control={control}
            inputProps={{
              autoCapitalize: 'none',
              keyboardType: 'phone-pad',
            }}
          />
          <MyInput
            formObject={{} as ChargeFormData}
            name="street"
            label={I18n.t('AutomaticCharge.Form.Fields.Street.Label')}
            validation={{
              errors,
              rules: {
                required: I18n.t(
                  'AutomaticCharge.Form.Fields.Street.Errors.Required',
                ),
              },
            }}
            control={control}
            inputProps={{
              autoCapitalize: 'none',
            }}
          />
          <MyInput
            formObject={{} as ChargeFormData}
            name="streetNumber"
            label={I18n.t('AutomaticCharge.Form.Fields.StreetNumber.Label')}
            validation={{
              errors,
              rules: {
                required: I18n.t(
                  'AutomaticCharge.Form.Fields.StreetNumber.Errors.Required',
                ),
              },
            }}
            control={control}
            inputProps={{
              autoCapitalize: 'none',
            }}
          />
          <MyInput
            formObject={{} as ChargeFormData}
            name="zipcode"
            label={I18n.t('AutomaticCharge.Form.Fields.Zipcode.Label')}
            validation={{
              errors,
              rules: {
                required: I18n.t(
                  'AutomaticCharge.Form.Fields.Zipcode.Errors.Required',
                ),
              },
            }}
            control={control}
            inputProps={{
              autoCapitalize: 'none',
            }}
          />
          <MyInput
            formObject={{} as ChargeFormData}
            name="city"
            label={I18n.t('AutomaticCharge.Form.Fields.City.Label')}
            validation={{
              errors,
              rules: {
                required: I18n.t(
                  'AutomaticCharge.Form.Fields.City.Errors.Required',
                ),
              },
            }}
            control={control}
            inputProps={
              {
                //  autoCapitalize: 'none',
              }
            }
          />
        </>
      )
    }

    const renderStepsForm = () => {
      return (
        <>
          <MySlider
            name="amount"
            formObject={{} as ChargeFormData}
            minimumValue={AutomaticChargeStepAmountMin}
            maximumValue={AutomaticChargeStepAmountMax}
            formatValue={FormatEuro}
            step={AutomaticChargeStepAmount}
            control={control}
            onSlideChange={(amount) => {
              automaticChargeStore.SetAmount(amount)
            }}
            label={I18n.t('AutomaticCharge.Form.Fields.Amount.Label')}
          />
          <MySlider
            name="weekAmount"
            formObject={{} as ChargeFormData}
            minimumValue={1}
            maximumValue={10}
            step={1}
            control={control}
            label={I18n.t('AutomaticCharge.Form.Fields.WeeksAmount.Label')}
          />
          {renderTransactionCosts()}
        </>
      )
    }

    const renderStatusInfo = () => {
      if (
        PreviousAutomaticCharge === null ||
        PreviousAutomaticCharge === undefined
      ) {
        return (
          <MyButton
            title={I18n.t('AutomaticCharge.Buttons.Create')}
            onPress={() => {
              automaticChargeStore.SetFormMode(FormModes.NewAutomaticCharge)
            }}
          />
        )
      } else if (AutomaticChargeStatusEnabled) {
        return (
          <>
            <MyLabelValueHorizontal
              value={FormatCentsToEuro(PreviousAutomaticCharge.StepAmount)}
              label={I18n.t('AutomaticCharge.Form.Fields.Amount.Label')}
            />
            <MyLabelValueHorizontal
              value={PreviousAutomaticCharge.StepLimit.toString()}
              label={I18n.t('AutomaticCharge.Form.Fields.WeeksAmount.Label')}
              style={{marginBottom: sizes.padding.md}}
            />

            <MyLabelValueHorizontal
              value={PreviousAutomaticCharge.AccountNumber}
              label={I18n.t('AutomaticCharge.Form.Fields.SelectedBank.Label')}
            />
            <MyLabelValueHorizontal
              value={PreviousAutomaticCharge.LastName}
              label={I18n.t('AutomaticCharge.Form.Fields.Surname.Label')}
            />
            <MyLabelValueHorizontal
              value={PreviousAutomaticCharge.EmailAddress}
              label={I18n.t('AutomaticCharge.Form.Fields.Email.Label')}
              style={{marginBottom: sizes.padding.md}}
            />

            <MyButton
              title={I18n.t('AutomaticCharge.Buttons.EditAmount')}
              onPress={() => {
                automaticChargeStore.SetFormMode(FormModes.EditSteps)
              }}
              buttonStyle={{marginBottom: sizes.padding.md}}
            />
            <MyButton
              title={I18n.t('AutomaticCharge.Buttons.EditBank')}
              onPress={() => {
                automaticChargeStore.SetFormMode(FormModes.NewAutomaticCharge)
              }}
              buttonStyle={{marginBottom: sizes.padding.md}}
            />
            <MyButton
              title={I18n.t('AutomaticCharge.Buttons.TurnOff')}
              onPress={() => {
                appUiStore.ShowConfirm(
                  I18n.t('AutomaticCharge.Form.ConfirmTurnOff', {
                    currencyName: CurrencyName,
                  }),
                  () => {
                    automaticChargeStore.TurnOff((errorMessage: string) => {
                      setError('amount', {
                        type: 'manual',
                        message: errorMessage,
                      })
                    })
                  },
                )
              }}
            />
          </>
        )
      }
    }

    switch (FormMode) {
      case FormModes.ShowStatus:
      default:
        return renderStatusInfo()
      case FormModes.NewAutomaticCharge:
        //case FormModes.EditAutomaticChargeFull:
        return (
          <>
            {renderStepsForm()}
            {renderBankform()}
          </>
        )
      case FormModes.EditSteps:
        return renderStepsForm()
    }
  }

  const GetStatusCheckBox = () => {
    const statusText = GetEnumTranslation(
      'AutomaticChargeStatusTypesDTO',
      AutomaticChargeStatusTypesDTO[Status],
    )

    const isEnabled =
      Status === AutomaticChargeStatusTypesDTO.EnabledWithVerification ||
      Status === AutomaticChargeStatusTypesDTO.EnabledWithoutVerification

    const getCheckBoxProperties = (): {
      color: string
      backGroundColor: string
      checkedIcon: string
      unCheckedIcon: string
    } => {
      const props = {
        backGroundColor: 'white',
        color: 'black',
        checkedIcon: 'check-square-o',
        unCheckedIcon: 'square-o',
      }
      switch (Status) {
        case AutomaticChargeStatusTypesDTO.EnabledWithVerification:
        case AutomaticChargeStatusTypesDTO.EnabledWithoutVerification: {
          props.backGroundColor = colors.green
          return props
        }
        case AutomaticChargeStatusTypesDTO.Pending: {
          props.backGroundColor = colors.yellow
          props.unCheckedIcon = 'hourglass-half'

          return props
        }
        case AutomaticChargeStatusTypesDTO.Blocked: {
          props.backGroundColor = colors.darkRed
          props.color = 'white'
          props.unCheckedIcon = 'ban'
          return props
        }

        case AutomaticChargeStatusTypesDTO.Off:
        case AutomaticChargeStatusTypesDTO.Denied:
        case AutomaticChargeStatusTypesDTO.Initial:
        default: {
          props.backGroundColor = 'white'
          return props
        }
      }
    }

    const props = getCheckBoxProperties()

    return (
      <>
        <Text>
          {i18n.t('AutomaticCharge.Form.Title', {
            currencyName: Wallet.Currency.Name,
          })}
        </Text>
        <CheckBox
          title={`${i18n.t('Common.Status')}: ${statusText}`}
          disabled={true}
          checked={isEnabled}
          checkedColor={props.color}
          uncheckedColor={props.color}
          checkedIcon={props.checkedIcon}
          uncheckedIcon={props.unCheckedIcon}
          textStyle={{color: props.color}}
          containerStyle={{backgroundColor: props.backGroundColor}}
        />
      </>
    )

    //const backgroundColor =
  }

  return (
    <>
      <StockImageLayout>
        <Container keyboardAware={false}>
          <Content headerMargin>
            <ScrollView>
              {GetStatusCheckBox()}
              <Divider style={{marginVertical: sizes.padding.md}} />
              {renderForm()}
            </ScrollView>
          </Content>
          <SubmitHeader
            onBackPress={() =>
              navigate('walletDetails', {
                currencyGuid: Wallet.Currency.CurrencyGuid,
              })
            }>
            {renderSubmitHeader()}
          </SubmitHeader>
        </Container>
      </StockImageLayout>
    </>
  )
}
export default observer(AutomaticCharge)
