import {ImageURISource} from 'react-native'
import AppConfig from '../Config/AppConfig'
import i18n from '../i18n'
import I18n from '../i18n'
import StockImages from '../Images/StockImages'
import SvgImages from '../Images/SvgImages'
import {IAlertMessage} from '../Models/Client'
import {DayStage} from './DateHelpers'
//import {useToast} from 'react-native-fast-toast'

//const toast = useToast()

export const GetLocalizedImageUrl = (
  guid?: string,
  noCache?: boolean,
): string => {
  guid = guid !== undefined && guid !== null ? guid.replace(/[/{/}]/g, '') : ''

  const noCacheQsp = noCache === true ? `?noCache=true&${GetRandomKey()}` : ''
  return `${AppConfig.Service.BaseUrl}api/content/v1/localizedimage/${guid}/nl-NL${noCacheQsp}`
}

export const GetFileUrl = (guid?: string, noCache?: boolean): string => {
  guid = guid !== undefined && guid !== null ? guid.replace(/[/{/}]/g, '') : ''

  //  console.log('GetFileUrl', noCache)

  const noCacheQsp = noCache === true ? `?noCache=true&${GetRandomKey()}` : ''
  const url = `${AppConfig.Service.BaseUrl}api/content/v1/file/${guid}${noCacheQsp}`

  return url
}
//

export const GetUserTicketQrCodeUrl = (userEventTicketId: number): string => {
  const url = `${AppConfig.Service.BaseUrl}api/content/v1/user/ticket/${userEventTicketId}/qrcode`
  return url
}

export const GetRandomKey = () => {
  return (
    new Date().getTime().toString() +
    Math.floor(Math.random() * Math.floor(new Date().getTime())).toString()
  )
}

export const NotImplemented = () => {
  console.log('NOT_IMPLEMENTED')
}

export const GetImageSource = (
  imageUrl?: string,
  image?: any,
): ImageURISource => {
  if (imageUrl === undefined || imageUrl === '') {
    return image
  }

  return {
    uri: imageUrl,
  }
}

export const GetImageUrl = (imageUrl?: string, image?: any) => {
  if (imageUrl === undefined || imageUrl === '') {
    return `url(${image})`
  }

  return `url(${imageUrl})`
}

// export const GetRandStockImageUrl = () => {
//   const keys = Object.keys(StockImages)
//   const randKey = keys[Math.floor(Math.random() * keys.length)]
//   return `url(${StockImages[randKey]})`
// }

export const GetRandStockImage = () => {
  const keys = Object.keys(StockImages)
  const randKey = keys[Math.floor(Math.random() * keys.length)]
  return StockImages[randKey]
}

export const GetRandSvgImage = () => {
  const keys = Object.keys(SvgImages)
  const randKey = keys[Math.floor(Math.random() * keys.length)]

  const svgImage = SvgImages[randKey]
  const dayStage = DayStage()
  return svgImage[dayStage]
}

export const GetI18NAlertMessage = (
  key: string,
  code: string,
): IAlertMessage => {
  const errors = i18n.t(key) as any
  if (errors && code && errors[code]) {
    const title = errors['Title']
    const message = errors[code]

    return {title, message, type: 'error'}
  }

  return {
    title: I18n.t('Common.ServerErrors.Title'),
    message: I18n.t('Common.ServerErrors.EXCEPTION', {code}),
    type: 'error',
  }
}
