import React, {useEffect} from 'react'
import {useToast} from 'react-native-fast-toast'
import {IToastMessage} from '../../Models/Client'

export const ToastTimeout = 3000

export interface IProps {
  toastMessage?: IToastMessage
}

const MyToast: React.FC<IProps> = ({toastMessage}) => {
  const toast = useToast()

  useEffect(() => {
    if (toast && toastMessage) {
      toast.show(toastMessage.message, {
        type: toastMessage.type,
        duration: ToastTimeout,
      })
    }
  }, [toastMessage])

  return null
}

export default MyToast
