import {makeAutoObservable, runInAction} from 'mobx'
import {DoServiceCall} from '../../../Helpers/ServiceHelpers'
import {myLog} from '../../../Services/Client/LogService'
import i18n from '../../../i18n'
import CouponController from '../../../Services/Topr.Core/CouponController'
import {ICouponSummarySTO} from '../../../Models/Server'
import {appUiStore} from '../../../Mobx/AppUiStore'

export default class Store {
  public IsLoading = true
  public CouponSummaries: ICouponSummarySTO[] = []

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize() {
    this.IsLoading = true
    CouponController.GetCouponSummaries()
      .then((couponSummaries) => {
        runInAction(() => {
          this.CouponSummaries = couponSummaries
          this.IsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }
}
export const couponSummaryStore = new Store()
