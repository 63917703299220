import React from 'react'
import {Platform, StyleSheet, TextStyle, View} from 'react-native'
import {Text} from 'react-native-elements'
import AppConfig from '../../Config/AppConfig'
import {sizes} from '../../Style'
interface IProps {
  inListItem?: boolean
}

const VersionLabel: React.FC<IProps> = ({inListItem}) => {
  const version =
    Platform.OS === 'android'
      ? AppConfig.Tenant.Version.Android
      : AppConfig.Tenant.Version.Ios

  const textStyle: TextStyle = inListItem
    ? {}
    : {
        marginHorizontal: sizes.padding.md,
        marginBottom: sizes.padding.md,
        textAlign: 'right',
      }
  return (
    <View style={{backgroundColor: '#fff'}}>
      <Text style={textStyle}>{version}</Text>
    </View>
  )
}

export default VersionLabel

VersionLabel.defaultProps = {
  inListItem: false,
}
