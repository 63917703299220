import {observer} from 'mobx-react'
import React from 'react'
import {
  //Image,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  View,
  Image,
} from 'react-native'

import {Icon} from 'react-native-elements'
import {GetFileUrl} from '../../Helpers/UiHelpers'
import {IUserSTO} from '../../Models/Server'
import Style, {colors} from '../../Style'
import {GetFirstLetterUser} from '../../Helpers/TextHelpers'
interface IProps extends TouchableWithoutFeedbackProps {
  user: IUserSTO | undefined
}

const ProfileButtonEmail: React.FC<IProps> = ({user, ...otherProps}) => {
  const {profileColorButton, profilePictureImage} = Style
  const renderProfile = () => {
    if (user === undefined) {
      return null
    }

    if (user.UserPictureFileGuid) {
      return renderImage()
    } else {
      return renderFirstLetter()
    }
  }

  const renderImage = () => {
    if (user !== undefined && user.UserPictureFileGuid === null) {
      return null
    }

    return (
      <img
        src={GetFileUrl(user?.UserPictureFileGuid?.toString(), true)}
        className="profilePictureImage"
      />
    )
  }

  const renderFirstLetter = () => {
    return (
      <View style={profileColorButton}>
        <Text style={{color: '#fff', textTransform: 'uppercase'}}>
          {GetFirstLetterUser(user)}
        </Text>
      </View>
    )
  }

  return (
    <>
      <TouchableOpacity {...otherProps}>
        <div className="is-flex is-flex-direction-row is-justify-content-center">
          {renderProfile()}
          <div className="is-flex is-flex-direction-column pl-2 is-flex-grow-1 is-justify-content-center is-hidden-touch">
            <b>{user?.Name}</b>
            {user?.EmailAddress !== null && (
              <div className="profile-email">{user?.EmailAddress}</div>
            )}
          </div>
          <div className="is-flex is-align-items-center is-hidden-touch">
            <Icon name="ellipsis-horizontal-sharp" type="ionicon" />
          </div>
        </div>
      </TouchableOpacity>
    </>
  )
}

export default observer(ProfileButtonEmail)
