import {observer} from 'mobx-react'
import React from 'react'
import {FlatList, RefreshControl} from 'react-native'
import {ImageTextCard, ToprScreenLoader} from '../../../../Components'
import {navigate} from '../../../../Helpers/NavigationHelper'
import {GetLocalText} from '../../../../Helpers/TextHelpers'
import {GetFileUrl, GetRandomKey} from '../../../../Helpers/UiHelpers'
import Style from '../../../../Style'
import {dashboardStore} from '../../Dashboard/Store'
import {dashboardHeaderStore} from '../DashboardHeader/Store'
import {LastItem} from '../../../../Helpers/ArrayHelpers'

const TicketsTab: React.FC = () => {
  const {IsUserEventsLoading, UserEvents} = dashboardStore
  const {defaultFlatList, flatListContentStyle} = Style
  return (
    <ToprScreenLoader isLoading={IsUserEventsLoading}>
      <FlatList
        bounces={false}
        onScroll={(event) => {
          dashboardHeaderStore.SetScrollY(event.nativeEvent.contentOffset.y)
        }}
        style={[
          defaultFlatList,
          {paddingTop: dashboardHeaderStore.HEADER_MAX_HEIGHT * 2},
        ]}
        refreshControl={
          <RefreshControl
            refreshing={IsUserEventsLoading}
            progressViewOffset={dashboardHeaderStore.HEADER_MAX_HEIGHT * 2}
            onRefresh={() => {
              dashboardStore.GetUserEvents()
            }}
          />
        }
        contentContainerStyle={[flatListContentStyle]}
        data={UserEvents}
        renderItem={({item}) => {
          const badgeText = item.UserEventTickets.length.toString()
          const lastItem = LastItem(UserEvents)
          return (
            <ImageTextCard
              isLast={lastItem?.EventId === item.EventId}
              key={item.EventId}
              imageUrl={GetFileUrl(item.ImageFileGuid)}
              title={GetLocalText(item.Name)}
              onPress={() => {
                navigate('ticketDetails', {
                  userEvent: item,
                })
              }}
              badgeText={badgeText}
            />
          )
        }}
        keyExtractor={() => {
          return GetRandomKey()
        }}
      />
    </ToprScreenLoader>
  )
}

export default observer(TicketsTab)
