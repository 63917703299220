import {observer} from 'mobx-react'
import React from 'react'
import {ImageCard} from '../../../../Components'
import CouponsCardWeb from '../../../../Components/Cards/CouponsCardWeb'
import {HorizontalScroll} from '../../../../Components/Web'
import {navigate} from '../../../../Helpers/NavigationHelper'
import i18n from '../../../../i18n'
import {dashboardStore} from '../../Dashboard/Store.web'

import ToprRowLoader from './ToprRowLoader.web'

interface IProps {}

const CouponsScroll: React.FC<IProps> = ({children}) => {
  const {GroupedCoupons, IsCouponsLoading} = dashboardStore

  const renderNoCoupons = () => {
    if (GroupedCoupons.length > 0) {
      return null
    }

    return (
      <ImageCard
        imageUrl="https://images.unsplash.com/photo-1594968973184-9040a5a79963?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80"
        text={i18n.t('Dashboard.NoCoupons')}
      />
    )
  }

  const renderCards = () => {
    return GroupedCoupons.map((groupedCoupon, key) => (
      <CouponsCardWeb
        key={key}
        onPress={() => {
          navigate('couponDetails', {
            userCouponGuid: groupedCoupon.UserCoupons[0].UserCouponGUID,
          })
        }}
        groupedCoupon={groupedCoupon}
      />
    ))
  }

  if (IsCouponsLoading) {
    return <ToprRowLoader />
  }

  return (
    <>
      <h1 className="title is-4 pt-4">{i18n.t('Common.Coupons')}</h1>
      <HorizontalScroll>
        {renderCards()}
        {renderNoCoupons()}
      </HorizontalScroll>
    </>
  )
}

export default observer(CouponsScroll)
