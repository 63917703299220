import React, {useState} from 'react'
import ReactMarkdown from 'react-markdown'
import {HelpItem} from '../../../Models/Strapi/Models'
import { IHelpItemViewModel } from '../../../Services/Topr.Umbraco/Models/IHelpItemViewModel'
interface IProps {
  helpItem: IHelpItemViewModel
}

const HelpItemView: React.FC<IProps> = ({helpItem}) => {
  
  return (
    <div className="column help-item markdown">
      <h4 className="question">{helpItem.Question}</h4>
      <div dangerouslySetInnerHTML={{__html: helpItem.Answer}} /> 
      
    </div>
  )
}

export default HelpItemView
