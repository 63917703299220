import {StackNavigationProp} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import * as React from 'react'
import {useEffect, useRef} from 'react'
import {AppState, AppStateStatus} from 'react-native'
import {AddAccountModal} from '../../../Components'

import {MainScreensList} from '../../../Navigation'
import {linkingStore} from '../../../Navigation/LinkingStore'
import {myLog} from '../../../Services/Client/LogService'
import {AddMenu, DashboardHeader, DashboardTabs, UserMenu} from '../Components'
import ChildUsersModal from '../Components/UserMenu/ChildUsersModal'
import {userMenuStore} from '../Components/UserMenu/Store'
import {dashboardStore} from './Store'
import {useNavigation} from '@react-navigation/native'
import {navigate} from '../../../Helpers/NavigationHelper'

//interface IProps extends StackScreenProps<MainScreensList, 'dashboard'> {}

type IProps = StackNavigationProp<MainScreensList, 'dashboard'>

//const Dashboard: React.FC<IProps> = ({navigation: {navigate}}) => {
const Dashboard: React.FC = () => {
  //!!! carefull, for invalid hook call on release ?!? !!!

  //  const navigation = useNavigation<IProps>()
  //  const {navigate} = navigation
  //!!! carefull, for invalid hook call on release ?!? !!!

  const appState = useRef(AppState.currentState)
  const _handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      dashboardStore.Initialize()
      //refresh when app returns to foreground
      // dashboardStore.SetIsAuthenticationMethodCheckLoading(true)
      // appUiStore
      //   .CheckAuthenticationMethodState()
      //   .then(() => dashboardStore.GetAllData())
      //   .catch(() => {
      //     navigate('Pincode')
      //   })
    }

    appState.current = nextAppState
  }
  useEffect(() => {
    myLog.warn('Dashboard')

    // navigate('automaticCharge', {
    //   currencyGuid: 'e4a6eb11-9b75-4d7f-9bc1-b1e1e469bf19',
    // })

    AppState.addEventListener('change', _handleAppStateChange)
    dashboardStore.Initialize()
    return linkingStore.Initialize()
  }, [dashboardStore])

  const {IsUserMenuVisible, IsAddMenuVisible} = dashboardStore

  return (
    <>
      <DashboardTabs />
      <AddMenu
        isVisible={IsAddMenuVisible}
        onClose={() => dashboardStore.ToggleAddMenu(false)}
      />
      <UserMenu
        isVisible={IsUserMenuVisible}
        onClose={() => dashboardStore.ToggleUserMenu(false)}
      />
      <ChildUsersModal
        isVisible={userMenuStore.ShowShildUsers}
        onClose={() => userMenuStore.ToggleChildMenu()}
      />
      <AddAccountModal />
      <DashboardHeader />
    </>
  )
}

export default observer(Dashboard)
