import {makeAutoObservable, runInAction} from 'mobx'
import {IUserCouponSummarySTO, IUserSavingCardSTO} from '../../../Models/Server'
import CouponController from '../../../Services/Topr.Core/CouponController'
import SavingcardController from '../../../Services/Topr.Core/SavingcardController'

export default class Store {
  public IsLoading = true
  public SavingCardGuid = ''
  public UserSavingCards: IUserSavingCardSTO[] = []
  private Skip = 0
  public Take = 20
  public HasMore = true
  public HasMoreLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize(savingCardGuid: string) {
    this.IsLoading = true
    this.SavingCardGuid = savingCardGuid
    this.GetUserSavingCards(true)
  }

  private GetUserSavingCards(reload: boolean) {
    this.HasMoreLoading = true

    if (reload) {
      this.Skip = 0
    }

    SavingcardController.GetUserSavingCards(
      this.SavingCardGuid,
      this.Skip,
      this.Take,
    )
      .then((userSavingCards) => {
        runInAction(() => {
          if (reload) {
            this.UserSavingCards = userSavingCards
          } else {
            this.UserSavingCards.push(...userSavingCards)
          }
          this.IsLoading = false
          this.HasMoreLoading = false
          this.HasMore = userSavingCards.length === this.Take
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public NextPage() {
    if (!this.HasMoreLoading && this.HasMore) {
      this.Skip += this.Take
      console.log('nextPage', this.Skip)
      this.GetUserSavingCards(false)
    }
  }
}
export const userSavingCardsSummaryStore = new Store()
