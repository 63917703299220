import React from 'react'
import {TextStyle} from 'react-native'
import {Icon} from 'react-native-elements'
import {AlertType} from '../../Models/Client/ErrorHandling/AlertType'
import {colors, sizes} from '../../Style'
interface IProps {
  type?: AlertType
}

const MyAlertIcon: React.FC<IProps> = ({type}) => {
  const iconStyle: TextStyle = {
    fontSize: 50,
    marginBottom: sizes.padding.md,
  }

  const myType = type || 'error'

  switch (myType) {
    case 'warning':
      return (
        <Icon
          name="info-outline"
          type="material"
          iconStyle={{
            ...iconStyle,
            color: colors.orange,
          }}
        />
      )
    case 'error':
      return (
        <Icon
          name="close-o"
          type="evilicon"
          iconStyle={{
            ...iconStyle,
            color: colors.darkRed,
          }}
        />
      )
    case 'success':
    default:
      return (
        <Icon
          name="check"
          type="evilicon"
          iconStyle={{
            ...iconStyle,
            color: colors.green,
          }}
        />
      )
      break
  }
}

export default MyAlertIcon
