import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Card} from 'react-native-elements'
import MyModal from './../MyModal'
import i18n from '../../../i18n'
import Style, {sizes} from '../../../Style'
import MyFooter from '../../Form/MyFooter'
import MyFooterTile from '../../Form/MyFooterTile'
import MyButton from '../../Buttons/MyButton'
import FormHeader from '../../Form/FormHeader'
import MyInput from '../../Form/MyInput'
import {useForm} from 'react-hook-form'
import Content from '../../Layout/Content'
import {addAccountStore} from './Store'
import {observer} from 'mobx-react'

interface IProps {}

export type MyFormData = {
  name: string
}

const AddAccountModal: React.FC<IProps> = () => {
  const {control, handleSubmit, errors, setError, setValue} =
    useForm<MyFormData>()

  const {IsSubmitting, IsVisible} = addAccountStore

  const onSubmit = (data: MyFormData) => {
    addAccountStore.AddAccount(data.name)
  }

  return (
    <MyModal
      onClose={() => addAccountStore.ToggleModal()}
      visible={IsVisible}
      closeOnBackPress
      position="center">
      <Card.Title>{i18n.t('UserMenu.Account.Add')}</Card.Title>
      <View>
        <MyInput
          formObject={{} as MyFormData}
          name="name"
          autofocus={true}
          control={control}
          inputProps={{
            autoCapitalize: 'none',
            onSubmitEditing: () => {
              handleSubmit((data) => onSubmit(data))()
            },
          }}
          validation={{
            errors,
            rules: {
              required: i18n.t(
                'AddAccountModal.Form.NameField.Errors.Required',
              ),
            },
          }}
          label={i18n.t('AddAccountModal.Form.NameField.Label')}
        />
      </View>
      <MyFooter>
        <MyFooterTile>
          <MyButton
            type="outline"
            onPress={() => {
              addAccountStore.ToggleModal()
            }}
            style={{marginRight: sizes.padding.sm}}
            title={i18n.t('Common.Cancel')}
          />
        </MyFooterTile>
        <MyFooterTile isLast>
          <MyButton
            isLoading={IsSubmitting}
            title={i18n.t('AddAccountModal.Form.Action')}
            onPress={handleSubmit(onSubmit)}
          />
        </MyFooterTile>
      </MyFooter>
    </MyModal>
  )
}

export default observer(AddAccountModal)

const styles = StyleSheet.create({})
