import React from 'react'
import {Keyboard, View} from 'react-native'
import Style, {sizes} from '../../Style'
import MyModal from './MyModal'
import {appUiStore} from '../../Mobx/AppUiStore'
import MyButton from '../Buttons/MyButton'
import i18n from '../../i18n'
import {Card, Text} from 'react-native-elements'
import {observer} from 'mobx-react'
import I18n from 'i18n-js'
//import {MyFooter, MyFooterTile} from '..'
import {goBack} from '../../Helpers/NavigationHelper'
import MyFooter from '../Form/MyFooter'
import MyFooterTile from '../Form/MyFooterTile'
import Content from '../Layout/Content'
interface IProps {}

const ConfirmModal: React.FC<IProps> = ({children}) => {
  const {ConfirmMessage} = appUiStore

  const visible = ConfirmMessage !== undefined
  const {modalMessage} = Style
  if (ConfirmMessage === undefined) {
    return null
  }
  return (
    <MyModal
      visible={visible}
      onClose={() => {
        appUiStore.CloseConfirm()
      }}>
      <Card.Title style={{paddingHorizontal: sizes.padding.md}}>
        {ConfirmMessage.Message}
      </Card.Title>
      {ConfirmMessage.AdditionalText && (
        <Content>
          <Text>{ConfirmMessage.AdditionalText}</Text>
        </Content>
      )}
      {/* <Text style={modalMessage}>{ConfirmMessage.Message}</Text> */}
      <MyFooter>
        <MyFooterTile>
          <MyButton
            type="outline"
            onPress={() => {
              appUiStore.CloseConfirm()
            }}
            style={{marginRight: sizes.padding.sm}}
            title={i18n.t('Common.Cancel')}
          />
        </MyFooterTile>
        <MyFooterTile isLast>
          <MyButton
            onPress={() => {
              ConfirmMessage.onOk()
              appUiStore.CloseConfirm()
            }}
            title={i18n.t('Common.Ok')}
          />
        </MyFooterTile>
      </MyFooter>
    </MyModal>
  )
}

export default observer(ConfirmModal)
