import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Icon, ListItem, Text} from 'react-native-elements'
import Style, {colors} from '../../Style'
interface IProps {
  title: string
}

const CardFooter: React.FC<IProps> = ({title}) => {
  const {title: titleStyle} = Style
  return (
    <ListItem style={{borderTopColor: colors.lightGrey, borderTopWidth: 1}}>
      <ListItem.Content style={{}}>
        <Text style={titleStyle}>{title}</Text>
      </ListItem.Content>
      <ListItem.Chevron />
    </ListItem>
  )
}

export default CardFooter
