import React from 'react'
import {View, KeyboardAvoidingView, ViewStyle, Platform} from 'react-native'
import {ScrollView} from 'react-native-gesture-handler'
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import Style from '../../Style'
interface IProps {
  keyboardAware?: boolean
  isGreyBackground?: boolean
  headerPadding?: boolean
  //onScroll?: (y: number) => void
}

const Container: React.FC<IProps> = ({
  children,
  keyboardAware,
  isGreyBackground,
  headerPadding,
  //onScroll,
}) => {
  const {container, containerBg} = Style

  const styles: ViewStyle[] = [container]

  if (headerPadding) {
    styles.push({
      paddingTop: 60,
    })
  }

  if (isGreyBackground) {
    styles.push(containerBg)
  }

  if (Platform.OS !== 'web' && keyboardAware) {
    return (
      <KeyboardAwareScrollView
        style={styles}
        // onScroll={(event) => {
        //   if (onScroll) {
        //     onScroll(event.nativeEvent.contentOffset.y)
        //   }
        // }}
        //  scrollEventThrottle={onScroll ? 5 : 0}
        //bounces={onScroll ? false : true}
      >
        {children}
      </KeyboardAwareScrollView>
    )
  }

  return <View style={[styles]}>{children}</View>
}

export default Container

Container.defaultProps = {
  keyboardAware: true,
  isGreyBackground: false,
  headerPadding: false,
}
