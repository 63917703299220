import {makeAutoObservable, runInAction} from 'mobx'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {IUserCouponSTO} from '../../../Models/Server'
import CouponController from '../../../Services/Topr.Core/CouponController'

export default class Store {
  public IsLoading = true

  public UserCoupon?: IUserCouponSTO = undefined

  constructor() {
    makeAutoObservable(this)
  }

  public GetUserCoupon(userCouponGuid: string) {
    this.IsLoading = true

    CouponController.GetUserCoupon(userCouponGuid)
      .then((userCoupon) => {
        runInAction(() => {
          this.UserCoupon = userCoupon
          this.IsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }
}
export const couponDetailsStore = new Store()
