import {makeAutoObservable, runInAction} from 'mobx'
import {DoServiceCall, Geti18NMessage} from '../../../Helpers/ServiceHelpers'
import {myLog} from '../../../Services/Client/LogService'
import i18n from '../../../i18n'
import UserController from '../../../Services/Topr.Core/UserController'
import {ToprError} from '../../../Models/Client'
import {goBack} from '../../../Helpers/NavigationHelper'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {IRequestIdentifier} from '../../../Models/Server'
import {differenceInSeconds, intervalToDuration} from 'date-fns'
import {FormatSeconds} from '../../../Helpers/DateHelpers'
import {BackHandler} from 'react-native'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'

let countDownTimer: NodeJS.Timeout
let refreshTimer: NodeJS.Timeout

const TimeOutServer = 600

export default class Store {
  public IsLoading = true
  public RequestIdentifier?: IRequestIdentifier = undefined
  public TimeOutSeconds = 0
  private ServerDifference = 0

  constructor() {
    makeAutoObservable(this)
  }

  private RequestIdentifierIsValid(requestIdentifier?: IRequestIdentifier) {
    if (requestIdentifier === undefined) {
      return false
    }

    const nowDevice = new Date()
    const validFrom = new Date(requestIdentifier.ValidFrom)
    const validTill = new Date(requestIdentifier.ValidTill)

    if (nowDevice < validFrom || nowDevice > validTill) {
      console.log('requestIdentifier invalid, refresh')
      return false
    }

    console.log('requestIdentifier VALID')

    return true
  }

  private StartTimer(
    requestIdentifier: IRequestIdentifier,
    setServerDifference: boolean,
  ) {
    this.ClearTimers()
    const timeOutInSeconds = differenceInSeconds(
      new Date(requestIdentifier.ValidTill),
      new Date(),
    )

    if (setServerDifference) {
      this.ServerDifference = TimeOutServer - timeOutInSeconds
    }

    //    this.TimeOutSeconds = timeOutInSeconds + this.ServerDifference

    this.TimeOutSeconds = timeOutInSeconds

    console.log(
      'timeOutInSeconds',
      timeOutInSeconds,
      this.TimeOutSeconds,
      new Date(requestIdentifier.ValidTill),
    )

    timeOutInSeconds
    //count down
    countDownTimer = setInterval(() => {
      runInAction(() => this.TimeOutSeconds--)
    }, 1000)

    // console.log(
    //   'timeout',
    //   timeOutInSeconds,
    //   FormatSeconds(timeOutInSeconds),
    //   this.ServerDifference,
    //   this.TimeOutSeconds,
    // )

    //refresh when finished
    refreshTimer = setTimeout(() => {
      this.ClearTimers()
      this.GetCrCode()
    }, timeOutInSeconds * 1000)
  }

  public Initialize() {
    this.IsLoading = true

    const onBackPress = () => {
      //refresh dashboard
      dashboardStore.Initialize()
      return false
    }

    BackHandler.addEventListener('hardwareBackPress', onBackPress)

    if (!this.RequestIdentifierIsValid(this.RequestIdentifier)) {
      this.GetCrCode()
      return () => this.onUnload()
    }
    this.IsLoading = false
    if (this.RequestIdentifier !== undefined) {
      this.StartTimer(this.RequestIdentifier, true)
    }

    return () => this.onUnload()
  }

  private ClearTimers() {
    clearInterval(countDownTimer)
    clearTimeout(refreshTimer)
  }

  private onUnload() {
    this.ClearTimers()
    BackHandler.removeEventListener('hardwareBackPress', null)
  }

  public GetCrCode() {
    this.IsLoading = true
    DoServiceCall(() => {
      UserController.CreateRequestIdentifier()
        .then((requestIdentifier) => {
          runInAction(() => {
            if (!this.RequestIdentifierIsValid(requestIdentifier)) {
              appUiStore.ShowAlert({
                title: i18n.t('Common.Error'),
                message: i18n.t('QrCode.InvalidClientDate'),
              })
              goBack()
            } else {
              this.IsLoading = false
              this.RequestIdentifier = requestIdentifier
              this.StartTimer(requestIdentifier, true)
            }
          })
        })
        .catch((toprError: ToprError) => {
          const errorMessage = Geti18NMessage(toprError)
          appUiStore.ShowToast({message: errorMessage})
          goBack()
        })
    })
  }
}
export const qrCodeStore = new Store()
