import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {ScrollView} from 'react-native'
import {Card, ListItem, Text} from 'react-native-elements'
import {
  Container,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyHeader,
} from '../../../Components'
import {FormatCurrency} from '../../../Helpers/TextHelpers'
import i18n from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style, {colors} from '../../../Style'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import {store as tokensStore} from '../../Token/Tokens/Store'
import {cancelAccountStore} from './Store'

interface IProps extends StackScreenProps<MainScreensList> {}

const CancelAccount: React.FC<IProps> = ({navigation: {goBack}, route}) => {
  useEffect(() => {
    myLog.warn('CancelAccount')
  }, [null])

  const {Wallets, User} = dashboardStore
  const {Tokens} = tokensStore
  const {MainUser} = persistedStore
  const {defaultFlatList, flatListContentStyle} = Style

  const renderWallets = () => {
    if (Wallets.length === 0) {
      return null
    }

    return (
      <Card>
        <Card.Title>{i18n.t('Common.Wallets')}</Card.Title>
        {Wallets.map((wallet, key) => (
          <ListItem key={key} topDivider>
            <ListItem.Content>
              <ListItem.Title>{wallet.Currency.Name}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Subtitle>
              {FormatCurrency(wallet.Balance, wallet.Currency)}
            </ListItem.Subtitle>
          </ListItem>
        ))}
      </Card>
    )
  }

  const renderTokens = () => {
    if (Tokens.length === 0) {
      return null
    }

    return (
      <Card>
        <Card.Title>{i18n.t('Common.Tokens')}</Card.Title>
        {Tokens.map((token, key) => (
          <ListItem key={key} topDivider>
            <ListItem.Content>
              <ListItem.Title>{token.ActivationCode}</ListItem.Title>
            </ListItem.Content>
          </ListItem>
        ))}
      </Card>
    )
  }

  return (
    <>
      <MyHeader onBackPress={goBack} title={i18n.t('CancelAccount.Header')} />
      <Container>
        <ScrollView
          style={[defaultFlatList]}
          contentContainerStyle={flatListContentStyle}>
          <Card>
            <Text>
              {i18n.t('CancelAccount.Info', {
                name: User?.Name,
                parentName: MainUser?.Name,
              })}
            </Text>
          </Card>
          {renderWallets()}
          {renderTokens()}
        </ScrollView>
        <MyFooter isGreyBackground>
          <MyFooterTile isLast>
            <MyButton
              icon={{
                name: 'trash',
                type: 'font-awesome',
                color: 'white',
              }}
              buttonStyle={{backgroundColor: colors.darkRed}}
              title={i18n.t('CancelAccount.Header')}
              isLoading={cancelAccountStore.IsSubmitting}
              onPress={() => {
                appUiStore.ShowConfirm(
                  i18n.t('CancelAccount.Confirm', {name: User?.Name}),
                  () => {
                    if (MainUser !== undefined && User !== undefined) {
                      cancelAccountStore.CancelChildUser(
                        MainUser.MainUserGuid,
                        User.MainUserGuid,
                        User.Name,
                      )
                    }
                  },
                )
              }}
            />
          </MyFooterTile>
        </MyFooter>
      </Container>
    </>
  )
}
export default observer(CancelAccount)
