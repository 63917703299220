import React from 'react'
import {View, StyleSheet} from 'react-native'
import {ToprLoader} from '../../../../Components'

interface IProps {}

const ToprRowLoader: React.FC<IProps> = ({children}) => {
  return (
    <div className="row-loader">
      <ToprLoader isLoading={true} />
    </div>
  )
}

export default ToprRowLoader
