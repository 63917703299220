export default class ClientToken {
  public AccessToken: string

  public ExpiryDateMs: number

  constructor(AccessToken: string, ExpiryDateMs: number) {
    this.AccessToken = AccessToken
    this.ExpiryDateMs = ExpiryDateMs
  }
}
