import {placeholder} from 'i18n-js'
import {observer} from 'mobx-react'
import React, {Component} from 'react'
import {
  Animated,
  StyleSheet,
  Text,
  View,
  I18nManager,
  Pressable,
  TouchableOpacity,
  Image,
} from 'react-native'
import {Badge, Card, ListItem} from 'react-native-elements'

import {RectButton} from 'react-native-gesture-handler'
import Swipeable from 'react-native-gesture-handler/Swipeable'

import Icon from 'react-native-vector-icons/MaterialIcons'
import RestaurantImage from '../../../Components/Cards/RestaurantImage'
import {
  FormatDateTime,
  FormatInzziiDateString,
} from '../../../Helpers/DateHelpers'
import {FormatToEuro} from '../../../Helpers/TextHelpers'
import {GetImageSource} from '../../../Helpers/UiHelpers'
import i18n from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {IMyPlacedOrder} from '../../../Models/Client'
import Style, {sizes} from '../../../Style'
import AppImages from '../../../Images/AppImages'
import {IOrderHistory} from '../../../Models/InzziiApi'

const AnimatedIcon = Animated.createAnimatedComponent(Icon)

interface IProps {
  orderHistory: IOrderHistory
  onPress: () => void
}

class PlacedOrderCard extends Component<IProps> {
  render() {
    const {orderHistory, onPress} = this.props

    const {noteText, badgeStyle} = Style
    let title = orderHistory.restaurantName
    if (orderHistory.orderNumber) {
      title += `, #${orderHistory.orderNumber}`
    }

    return (
      <TouchableOpacity onPress={onPress}>
        <Card>
          {/* <RestaurantImage restaurant={orderHistory.Restaurant} /> */}
          {/* <Card.Title>{title}</Card.Title> */}

          <View style={styles.container}>
            <View style={{paddingRight: sizes.padding.md, flex: 1}}>
              <Text>{title}</Text>
              <Text style={noteText}>
                {FormatInzziiDateString(orderHistory.orderDate)}
              </Text>
            </View>
            <Badge
              value={orderHistory.totalAmount}
              badgeStyle={badgeStyle}
              textStyle={{fontSize: 14}}
            />
          </View>
        </Card>
      </TouchableOpacity>
    )
  }
}

export default observer(PlacedOrderCard)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
})
