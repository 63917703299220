import * as Location from 'expo-location'
import {makeAutoObservable, runInAction} from 'mobx'
import {GetCurrentLocation} from '../../../Helpers/LocationHelper'
import {DoServiceCall} from '../../../Helpers/ServiceHelpers'
import {ILocationSTO} from '../../../Models/Server'
import LocationController from '../../../Services/Topr.Core/LocationController'

export default class Store {
  //public LocationPermissionStatus: string = 'denied'

  public IsLocationsLoading = true
  public Locations: ILocationSTO[] = []
  public Search = ''

  public Status = ''
  public get IsLocationAuthorized(): boolean {
    return this.Status === 'granted'
  }

  public get FilteredLocations(): ILocationSTO[] {
    if (this.Search !== '') {
      return this.Locations.filter(
        (x) =>
          x.Name.toLowerCase().indexOf(this.Search.toLocaleLowerCase()) > -1 ||
          x.City.toLowerCase().indexOf(this.Search.toLocaleLowerCase()) > -1,
      )
    }

    return this.Locations
  }

  constructor() {
    makeAutoObservable(this)
  }

  public async Initialize() {
    this.IsLocationsLoading = true
    this.Search = ''

    let {status} = await Location.getPermissionsAsync()

    runInAction(() => (this.Status = status))

    if (this.IsLocationAuthorized) {
      this.NearbyLocations()
    }
  }

  public async RequestLocationPermission() {
    let {status} = await Location.requestPermissionsAsync()
    runInAction(() => {
      this.Status = status
    })
    if (status === 'granted') {
      this.NearbyLocations()
    }
  }

  public onSearchTextChanged(text: string) {
    this.Search = text
  }

  public NearbyLocations() {
    DoServiceCall(() => {
      this.Locations = []
      this.IsLocationsLoading = true

      //web?

      GetCurrentLocation().then((location) => {
        LocationController.NearbyLocationsV2(
          location.coords.latitude,
          location.coords.longitude,
          150000,
          100,
        )
          .then((locations) => {
            runInAction(() => {
              this.Locations = locations
              this.IsLocationsLoading = false
            })
          })
          .catch(() => {
            runInAction(() => {
              this.IsLocationsLoading = false
            })
          })
      })
    })
  }
}

export const store = new Store()
