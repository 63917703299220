import React from 'react'

interface IProps {
  isWhite?: boolean
}

const Container: React.FC<IProps> = ({children, isWhite}) => {
  return (
    <div className={`webroot ${isWhite ? 'white' : ''}`}>
      <div className="container">{children}</div>
    </div>
  )
}

export default Container

Container.defaultProps = {
  isWhite: false,
}
