import React from 'react'
import {Icon} from 'react-native-elements'
import {GetLocalizedImageUrl} from '../../Helpers/UiHelpers'
import {IGroupedCoupon} from '../../Models/Server'
import Badge from '../Web/Badge.web'
import CardTitle from '../Web/CardTitle.web'

interface IProps {
  groupedCoupon: IGroupedCoupon
  onPress: () => void
}
const CouponsCardWeb: React.FC<IProps> = ({groupedCoupon, onPress}) => {
  const imageGuid = groupedCoupon.Coupon.Images[0].Guid

  return (
    <div className="card-tile shadow" onClick={onPress}>
      <div
        className="card-img"
        style={{
          backgroundImage: `url(${GetLocalizedImageUrl(imageGuid)})`,
        }}></div>
      <div className="card-footer px-2">
        <Badge>{`${groupedCoupon.UserCoupons.length}x`}</Badge>
        <CardTitle>{groupedCoupon.Coupon.FullTitle}</CardTitle>
        <Icon name="chevron-thin-right" type="entypo" />
      </div>
    </div>
  )
}

export default CouponsCardWeb
