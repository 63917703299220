import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Tab} from 'react-native-elements'
import AppConfig from '../../Config/AppConfig'
import Style from '../../Style'
interface IProps {
  tabs: string[]
  onChange: (index: number) => void
}

const MyTab: React.FC<IProps> = ({tabs, onChange}) => {
  const [index, setIndex] = React.useState(0)

  const renderTabs = () => {
    return tabs.map((tab, key) => {
      return (
        <Tab.Item
          key={key}
          titleStyle={{
            color: key === 0 ? AppConfig.Tenant.MainColor : '#555',
          }}
          buttonStyle={tabButtonStyle}
          title={tab}
        />
      )
    })
  }

  const {tabButtonStyle} = Style

  return (
    <Tab
      value={index}
      onChange={(value) => {
        setIndex(value)
        onChange(value)
      }}
      indicatorStyle={{backgroundColor: AppConfig.Tenant.MainColor}}>
      {renderTabs()}
    </Tab>
  )
}

export default MyTab
