import React, {useEffect, useState} from 'react'
import {Platform, ScrollView} from 'react-native'
import {GetImageUrl, GetRandSvgImage} from '../../Helpers/UiHelpers'
import {IsWebAndMobileOrApp} from '../../Helpers/WebHelpers'
import SvgImages from '../../Images/SvgImages'
import {deviceHeight} from '../../Style'
import PageLoader from './PageLoader/PageLoader'
interface IProps {
  imageUrl?: string
  padder?: boolean
  isLoading?: boolean
}

const StockImageLayout: React.FC<IProps> = ({
  children,
  imageUrl,
  padder,
  isLoading,
}) => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('')
  useEffect(() => {
    //console.log('render stockLayout')
    const randomSvgImage = GetRandSvgImage()
    setBackgroundImageUrl(GetImageUrl(imageUrl, randomSvgImage))
  }, [null, isLoading])
  //only use for web
  if (IsWebAndMobileOrApp()) {
    //if (Platform.OS !== 'web') {
    return <>{children}</>
  }

  const testImage = SvgImages.Valley

  // return (
  //   <>
  //     <div
  //       className="column paper is-flex flex1 is-flex-direction-column"
  //       style={{padding: 0, minHeight: 0}}>
  //       <div
  //         style={{
  //           maxHeight: '100%',
  //         }}>
  //         <div
  //           style={{
  //             backgroundColor: 'green',
  //             //flex: 1,
  //             height: '100%',
  //             //flexDirection: 'column',
  //             display: 'block',
  //             overflowY: 'scroll',
  //           }}>
  //           <div
  //             style={{
  //               height: 800,
  //               backgroundColor: 'red',
  //               width: 100,
  //             }}></div>
  //           <br />
  //           <div
  //             style={{
  //               height: 800,
  //               backgroundColor: 'red',
  //               width: 100,
  //             }}></div>
  //         </div>

  //         <hr />
  //       </div>
  //     </div>
  //   </>
  // )

  return (
    <>
      <PageLoader isLoading={isLoading} />
      {!isLoading && (
        <div className="columns flex1">
          <div
            className="column is-two-thirds is-hidden-mobile bg-img"
            style={{
              backgroundImage: backgroundImageUrl,
            }}></div>
          <div
            className="column paper is-flex"
            //style={{padding: padder ? '.75rem' : 0, backgroundColor: 'green'}}
            style={{padding: 0, minHeight: 0}}>
            {children}
            {/* <div className="contentOverFlow">
              <div className="boxMin">
                <div
                  style={{
                    height: 800,
                    backgroundColor: 'red',
                    width: 100,
                  }}></div>
                <br />
                <div
                  style={{
                    height: 800,
                    backgroundColor: 'red',
                    width: 100,
                  }}></div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  )
}

StockImageLayout.defaultProps = {
  padder: true,
  isLoading: false,
}

export default StockImageLayout
