import {StackScreenProps} from '@react-navigation/stack'
import I18n from 'i18n-js'
import {Observer, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {FlatList, View} from 'react-native'
import {Text} from 'react-native-elements'
import {
  Container,
  Content,
  ImageCard,
  ImageHeader,
  SubmitHeader,
  ToprLoader,
  ToprScreenLoader,
} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import {GetFileUrl, GetRandomKey} from '../../../Helpers/UiHelpers'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style from '../../../Style'
import UserSavingCardRow from './Components/UserSavingCardRow'
import {userSavingCardsSummaryStore} from './Store'

interface IProps
  extends StackScreenProps<MainScreensList, 'userSavingCardsSummary'> {}

const UserSavingCardsSummary: React.FC<IProps> = ({
  navigation: {goBack},
  route,
}) => {
  const {IsLoading, UserSavingCards, HasMoreLoading} =
    userSavingCardsSummaryStore
  const savingCardSummary = route.params.savingCardSummary
  useEffect(() => {
    myLog.warn('CouponsSummary')
    userSavingCardsSummaryStore.Initialize(
      savingCardSummary.SavingCard.SavingCardGuid,
    )
  }, [null])

  const {defaultFlatListWhite, flatListContentStyleStock, flatListLoader} =
    Style

  const renderFooter = () => {
    if (!HasMoreLoading || IsLoading) {
      return null
    }

    return (
      <View style={flatListLoader}>
        <ToprLoader size="small" isLoading={true} />
      </View>
    )
  }

  return (
    <>
      <Container keyboardAware={false}>
        <StockImageLayout
          imageUrl={GetFileUrl(savingCardSummary.SavingCard.ImageGuid)}
          padder={false}
          isLoading={IsLoading}>
          <Content headerMargin padder={false}>
            <FlatList
              style={defaultFlatListWhite}
              ListEmptyComponent={
                <ImageCard
                  imageUrl="https://images.unsplash.com/photo-1565165928601-867958a20fdd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                  text={I18n.t('Dashboard.NoSavingCards')}
                />
              }
              contentContainerStyle={[flatListContentStyleStock]}
              refreshing={HasMoreLoading}
              onEndReached={() => userSavingCardsSummaryStore.NextPage()}
              onEndReachedThreshold={0.5}
              initialNumToRender={userSavingCardsSummaryStore.Take}
              data={UserSavingCards}
              ListFooterComponent={() => {
                return <Observer>{() => renderFooter()}</Observer>
              }}
              onRefresh={() =>
                userSavingCardsSummaryStore.Initialize(
                  savingCardSummary.SavingCard.SavingCardGuid,
                )
              }
              renderItem={({item}) => (
                <UserSavingCardRow userSavingCard={item} />
              )}
              keyExtractor={() => {
                return GetRandomKey()
              }}
            />
          </Content>
          <SubmitHeader onBackPress={goBack}>
            {savingCardSummary.SavingCard.Title}
          </SubmitHeader>
        </StockImageLayout>
      </Container>
    </>
  )
}
export default observer(UserSavingCardsSummary)
