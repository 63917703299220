import React, {useRef} from 'react'
import {View, Animated, Text} from 'react-native'
import Style, {colors} from '../../Style'

interface IProps {
  duration: number
  delayStart?: number
  delayFadeOut?: number
  size?: 'normal' | 'small'
  bordered?: boolean
}

const Dot: React.FC<IProps> = ({
  delayStart,
  duration,
  delayFadeOut,
  size,
  bordered,
}) => {
  const fadeAnim = useRef(new Animated.Value(0)).current

  let fadeInAndOut = Animated.sequence([
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration,
      useNativeDriver: true,
    }),
    Animated.timing(fadeAnim, {
      toValue: 0,
      delay: delayFadeOut,
      duration,
      useNativeDriver: true,
    }),
  ])

  React.useEffect(() => {
    setTimeout(() => {
      Animated.loop(fadeInAndOut).start()
    }, delayStart)
  }, [fadeAnim])

  const {dot, dotSmall} = Style
  const dotStyle = size === 'normal' ? dot : dotSmall
  const backgroundStyle = bordered ? {backgroundColor: colors.main} : {}

  return (
    <Animated.View
      style={{
        opacity: fadeAnim,
      }}>
      <View style={[dotStyle, backgroundStyle]} />
    </Animated.View>
  )
}
export default Dot

Dot.defaultProps = {
  delayStart: 0,
  duration: 750,
  size: 'normal',
  bordered: false,
}
