import {AlertType} from './AlertType'

export class MyErrorMessage {
  public Code: string
  public I18TitleKey: string
  public I18MessageKey: string
  public AlertType?: AlertType
  public Values?: any

  constructor(
    code: string,
    i18TitleKey: string,
    i18MessageKey: string,
    alertType?: AlertType,
    values?: any,
  ) {
    this.Code = code
    this.I18TitleKey = i18TitleKey
    this.I18MessageKey = i18MessageKey
    this.Values = values
    this.AlertType = alertType
  }
}
