import React from 'react'
import {Button, ButtonProps} from 'react-native-elements'
import AppConfig from '../../Config/AppConfig'
import {defaultFontWeight, boldFontWeight} from '../../Style'
export interface IMyButtonProps extends ButtonProps {
  isLoading?: boolean
  isBold?: boolean
}

const MyButton: React.FC<IMyButtonProps> = ({
  isLoading,
  isBold,
  ...otherProps
}) => {
  const disabled = isLoading || otherProps.disabled

  const fontWeight = isBold ? boldFontWeight : defaultFontWeight
  if (otherProps.type === 'outline') {
    otherProps.buttonStyle = [
      otherProps.buttonStyle,
      {
        borderColor: AppConfig.Tenant.MainColorDark,
      },
    ]

    otherProps.titleStyle = {
      ...fontWeight,
      color: AppConfig.Tenant.MainColorDark,
    }
  }

  if (otherProps.type === 'solid') {
    otherProps.titleStyle = [
      otherProps.titleStyle,
      {
        ...fontWeight,
      },
    ]
  }

  return (
    <Button disabled={disabled} loading={isLoading} {...otherProps}></Button>
  )
}

export default MyButton

MyButton.defaultProps = {
  isBold: true,
  type: 'solid',
}
