import axios, {AxiosError, AxiosResponse} from 'axios'
import AppConfig from '../../Config/AppConfig'
import {HandleAxiosCatch, HandleInziiCatch} from '../../Helpers/ServiceHelpers'
import {
  IArticle,
  ICondiment,
  ICondimentGroup,
  IDepartment,
} from '../../Models/InzziiApi'

export default {
  GetDepartments: (branchofficeId: string) => {
    return new Promise<IDepartment[]>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/Article/${branchofficeId}/Departments`
      axios
        .get(url, {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then((response: AxiosResponse<IDepartment[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleInziiCatch(error, reject)
        })
    })
  },
  GetArticles: (branchofficeId: string) => {
    return new Promise<IArticle[]>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/Article/${branchofficeId}/Plus`
      axios
        .get(url, {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then((response: AxiosResponse<IArticle[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleInziiCatch(error, reject)
        })
    })
  },
  GetCondimentGroups: (branchofficeId: string, pluId: number) => {
    return new Promise<ICondimentGroup[]>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/Article/${branchofficeId}/Condiments/${pluId}`

      axios
        .get(url, {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then((response: AxiosResponse<ICondimentGroup[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleInziiCatch(error, reject)
        })
    })
  },
}
