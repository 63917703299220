export enum OrderLineType {
  Plu = 0,
  Modifier = 2, //Extra Text
  ModifierWithPrice = 3, //isSupplement
}

export enum DeliveryType {
  Deliver = 0,
  Pickup = 1,
  Table = 2,
}
export enum PaymentGateway {
  PayOnDelivery = 0,
  PendingPayment = 1,
  //New payment methods from  topr, will also use PendingPayment = 1,
}
