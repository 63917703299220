import React from 'react'
import {
  TouchableOpacity,
  View,
  Image,
  ImageStyle,
  StyleSheet,
} from 'react-native'
import {Badge, Card, Icon, Text} from 'react-native-elements'

import {FormatCurrency} from '../../Helpers/TextHelpers'
import {GetLocalizedImageUrl} from '../../Helpers/UiHelpers'
import i18n from '../../i18n'
import {
  ICouponSummarySTO,
  IGroupedCoupon,
  IWalletSTO,
} from '../../Models/Server'
import Style, {fullWidthHeightAbs, sizes} from '../../Style'
import {colors} from '../../Style/index'
import TextBadge from '../Labels/TextBadge'
import CardFooter from './CardFooter'

interface IProps {
  couponSummary: ICouponSummarySTO
  onPress: () => void
}
const CouponSummaryCard: React.FC<IProps> = ({couponSummary, onPress}) => {
  const imageGuid = couponSummary.Coupon.Images[0].Guid
  const {backgroundImageStyle} = Style

  return (
    <TouchableOpacity onPress={onPress}>
      <Card containerStyle={{padding: 0}}>
        <View>
          <Image
            source={{
              uri: GetLocalizedImageUrl(imageGuid),
            }}
            style={backgroundImageStyle as ImageStyle}
          />
          <TextBadge
            title={i18n.t('CouponsSummary.NotRedeemed')}
            value={couponSummary.NotRedeemed}
          />
          <TextBadge
            title={i18n.t('CouponsSummary.Redeemed')}
            value={couponSummary.Redeemed}
          />
          <TextBadge
            title={i18n.t('Common.Total')}
            value={couponSummary.Total}
          />
        </View>
        <CardFooter title={couponSummary.Coupon.FullTitle} />
      </Card>
    </TouchableOpacity>
  )
}

export default CouponSummaryCard

const styles = StyleSheet.create({
  backgroundImage: {...fullWidthHeightAbs, opacity: 0.2},
  imageContainer: {
    height: sizes.image.tileImageHeight,
    justifyContent: 'center',
  },
})
