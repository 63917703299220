import {makeAutoObservable, runInAction} from 'mobx'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {myLog} from '../../../Services/Client/LogService'
import i18n from '../../../i18n'
import UserController from '../../../Services/Topr.Core/UserController'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import ToprError from '../../../Models/Client/ErrorHandling/ToprError'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {goBack} from '../../../Helpers/NavigationHelper'

export default class Store {
  public IsSubmitting = false
  constructor() {
    makeAutoObservable(this)
  }

  public ChangeName(name: string) {
    DoServiceCall(() => {
      this.IsSubmitting = true

      UserController.SetName(name, dashboardStore.User?.MainUserGuid)
        .then(() => {
          runInAction(() => (this.IsSubmitting = false))
          appUiStore.ShowToast({message: i18n.t('ChangeName.Form.Succes')})
          dashboardStore.GetUser()
          goBack()
        })
        .catch((error: ToprError) => {
          SetFriendlyMessage(error, (message) => {
            appUiStore.ShowToast({message})
          })
          runInAction(() => (this.IsSubmitting = false))
        })
    })
  }
}
export const changeNameStore = new Store()
