import {AppLinkTypes} from '../Enums'
import {AppLink} from './AppLink'

//AppReturnUrl: `https://www.toprwallet.nl/${AppLinks.ConfirmPaymentOrder.Controller}/${AppLinks.ConfirmPaymentOrder.Action}`,

//applinks hebben minimaal controller/action/:optionalpar1/:optionalpar2
const AppLinks = [
  new AppLink('register/confirm', AppLinkTypes.RegisterConfirm),
  new AppLink('email/confirmChange', AppLinkTypes.ConfirmChangeEmail),
  new AppLink('user/resetPassword', AppLinkTypes.ResetPassword),

  //applinks met een id parameter {0}
  new AppLink('wallet/details/{0}/emandateResult', AppLinkTypes.EMandateResult),
  new AppLink(
    'wallet/details/{0}/confirmPaymentOrder',
    AppLinkTypes.ConfirmPaymentOrder,
  ),
]

//wallet/details/:currencyGuid/:action/:status
//wallet/details/a63e57dc-42ba-4635-9a3f-059087fe9a68/emandateResult/status
//wallet/details/a63e57dc-42ba-4635-9a3f-059087fe9a68/confirmPaymentOrder/status

//wallet/details/a63e57dc-42ba-4635-9a3f-059087fe9a68/confirmPaymentOrder/PAYMENT_CANCELED

export default AppLinks

// export const getAppLink = (url: string) => {
//   return AppLinks.find(
//     (appLink) => appLink.Url.toLowerCase() === url.toLowerCase(),
//   )
// }

export const getAppLinkByType = (deeplink: AppLinkTypes) => {
  return AppLinks.find((appLink) => appLink.DeepLink === deeplink)
}
