import {Mutex} from 'async-mutex'
import axios from 'axios'
import {Platform} from 'react-native'
import AppConfig from '../../../Config/AppConfig'
import i18n, {primaryLanguage} from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {appUiStore} from '../../../Mobx/AppUiStore'
import AuthenticationController from '../AuthenticationController'

//used when calls require an authenticated client (ClientToken) authentication calls like authenticateUser and refreshToken
export const axiosAuthClient = axios.create({
  baseURL: AppConfig.Service.BaseUrl,
  headers: {
    'Content-Type': 'application/json',
    'accept-language': primaryLanguage,
  },
})

const mutex = new Mutex()
axiosAuthClient.interceptors.request.use(
  async (config) => {
    if (!appUiStore.IsConnected) {
      appUiStore.ShowToast({
        message: i18n.t('Common.ServerErrors.NO_CONNECTION'),
      })
      Promise.reject(new Error('No connection'))
    }

    await mutex.waitForUnlock()
    const {ClientToken} = persistedStore
    if (ClientToken && ClientToken.AccessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${ClientToken.AccessToken}`,
      }
    }

    return config
  },
  (error) => Promise.reject(error),
)

axiosAuthClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true

      if (!mutex.isLocked()) {
        const release = await mutex.acquire()

        try {
          console.log('try AuthenticateClient')
          const response = await AuthenticationController.AuthenticateClient()
          console.log('*** AuthenticatedClient *** :)')

          if (response.AccessToken) {
            //updated with new acces token
            config.headers = {
              ...config.headers,
              authorization: `Bearer ${response.AccessToken}`,
            }
          }

          return axios(config)
        } finally {
          release()
        }
      } else {
        // wait until the mutex is available without locking it
        //console.log('wait for refresh 2 ')
        await mutex.waitForUnlock()

        //updated accestoken
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${persistedStore.ClientToken?.AccessToken}`,
        }
        return axios(config)
      }
    }

    return Promise.reject(error)
  },
)
