import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {
  CardStyleInterpolators,
  createStackNavigator,
  StackNavigationProp,
  StackScreenProps,
} from '@react-navigation/stack'
import {MainScreensList} from '../../../Navigation'
import {
  FormHeader,
  Content,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  Container,
} from '../../../Components'
import i18n from '../../../i18n'
import {sizes} from '../../../Style'
import {myLog} from '../../../Services/Client/LogService'

import Article from './Article/Article'
import Cart from './Cart/Cart'
import OrderPayment from './OrderPayment/OrderPayment'
import Checkout from './Checkout/Checkout'
import Articles from './Articles/Articles'
import {orderOnlineStore} from './Store'
import {CompositeNavigationProp, useNavigation} from '@react-navigation/native'
import {IArticle, IOrderResult} from '../../../Models/InzziiApi'

interface IProps extends StackScreenProps<MainScreensList, 'orderOnline'> {}

export type OrderOnlineList = {
  articles: undefined
  article: {article: IArticle}
  cart: undefined
  checkout: undefined
  orderPayment: undefined //{orderResult: IOrderResult}
}

const OrderOnlineStack = createStackNavigator<OrderOnlineList>()
//const sd = StackNavigationProp<OrderOnlineList>

export type OrderNavigationProp = CompositeNavigationProp<
  StackNavigationProp<MainScreensList>,
  StackNavigationProp<OrderOnlineList>
>

const index: React.FC<IProps> = ({navigation: {navigate}, route}) => {
  const navigation = useNavigation<OrderNavigationProp>()

  useEffect(() => {
    if (route.params === undefined) {
      //only initialize when not redirected from payment complete after charge
      orderOnlineStore.Initialize()
    } else {
      //after charge complete
      //redirect to order payment
    }

    //orderOnlineStore.TestOrderRequestLines()
    //navigation.navigate('checkout')

    // navigation.navigate('orderPayment', {
    //   orderResult: {
    //     orderId: '123-456',
    //     orderNumber: 345,
    //     totalAmount: 3.01,
    //   },
    // })

    //init
  }, [null])
  return (
    <>
      <OrderOnlineStack.Navigator
        screenOptions={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          headerShown: false,
        }}>
        <OrderOnlineStack.Screen name="articles" component={Articles} />
        <OrderOnlineStack.Screen name="article" component={Article} />
        <OrderOnlineStack.Screen name="cart" component={Cart} />
        <OrderOnlineStack.Screen name="checkout" component={Checkout} />
        <OrderOnlineStack.Screen name="orderPayment" component={OrderPayment} />
      </OrderOnlineStack.Navigator>
    </>
  )
}
export default observer(index)
