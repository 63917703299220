export default {
  NO_DATA: 'NO_DATA',
  NO_CONNECTION: 'NO_CONNECTION',
  EXCEPTION: 'EXCEPTION',
  TECHNICAL_EXCEPTION: 'TECHNICAL_EXCEPTION',
  UNHANDLED_SERVER_ERROR: 'UNHANDLED_SERVER_ERROR',
  UNHANDLED_INZZII_ERROR: 'UNHANDLED_INZZII_ERROR',
  UNHANDLED_CLIENT_ERROR: 'UNHANDLED_CLIENT_ERROR',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  INVALID_GRANT: 'invalid_grant',
  API_METHOD_NOT_FOUND: ' API_METHOD_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_NO_NAME: 'USER_NO_NAME',
  USER_PASSWORD_INVALID: 'USER_PASSWORD_INVALID',
  USER_LOCKED_SHORT: 'USER_LOCKED_SHORT',
  USER_LOCKED_LONG: 'USER_LOCKED_LONG',
  USER_WAITING_FOR_CONFIRMATION: 'USER_WAITING_FOR_CONFIRMATION',
  USER_EMAIL_NOT_UNIQUE: 'USER_EMAIL_NOT_UNIQUE',
  ENTITY_NOT_FOUND_EXCEPTION: 'ENTITY_NOT_FOUND_EXCEPTION',
}
