import React from 'react'
import {View} from 'react-native'
import Style from '../../Style'
import ToprLoader from './ToprLoader'

interface IProps {
  isLoading: boolean
  isWhite?: boolean
}

const ToprScreenLoader: React.FC<IProps> = ({isLoading, isWhite, children}) => {
  if (!isLoading) {
    return <>{children}</>
  }

  const {fullWidthHeightAbs, centerXY, containerBg, flex1} = Style
  const container = isWhite ? flex1 : containerBg

  return (
    <View style={[fullWidthHeightAbs, centerXY, container]}>
      <ToprLoader isLoading={true} />
    </View>
  )
}

export default ToprScreenLoader

ToprScreenLoader.defaultProps = {
  isLoading: false,
  isWhite: false,
}
