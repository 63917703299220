import {Mutex} from 'async-mutex'
import axios from 'axios'
import {Platform} from 'react-native'
import AppConfig from '../../../Config/AppConfig'
import i18n, {primaryLanguage} from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {appUiStore} from '../../../Mobx/AppUiStore'
import AuthenticationController from '../AuthenticationController'

const baseUrl = () => {
  if (Platform.OS === 'web') {
    return AppConfig.Service.ToprAuthUrl
  }
  return AppConfig.Service.BaseUrl
}

//pass proxy calls through  AppConfig.Service.ToprAuthUrl for web, native go directly
export const axiosProxy = axios.create({
  baseURL: baseUrl(),
  headers: {
    'Content-Type': 'application/json',
    'accept-language': primaryLanguage,
  },
})
