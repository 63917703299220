import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {FixGuid} from '../../Helpers/TextHelpers'
import {
  ICouponSummarySTO,
  IUserCouponSTO,
  IUserCouponSummarySTO,
} from '../../Models/Server'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  RedeemableUserCoupons: () => {
    return new Promise<IUserCouponSTO[]>(async (resolve, reject) => {
      axiosAuthUser
        .get('api/v1/usercoupons/redeemable')
        .then((response: AxiosResponse<IUserCouponSTO[]>) => {
          if (response.data) {
            response.data.forEach((userCoupon) => {
              userCoupon.UserCouponGUID = FixGuid(userCoupon.UserCouponGUID)
            })
          }

          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetCouponSummaries: () => {
    return new Promise<ICouponSummarySTO[]>(async (resolve, reject) => {
      axiosAuthUser
        .get('api/v1/coupons/summary')
        .then((response: AxiosResponse<ICouponSummarySTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetUserCouponSummaries: (couponGuid: string, skip: number, take: number) => {
    return new Promise<IUserCouponSummarySTO[]>(async (resolve, reject) => {
      axiosAuthUser
        .get(`api/v2/usercoupons/summary/${couponGuid}/${skip}/${take}`)
        .then((response: AxiosResponse<IUserCouponSummarySTO[]>) => {
          if (response.data) {
            response.data.forEach((userCouponSummary) => {
              userCouponSummary.UserCouponGuid = FixGuid(
                userCouponSummary.UserCouponGuid,
              )
            })
          }

          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetUserCoupon: (userCouponGuid: string) => {
    return new Promise<IUserCouponSTO>(async (resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/usercoupon/${userCouponGuid}`)
        .then((response: AxiosResponse<IUserCouponSTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
