import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {DoServiceCall, Geti18NMessage} from '../../../../Helpers/ServiceHelpers'
import {myLog} from '../../../../Services/Client/LogService'
import i18n from '../../../../i18n'
import {
  IMyOrderRequestLine,
  IOrderResult,
  OrderLineType,
} from '../../../../Models/InzziiApi'
import WalletController from '../../../../Services/Topr.Core/WalletController'
import {
  IStartBasicUserPaymentRequest,
  IWalletSTO,
} from '../../../../Models/Server'
import {ToprError} from '../../../../Models/Client'
import {appUiStore} from '../../../../Mobx/AppUiStore'
import {goBack, navigate} from '../../../../Helpers/NavigationHelper'
import {orderOnlineStore} from '../Store'
import {ITransactionItemSTOV2} from '../../../../Models/Server/Payment/ITransactionItemSTOV2'
import ArantekController from '../../../../Services/Topr.Core/ArantekController'
import RestaurantController from '../../../../Services/Inzii.Api/RestaurantController'
import {persistedStore} from '../../../../Mobx'
import {FormatToEuro} from '../../../../Helpers/TextHelpers'
import {dashboardStore} from '../../../Dashboard/Dashboard/Store'

export default class Store {
  public IsLoading = true
  public IsSubmitting = false
  public Wallets: IWalletSTO[] = []
  public SelectedWallet?: IWalletSTO = undefined

  public get MissingAmountBalance() {
    if (this.SelectedWallet) {
      return (
        (this.SelectedWallet.Balance -
          orderOnlineStore.TotalAmountOrder * 100) *
        -1
      )
    }
    return 0
  }

  public get HasEnoughBalance(): boolean {
    return this.MissingAmountBalance <= 0
  }

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize() {
    this.IsLoading = true
    this.IsSubmitting = false

    WalletController.GetWalletsInzzii(orderOnlineStore.VSamUid)
      .then((wallets) => {
        runInAction(() => {
          this.IsLoading = false
          this.Wallets = wallets

          if (wallets.length > 0) {
            this.SetSelectedWallet(wallets[0])

            //if enough balance, do payment straight away
            if (this.HasEnoughBalance) {
              appUiStore.ShowAuthenticateConfirm({
                OnOk: () => {
                  orderPaymentStore.CreateTableOrderAndDoPayment()
                },
                Message: i18n.t('OrderOnline.PaymentForm.AuthenticateAction', {
                  amount: orderOnlineStore.TotalAmountOrderFormatted,
                }),
              })
            }
          }
        })
      })
      .catch((toprError: ToprError) => {
        const errorMessage = Geti18NMessage(toprError)
        appUiStore.ShowToast({message: errorMessage, type: 'danger'})
        goBack()
      })
  }

  public SetSelectedWallet(wallet: IWalletSTO) {
    this.SelectedWallet = wallet
  }

  private GetArticleGroup(orderRequestLine: IMyOrderRequestLine) {
    const {AllArticles, Departments} = orderOnlineStore

    const getArticleGroupPlu = () => {
      let result = 'NoArticleGroup'
      const article = AllArticles.find(
        (x) => x.number === orderRequestLine.random,
      )
      if (article) {
        const department = Departments.find(
          (x) => x.number === article.departmentNumber,
        )
        if (department) {
          result = department.name
        }
      }

      return result
    }

    switch (orderRequestLine.type) {
      case OrderLineType.Plu:
        //orderOnlineStore.Departments.fin
        return getArticleGroupPlu()
      case OrderLineType.ModifierWithPrice:
      case OrderLineType.Modifier:
        return i18n.t('OrderOnline.CondimentArticleGroup')

      default:
        return 'NoArticleGroup'
    }
  }

  private ConvertToTransactionItem(
    lineNumber: number,
    orderRequestLine: IMyOrderRequestLine,
  ): ITransactionItemSTOV2 {
    const result: ITransactionItemSTOV2 = {
      ArticleGroup: this.GetArticleGroup(orderRequestLine),
      ArticleCode: orderRequestLine.random,
      Description: orderRequestLine.name,
      LineNumber: lineNumber,
      Quantity: orderRequestLine.quantity,
      UnitPrice: orderRequestLine.price * 100,
      VAT: 0,
    }

    return result
  }

  //Create from OrderRequestLines
  private CreateTransactionItems() {
    const {OrderRequestLines} = orderOnlineStore
    const transactionItems: ITransactionItemSTOV2[] = []

    let lineNumber = 1
    OrderRequestLines.forEach((orderRequestLineQuest) => {
      transactionItems.push(
        this.ConvertToTransactionItem(lineNumber, orderRequestLineQuest),
      )
      lineNumber++

      //add condiments, check price, burger select
      if (
        orderRequestLineQuest.linkedOrderLines &&
        orderRequestLineQuest.linkedOrderLines.length > 0
      ) {
        orderRequestLineQuest.linkedOrderLines.forEach((linkedOrderLine) => {
          //only lines with a price
          if (linkedOrderLine.type === OrderLineType.ModifierWithPrice) {
            transactionItems.push(
              this.ConvertToTransactionItem(lineNumber, linkedOrderLine),
            )
            lineNumber++
          }
        })
      }
    })

    return transactionItems
  }

  public CreateTableOrderAndDoPayment() {
    runInAction(() => (this.IsSubmitting = true))
    RestaurantController.TableOrder(
      orderOnlineStore.User?.accessToken,
      orderOnlineStore.BranchofficeId,
      orderOnlineStore.TableNumber,
      orderOnlineStore.OrderRequestLines,
      1, //gateway pending payment
      '',
    )
      .then((orderResult) => {
        this.DoArantekPayment(orderResult)
      })
      .catch(this.HandleErrorCatch)
      .finally(() => {
        runInAction(() => (this.IsSubmitting = false))
      })
  }

  private HandleErrorCatch = (toprError: ToprError) => {
    // other errors
    if (toprError.Code !== 'LOYALTYWALLET_INSUFFICIENT_BALANCE') {
      const errorMessage = Geti18NMessage(toprError)
      appUiStore.ShowAlert({
        message: errorMessage,
        type: 'error',
        title: i18n.t('OrderOnline.PaymentForm.ServerErrors.Title'),
      })
    }
  }

  public DoArantekPayment(createTableOrderResult: IOrderResult) {
    if (this.SelectedWallet) {
      const {TotalAmountOrder, TableNumber, VSamUid} = orderOnlineStore
      const amountCents =
        this.SelectedWallet.Currency.Code === 'HBC'
          ? TotalAmountOrder * 300
          : TotalAmountOrder * 100

      const description = i18n
        .t('OrderOnline.PaymentForm.PaymentOrderDescriptionTable', {
          tableNumber: TableNumber,
        })
        .toString()

      const startPaymentRequest: IStartBasicUserPaymentRequest = {
        Amount: amountCents,
        Description: description,
        Reference: createTableOrderResult.orderId,
        SamUID: VSamUid,
        TransactionItems: this.CreateTransactionItems(),
      }

      runInAction(() => (this.IsSubmitting = true))

      //1 start
      ArantekController.StartPayment(
        this.SelectedWallet.Currency.Code,
        startPaymentRequest,
      )
        .then((loyaltyTransactionGuid: string) => {
          //2 confirm right away
          ArantekController.ConfirmPayment(loyaltyTransactionGuid, {
            Amount: amountCents,
            SamUID: VSamUid,
          })
            .then(() => {
              const accessToken =
                orderOnlineStore.User !== undefined
                  ? orderOnlineStore.User.accessToken
                  : ''

              //3 set payment complete @ Innzzii
              RestaurantController.CompletePayment(
                accessToken,
                createTableOrderResult.orderId,
              )
                .then(() => {
                  if (orderOnlineStore.Restaurant) {
                    runInAction(() => (this.IsSubmitting = false))

                    dashboardStore.GetWallets()

                    appUiStore.ShowAlert({
                      message: i18n.t(
                        'OrderOnline.PaymentForm.Success.Message',
                        {
                          orderNumber: createTableOrderResult.orderNumber,
                        },
                      ),
                      type: 'success',
                      title: i18n.t('OrderOnline.PaymentForm.Success.Title'),
                      additionalCloseAction: () => {
                        navigate('dashboard')
                        navigate('placedOrders')
                      },
                    })
                  }
                })
                .catch(this.HandleErrorCatch)
            })
            .catch(this.HandleErrorCatch)
        })
        .catch(this.HandleErrorCatch)
    }
  }
}

export const orderPaymentStore = new Store()
