import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {StackScreenProps} from '@react-navigation/stack'
import {MainScreensList} from '../../../../Navigation'
import {
  FormHeader,
  Content,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  Container,
  FormInfo,
  MyHeader,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../../Components'
import i18n from '../../../../i18n'
import {sizes} from '../../../../Style'
import {myLog} from '../../../../Services/Client/LogService'
import {useForm} from 'react-hook-form'
import {AddTokenFormData, store} from '../Store'
import AppConfig from '../../../../Config/AppConfig'
import {goBack} from '../../../../Helpers/NavigationHelper'
import {appUiStore} from '../../../../Mobx/AppUiStore'
import {StockImageLayout} from '../../../../Components/Web'

interface IProps {}

const AddTokenActivationcode: React.FC<IProps> = () => {
  const {control, handleSubmit, errors, setValue, setError, formState} =
    useForm<AddTokenFormData>({mode: 'onChange'})

  useEffect(() => {
    myLog.info('AddToken')
    //store.Initialize()

    if (AppConfig.IsDebugMode) {
      setValue('activationCode', '35488233')
    }
  }, [store])

  const onSubmit = (data: AddTokenFormData) => {
    //store.SetFormData('name', data.name)
    store.ActivateWithCode(data.activationCode, (message: string) => {
      setError('activationCode', {
        type: 'manual',
        message,
      })
    })
  }
  const {IsSubmitting} = store

  return (
    <>
      <Container>
        <StockImageLayout>
          <Content headerMargin>
            <FormInfo>{i18n.t('AddToken.Info')}</FormInfo>
            <MyInput
              formObject={{} as AddTokenFormData}
              name="activationCode"
              label={i18n.t('AddToken.Form.Fields.ActivationCode.Label')}
              control={control}
              autofocus
              inputProps={{
                keyboardType: 'number-pad',
                maxLength: 8,
                onSubmitEditing: () => handleSubmit((data) => onSubmit(data))(),
              }}
              validation={{
                errors,
                rules: {
                  required: i18n.t(
                    'AddToken.Form.Fields.ActivationCode.Errors.Required',
                  ),
                },
              }}
            />
          </Content>
          <SubmitHeader onBackPress={goBack}>
            <SubmitHeaderButton
              isLoading={IsSubmitting}
              disabled={!formState.isValid}
              title={i18n.t('AddToken.Header')}
              onPress={handleSubmit(onSubmit)}
            />
          </SubmitHeader>
        </StockImageLayout>
      </Container>
    </>
  )
}
export default observer(AddTokenActivationcode)
