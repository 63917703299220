import React, {PropsWithChildren, useEffect, useState} from 'react'
import {View, StyleSheet} from 'react-native'
import Slim, {ISlimProps} from './Slim/index.web'
import Style from '../../Style'
import {Controller} from 'react-hook-form'
import {IFileDTO} from '../../Models/Server'
import i18n from '../../i18n'

//UseFormMethods
interface IProps<T> {
  //name: string
  name: keyof T
  formObject: T
  control: any
  validation?: {
    errors: any
    rules: any
    defaultValue?: string
  }
  //slim

  label: string
  //infoText?: string
  initialImage?: IFileDTO
  sizeSlim?: {width: number; height: number}
  //onImageChange: (file?: IFileDTO) => void
}

const MySlim = <ObjectType,>({
  name,
  formObject,
  validation,
  label,
  control,
  initialImage,
  sizeSlim,
}: //onImageChange,
PropsWithChildren<IProps<ObjectType>>) => {
  //const MySlim: React.FC<IProps> = ({children}) => {
  const ratio =
    sizeSlim !== undefined ? `${sizeSlim.width}:${sizeSlim.height}` : undefined

  const [fileId, setFiledId] = useState(-1)
  const [isInitialized, setIsInitialized] = useState(false)
  const [initialBase64, setInitialBase64] = useState('')

  useEffect(() => {
    // if (value !== null && value !== undefined) {
    //   const file = value as FileDTO
    //   setFiledId(file.FileId)
    //   setInitialBase64(`data:image/jpeg;base64,${file.Data}`)

    // }
    setIsInitialized(true)
  }, [null])

  const rules = validation !== undefined ? validation.rules : {}
  //const errors = validation !== undefined ? validation.errors : {}
  const errorsMessage =
    validation !== undefined && validation.errors[name] !== undefined
      ? validation.errors[name].message
      : undefined

  const defaultValue =
    validation !== undefined && validation.defaultValue !== undefined
      ? validation.defaultValue
      : ''
  return (
    <>
      <Controller
        control={control}
        name={name.toString()}
        rules={rules}
        defaultValue={defaultValue}
        render={(
          {onChange, onBlur, value, name, ref},
          {invalid, isTouched, isDirty},
        ) => (
          <>
            <Slim
              name={name.toString()}
              size={sizeSlim}
              ratio={ratio}
              label={label}
              initialImage={initialBase64}
              didTransform={(data) => {
                const base64: string = data.output.image.toDataURL()

                const file: IFileDTO = {
                  Name: data.input.name,
                  MimeType: data.input.type,
                  Data: base64.split(',')[1],
                  FileId: fileId,
                }

                // if (initialImage) {
                //   file.FileId = initialImage.FileId
                //   file.FileGuid = initialImage.FileGuid
                // }
                onChange(file)
              }}
              didRemove={() => {
                onChange(undefined)
              }}
            />
            {/* <Input
              inputContainerStyle={inputContainer}
              containerStyle={{paddingHorizontal: 0}}
              labelStyle={labelStyle}
              ref={inputRef}
              label={label}
              value={value}
              errorMessage={errorsMessage}
              onBlur={onBlur}
              onChangeText={(value) => {
                onChange(value)

                if (onTextChange) {
                  onTextChange(value)
                }
              }}
              {...inputProps}
              secureTextEntry={secureTextEntryState}
              multiline={multiline}
              numberOfLines={numberOfLines}
            />
            {renderPasswordToggle()} */}
          </>
        )}
      />
    </>
  )
}

export default MySlim

const styles = StyleSheet.create({})
