import {StackNavigationProp} from '@react-navigation/stack'
import {observer} from 'mobx-react'

import React, {useEffect} from 'react'
import {Text} from 'react-native'
import {MainScreensList} from '../../../Navigation'
import {Container, Content, ResultCard} from '../../../Components'
import I18n from '../../../i18n'
import {store} from '../Store'
import {myLog} from '../../../Services/Client/LogService'
import Style, {colors, sizes} from '../../../Style'
import {StockImageLayout} from '../../../Components/Web'
import {appUiStore} from '../../../Mobx/AppUiStore'

interface IProps {
  navigation: StackNavigationProp<MainScreensList>
}

const ConfirmationSentStep: React.FC<IProps> = ({
  navigation: {navigate},
  navigation,
}) => {
  const {FormData} = store

  useEffect(() => {
    myLog.info('ConfirmationSent')
  }, [null])

  return (
    <Container isGreyBackground>
      <StockImageLayout>
        <Content headerMargin>
          <ResultCard
            title={I18n.t('Register.ConfirmationSentStep.Header')}
            button={{
              text: I18n.t('RegisterConfirm.GoToLogin'),
              action: () => navigate('login'),
            }}>
            <Text>
              {I18n.t('Register.ConfirmationSentStep.TextPart1')}
              <Text style={{color: colors.main}}>{FormData.email}</Text>.
              {I18n.t('Register.ConfirmationSentStep.TextPart2')}
            </Text>
          </ResultCard>
        </Content>
      </StockImageLayout>
    </Container>
  )
}

export default observer(ConfirmationSentStep)
//simple email regex
//https://stackoverflow.com/questions/742451/what-is-the-simplest-regular-expression-to-validate-emails-to-not-accept-them-bl
