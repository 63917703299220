import {StackNavigationProp} from '@react-navigation/stack'
import React, {useEffect} from 'react'
import {View, StyleSheet} from 'react-native'
//import {navigate} from '../../Helpers/NavigationHelper'
import {MainScreensListWeb} from '../../Navigation/index.web'
import Style from '../../Style'

//type argsType = undefined extends MainScreensListWeb[ keyof MainScreensListWeb]
interface IProps {
  //routeName: RouteName extends keyof MainScreensListWeb
  navigation: StackNavigationProp<MainScreensListWeb, keyof MainScreensListWeb>
  routeName: keyof MainScreensListWeb
  args?: any
}

const Redirect: React.FC<IProps> = ({
  args,
  routeName,
  navigation: {navigate},
}) => {
  useEffect(() => {
    navigate(routeName, args)
  })

  return <View>redirect ?</View>
}

export default Redirect

const styles = StyleSheet.create({})
