import {observer} from 'mobx-react'
import React from 'react'
import {Container, Content} from '../../../../Components'
import {MyTab, StockImageLayout} from '../../../../Components/Web'
import i18n from '../../../../i18n'
import {appUiStore} from '../../../../Mobx/AppUiStore'
import {IWalletSTO} from '../../../../Models/Server'
import {sendMoneyStore} from '../Store'
import SearchTab from './SearchTab'
import SendMoneyHeader from './SendMoneyHeader'
import WalletTab from './WalletTab'

interface IProps {
  wallet: IWalletSTO
}

const SendMoneyTabs: React.FC<IProps> = ({wallet}) => {
  //const [index, setIndex] = React.useState(0)
  const {SelectedTabIndex} = sendMoneyStore

  const renderTabViews = () => {
    if (SelectedTabIndex === 1) {
      return <SearchTab wallet={wallet} />
    }

    return <WalletTab wallet={wallet} />
  }

  return (
    <>
      <Content headerMargin>
        <MyTab
          tabs={[
            i18n.t('SendMoney.Tabs.ChildWallet'),
            i18n.t('SendMoney.Tabs.SearchWallet'),
          ]}
          onChange={(index) => {
            sendMoneyStore.SetSelectedTabIndex(index)
          }}
        />
        {renderTabViews()}
      </Content>
      <SendMoneyHeader currencyName={wallet.Currency.Name} />
    </>
  )
}

export default observer(SendMoneyTabs)
