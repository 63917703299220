import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {
  IConfirmPaymentRequest,
  IStartBasicUserPaymentRequest,
} from '../../Models/Server'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  GetBrancheOfficeId: (vsamGuid: string) => {
    return new Promise<string>(async (resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/arantek/branch?SamUid=${vsamGuid}`)
        .then((response: AxiosResponse<{BranchId: string}>) => {
          resolve(response.data.BranchId)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  StartPayment: (
    currencyCode: string,
    request: IStartBasicUserPaymentRequest,
  ) => {
    return new Promise<string>(async (resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/arantek/payment/${currencyCode}/start`, request)
        .then((response: AxiosResponse<string>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },

  ConfirmPayment: (
    transactionguid: string,
    request: IConfirmPaymentRequest,
  ) => {
    return new Promise<void>(async (resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/arantek/payment/${transactionguid}/confirm`, request)
        .then((response: AxiosResponse) => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
