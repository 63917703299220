import {AxiosError, AxiosResponse} from 'axios'
import AppConfig from '../../Config/AppConfig'
import {HandleAxiosCatch, Json2Url} from '../../Helpers/ServiceHelpers'
import {FixGuid} from '../../Helpers/TextHelpers'

import {
  ICurrencyChargeMethodSTO,
  ITransactionSTO,
  IWalletSTO,
  IWithdrawInfoSTO,
} from '../../Models/Server'
import {ILoyaltyTransactionItemSTO} from '../../Models/Server/Wallet/ILoyaltyTransactionItemSTO'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  GetWallets: () => {
    return new Promise<IWalletSTO[]>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v2/wallets/consumer/${AppConfig.Tenant.TenantID}`)
        .then((response: AxiosResponse<IWalletSTO[]>) => {
          if (response.data) {
            response.data.forEach((wallet) => {
              wallet.Currency.CurrencyGuid = FixGuid(
                wallet.Currency.CurrencyGuid,
              )
            })
          }
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetWalletsInzzii: (vsamUid: string) => {
    return new Promise<IWalletSTO[]>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/wallets/consumer/inzzii?SamUid=${vsamUid}`)
        .then((response: AxiosResponse<IWalletSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  //
  GetWallet: (currencyGuid: string) => {
    return new Promise<IWalletSTO>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/wallet/${currencyGuid}`)
        .then((response: AxiosResponse<IWalletSTO>) => {
          if (response.data) {
            response.data.Currency.CurrencyGuid = FixGuid(
              response.data.Currency.CurrencyGuid,
            )
          }

          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetWithdrawInfo: (currencyGuid: string) => {
    return new Promise<IWithdrawInfoSTO>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/wallet/${currencyGuid}/withdrawinfo`)
        .then((response: AxiosResponse<IWithdrawInfoSTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },

  GetTransactions: (
    currencyGuid: string | undefined,
    skip: number,
    take: number,
  ) => {
    return new Promise<ITransactionSTO[]>((resolve, reject) => {
      const data = {
        Skip: skip,
        Take: take,
      }
      const queryStringParams = Json2Url(data)

      const url = `api/v1/wallet/${currencyGuid}/transactions/descending?${queryStringParams}`
      axiosAuthUser
        .get(url)
        .then((response: AxiosResponse<ITransactionSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetTransactionsPdfWeb: (currencyGuid: string | undefined) => {
    return new Promise<void>((resolve, reject) => {
      const url = `api/v1/wallet/${currencyGuid}/transactions/descending/pdf`
      axiosAuthUser
        .get(url, {
          responseType: 'blob',
        })
        .then((response: AxiosResponse<any>) => {
          var file = new Blob([response.data], {type: 'application/pdf'})
          var fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetTransactionsPeriodPdfWeb: (
    currencyGuid: string | undefined,
    fromDate: Date,
    tillDate: Date,
  ) => {
    return new Promise<void>((resolve, reject) => {
      const url = `api/v1/wallet/${currencyGuid}/transactions/period/pdf`
      axiosAuthUser
        .post(
          url,
          {
            From: fromDate,
            Till: tillDate,
          },
          {
            responseType: 'blob',
          },
        )
        .then((response: AxiosResponse<any>) => {
          var file = new Blob([response.data], {type: 'application/pdf'})
          var fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },

  GetTransactionItems: (loyaltyTransactionGuid: string) => {
    return new Promise<ILoyaltyTransactionItemSTO[]>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/transaction/${loyaltyTransactionGuid}/items`)
        .then((response: AxiosResponse<ILoyaltyTransactionItemSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetChargeMethods: (currencyGuid: string) => {
    return new Promise<ICurrencyChargeMethodSTO[]>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/wallet/${currencyGuid}/topup/methods`)
        .then((response: AxiosResponse<ICurrencyChargeMethodSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
