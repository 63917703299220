import {max} from 'date-fns'
import React, {PropsWithChildren} from 'react'
import {Controller} from 'react-hook-form'
import {Text, View} from 'react-native'
import {Slider} from 'react-native-elements'
import Style, {colors, sizes} from '../../Style'

interface IProps<T> {
  name: keyof T
  control: any
  label: string
  defaultValue?: number
  formObject: T
  maximumValue?: number
  minimumValue?: number
  step?: number

  formatValue?: (value: number) => string
  onSlideChange?: (value: number) => void
}

const MySlider = <ObjectType,>(
  props: PropsWithChildren<IProps<ObjectType>>,
) => {
  const {
    name,
    control,
    label,
    defaultValue,
    minimumValue,
    maximumValue,
    step,
    formatValue,
    onSlideChange,
  } = props
  const {labelStyle, flexRow, justifySpaceBetween} = Style

  return (
    <>
      <Controller
        control={control}
        defaultValue={defaultValue}
        render={({onChange, value}) => {
          const getFormattedValue = (value: number): string => {
            if (formatValue === undefined) {
              return value.toString()
            }

            return formatValue(value)
          }

          return (
            <View style={{marginBottom: sizes.padding.md}}>
              <View style={[flexRow, justifySpaceBetween]}>
                <Text style={labelStyle}>{label}</Text>
                <Text>{getFormattedValue(value)}</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  alignItems: 'stretch',
                  justifyContent: 'center',
                }}>
                <Slider
                  value={value}
                  onValueChange={(value) => {
                    onChange(value)

                    if (onSlideChange) {
                      onSlideChange(value)
                    }
                  }}
                  minimumTrackTintColor={colors.main}
                  thumbStyle={{
                    height: 30,
                    width: 30,
                    backgroundColor: colors.main,
                  }}
                  maximumValue={maximumValue}
                  minimumValue={minimumValue}
                  step={step}
                />
              </View>
            </View>
          )
        }}
        name={name.toString()}
      />
    </>
  )
}

export default MySlider
