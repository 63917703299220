import {QrCodeTypes} from '../Enums'
import {IQrObject} from './IQrObject'
export class TableQrObject implements IQrObject {
  public Type: QrCodeTypes = 'Table'
  public Params: {
    TableNumber: string
    VSamUid: string
  }

  constructor(tableNumber: string, vsamUid: string) {
    this.Params = {
      TableNumber: tableNumber,
      VSamUid: vsamUid,
    }
  }
}
