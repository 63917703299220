import {makeAutoObservable, runInAction} from 'mobx'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {IWalletSTO} from '../../../Models/Server'
import {myLog} from '../../../Services/Client/LogService'
import I18n from '../../../i18n'
import TokenController from '../../../Services/Topr.Core/TokenController'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {goBack} from '../../../Helpers/NavigationHelper'
import {MyErrorMessage, ToprError} from '../../../Models/Client'
import i18n from '../../../i18n/index'
import {store as tokenStore} from '../Tokens/Store'
import {IsNfcSupportedAndEnabled} from '../../../Helpers/NfcHelpers'
import {dashboardStore} from '../../Dashboard/Dashboard/Store.web'
export type AddTokenFormData = {
  activationCode: string
  description: string
}

export default class Store {
  public Wallets: IWalletSTO[] = []
  public IsSubmitting = false
  public IsLoading = true
  public HasNfcEnabled = false
  constructor() {
    makeAutoObservable(this)
  }

  public InitialState() {
    this.IsLoading = true
    this.HasNfcEnabled = false
    this.IsSubmitting = false
  }

  public Initialize() {
    IsNfcSupportedAndEnabled()
      .then((isSupportedAndEnabled) => {
        //console.log(' store', isSupportedAndEnabled)
        runInAction(() => {
          this.IsLoading = false
          this.SetHasNfcEnabled(isSupportedAndEnabled)
        })
      })
      .catch((error) => {
        runInAction(() => {
          this.IsLoading = false
          this.SetHasNfcEnabled(false)
        })
      })
  }

  public ActivateWithCode(
    activationCode: string,
    onError: (errorMessage: string) => void,
  ) {
    //DoServiceCall(() => {
    this.IsSubmitting = true
    TokenController.ActivateWithCode(activationCode)
      .then(() => {
        appUiStore.ShowToast({
          message: i18n.t('AddToken.Form.Succes', {activationCode}),
        })
        tokenStore.GetTokens()
        dashboardStore.GetWallets()
        goBack()
        runInAction(() => {
          this.IsSubmitting = false
        })
      })
      .catch((toprError: ToprError) => {
        runInAction(() => {
          this.IsSubmitting = false
        })
        SetFriendlyMessage(toprError, onError, [
          new MyErrorMessage(
            'TOKEN_NOT_FOUND',
            'AddToken.Form.ServerErrors.Title',
            'AddToken.Form.ServerErrors.TOKEN_NOT_FOUND',
            'warning',
          ),
          new MyErrorMessage(
            'TOKEN_ALREADY_ACTIVATED',
            'AddToken.Form.ServerErrors.Title',
            'AddToken.Form.ServerErrors.TOKEN_ALREADY_ACTIVATED',
            'warning',
          ),
        ])
      })
    // })
  }

  public SetHasNfcEnabled(enabled: boolean) {
    this.HasNfcEnabled = enabled
  }

  public SetIsloading(isLoading: boolean) {
    this.IsLoading = isLoading
  }
}

export const store = new Store()
