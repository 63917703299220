import {StackScreenProps} from '@react-navigation/stack'
import {Observer, observer} from 'mobx-react'
import React, {ReactNode, useEffect, useRef} from 'react'
import {FlatList, View} from 'react-native'
import {Badge, Icon, Text, Input} from 'react-native-elements'
import {ImageHeader, ToprLoader, ToprScreenLoader} from '../../../Components'
import {LeftColumnLayout, Spinner} from '../../../Components/Web'
import {FormatCurrency, GetTenantPagetitle} from '../../../Helpers/TextHelpers'
import {GetLocalizedImageUrl, GetRandomKey} from '../../../Helpers/UiHelpers'
import i18n from '../../../i18n'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style, {sizes} from '../../../Style'
import SideMenuItem from '../../Dashboard/Components/Web/SideMenuItem.web'
import TransactionItemsModal from './Components/TransactionItemsModal'
import TransactionRow from './Components/TransactionRow'
import {walletDetailsStore} from './Store'
import {linkingStore} from '../../../Navigation/LinkingStore'
import {MainScreensListWeb} from '../../../Navigation/index.web'
import ReportModal from './Components/ReportModal'
import {TextInput} from 'react-native-gesture-handler'

interface IProps
  extends StackScreenProps<MainScreensListWeb, 'walletDetails'> {}

const WalletDetailsWeb: React.FC<IProps> = ({
  navigation: {goBack, navigate, setOptions},
  route,
}) => {
  const currencyGuid = route.params.currencyGuid
  useEffect(() => {
    myLog.info(`WalletDetails`, currencyGuid)
    walletDetailsStore.Initialize(currencyGuid)

    linkingStore.ProcessUrl(window.location.href)
    //TestUrls.WalletDetails()
  }, [null])

  const {
    IsTransactionsLoading,
    Transactions,
    Wallet,
    IsLoading,
    IsPdfLoading,
    IsTransactionItemsLoading,
    IsTransactionItemsModalVisible,
    TransactionItems,
    SearchVisible,
    SearchText,
    FilteredTransactions,
  } = walletDetailsStore

  useEffect(() => {
    if (Wallet) {
      setOptions({title: GetTenantPagetitle(Wallet.Currency.Name)})
    }
  }, [Wallet])

  const {
    flatListLoader,
    imageHeaderTitle,
    flatListHeader,
    defaultFlatListWhite,
    flatListContentStyle,
    transactionRow,
    badgeStyle,
    flex1,
    flexRow,
  } = Style

  const renderSearch = () => {
    if (!SearchVisible) {
      return null
    }

    return (
      <Input
        value={SearchText}
        leftIcon={<Icon name="search" type="octicons" />}
        rightIcon={
          <Icon
            name="close"
            type="evilicons"
            onPress={() => walletDetailsStore.onSearchTextChange('')}
          />
        }
        onChangeText={(value) => {
          walletDetailsStore.onSearchTextChange(value)
        }}
        autoFocus={SearchVisible}
      />
    )
  }

  const renderTransactions = () => {
    return (
      <>
        <View style={[flatListHeader]}>
          <Text>{i18n.t('WalletDetails.Transactions')}</Text>
          <View style={flexRow}>
            <Icon
              name="search"
              type="octicons"
              onPress={() => {
                walletDetailsStore.ToggleSearch()
              }}
              style={{marginRight: sizes.padding.md}}
            />
            <Spinner isLoading={IsPdfLoading}>
              <Icon
                name="pdffile1"
                type="ant-design"
                onPress={() => {
                  walletDetailsStore.ToggleIsReportModalVisible(true)
                }}
                // onPress={() => walletDetailsStore.GetTransactionPdfWeb()}
              />
            </Spinner>
          </View>
        </View>
        {renderSearch()}
        <View style={[flex1]}>
          <ToprScreenLoader isWhite isLoading={IsTransactionsLoading}>
            <FlatList
              contentContainerStyle={flatListContentStyle}
              ListEmptyComponent={
                <View style={transactionRow}>
                  <Text>{i18n.t('WalletDetails.NoTransactions')}</Text>
                </View>
              }
              style={[defaultFlatListWhite]}
              refreshing={IsTransactionsLoading}
              onEndReached={() => walletDetailsStore.NextPage()}
              onEndReachedThreshold={0.5}
              initialNumToRender={walletDetailsStore.Take}
              onRefresh={() => walletDetailsStore.GetTransactions(true)}
              data={FilteredTransactions}
              renderItem={({item}) => (
                <TransactionRow
                  transaction={item}
                  onPressTransactionItemIcon={() => {
                    walletDetailsStore.ShowTransactionItemsModal(
                      item.TransactionId,
                    )
                  }}
                />
              )}
              ListFooterComponent={() => {
                return <Observer>{() => renderFooter()}</Observer>
              }}
              keyExtractor={() => {
                return GetRandomKey()
              }}
            />
          </ToprScreenLoader>
        </View>
      </>
    )
  }

  const renderFooter = () => {
    if (
      walletDetailsStore.IsTransactionsLoading ||
      !walletDetailsStore.HasMoreLoading ||
      walletDetailsStore.IsLoading
    ) {
      return null
    }

    return (
      <View style={flatListLoader}>
        <ToprLoader size="small" isLoading={true} />
      </View>
    )
  }

  const renderSideMenu = () => {
    const elements: ReactNode[] = []

    if (Wallet === undefined) {
      return null
    }

    if (Wallet.Currency.ConsumerCharge) {
      elements.push(
        <SideMenuItem
          key="charge"
          name="arrowup"
          type="ant-design"
          onClick={() => navigate('charge', {wallet: Wallet})}>
          {i18n.t('WalletDetails.Charge')}
        </SideMenuItem>,
      )
    }

    if (Wallet.Currency.ConsumerWithdraw) {
      elements.push(
        <SideMenuItem
          key="withdraw"
          name="arrowdown"
          type="ant-design"
          onClick={() => navigate('withdraw', {wallet: Wallet})}>
          {i18n.t('WalletDetails.Withdraw')}
        </SideMenuItem>,
      )
    }

    if (Wallet.Currency.ConsumerSendMoney) {
      elements.push(
        <SideMenuItem
          key="sendMoney"
          name="arrowright"
          type="ant-design"
          onClick={() =>
            navigate('sendMoney', {currencyGuid: Wallet.Currency.CurrencyGuid})
          }>
          {i18n.t('WalletDetails.SendMoney')}
        </SideMenuItem>,
      )
    }

    if (Wallet.Currency.AutomaticChargeAllowed) {
      elements.push(
        <SideMenuItem
          key="automaticCharge"
          name="bolt"
          type="material"
          onClick={() =>
            navigate('automaticCharge', {
              currencyGuid: Wallet.Currency.CurrencyGuid,
            })
          }>
          {i18n.t('WalletDetails.AutomaticCharge')}
        </SideMenuItem>,
      )
    }

    return <ul>{elements}</ul>
  }

  return (
    <>
      <LeftColumnLayout leftContentCenter={renderSideMenu()} noPadding>
        <ToprScreenLoader isWhite isLoading={IsLoading}>
          <ImageHeader
            imageSource={{
              uri: GetLocalizedImageUrl(
                Wallet?.Currency.AppPhotoLocalizedImageGUID,
              ),
            }}
            onBackPress={() => navigate('dashboard')}>
            <Text style={imageHeaderTitle}>{Wallet?.Currency.Name}</Text>
            <Badge
              value={FormatCurrency(Wallet?.Balance, Wallet?.Currency)}
              badgeStyle={badgeStyle}
              textStyle={{fontSize: 24} as any}
            />
          </ImageHeader>
          <TransactionItemsModal
            currency={Wallet?.Currency}
            isLoading={IsTransactionItemsLoading}
            isVisible={IsTransactionItemsModalVisible}
            onClose={() => walletDetailsStore.HideTransactionItemsModal()}
            transactionItemRows={TransactionItems}
          />
          <ReportModal
            onClose={() => walletDetailsStore.ToggleIsReportModalVisible(false)}
            onOk={(fromDate, tillDate) => {
              walletDetailsStore.GetTransactionPeriodPdfWeb(fromDate, tillDate)
            }}
            isVisible={walletDetailsStore.IsReportModalVisible}
          />
          {renderTransactions()}
        </ToprScreenLoader>
      </LeftColumnLayout>

      {/* <FooterBar>{renderFooterButtons()}</FooterBar> */}
    </>
  )
}
export default observer(WalletDetailsWeb)
