import React from 'react'
import {TouchableOpacity, View} from 'react-native'
import {Icon, Text} from 'react-native-elements'
import Style from '../../Style'
interface IProps {
  icon: {name: string; type: string}
  text: string
  onPress: () => void
}

const FooterBarButton: React.FC<IProps> = ({icon, text, onPress}) => {
  return (
    <TouchableOpacity
      style={{width: 70, alignItems: 'center'}}
      onPress={onPress}>
      <Icon {...icon} color="white" size={30} />
      <Text style={{color: '#fff', fontSize: 11}}>{text}</Text>
    </TouchableOpacity>
  )
}

export default FooterBarButton
