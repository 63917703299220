import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {CreateAppLinkUrl} from '../../Helpers/UrlHelpers'
import {AppLinkTypes, PaymentOrderStatusTypes} from '../../Models/Client/Enums'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  TopUpStart: (
    chargeMethodGuid: string,
    amount: number,
    currencyGuid: string,
  ) => {
    type Response = {
      RedirectUrl: string
      PaymentOrderGuid: string
    }

    return new Promise<Response>((resolve, reject) => {
      const data = {
        Amount: amount,
        AppReturnUrl: CreateAppLinkUrl(
          AppLinkTypes.ConfirmPaymentOrder,
          currencyGuid,
        ),
      }

      axiosAuthUser
        .post(`api/v2/wallet/topup/${chargeMethodGuid}/start`, data)
        .then((response: AxiosResponse<Response>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  PaymentOrderStatus: (paymentOrderGuid?: string) => {
    return new Promise<any>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/paymentorder/status/${paymentOrderGuid}`)
        .then((response: AxiosResponse<any>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  Withdraw: (
    currencyGuid: string,
    amount: number,
    iban: string,
    name: string,
    email: string,
  ) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        Name: name,
        Iban: iban,
        Amount: amount,
        Email: email,
      }
      axiosAuthUser
        .post(`api/v1/wallet/${currencyGuid}/withdraw`, data)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  SendMoney: (
    currencyGuid: string | undefined,
    amount: number,
    toUserGuid: string | undefined,
    description: String,
  ) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        ToUserGuid: toUserGuid,
        CurrencyGuid: currencyGuid,
        Amount: amount,
        Description: description,
      }
      axiosAuthUser
        .post(`api/v1/wallet/sendmoney`, data)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
