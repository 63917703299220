import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {Text} from 'react-native-elements'
import {
  Container,
  Content,
  MyHeader,
  ResultCard,
  ToprScreenLoader,
} from '../../Components'
import {StockImageLayout} from '../../Components/Web'
import i18n from '../../i18n'
import {appUiStore} from '../../Mobx/AppUiStore'
import {MainScreensList} from '../../Navigation'
import {myLog} from '../../Services/Client/LogService'
import {store} from './Store'
interface IProps extends StackScreenProps<MainScreensList, 'confirm'> {}

const RegisterConfirm: React.FC<IProps> = ({
  navigation: {navigate, goBack},
  navigation,
  route,
}) => {
  useEffect(() => {
    //navigate('Pincode')
    const {userGuid} = route.params
    myLog.info(`confirm ${userGuid}`)

    store.InitializeConfirm(userGuid)
  }, [store])

  const {IsConfirming, ResultMessage} = store
  const renderResult = () => {
    if (ResultMessage === undefined) {
      return null
    }

    return (
      <Container isGreyBackground>
        <StockImageLayout>
          <Content headerMargin>
            <ResultCard
              title={ResultMessage.title}
              button={{
                text: i18n.t('RegisterConfirm.GoToLogin'),
                action: () => navigate('login'),
              }}>
              <Text>{ResultMessage.message}</Text>
            </ResultCard>
          </Content>
        </StockImageLayout>
      </Container>
    )
  }

  return (
    <>
      <MyHeader onBackPress={goBack} title={i18n.t('RegisterConfirm.Header')} />
      <ToprScreenLoader isLoading={IsConfirming}>
        {renderResult()}
      </ToprScreenLoader>
    </>
  )
}

export default observer(RegisterConfirm)
