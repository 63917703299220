import {makeAutoObservable, runInAction} from 'mobx'
import {persistedStore} from '../../../../Mobx'
import AuthenticationController from '../../../../Services/Topr.Core/AuthenticationController'
import {dashboardStore} from '../../Dashboard/Store'

export default class Store {
  public ShowShildUsers = false

  public ShowConfig = false

  constructor() {
    makeAutoObservable(this)
  }

  public ToggleChildMenu() {
    this.ShowShildUsers = !this.ShowShildUsers
  }

  public ToggleConfigModal() {
    if (persistedStore.MainUser?.EmailAddress === 'superdopey@live.nl') {
      this.ShowConfig = !this.ShowConfig
    }
  }

  public SwitchToChildUser(userGuid: string) {
    dashboardStore.ToggleUserMenu(false)
    AuthenticationController.SwitchUser(userGuid)
      .then(() => {
        runInAction(() => {
          this.ShowShildUsers = false
        })
        dashboardStore.Initialize()
        //navigate('dashboard')
      })
      .catch(() => {
        runInAction(() => {
          this.ShowShildUsers = false
        })
      })
  }

  public SwitchBackToParent() {
    //return to the parent Token
    persistedStore.SwitchBackToParentUserToken()
    dashboardStore.Initialize()
    dashboardStore.ToggleUserMenu(false)
    //navigate('dashboard')
  }
}
export const userMenuStore = new Store()
