import {StackScreenProps} from '@react-navigation/stack'
import 'bulma/css/bulma.min.css'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {Icon, Input} from 'react-native-elements'
import {ToprScreenLoader} from '../../../Components'
import {Container} from '../../../Components/Web'
import Redirect from '../../../Components/Web/Redirect'
import {ContentElements} from '../../../Components/Web/Strapi'
import {MainScreensListWeb} from '../../../Navigation/index.web'
import '../../../Style/strapi.scss'
import '../../../Style/style.web.scss'
import HelpItemsView from '../Components/HelpItemsView'
import TopMenuView from '../Components/TopMenu'
import {helpStore} from './Store'
import '../style.scss'
import i18n from '../../../i18n'

interface IProps extends StackScreenProps<MainScreensListWeb, 'help'> {}

const Help: React.FC<IProps> = ({children, navigation}) => {
  useEffect(() => {
    helpStore.GetHelp()
  }, [helpStore])

  const {IsLoading, Page, FilteredHelpItems, Search} = helpStore

  if (IsLoading) {
    //return loader
    return <ToprScreenLoader isLoading={IsLoading} isWhite />
  }

  //if strapi fails go to login
  if (Page === undefined) {
    return <Redirect routeName="login" navigation={navigation} />
  }

  const renderClearSearchIcon = () => {
    if (Search === '') {
      return undefined
    }
    return (
      <Icon
        name="times"
        type="font-awesome"
        color="black"
        onPress={() => {
          helpStore.SetSearch('')
        }}
      />
    )
  }

  return (
    <>
      <TopMenuView page={Page} />
      <Container isWhite>
        <ContentElements contentElements={Page.Content} />
        <hr />
        <div className="columns is-mobile">
          <div className="column is-4">
            <Input
              placeholder={i18n.t('Common.Search')}
              leftIcon={{type: 'font-awesome', name: 'search'}}
              rightIcon={renderClearSearchIcon()}
              value={Search}
              onChangeText={(value) => helpStore.SetSearch(value)}
            />
          </div>
        </div>

        <HelpItemsView helpItems={FilteredHelpItems} />
      </Container>
    </>
  )
}

export default observer(Help)
