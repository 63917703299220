import {observer} from 'mobx-react'
import React from 'react'
import {Icon, ListItem} from 'react-native-elements'
import {MyModal} from '../../../../Components'
import i18n from '../../../../i18n'
import {appUiStore} from '../../../../Mobx/AppUiStore'
import {TokenStates} from '../../../../Models/Client/Enums'
import {ITokenSTO} from '../../../../Models/Server'
import {store} from '../Store'
import TokenIcon from './TokenIcon'

interface IProps {
  token?: ITokenSTO
  isVisible: boolean
  onClose: () => void
}

const TokenMenu: React.FC<IProps> = ({onClose, isVisible, token}) => {
  if (token === undefined) {
    return null
  }

  const renderBlockButtons = () => {
    if (token.IsBlocked) {
      return (
        <ListItem
          onPress={() => {
            onClose()
            appUiStore.ShowConfirm(
              i18n.t('Tokens.TokenMenu.UnblockTokenAction.Confirm', {
                activationCode: token.ActivationCode,
              }),
              () => {
                store.Unblock()
              },
            )
          }}>
          <TokenIcon tokenState={TokenStates.Active} />
          <ListItem.Content>
            <ListItem.Title>
              {i18n.t('Tokens.TokenMenu.UnblockTokenAction.Title')}
            </ListItem.Title>
          </ListItem.Content>
        </ListItem>
      )
    }

    return (
      <ListItem
        onPress={() => {
          onClose()
          appUiStore.ShowConfirm(
            i18n.t('Tokens.TokenMenu.BlockTokenAction.Confirm', {
              activationCode: token.ActivationCode,
            }),
            () => {
              store.Block()
            },
          )
        }}>
        <TokenIcon tokenState={TokenStates.Blocked} />

        <ListItem.Content>
          <ListItem.Title>
            {i18n.t('Tokens.TokenMenu.BlockTokenAction.Title')}
          </ListItem.Title>
        </ListItem.Content>
      </ListItem>
    )
  }

  return (
    <>
      <MyModal onClose={onClose} visible={isVisible} closeOnBackPress={true}>
        <ListItem>
          <ListItem.Content style={{alignItems: 'center'}}>
            <ListItem.Title>
              {token.Description} ({token.ActivationCode})
            </ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem
          onPress={() => {
            store.ToggleSetDescriptionTokenModalIsVisible()
          }}>
          <Icon name="edit" type="font-awesome" />
          <ListItem.Content>
            <ListItem.Title>
              {i18n.t('SetDescriptionModal.Title')}
            </ListItem.Title>
          </ListItem.Content>
        </ListItem>
        {renderBlockButtons()}
        <ListItem onPress={() => store.ToggleOtherUserModalIsVisible()}>
          <Icon name="users" type="font-awesome-5" />
          <ListItem.Content>
            <ListItem.Title>
              {i18n.t('Tokens.TokenMenu.SwitchUserAction.Title')}
            </ListItem.Title>
          </ListItem.Content>
          <ListItem.Chevron />
        </ListItem>
      </MyModal>
    </>
  )
}

export default observer(TokenMenu)
