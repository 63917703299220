import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {ICurrencySTO} from '../../Models/Server'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  Join: (merchantGuid: string) => {
    return new Promise<ICurrencySTO[]>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/merchant/${merchantGuid}/join`, null)
        .then((response: AxiosResponse<ICurrencySTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
