export interface ICurrencyChargeMethodSTO {
  /**Id */
  Guid: string
  Name: string

  IconFile: string

  TransactionCostsFixed: number
  TransactionCostsPercentage: number
}

export enum PaymentMethodTypes {
  AutomaticChargeCollection = 17,
}

export const getPaymentMethodTransactionCosts = (
  currencyChargeMethodSTO: ICurrencyChargeMethodSTO,
  amount?: number,
) => {
  let transactionCosts = 0

  if (
    currencyChargeMethodSTO &&
    currencyChargeMethodSTO.TransactionCostsFixed > 0
  ) {
    return currencyChargeMethodSTO.TransactionCostsFixed * 100
  }

  if (
    amount !== undefined &&
    amount > 0 &&
    currencyChargeMethodSTO &&
    currencyChargeMethodSTO.TransactionCostsPercentage > 0
  ) {
    const percentage = currencyChargeMethodSTO.TransactionCostsPercentage / 100

    return percentage * amount * 100 //back to cents
  }

  return transactionCosts
}
