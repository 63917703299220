import {makeAutoObservable, runInAction} from 'mobx'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {IWalletSTO} from '../../../Models/Server'
import {myLog} from '../../../Services/Client/LogService'
import I18n from '../../../i18n'
import {navigate} from '../../../Helpers/NavigationHelper'
import i18n from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {appUiStore} from '../../../Mobx/AppUiStore'
import UserController from '../../../Services/Topr.Core/UserController'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import ToprError from '../../../Models/Client/ErrorHandling/ToprError'

export default class Store {
  public IsSubmitting = false
  constructor() {
    makeAutoObservable(this)
  }

  public CancelChildUser(
    mainUserGuid: string,
    childUserGuid: string,
    name: string,
  ) {
    DoServiceCall(() => {
      runInAction(() => (this.IsSubmitting = true))
      UserController.CancelChildUser(mainUserGuid, childUserGuid)
        .then(() => {
          //switch back to parent user

          appUiStore.ShowToast({
            message: i18n.t('CancelAccount.Succes', {name}),
          })
          persistedStore.SwitchBackToParentUserToken()
          dashboardStore.Initialize()
          UserController.GetChildUsers()
          navigate('dashboard')

          runInAction(() => (this.IsSubmitting = false))
        })
        .catch((toprError: ToprError) => {
          SetFriendlyMessage(toprError, (errorMessage) => {
            appUiStore.ShowToast({message: errorMessage})
          })
          runInAction(() => (this.IsSubmitting = false))
        })
    })
  }
}
export const cancelAccountStore = new Store()
