import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {StackScreenProps} from '@react-navigation/stack'
import {MainScreensList} from '../../../Navigation'
import {
  FormHeader,
  Content,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  Container,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import i18n from '../../../i18n'
import {sizes} from '../../../Style'
import {myLog} from '../../../Services/Client/LogService'
import {changeEmailStore} from './Store'
import {useForm} from 'react-hook-form'
import AppConfig from '../../../Config/AppConfig'

interface IProps extends StackScreenProps<MainScreensList> {}

export type MyFormData = {
  email: string
}

const ChangeEmail: React.FC<IProps> = ({navigation: {goBack}, route}) => {
  const {
    control,
    handleSubmit,
    errors,
    setError,
    setValue,
    formState,
  } = useForm<MyFormData>({mode: 'onChange'})
  useEffect(() => {
    myLog.warn('ChangeEmail')

    if (AppConfig.IsDebugMode) {
      //
      //setValue('email', 'r.de.ruijter@mybility.nl')
      setValue('email', 'superdopey@live.nl')
    }
    changeEmailStore.Initialize()
  }, [null])

  const {IsSubmitting} = changeEmailStore

  const onSubmit = (data: MyFormData) => {
    changeEmailStore.ChangeEmail(data.email, (errorMessage) => {
      setError('email', {
        type: 'manual',
        message: errorMessage,
      })
    })
  }
  return (
    <>
      <Container>
        <Content headerMargin>
          <MyInput
            formObject={{} as MyFormData}
            name="email"
            control={control}
            autofocus={true}
            inputProps={{
              autoCapitalize: 'none',
              keyboardType: 'email-address',
              onSubmitEditing: () => {
                handleSubmit((data) => onSubmit(data))()
              },
            }}
            validation={{
              errors,
              rules: {
                required: i18n.t('ChangeEmail.Form.EmailField.Errors.Required'),
                pattern: {
                  value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
                  message: i18n.t('ChangeEmail.Form.EmailField.Errors.Regex'),
                },
              },
            }}
            label={i18n.t('ChangeEmail.Form.EmailField.Label')}
          />
        </Content>
      </Container>
      <SubmitHeader onBackPress={goBack}>
        <SubmitHeaderButton
          disabled={!formState.isValid}
          isLoading={IsSubmitting}
          title={i18n.t('ChangeEmail.Form.Action')}
          onPress={handleSubmit(onSubmit)}
        />
      </SubmitHeader>
    </>
  )
}
export default observer(ChangeEmail)
