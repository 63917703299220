import React from 'react'
import {TextStyle, Text, View} from 'react-native'
import Style from '../../Style'
interface IProps {
  style?: TextStyle
}

const FormInfo: React.FC<IProps> = ({children, style}) => {
  return <Text style={[Style.formIntro, style]}>{children}</Text>
}

export default FormInfo
