import React from 'react'
import {Platform} from 'react-native'
import AppConfig from '../../Config/AppConfig'

interface IProps {}

const WebIncludes: React.FC<IProps> = ({children}) => {
  const fontStyles = () => {
    if (Platform.OS !== 'web') {
      return null
    }

    return (
      <>
        <style type="text/css">{`
            @font-face {
              font-family: 'OpenSans-Light';
              src: url(${require('../../../assets/fonts/OpenSans-Light.ttf')}) format('truetype');
            }

            .badge{
              background-color: ${AppConfig.Tenant.MainColor};
            }

            .navbar-link.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover{
              color: ${AppConfig.Tenant.MainColor};
            }

            .navbar-link:not(.is-arrowless)::after{
              border-color: ${AppConfig.Tenant.MainColor};
            }

            .button.is-primary{
              background-color: ${AppConfig.Tenant.MainColor};
            }

            .button.is-primary.is-hovered, .button.is-primary:hover{
              background-color: ${AppConfig.Tenant.MainColor};
            }

            .markdown a {
              color:${AppConfig.Tenant.MainColor};
            }

            .download {
              color:${AppConfig.Tenant.MainColor};
              border-color:${AppConfig.Tenant.MainColor};
            }

        `}</style>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.15.3/css/all.min.css"></link>
      </>
    )
  }

  return <>{fontStyles()}</>
}

export default WebIncludes
