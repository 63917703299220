export default {
  China: {
    Day: require('../../assets/svg/china/China-day.svg'),
    Sunset: require('../../assets/svg/china/China-sunset.svg'),
    Night: require('../../assets/svg/china/China-night.svg'),
  },
  Fuji: {
    Day: require('../../assets/svg/fuji/Mt.FujiJapan-day.svg'),
    Sunset: require('../../assets/svg/fuji/Mt.FujiJapan-sunset.svg'),
    Night: require('../../assets/svg/fuji/Mt.FujiJapan-night.svg'),
  },
  Italy: {
    Day: require('../../assets/svg/italy/Italy-day.svg'),
    Sunset: require('../../assets/svg/italy/Italy-sunset.svg'),
    Night: require('../../assets/svg/italy/Italy-night.svg'),
  },
  Lighthouse: {
    Day: require('../../assets/svg/lighthouse/SeaLighthouse-day.svg'),
    Sunset: require('../../assets/svg/lighthouse/SeaLighthouse-sunset.svg'),
    Night: require('../../assets/svg/lighthouse/SeaLighthouse-night.svg'),
  },
  London: {
    Day: require('../../assets/svg/london/London-day.svg'),
    Sunset: require('../../assets/svg/london/London-sunset.svg'),
    Night: require('../../assets/svg/london/London-night.svg'),
  },
  Ship: {
    Day: require('../../assets/svg/ship/SeaShip-day.svg'),
    Sunset: require('../../assets/svg/ship/SeaShip-sunset.svg'),
    Night: require('../../assets/svg/ship/SeaShip-night.svg'),
  },
  //   Snow: {
  //     Day: require('../../assets/svg/snow/Snow-day.svg'),
  //     Sunset: require('../../assets/svg/snow/Snow-sunset.svg'),
  //     Night: require('../../assets/svg/snow/Snow-night.svg'),
  //   },
  Street: {
    Day: require('../../assets/svg/street/Street-day.svg'),
    Sunset: require('../../assets/svg/street/Street-sunset.svg'),
    Night: require('../../assets/svg/street/Street-night.svg'),
  },
  Tropical: {
    Day: require('../../assets/svg/tropical/Tropical-day.svg'),
    Sunset: require('../../assets/svg/tropical/Tropical-sunset.svg'),
    Night: require('../../assets/svg/tropical/Tropical-night.svg'),
  },
  Valley: {
    Day: require('../../assets/svg/valley/Valley-day.svg'),
    Sunset: require('../../assets/svg/valley/Valley-sunset.svg'),
    Night: require('../../assets/svg/valley/Valley-night.svg'),
  },
}
