import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {StackScreenProps} from '@react-navigation/stack'
import {MainScreensList} from '../../../Navigation'
import {
  FormHeader,
  Content,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  Container,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import i18n from '../../../i18n'
import {sizes} from '../../../Style'
import {myLog} from '../../../Services/Client/LogService'
import {changePasswordStore} from './Store'
import {useForm} from 'react-hook-form'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import AppConfig from '../../../Config/AppConfig'
import ToprError from '../../../Models/Client/ErrorHandling/ToprError'

interface IProps extends StackScreenProps<MainScreensList> {}

export type MyFormData = {
  oldPassword: string
  password: string
  confirmPassword: string
}

const ChangePassword: React.FC<IProps> = ({navigation: {goBack}, route}) => {
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    setError,
    formState,
  } = useForm<MyFormData>({mode: 'onChange'})

  useEffect(() => {
    myLog.warn('ChangePassword')

    if (AppConfig.IsDebugMode) {
      setValue('oldPassword', 'sdopey01')
      setValue('password', 'sdopey01')
      setValue('confirmPassword', 'sdopey01')
    }
  }, [null])

  const {IsSubmitting} = changePasswordStore

  const onSubmit = (data: MyFormData) => {
    changePasswordStore
      .ValidatePassword(data.oldPassword)
      .then((isValid) => {
        if (!isValid) {
          setError('oldPassword', {
            type: 'manual',
            message: i18n.t(
              'ChangePassword.Form.Fields.OldPassword.Errors.Invalid',
            ),
          })

          return
        }
        //old pasword valid, save the new password
        changePasswordStore.ChangePassword(data.password)
      })
      .catch((error: ToprError) => {
        setError('oldPassword', {
          type: 'manual',
          message: error.Message,
        })
      })
  }
  return (
    <>
      <Container>
        <Content headerMargin>
          <MyInput
            formObject={{} as MyFormData}
            name="oldPassword"
            control={control}
            autofocus={true}
            inputProps={{
              secureTextEntry: true,
              autoCapitalize: 'none',
            }}
            validation={{
              errors,
              rules: {
                required: i18n.t(
                  'ChangePassword.Form.Fields.OldPassword.Errors.Required',
                ),
              },
            }}
            label={i18n.t('ChangePassword.Form.Fields.OldPassword.Label')}
          />
          <MyInput
            formObject={{} as MyFormData}
            name="password"
            control={control}
            inputProps={{
              autoCapitalize: 'none',
              secureTextEntry: true,
            }}
            validation={{
              errors,
              rules: {
                required: i18n.t(
                  'ChangePassword.Form.Fields.Password.Errors.Required',
                ),
                minLength: {
                  value: 6,
                  message: i18n.t(
                    'ChangePassword.Form.Fields.Password.Errors.MinLength',
                  ),
                },
              },
            }}
            label={i18n.t('ChangePassword.Form.Fields.Password.Label')}
          />
          <MyInput
            formObject={{} as MyFormData}
            name="confirmPassword"
            control={control}
            inputProps={{
              autoCapitalize: 'none',
              secureTextEntry: true,
              onSubmitEditing: () => {
                handleSubmit((data) => onSubmit(data))()
              },
            }}
            validation={{
              errors,
              rules: {
                required: i18n.t(
                  'ChangePassword.Form.Fields.ConfirmPassword.Errors.Required',
                ),
                validate: {
                  checkPincode: (confirmPassword: string) => {
                    const values = getValues()
                    return (
                      values.password === confirmPassword ||
                      i18n.t(
                        'ChangePassword.Form.Fields.ConfirmPassword.Errors.Equality',
                      )
                    )
                  },
                },
              },
            }}
            label={i18n.t('ChangePassword.Form.Fields.ConfirmPassword.Label')}
          />
        </Content>
      </Container>
      <SubmitHeader onBackPress={goBack}>
        <SubmitHeaderButton
          disabled={!formState.isValid}
          isLoading={IsSubmitting}
          title={i18n.t('ChangePassword.Form.Action')}
          onPress={handleSubmit(onSubmit)}
        />
      </SubmitHeader>
    </>
  )
}
export default observer(ChangePassword)
