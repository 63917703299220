import {StackScreenProps} from '@react-navigation/stack'
import I18n from 'i18n-js'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {FlatList} from 'react-native'
import {
  Container,
  Content,
  ImageCard,
  SavingCardSummaryCard,
  SubmitHeader,
  ToprScreenLoader,
} from '../../../Components'
import MyHeader from '../../../Components/Headers/MyHeader'
import {StockImageLayout} from '../../../Components/Web'
import {GetRandomKey} from '../../../Helpers/UiHelpers'
import i18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style from '../../../Style'
import {savingCardSummaryStore} from './Store'

interface IProps extends StackScreenProps<MainScreensList> {}

const SavingCardsSummary: React.FC<IProps> = ({
  navigation: {navigate, goBack},
}) => {
  const {IsLoading, SavingCardSummaries} = savingCardSummaryStore

  useEffect(() => {
    myLog.warn('SavingCardsSummary')
    savingCardSummaryStore.Initialize()
  }, [null])

  const {defaultFlatListWhite, flatListContentStyleStock} = Style
  return (
    <>
      {/* <MyHeader
        onBackPress={goBack}
        title={i18n.t('SavingCardsSummary.Header')}
      /> */}

      <Container keyboardAware={false}>
        <StockImageLayout padder={false} isLoading={IsLoading}>
          <Content headerMargin padder={false}>
            <FlatList
              style={defaultFlatListWhite}
              ListEmptyComponent={
                <ImageCard
                  imageUrl="https://images.unsplash.com/photo-1565165928601-867958a20fdd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                  text={I18n.t('Dashboard.NoSavingCards')}
                />
              }
              contentContainerStyle={[flatListContentStyleStock]}
              refreshing={IsLoading}
              onRefresh={() => savingCardSummaryStore.Initialize()}
              data={SavingCardSummaries}
              renderItem={({item}) => (
                <SavingCardSummaryCard
                  savingSummary={item}
                  onPress={() => {
                    navigate('userSavingCardsSummary', {
                      savingCardSummary: item,
                    })
                  }}
                />
              )}
              keyExtractor={() => {
                return GetRandomKey()
              }}
            />
          </Content>
          <SubmitHeader onBackPress={goBack}>
            {i18n.t('SavingCardsSummary.Header')}
          </SubmitHeader>
        </StockImageLayout>
      </Container>
    </>
  )
}
export default observer(SavingCardsSummary)
