import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {FlatList, Platform, Text, View} from 'react-native'
import {Overlay} from 'react-native-elements'
import {
  FormInfo,
  ImageButton,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyModal,
  ToprScreenLoader,
} from '../../../../Components'
import {navigate} from '../../../../Helpers/NavigationHelper'
import {
  GetFileUrl,
  GetLocalizedImageUrl,
  GetRandomKey,
} from '../../../../Helpers/UiHelpers'
import I18n from '../../../../i18n'
import {appUiStore} from '../../../../Mobx/AppUiStore'
import {ILocationSTO} from '../../../../Models/Server'
import {myLog} from '../../../../Services/Client/LogService'
import Style, {deviceWidth, sizes} from '../../../../Style'
import {store} from '../Store'
import i18n from '../../../../i18n/index'
import {persistedStore} from '../../../../Mobx'

interface IProps {
  location: ILocationSTO
}
const PaymentTab: React.FC<IProps> = ({location}) => {
  useEffect(() => {
    store.GetConsumerCurrencies(location.MerchantGuid)
  }, [null])

  const {defaultFlatList, defaultFlatListWhite} = Style
  const {IsCurrenciesLoading, Currencies, IsSubmitting} = store

  const renderFooter = () => {
    if (Currencies.length === 0 || persistedStore.UserToken === undefined) {
      return null
    }

    return (
      <MyFooter isGreyBackground={Platform.OS !== 'web'}>
        <MyFooterTile isLast>
          <MyButton
            isLoading={IsSubmitting}
            title={I18n.t('LocationDetails.AddLocation.Action')}
            onPress={() => {
              appUiStore.ShowConfirm(
                i18n.t('LocationDetails.AddLocation.Confirm'),
                () => store.JoinMerchant(location),
              )
            }}
          />
        </MyFooterTile>
      </MyFooter>
    )
  }

  return (
    <>
      <ToprScreenLoader
        isLoading={IsCurrenciesLoading}
        isWhite={Platform.OS === 'web'}>
        <FlatList
          style={Platform.OS === 'web' ? defaultFlatListWhite : defaultFlatList}
          refreshing={IsCurrenciesLoading}
          onRefresh={() => store.GetConsumerCurrencies(location.MerchantGuid)}
          data={Currencies}
          renderItem={({item}) => (
            <ImageButton
              imageUrl={GetLocalizedImageUrl(item.AppPhotoLocalizedImageGUID)}>
              <Text>{item.Name}</Text>
            </ImageButton>
          )}
          keyExtractor={() => {
            return GetRandomKey()
          }}
        />
        {renderFooter()}
      </ToprScreenLoader>
    </>
  )
}

export default observer(PaymentTab)
