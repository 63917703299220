import {makeAutoObservable, runInAction} from 'mobx'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {ICurrencyChargeMethodSTO, IWalletSTO} from '../../../Models/Server'
import {myLog} from '../../../Services/Client/LogService'
import I18n from '../../../i18n'
import WalletController from '../../../Services/Topr.Core/WalletController'
import {Linking} from 'react-native'
import {CreateAppLinkUrl, GetAppLinkFromUrl} from '../../../Helpers/UrlHelpers'
import {AppLinks} from '../../../Models/Client/AppLinks'
import {AlertType} from '../../../Models/Client/ErrorHandling/AlertType'
import {goBack, navigate} from '../../../Helpers/NavigationHelper'
import {walletDetailsStore} from '../Details/Store'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {IAlertMessage, MyErrorMessage, ToprError} from '../../../Models/Client'
import TransactionController from '../../../Services/Topr.Core/TransactionController'
import {GetRandStockImage} from '../../../Helpers/UiHelpers'
import {orderOnlineStore} from '../../Location/OrderOnline/Store'
import {
  AppLinkTypes,
  ChargeSuccessReturn,
  PaymentOrderStatusTypes,
} from '../../../Models/Client/Enums'
import i18n from '../../../i18n'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import {orderPaymentStore} from '../../Location/OrderOnline/OrderPayment/Store'
import {persistedStore} from '../../../Mobx'

export type ChargeFormData = {
  name: string
  amount: number // cents
}

export type HbChargeValue = {
  Amount: number
  Text: string
}

export default class Store {
  public IsSubmitting = false
  public IsChargeMethodsLoading = true
  public ChargeMethods: ICurrencyChargeMethodSTO[] = []
  public SelectedChargeMethod?: ICurrencyChargeMethodSTO = undefined

  public HBChargeValues: HbChargeValue[] = [
    {
      Amount: 10,
      Text: '10 Munten (€ 30,-)',
    },
    {
      Amount: 20,
      Text: '20 Munten (€ 60,-)',
    },
    {
      Amount: 30,
      Text: '30 Munten (€ 90,-)',
    },
  ]
  public SelectedHBChargeValue?: HbChargeValue = undefined

  private CurrencyGuid = ''
  private ChargeSuccessReturn: ChargeSuccessReturn =
    ChargeSuccessReturn.WalletDetails

  public CheckingPaymentOrder = false
  private RetryCount = 0
  private MaxRetryCount = 20

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize(
    currencyGuid: string,
    chargeSuccessReturn: ChargeSuccessReturn,
  ) {
    this.ChargeMethods = []
    this.SelectedChargeMethod = undefined
    this.CurrencyGuid = currencyGuid
    this.ChargeSuccessReturn = chargeSuccessReturn
    persistedStore.SetPaymentOrderGuid(undefined)
    this.GetCurrencyChargeMethods(currencyGuid)
    this.SelectedHBChargeValue = this.HBChargeValues[0]
  }

  private GetCurrencyChargeMethods(currencyGuid: string) {
    DoServiceCall(() => {
      this.IsChargeMethodsLoading = true
      WalletController.GetChargeMethods(currencyGuid)
        .then((chargeMethods) => {
          //
          runInAction(() => {
            const sortedChargeMethods = chargeMethods.slice().sort((a, b) => {
              if (a.Name < b.Name) {
                return 1
              }
              if (a.Name > b.Name) {
                return -1
              }
              return 0
            })
            if (sortedChargeMethods.length >= 1) {
              this.SetSelectedChargeMethod(sortedChargeMethods[0])
            }

            //test
            // sortedChargeMethods.push({
            //   Name: 'sd',
            //   Guid: '123',
            //   IconFile: '',
            //   TransactionCostsFixed: 0,
            //   TransactionCostsPercentage: 2.5,
            // })

            //    console.log(sortedChargeMethods)
            this.ChargeMethods = sortedChargeMethods
            this.IsChargeMethodsLoading = false
          })
        })
        .catch(() => {
          runInAction(() => (this.IsChargeMethodsLoading = false))
        })
    })
  }

  public SetSelectedChargeMethod(chargeMethod: ICurrencyChargeMethodSTO) {
    this.SelectedChargeMethod = chargeMethod
  }

  public SetSelectedHBChargeValue(hbChargeValue: HbChargeValue) {
    this.SelectedHBChargeValue = hbChargeValue
  }

  public GoBack() {
    //return to order

    console.log('goback', ChargeSuccessReturn[this.ChargeSuccessReturn])
    //update dashboard
    dashboardStore.Initialize()
    if (this.ChargeSuccessReturn === ChargeSuccessReturn.OrderPayment) {
      orderPaymentStore.Initialize()
      navigate('orderOnline', {screen: 'orderPayment'} as any)
    } else {
      walletDetailsStore.Initialize(this.CurrencyGuid)
      navigate('walletDetails', {currencyGuid: this.CurrencyGuid})
    }
  }

  public SetCurrencyGuid(currencyGuid: string) {
    this.CurrencyGuid = currencyGuid
  }

  public InitCheckPaymentOrder() {
    this.RetryCount = 0
    this.CheckTopUpCompletion()
  }

  //after ideal (or other payment is complete), our  timer has to do the actual topup to the wallet
  //check the status of this via the paymentorderGuid
  public CheckTopUpCompletion() {
    this.CheckingPaymentOrder = true

    if (this.RetryCount < this.MaxRetryCount) {
      TransactionController.PaymentOrderStatus(persistedStore.PaymentOrderGuid)
        .then((paymentOrderStatusType) => {
          runInAction(() => this.RetryCount++)

          const enumNumber = PaymentOrderStatusTypes[
            paymentOrderStatusType
          ] as any

          //check invalid PaymentOrderStatusTypes < 0
          if (enumNumber < 0) {
            appUiStore.ShowAlert({
              type: 'warning',
              message: i18n.t(
                `Charge.Form.ServerErrors.${paymentOrderStatusType}`,
              ),
              title: i18n.t(`Charge.Form.ServerErrors.Title`),
              additionalCloseAction: () => {
                runInAction(() => (this.CheckingPaymentOrder = false))
                this.GoBack()
              },
            })
          } else {
            if (
              paymentOrderStatusType ===
              PaymentOrderStatusTypes[PaymentOrderStatusTypes.Processed]
            ) {
              appUiStore.ShowAlert({
                type: 'success',
                message: i18n.t(`Charge.Form.Success.Message`),
                title: i18n.t(`Charge.Form.Success.Title`),
              })

              this.GoBack()
              runInAction(() => (this.CheckingPaymentOrder = false))
            } else {
              setTimeout(() => {
                this.CheckTopUpCompletion()
              }, 1000)
            }
          }
        })
        .catch((toprError: ToprError) => {
          runInAction(() => (this.CheckingPaymentOrder = false))
          SetFriendlyMessage(toprError, (message) => {
            appUiStore.ShowAlert({
              type: 'warning',
              message,
              title: i18n.t(`Charge.Form.ServerErrors.Title`),
              additionalCloseAction: () => {
                runInAction(() => (this.CheckingPaymentOrder = false))
                this.GoBack()
              },
            })
          })
        })
    } else {
      appUiStore.ShowAlert({
        type: 'warning',
        message: 'Verwerking duurt te lang, neem contact op met de helpdesk ',
        title: i18n.t(`Charge.Form.ServerErrors.Title`),
        additionalCloseAction: () => this.GoBack(),
      })
    }
  }

  public Topup(
    chargeMethodGuid: string,
    amount: number,
    onError: (errorMessage: string) => void,
  ) {
    //wallet/details/a63e57dc-42ba-4635-9a3f-059087fe9a68/confirmPaymentOrder/status

    DoServiceCall(() => {
      this.IsSubmitting = true
      TransactionController.TopUpStart(
        chargeMethodGuid,
        amount,
        this.CurrencyGuid,
      )
        .then((response) => {
          persistedStore.SetPaymentOrderGuid(response.PaymentOrderGuid)
          Linking.openURL(response.RedirectUrl)
          runInAction(() => {
            this.IsSubmitting = false
          })
        })
        .catch((toprError: ToprError) => {
          console.log('toprError', toprError)
          SetFriendlyMessage(toprError, onError, [
            new MyErrorMessage(
              'CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED',
              'Charge.Form.ServerErrors.Title',
              'Charge.Form.ServerErrors.CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED',
              //null,
              //'warning'
            ),
          ])
          runInAction(() => {
            this.IsSubmitting = false
          })
        })
    })
  }
}
export const chargeStore = new Store()
