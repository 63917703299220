import {observer} from 'mobx-react'
import React from 'react'
import {Divider, Icon, ListItem} from 'react-native-elements'
import {MyModal, ProfileButton, VersionLabel} from '../../../../Components'
import {navigate} from '../../../../Helpers/NavigationHelper'
import i18n from '../../../../i18n'
import {appUiStore} from '../../../../Mobx/AppUiStore'
import {sizes} from '../../../../Style/index'
import {dashboardStore} from '../../Dashboard/Store'
import UserListItem from './UserListItem'

interface IProps {
  isVisible: boolean
  onClose: () => void
}

const UserMenu: React.FC<IProps> = ({onClose, isVisible}) => {
  const {User} = dashboardStore

  if (User === undefined) {
    return null
  }

  return (
    <>
      <MyModal onClose={onClose} visible={isVisible} closeOnBackPress={true}>
        <ListItem
          onPress={() => {
            navigate('account')
            onClose()
          }}>
          <ProfileButton user={User} />
          <ListItem.Content>
            <ListItem.Title>{User.Name}</ListItem.Title>
            {User.EmailAddress && (
              <ListItem.Subtitle>{User.EmailAddress}</ListItem.Subtitle>
            )}
            <ListItem.Subtitle style={{paddingTop: sizes.padding.md}}>
              {i18n.t('UserMenu.Account.Manage')}
            </ListItem.Subtitle>
          </ListItem.Content>
          <ListItem.Chevron />
        </ListItem>
        <Divider />
        <UserListItem />

        {/* <ListItem
          onPress={() => {
            navigate('tokens')
            onClose()
          }}>
          <Icon name="credit-card" type="font-awesome" />
          <ListItem.Content>
            <ListItem.Title>{i18n.t('Common.Cards')}</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem
          onPress={() => {
            navigate('placedOrders')
            onClose()
          }}>
          <Icon type="material-icons" name="shopping-bag" />
          <ListItem.Content>
            <ListItem.Title>{i18n.t('PlacedOrders.Header')}</ListItem.Title>
          </ListItem.Content>
        </ListItem> */}

        <ListItem
          onPress={() => {
            appUiStore.ShowConfirm(
              i18n.t('UserMenu.ResetPincodeConfirm'),
              () => {
                appUiStore.ResetPincode()
                onClose()
              },
            )
          }}>
          <Icon name="lock-reset" type="material-community" />
          <ListItem.Content>
            <ListItem.Title>{i18n.t('UserMenu.ResetPincode')}</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem
          onPress={() => {
            appUiStore.Logout()
            onClose()
          }}>
          <Icon name="sign-out" type="font-awesome" />
          <ListItem.Content>
            <ListItem.Title>{i18n.t('UserMenu.Logoff')}</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem>
          <ListItem.Content style={{alignItems: 'flex-end'}}>
            <VersionLabel inListItem />
          </ListItem.Content>
        </ListItem>
      </MyModal>
    </>
  )
}

export default observer(UserMenu)
