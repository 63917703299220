import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {Image, View} from 'react-native'
import {Text} from 'react-native-elements'
import {OrderOnlineList} from '..'
import {
  Container,
  Content,
  FullFooterButton,
  MyButton,
  MyHeader,
  MySelectList,
  ToprScreenLoader,
} from '../../../../Components'
import {
  FormatCentsToEuro,
  FormatCurrency,
} from '../../../../Helpers/TextHelpers'
import {GetLocalizedImageUrl} from '../../../../Helpers/UiHelpers'
import {appUiStore} from '../../../../Mobx/AppUiStore'
import {IWalletSTO} from '../../../../Models/Server'
import {MainScreensList} from '../../../../Navigation'
import {myLog} from '../../../../Services/Client/LogService'
import Style, {colors, sizes} from '../../../../Style'
import i18n from '../../../../i18n'
import {orderOnlineStore} from '../Store'
import {orderPaymentStore} from './Store'
import {ChargeSuccessReturn} from '../../../../Models/Client/Enums'

interface IProps
  extends StackScreenProps<OrderOnlineList & MainScreensList, 'orderPayment'> {}

const OrderPayment: React.FC<IProps> = ({
  navigation: {goBack, navigate},
  route,
}) => {
  useEffect(() => {
    myLog.warn('OrderPayment', route.params)
    orderPaymentStore.Initialize()

    //orderPaymentStore.Test()
  }, [null])
  const {pickerRow, pickerItemImage, pickerText} = Style

  const {
    IsLoading,
    Wallets,
    SelectedWallet,
    IsSubmitting,
    MissingAmountBalance,
    HasEnoughBalance,
  } = orderPaymentStore
  const {TotalAmountOrderFormatted} = orderOnlineStore

  const renderBalanceMessage = () => {
    if (HasEnoughBalance || SelectedWallet === undefined) {
      return null
    }

    return (
      <>
        <Text style={{color: colors.darkRed, marginBottom: sizes.padding.md}}>
          {i18n.t('OrderOnline.PaymentForm.InsufficientBalance')}
        </Text>
        <MyButton
          title={`${FormatCentsToEuro(MissingAmountBalance)} Opwaarderen`}
          onPress={() => {
            navigate('charge', {
              wallet: SelectedWallet,
              chargeSuccessReturn: ChargeSuccessReturn.OrderPayment,
              initialAmount: MissingAmountBalance,
            })
          }}
        />
      </>
    )
  }

  return (
    <ToprScreenLoader isLoading={IsLoading}>
      <Container>
        <MyHeader
          title={i18n.t('OrderOnline.Pay')}
          onBackPress={() => navigate('cart')}
        />
        <Content>
          <MySelectList
            label={i18n.t(
              'OrderOnline.PaymentForm.Fields.SelectedCurrency.Label',
            )}
            options={Wallets}
            selectedOption={SelectedWallet}
            onSelect={(option: IWalletSTO) =>
              orderPaymentStore.SetSelectedWallet(option)
            }
            isItemSelected={(a: IWalletSTO, b: IWalletSTO) => {
              return a.LoyaltyWalletGUID === b.LoyaltyWalletGUID
            }}
            renderListItem={(wallet: IWalletSTO, isHighLighted: boolean) => {
              return (
                <View style={pickerRow}>
                  <View
                    style={{
                      padding: sizes.padding.sm,
                      borderWidth: 1,
                      borderColor: colors.grey,
                    }}>
                    <Image
                      style={pickerItemImage}
                      source={{
                        uri: GetLocalizedImageUrl(
                          wallet.Currency.AppPhotoLocalizedImageGUID,
                        ),
                      }}
                    />
                  </View>
                  <Text
                    style={[isHighLighted && {color: colors.main}, pickerText]}>
                    {wallet.Currency.Name} (
                    {FormatCurrency(wallet.Balance, wallet.Currency)})
                  </Text>
                </View>
              )
            }}
          />
          {renderBalanceMessage()}
        </Content>
      </Container>
      <FullFooterButton
        isLoading={IsSubmitting}
        disabled={!HasEnoughBalance}
        onPress={() => {
          appUiStore.ShowAuthenticateConfirm({
            OnOk: () => {
              orderPaymentStore.CreateTableOrderAndDoPayment()
            },
            Message: i18n.t('OrderOnline.PaymentForm.AuthenticateAction', {
              amount: TotalAmountOrderFormatted,
            }),
          })
        }}
        title={`${i18n.t('OrderOnline.Pay')} (${TotalAmountOrderFormatted})`}
      />
    </ToprScreenLoader>
  )
}
export default observer(OrderPayment)
