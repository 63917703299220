import {StackNavigationProp} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {FlatList, View} from 'react-native'
import {Icon, Input, Text} from 'react-native-elements'
import {
  Container,
  Content,
  LocationCard,
  ResultCard,
  SubmitHeader,
} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import AppConfig from '../../../Config/AppConfig'
import {GetRandomKey} from '../../../Helpers/UiHelpers'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style from '../../../Style'
import {default as I18n, default as i18n} from '../../../i18n'
import {store} from './Store'
import {persistedStore} from '../../../Mobx'

interface IProps {
  navigation: StackNavigationProp<MainScreensList>
}

const Locations: React.FC<IProps> = ({navigation: {navigate}}) => {
  useEffect(() => {
    myLog.info('Locations')
    store.Initialize()
    //  AppState.addEventListener('change', _handleAppStateChange)
  }, [null])

  const {FilteredLocations, IsLocationsLoading, IsLocationAuthorized, Search} =
    store
  const {defaultFlatList, flatListContentStyleStock} = Style
  const {UserToken} = persistedStore

  const renderLocationAccessDenied = () => {
    //dont show when location is authorized
    if (IsLocationAuthorized) {
      return null
    }

    // const renderSettingsMessage = () => {
    //   const text =
    //     Platform.OS === 'android'
    //       ? I18n.t('Locations.RequestAccess.SettingsMessageAndroid')
    //       : I18n.t('Locations.RequestAccess.SettingsMessageIos')

    //   return <Text style={{marginTop: sizes.padding.md}}>{text}</Text>
    // }
    return (
      <View style={defaultFlatList}>
        <ResultCard
          title="Locatie toegang"
          button={{
            text: I18n.t('Locations.RequestAccess.AllowLocationPermission'),
            action: () => {
              store.RequestLocationPermission()
              //Linking.openSettings(),
            },
          }}>
          <Text>
            {I18n.t('Locations.RequestAccess.Message', {
              tenantName: AppConfig.Tenant.AppName,
            })}
          </Text>
          {/* {renderSettingsMessage()} */}
        </ResultCard>
      </View>
    )
  }

  const renderLocations = () => {
    if (!IsLocationAuthorized) {
      return null
    }

    const renderSearch = () => {
      const icon = Search === '' ? 'search1' : 'close'

      return (
        <Input
          placeholder={i18n.t('Common.Search')}
          leftIcon={
            <Icon name="search1" type="antdesign" size={24} color="black" />
          }
          rightIcon={
            Search !== '' && (
              <Icon
                name={icon}
                type="antdesign"
                size={24}
                color="black"
                onPress={() => {
                  if (Search !== '') {
                    store.onSearchTextChanged('')
                  }
                }}
              />
            )
          }
          onChangeText={(text) => {
            store.onSearchTextChanged(text)
          }}
          value={Search}
        />
      )
    }

    return (
      <>
        {renderSearch()}
        <FlatList
          style={defaultFlatList}
          contentContainerStyle={[flatListContentStyleStock]}
          refreshing={IsLocationsLoading}
          onRefresh={() => store.NearbyLocations()}
          data={FilteredLocations}
          renderItem={({item}) => (
            <LocationCard
              location={item}
              onPress={() => {
                navigate('locationDetails', {location: item})
              }}
            />
          )}
          keyExtractor={() => {
            return GetRandomKey()
          }}
        />
      </>
    )
  }

  return (
    <>
      <StockImageLayout padder={false} isLoading={IsLocationsLoading}>
        <Container keyboardAware={false}>
          <Content headerMargin padder={false} style={{flex: 1}}>
            {renderLocationAccessDenied()}
            {renderLocations()}
          </Content>
          <SubmitHeader
            onBackPress={() => {
              if (UserToken === undefined) {
                navigate('login')
              } else {
                navigate('dashboard')
              }
            }}
            title={i18n.t('Locations.Header')}
          />
        </Container>
      </StockImageLayout>
    </>
  )
}
export default observer(Locations)
