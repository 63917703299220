import React from 'react'
import { UmbracoMedia } from '../../../Helpers/UrlHelpers'
import AppImages from '../../../Images/AppImages'
import { IDownloadContentViewModel } from '../../../Services/Topr.Umbraco/Models/IDownloadContentViewModel'
interface IProps {
  data: IDownloadContentViewModel
}

const Download: React.FC<IProps> = ({data}) => {
  const renderContent = () => {
    const reactElements: React.ReactNode[] = []

    reactElements.push(
      <div className="column" key="image">
        <img src={UmbracoMedia(data.ImageUrl)} />
      </div>,
    )
    reactElements.push(
      <div className="column" key="text">        
        <div key="text" dangerouslySetInnerHTML={{__html:data.Text}} />        
        <div className="dl-buttons">
          <a href={data.AppStoreLink} target="_blank">
            <img
             src={AppImages.AppButtons.AppStore}              
              alt="App Store download"
            />
          </a>
          <a href={data.PlayStoreLink} target="_blank">
            <img
              src={AppImages.AppButtons.PlayStore}
              alt="Play Store download"
            />
          </a>
        </div>
      </div>,
    )

    return reactElements
  }
  return (
    <div className="section download-block">
      <div className="columns">{renderContent()}</div>
    </div>
  )
}

export default Download
