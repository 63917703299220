import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {
  Container,
  Content,
  CouponInfoCard,
  SubmitHeader,
} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import {GetLocalizedImageUrl} from '../../../Helpers/UiHelpers'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import {couponDetailsStore} from './Store'

interface IProps extends StackScreenProps<MainScreensList, 'couponDetails'> {}

const CouponDetails: React.FC<IProps> = ({navigation: {goBack}, route}) => {
  const userCouponGuid = route.params.userCouponGuid
  useEffect(() => {
    myLog.info(`CouponDetails`)
    couponDetailsStore.GetUserCoupon(userCouponGuid)
  }, [null])

  const {IsLoading, UserCoupon} = couponDetailsStore
  const imageGuid = UserCoupon?.Coupon.Images[0].Guid

  return (
    // <ToprScreenLoader isLoading={IsLoading}>
    <>
      <Container isGreyBackground>
        <StockImageLayout
          imageUrl={GetLocalizedImageUrl(imageGuid)}
          isLoading={IsLoading}
          //isLoading={true}
        >
          <Content headerMargin padder={false}>
            <img
              src={GetLocalizedImageUrl(imageGuid)}
              className="is-hidden-tablet"
            />
            <CouponInfoCard userCoupon={UserCoupon} />
          </Content>
          <SubmitHeader
            onBackPress={goBack}
            title={UserCoupon?.Coupon.FullTitle}
          />
        </StockImageLayout>
      </Container>
    </>
    // </ToprScreenLoader>
  )
}
export default observer(CouponDetails)
