import {makeAutoObservable} from 'mobx'
import {goBack} from '../../../Helpers/NavigationHelper'
import {DoServiceCall} from '../../../Helpers/ServiceHelpers'
import {GetI18NAlertMessage} from '../../../Helpers/UiHelpers'
import i18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {ToprError} from '../../../Models/Client'
import UserController from '../../../Services/Topr.Core/UserController'

export type MyFormData = {
  email: string
}

export default class Store {
  public IsSubmitting = false

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize() {
    this.InitialState()
  }

  public InitialState() {
    this.IsSubmitting = false
  }

  public ForgotPassword(email: string) {
    DoServiceCall(() => {
      this.IsSubmitting = true
      UserController.ForgotPassword(email)
        .then(() => {
          //reset form
          this.InitialState()
          appUiStore.ShowAlert({
            title: i18n.t('ForgotPassword.Form.Succes.Title'),
            message: i18n.t('ForgotPassword.Form.Succes.Message', {email}),
            type: 'success',
          })
          goBack()
          //navigate('dashboard')
        })
        .catch((toprError: ToprError) => {
          //SetFriendlyMessage(toprError, onError, [])
          const alert = GetI18NAlertMessage(
            'RegisterConfirm.ServerErrors',
            toprError.Code,
          )

          appUiStore.ShowAlert(alert)
          this.InitialState()
        })
    })
  }
}
