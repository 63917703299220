import React from 'react'
import {Platform, View, ViewStyle} from 'react-native'
import {Card, Icon, ListItem, Text} from 'react-native-elements'
import {ISystemMessageViewModel} from '../../Services/Topr.Umbraco/Models'
import Style, {sizes} from '../../Style'
import MyButton from '../Buttons/MyButton'
import ReactMarkdown from 'react-markdown'
import Markdown from 'react-native-markdown-display'

interface IProps {
  systemMessages: ISystemMessageViewModel[]
}

const SystemMessageCard: React.FC<IProps> = ({systemMessages}) => {
  if (systemMessages.length === 0) {
    return null
  }
  const markDownStyle: ViewStyle = {paddingBottom: sizes.padding.md}
  const marginLeft = Platform.OS === 'web' ? 0 : 15
  return (
    <Card containerStyle={{padding: 0, marginLeft: marginLeft}}>
      {/* <Card.Title>titel</Card.Title> */}
      {systemMessages.map((systemMessage, i) => (
        <ListItem
          key={i}
          containerStyle={{
            backgroundColor: '#f7f0ce',
            paddingLeft: 0,
            paddingVertical: 0,
          }}>
          <View
            style={{
              backgroundColor: '#00000055', //semi transparant black
              alignSelf: 'stretch',
              justifyContent: 'center',
              padding: sizes.padding.md,
            }}>
            <Icon name="warning" size={35} color="white" />
          </View>
          <ListItem.Content style={markDownStyle}>
            <Markdown>{systemMessage.Text}</Markdown>
          </ListItem.Content>
        </ListItem>
      ))}
    </Card>
  )
}

export default SystemMessageCard
