import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {Linking, Platform} from 'react-native'
import AppConfig from '../../../Config/AppConfig'
import {goBack} from '../../../Helpers/NavigationHelper'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {CreateAppLinkUrl} from '../../../Helpers/UrlHelpers'
import i18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {dashboardStore} from '../../Dashboard/Dashboard/Store.web'
import {IAlertMessage, ToprError} from '../../../Models/Client'
import {AppLinkTypes} from '../../../Models/Client/Enums'
import {
  AutomaticChargeStatusTypesDTO,
  IAutomaticChargeSTO,
  ICreateAutomaticChargeRequest,
  ICurrencyChargeMethodSTO,
  IDebtorBank,
  IEditAutomaticChargeStepsRequest,
  IWalletSTO,
} from '../../../Models/Server'
import {PaymentMethodTypes} from '../../../Models/Server/Wallet/ICurrencyChargeMethodSTO'
import AutomaticChargeController from '../../../Services/Topr.Core/AutomaticChargeController'
import CurrencyController from '../../../Services/Topr.Core/CurrencyController'
import WalletController from '../../../Services/Topr.Core/WalletController'

export type ChargeFormData = {
  selectedBank: string //also a separate prop
  initials: string
  surname: string
  email: string
  phone: string
  street: string
  streetNumber: string
  zipcode: string
  city: string
  amount: number // in euro, not cents also a separate prop
  weekAmount: number
}

export enum FormModes {
  ShowStatus, //(AutomaticChargeStatusTypesDTO.Off)
  NewAutomaticCharge,
  EditSteps, //when enabled
}

export default class Store {
  public IsLoading = true
  public Wallet?: IWalletSTO = undefined
  public IsSubmitting = false
  private CurrencyGuid = ''
  public AutomaticCharePaymentMethod?: ICurrencyChargeMethodSTO = undefined

  public Banks: IDebtorBank[] = []
  public SelectedBank?: IDebtorBank = undefined
  public Amount = 0

  public get DefaultValues(): ChargeFormData {
    if (AppConfig.IsDebugMode) {
      return {
        selectedBank: '',
        initials: 'E.R.',
        surname: 'de Ruijter',
        email: 'superdopey@live.nl',
        phone: '0612345678',
        street: 'Reigerstraat',
        streetNumber: '56',
        zipcode: '3265CD',
        city: 'Piershil',
        amount: 10,
        weekAmount: 1,
      }
    }

    // live
    return {
      selectedBank: '',
      initials: '',
      surname: '',
      email:
        dashboardStore.User !== undefined
          ? dashboardStore.User.EmailAddress
          : '',
      phone: '',
      street: '',
      streetNumber: '',
      zipcode: '',
      city: '',
      amount: 10,
      weekAmount: 1,
    }
  }

  public Status: AutomaticChargeStatusTypesDTO =
    AutomaticChargeStatusTypesDTO.Off

  public get AutomaticChargeStatusEnabled() {
    return (
      this.Status === AutomaticChargeStatusTypesDTO.EnabledWithVerification ||
      this.Status === AutomaticChargeStatusTypesDTO.EnabledWithoutVerification
    )
  }

  public FormMode: FormModes = FormModes.ShowStatus
  public PreviousAutomaticCharge?: IAutomaticChargeSTO = undefined

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize(currencyGuid: string) {
    this.CurrencyGuid = currencyGuid
    this.IsLoading = true
    this.Wallet = undefined
    this.AutomaticCharePaymentMethod = undefined

    this.SelectedBank = undefined
    this.PreviousAutomaticCharge = undefined

    this.FormMode = FormModes.ShowStatus

    DoServiceCall(() => {
      Promise.all([
        WalletController.GetWallet(currencyGuid),
        CurrencyController.GetPaymentMethod(
          currencyGuid,
          PaymentMethodTypes.AutomaticChargeCollection,
        ),
        AutomaticChargeController.GetBanks(currencyGuid),
        AutomaticChargeController.Latest(currencyGuid),
      ])
        .then(([wallet, paymentMethod, banks, automaticCharge]) => {
          runInAction(() => {
            this.Wallet = wallet

            this.AutomaticCharePaymentMethod = paymentMethod
            this.Amount = wallet.Currency.AutomaticChargeStepAmount
            this.Banks = banks

            this.InitializeAutomaticCharge(automaticCharge)
          })
        })
        .catch((error: ToprError) => {
          runInAction(() => {
            appUiStore.ShowToast({
              message: i18n.t('AutomaticCharge.LoadingFailed'),
              type: 'warning',
            })
            goBack()
          })
        })
        .finally(() => {
          runInAction(() => {
            this.IsLoading = false
          })
        })
    })
  }

  public SetFormMode(formMode: FormModes) {
    this.FormMode = formMode
  }
  private InitializeAutomaticCharge(
    automaticCharge: IAutomaticChargeSTO | null,
  ) {
    runInAction(() => {
      //console.log('InitializeAutomaticCharge', automaticCharge, '-')
      if (
        automaticCharge === null ||
        automaticCharge === undefined ||
        automaticCharge.toString() === ''
      ) {
        //no ac set yet
        this.Status = AutomaticChargeStatusTypesDTO.Off
        return
      }

      this.PreviousAutomaticCharge = automaticCharge

      //is set
      this.Status = automaticCharge.AutomaticChargeStatusId

      this.DefaultValues.initials = automaticCharge.Initials
      this.DefaultValues.selectedBank = automaticCharge.Bic

      this.DefaultValues.surname = automaticCharge.LastName
      this.DefaultValues.email = automaticCharge.EmailAddress
      this.DefaultValues.phone = automaticCharge.PhoneNumber
      this.DefaultValues.street = automaticCharge.StreetName
      this.DefaultValues.streetNumber = automaticCharge.StreetNumber
      this.DefaultValues.zipcode = automaticCharge.Zipcode
      this.DefaultValues.city = automaticCharge.City
      this.DefaultValues.amount = automaticCharge.StepAmount / 100
      this.DefaultValues.weekAmount = automaticCharge.StepLimit
      this.SelectedBank = this.Banks.find(
        (x) => x.DebtorBankId === automaticCharge.Bic,
      )
    })
  }

  public SetAmount(amount: number) {
    this.Amount = amount
  }

  public SetSelectedBank(selectedBank: IDebtorBank) {
    this.SelectedBank = selectedBank
  }

  private FormData2ICreateAutomaticChargeRequest(
    data: ChargeFormData,
  ): ICreateAutomaticChargeRequest {
    return {
      Initials: data.initials,
      DebtorBankId:
        this.SelectedBank !== undefined ? this.SelectedBank.DebtorBankId : '',
      City: data.city,
      CurrencyGuid: this.CurrencyGuid,
      EmailAddress: data.email,
      LastName: data.surname,
      PhoneNumber: data.phone,
      StepAmount: data.amount,
      StepLimit: data.weekAmount,
      StreetName: data.street,
      StreetNumber: data.streetNumber,
      Zipcode: data.zipcode,
      ReturnUrl: CreateAppLinkUrl(
        AppLinkTypes.EMandateResult,
        this.CurrencyGuid,
      ),
    }
  }

  public SaveAutomaticCharge(
    data: ChargeFormData,
    onError: (errorMessage: string) => void,
  ) {
    switch (this.FormMode) {
      case FormModes.NewAutomaticCharge:
        //case FormModes.EditAutomaticChargeFull:
        this.CreateAutomaticCharge(data, onError)
        break
      case FormModes.EditSteps:
        this.EditSteps(data, onError)
        break
    }

    //todo: turn off

    // if (this.PreviousAutomaticCharge === undefined) {
    //   if (this.IsEnabled) {
    //     //console.log('create new ac')
    //     this.CreateAutomaticCharge(data, onError)
    //   }
    // } else {
    //   //PreviousAutomaticCharge edit or turn off

    //   if (this.IsEnabled) {
    //     //edit
    //     //console.log('edit ac')
    //     this.EditAutomaticCharge(data, onError)
    //   } else {
    //     //console.log('turnoff ac')
    //     this.TurnOff(onError)
    //   }
    // }
  }

  public TurnOff(onError: (errorMessage: string) => void) {
    this.IsSubmitting = true
    AutomaticChargeController.TurnOff(this.CurrencyGuid)
      .then(() => {
        appUiStore.ShowAlert({
          type: 'warning',
          message: i18n.t('AutomaticCharge.Form.TurnOffMessage.Message'),
          title: i18n.t('AutomaticCharge.Form.TurnOffMessage.Title'),
        })
        goBack()
      })
      .catch((toprError: ToprError) => {
        SetFriendlyMessage(toprError, onError)
      })
      .finally(() =>
        runInAction(() => {
          this.IsSubmitting = false
        }),
      )
  }

  private CreateAutomaticCharge(
    data: ChargeFormData,
    onError: (errorMessage: string) => void,
  ) {
    DoServiceCall(() => {
      this.IsSubmitting = true

      console.log('CreateAutomaticCharge - 0')

      AutomaticChargeController.Insert(
        this.FormData2ICreateAutomaticChargeRequest(data),
      )
        .then((issuerUrl) => {
          //console.log('insert succes', issuerUrl, Platform.OS)
          console.log('CreateAutomaticCharge - 1', issuerUrl, Platform.OS)
          if (Platform.OS === 'web') {
            window.location.href = issuerUrl
          } else {
            Linking.openURL(issuerUrl)
            goBack()
          }
        })
        .catch((toprError: ToprError) => {
          SetFriendlyMessage(toprError, onError)
        })
        .finally(() =>
          runInAction(() => {
            this.IsSubmitting = false
          }),
        )
    })
  }

  private EditSteps(
    data: ChargeFormData,
    onError: (errorMessage: string) => void,
  ) {
    DoServiceCall(() => {
      this.IsSubmitting = true

      const request: IEditAutomaticChargeStepsRequest = {
        StepAmount: data.amount,
        StepLimit: data.weekAmount,
      }

      AutomaticChargeController.EditSteps(this.CurrencyGuid, request)
        .then(() => {
          goBack()

          appUiStore.ShowAlert({
            type: 'warning',
            message: i18n.t('AutomaticCharge.Form.EditMessage.Message'),
            title: i18n.t('AutomaticCharge.Form.EditMessage.Title'),
          })
        })
        .catch((toprError: ToprError) => {
          SetFriendlyMessage(toprError, onError)
        })
        .finally(() =>
          runInAction(() => {
            this.IsSubmitting = false
          }),
        )
    })
  }
}
export const automaticChargeStore = new Store()
