//import CallToActions from './CallToActions'
//import {store as tokenStore} from '../../Token/Tokens/Store'
import {observer} from 'mobx-react'
import React from 'react'
import {RefreshControl, ScrollView, View} from 'react-native'
import {
  BottomListSpacer,
  ImageTextCard,
  SystemMessageCard,
  ToprScreenLoader,
} from '../../../../Components'
import {navigate} from '../../../../Helpers/NavigationHelper'
import {FormatCurrency} from '../../../../Helpers/TextHelpers'
import {GetLocalizedImageUrl} from '../../../../Helpers/UiHelpers'
import Style from '../../../../Style'
import {store as tokenStore} from '../../../Token/Tokens/Store'
import {dashboardStore} from '../../Dashboard/Store'
import CallToActions from '../CallToActions'
import DashboardFooter from '../DashboardFooter'
import {dashboardHeaderStore} from '../DashboardHeader/Store'
interface IProps {}

const WalletsTab: React.FC<IProps> = ({children}) => {
  const {Wallets, IsWalletLoading, SystemMessages} = dashboardStore
  const {Tokens} = tokenStore
  const {HEADER_MAX_HEIGHT} = dashboardHeaderStore

  const {defaultFlatList, flatListContentStyle} = Style

  const renderWalletCards = () => {
    return Wallets.map((wallet, key) => (
      <ImageTextCard
        key={key}
        imageUrl={GetLocalizedImageUrl(
          wallet.Currency.AppPhotoLocalizedImageGUID,
        )}
        title={wallet.Currency.Name}
        onPress={() => {
          navigate('walletDetails', {
            currencyGuid: wallet.Currency.CurrencyGuid,
          })
        }}
        badgeText={FormatCurrency(wallet.Balance, wallet.Currency)}
      />
    ))
  }

  return (
    <ToprScreenLoader isLoading={IsWalletLoading}>
      <ScrollView
        style={[
          defaultFlatList,
          {
            paddingTop: dashboardHeaderStore.HEADER_MAX_HEIGHT * 2,
          },
        ]}
        contentContainerStyle={[flatListContentStyle]}
        bounces={false}
        onScroll={(event) => {
          dashboardHeaderStore.SetScrollY(event.nativeEvent.contentOffset.y)
        }}
        scrollEventThrottle={5}
        refreshControl={
          <RefreshControl
            refreshing={IsWalletLoading}
            progressViewOffset={dashboardHeaderStore.HEADER_MAX_HEIGHT * 2}
            onRefresh={() => {
              dashboardStore.GetWallets()
              dashboardStore.GetUser()
            }}
          />
        }>
        <CallToActions wallets={Wallets.length} tokens={Tokens.length} />
        <SystemMessageCard systemMessages={SystemMessages} />
        {renderWalletCards()}
        <BottomListSpacer />
      </ScrollView>
      <DashboardFooter />
    </ToprScreenLoader>
  )
}

export default observer(WalletsTab)
