import React from 'react'
import {View} from 'react-native'
import {Icon, IconProps, Input} from 'react-native-elements'
import Style from '../../Style'
interface IProps {
  label: string
  //icon: IconProps
  value: string
}

const MyLabelValue: React.FC<IProps> = ({label, value}) => {
  const {flexRow, inputContainer, labelStyle} = Style
  return (
    <View>
      <View style={flexRow}>
        {/* <Icon name="select-arrows" type="entypo" /> */}
        <Input
          disabled={true}
          inputContainerStyle={inputContainer}
          containerStyle={{paddingHorizontal: 0}}
          labelStyle={labelStyle}
          value={value}
          label={label}
        />
      </View>
    </View>
  )
}

export default MyLabelValue
