import {ErrorMessage} from '@hookform/error-message'

import React, {PropsWithChildren} from 'react'
import {Controller} from 'react-hook-form'
import {Text, View} from 'react-native'
import {CheckBox} from 'react-native-elements'
import Style, {sizes} from '../../Style'
interface IProps<T> {
  name: keyof T
  control: any
  validation?: {
    errors: any
    rules: any
    defaultValue?: string | boolean
  }
  formObject: T
}

const MyCheckBox = <ObjectType,>(
  props: PropsWithChildren<IProps<ObjectType>>,
) => {
  const {name, control, validation, children} = props
  const rules = validation !== undefined ? validation.rules : {}
  const errors = validation !== undefined ? validation.errors : {}
  const defaultValue =
    validation !== undefined && validation.defaultValue !== undefined
      ? validation.defaultValue
      : false

  const {errorMessage} = Style

  return (
    <>
      <Controller
        control={control}
        render={({onChange, value}) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <CheckBox
                containerStyle={{marginLeft: 0, paddingLeft: 0}}
                onPress={() => onChange(!value)}
                checked={value}
              />
              <View style={{flex: 1}}>
                {children}
                <ErrorMessage
                  errors={errors}
                  name={name.toString()}
                  render={({message}) => (
                    <Text style={errorMessage}>{message}</Text>
                  )}
                />
              </View>
            </View>
          )
        }}
        name={name.toString()}
        rules={rules}
        defaultValue={defaultValue}
      />
      {/* <ErrorMessage
        errors={errors}
        name={name.toString()}
        render={({message}) => <Text style={errorMessage}>{message}</Text>}
      /> */}
    </>
  )
}

export default MyCheckBox
