import {observer} from 'mobx-react'
import React from 'react'
import {Animated, StyleSheet, View} from 'react-native'
import {Icon, Image, withBadge} from 'react-native-elements'
import {ProfileButton} from '../../../../Components'
import Style from '../../../../Style'
import AppImages from '../../../../Images/AppImages'
import {dashboardStore} from '../../Dashboard/Store'
import {dashboardHeaderStore} from './Store'

interface IProps {}

const DashboardHeader: React.FC<IProps> = ({children}) => {
  const {flexRow} = Style
  const BadgedIcon = withBadge(1, {right: 0})(Icon)
  const {User} = dashboardStore

  const {headerContainer} = style

  const {ScrollY, HEADER_MAX_HEIGHT} = dashboardHeaderStore

  const headerTranslateY = ScrollY.interpolate({
    inputRange: [0, HEADER_MAX_HEIGHT],
    outputRange: [0, -HEADER_MAX_HEIGHT],
    extrapolate: 'clamp',
  })

  return (
    <Animated.View
      style={[headerContainer, {transform: [{translateY: headerTranslateY}]}]}>
      <Image
        source={AppImages.Logo.ToprWallet}
        style={{
          width: 138,
          height: 35,
        }}
      />
      <View style={flexRow}>
        {/* <View style={{marginRight: sizes.padding.md}}>
          <BadgedIcon
            type="evilicon"
            name="bell"
            size={44}
            iconStyle={{marginTop: 5}}
            onPress={() => console.log('notifications')}
          />
        </View> */}
        <ProfileButton
          user={User}
          onPress={() => {
            dashboardStore.ToggleUserMenu(true)
          }}
        />
        {/* <Icon
          name="menu-outline"
          type="ionicon"
          size={30}
          iconStyle={{marginTop: 5, marginLeft: 20}}
          onPress={() => generalMenustore.ToggleIsVisible()}
        /> */}
      </View>
    </Animated.View>
  )
}

export default observer(DashboardHeader)

const style = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: 10,
    borderBottomColor: '#f0f0f0',
    borderBottomWidth: 1,

    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: dashboardHeaderStore.HEADER_MAX_HEIGHT,
  },
})
