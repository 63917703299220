import {useFocusEffect} from '@react-navigation/native'
import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {BackHandler, StyleSheet, View} from 'react-native'
import {Badge, Icon, Text} from 'react-native-elements'
import {OrderOnlineList} from '..'
import {FullFooterButton, ToprScreenLoader} from '../../../../Components'
import {navigate as navigateMain} from '../../../../Helpers/NavigationHelper'
import i18n from '../../../../i18n'
import {myLog} from '../../../../Services/Client/LogService'
import Style, {sizes} from '../../../../Style'
import {orderOnlineStore} from '../Store'
import CategorySectionList from './Components/CategorySectionList'

interface IProps extends StackScreenProps<OrderOnlineList, 'articles'> {}

const Articles: React.FC<IProps> = ({navigation: {navigate, goBack}}) => {
  useEffect(() => {
    myLog.warn('Articles')
  }, [null])

  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        navigateMain('dashboard')
        return true
      }

      BackHandler.addEventListener('hardwareBackPress', onBackPress)

      return () =>
        BackHandler.removeEventListener('hardwareBackPress', onBackPress)
    }, [null]),
  )

  const {
    Isloading,
    OrderRequestLines,
    TotalAmountOrderFormatted,
    TotalProducts,
  } = orderOnlineStore

  const {iconContainer, badgeStyle} = style

  const renderCartFooter = () => {
    if (OrderRequestLines.length === 0) {
      return null
    }
    return (
      <FullFooterButton onPress={() => navigate('cart')}>
        <View style={iconContainer}>
          <Icon
            type="material-icons"
            name="shopping-bag"
            size={30}
            color="#fff"
            iconStyle={{marginLeft: sizes.padding.md}}
          />
          <Badge
            status="warning"
            containerStyle={badgeStyle}
            value={TotalProducts}
          />
        </View>
        <Text style={Style.fullFooterText}>
          {i18n.t('OrderOnline.Checkout')} ({TotalAmountOrderFormatted})
        </Text>
      </FullFooterButton>
    )
  }

  return (
    <>
      <ToprScreenLoader isLoading={Isloading}>
        <CategorySectionList />
        {renderCartFooter()}
      </ToprScreenLoader>
    </>
  )
}
export default observer(Articles)

const style = StyleSheet.create({
  iconContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
  },
  badgeStyle: {position: 'absolute', bottom: 4, right: -4},
})
