import {QrCodeTypes} from '../Enums'
import {IQrObject} from './IQrObject'
export class TicketQrObject implements IQrObject {
  public Type: QrCodeTypes = 'Ticket'
  public Params: {
    UserEventTicketId: number
    UserId: number
  }

  constructor(userEventTicketId: number, userId: number) {
    this.Params = {
      UserEventTicketId: userEventTicketId,
      UserId: userId,
    }
  }
}
