import React from 'react'
import {Image, View, ViewStyle, Text} from 'react-native'

import Style from '../../Style'
import AppImages from '../../Images/AppImages'
import Dot from './Dot'

interface IProps {
  isLoading: boolean
  size?: 'normal' | 'small'
}
const ToprLoader: React.FC<IProps> = ({isLoading, size}) => {
  if (!isLoading) {
    return null
  }

  // const height = 72
  // const width = 125
  const height = size === 'normal' ? 72 : 48
  const width = size === 'normal' ? 125 : 83
  const {fullWidthHeightAbs, toprLoader} = Style

  const paddingStyle: ViewStyle =
    size === 'normal' ? {paddingHorizontal: 20} : {paddingHorizontal: 5}

  const renderDots = () => {
    const dots: React.ReactNode[] = []
    const count = 3
    const duration = 400

    for (let index = 0; index < count; index++) {
      //start when prev dot is finished fadein
      const delayStart = index * duration
      dots.push(
        <Dot
          key={index}
          duration={duration}
          delayFadeOut={1000}
          delayStart={delayStart}
          size={size}
        />,
      )
    }

    return dots
  }

  return (
    <View
      style={{
        width,
        height,
      }}>
      <Image
        source={AppImages.Logo.ToprCallOutBg}
        style={{
          width,
          height,
        }}
      />
      <View style={[toprLoader, fullWidthHeightAbs, paddingStyle]}>
        {renderDots()}
      </View>
    </View>
  )
}

export default ToprLoader

ToprLoader.defaultProps = {
  isLoading: false,
  size: 'normal',
}
