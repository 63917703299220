import {makeAutoObservable, runInAction} from 'mobx'
import {goBack} from '../../../Helpers/NavigationHelper'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {FormatCentsToEuro} from '../../../Helpers/TextHelpers'
import I18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {MyErrorMessage, ToprError} from '../../../Models/Client'
import {IWithdrawInfoSTO} from '../../../Models/Server'
import TransactionController from '../../../Services/Topr.Core/TransactionController'
import WalletController from '../../../Services/Topr.Core/WalletController'

import {walletDetailsStore} from '../Details/Store'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import {GetRandStockImage} from '../../../Helpers/UiHelpers'

export type WithdrawFormData = {
  iban: string
  amount: number // cents
  name: string
  email: string
}

export default class Store {
  public IsSubmitting = false
  public IsLoading = true
  public WithdrawInfo?: IWithdrawInfoSTO = undefined
  public WithdrawCosts = 50
  private CurrencyGuid?: string = undefined

  public get MaxWithdrawAmount(): number {
    return this.WithdrawInfo !== undefined
      ? this.WithdrawInfo.MaxWithdrawAmount
      : 0
  }

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize(currencyGuid: string) {
    this.CurrencyGuid = currencyGuid

    this.GetWithdrawInfo(currencyGuid)
  }

  public GetWithdrawInfo(currencyGuid: string) {
    DoServiceCall(() => {
      this.IsLoading = true
      WalletController.GetWithdrawInfo(currencyGuid)
        .then((info) =>
          runInAction(() => {
            this.WithdrawInfo = info
            this.IsLoading = false
          }),
        )
        .catch(() => runInAction(() => (this.IsLoading = false)))
    })
  }
  public Withdraw(currencyGuid: string, formData: WithdrawFormData) {
    DoServiceCall(() => {
      this.IsSubmitting = true
      TransactionController.Withdraw(
        currencyGuid,
        formData.amount,
        formData.iban.trim(),
        formData.name,
        formData.email,
      )
        .then(() =>
          runInAction(() => {
            this.IsSubmitting = false
            const amountWithoutCosts = FormatCentsToEuro(
              formData.amount - this.WithdrawCosts,
            )
            appUiStore.ShowAlert({
              message: I18n.t('Withdraw.Form.Success.Message', {
                amountWithoutCosts,
              }),
              title: I18n.t('Withdraw.Form.Success.Title'),
              type: 'success',
              additionalCloseAction: () => {
                if (this.CurrencyGuid) {
                  walletDetailsStore.Initialize(this.CurrencyGuid)
                }
                dashboardStore.GetWallets()
                goBack()
              },
            })
          }),
        )
        .catch((toprError: ToprError) => {
          SetFriendlyMessage(
            toprError,
            (erorMessage) => {
              appUiStore.ShowAlert({
                message: erorMessage,
                title: I18n.t('Charge.Form.ServerErrors.Title'),
                type: 'error',
              })
            },
            [
              new MyErrorMessage(
                'CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED',
                'Charge.Form.ServerErrors.Title',
                'Charge.Form.ServerErrors.CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED',
                //null,
                //'warning'
              ),
            ],
          )
          runInAction(() => {
            this.IsSubmitting = false
          })
        })
    })
  }
}
export const withdrawStore = new Store()
