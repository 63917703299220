import React from 'react'
import {Text} from 'react-native'
import {useForm} from 'react-hook-form'
import {Card} from 'react-native-elements'
import {MyButton, MyFooter, MyFooterTile, MyModal} from '../../../../Components'
import i18n from '../../../../i18n'
import {IFileDTO} from '../../../../Models/Server'
import {sizes} from '../../../../Style/index'
import MySlim from '../../../../Components/Form/MySlim.web'
import {changeProfilePictureStore} from '../../ChangeProfilePicture/Store'

interface IProps {
  visible: boolean
  onClose: () => void
}

interface IFormValues {
  ImageFile?: IFileDTO
}

const ChangePictureModal: React.FC<IProps> = ({visible, onClose}) => {
  const {control, handleSubmit, errors, setError, setValue} =
    useForm<IFormValues>()

  const onSubmit = (data: IFormValues) => {
    //console.log('submit', data.ImageFile?.Data)
    changeProfilePictureStore.SavePicture(data.ImageFile?.Data!)
  }

  const {IsSubmitting} = changeProfilePictureStore

  const renderWebForm = () => {
    return (
      <>
        <MySlim
          formObject={{} as IFormValues}
          name="ImageFile"
          control={control}
          label={i18n.t('Common.DropImage')}
          sizeSlim={{width: 600, height: 600}}
        />
        <Card.Divider />
        <MyFooter>
          <MyFooterTile>
            <MyButton
              type="outline"
              onPress={() => {
                onClose()
              }}
              style={{marginRight: sizes.padding.sm}}
              title={i18n.t('Common.Cancel')}
            />
          </MyFooterTile>
          <MyFooterTile isLast>
            <MyButton
              isLoading={IsSubmitting}
              title={i18n.t('AddAccountModal.Form.Action')}
              onPress={handleSubmit(onSubmit)}
            />
          </MyFooterTile>
        </MyFooter>
      </>
    )
  }

  return (
    <MyModal
      onClose={onClose}
      visible={visible}
      position="center"
      title={i18n.t('Account.PictureModal.ChoosePicture')}
      closeOnBackPress>
      {renderWebForm()}
    </MyModal>
  )
}

export default ChangePictureModal
