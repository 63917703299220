import React from 'react'
import {Icon} from 'react-native-elements'
interface IProps {
  name: string
  type?: string
  onClick?: () => void
}

const SideMenuItem: React.FC<IProps> = ({name, type, children, onClick}) => {
  return (
    <li className="py-4" onClick={onClick}>
      <Icon name={name} type={type} />
      <span className="pl-4 is-hidden-touch">{children}</span>
    </li>
  )
}

export default SideMenuItem
