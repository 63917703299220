import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Text} from 'react-native-elements'
import {
  Content,
  EuroTextInput,
  FormInfo,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  MyLabelValue,
} from '../../../../Components'
import AppConfig from '../../../../Config/AppConfig'
import {FormatCentsToEuro} from '../../../../Helpers/TextHelpers'
import I18n from '../../../../i18n'
import i18n from '../../../../i18n/index'
import {IWalletSTO} from '../../../../Models/Server'
import Style from '../../../../Style'
import {sendMoneyStore, WalletFormData} from '../Store'

interface IProps {
  wallet: IWalletSTO
}

const SearchTab: React.FC<IProps> = ({wallet}) => {
  const {highlightText} = Style

  const {control, handleSubmit, errors, setError, setValue, formState} =
    useForm<WalletFormData>()

  useEffect(() => {
    //
    if (AppConfig.IsDebugMode) {
      setValue('search', 'superdopey@live.nl')
    }

    return () => {
      sendMoneyStore.ClearFoundUser()
    }
  }, [null])

  const {FoundUser, IsSubmitting} = sendMoneyStore
  const onSubmit = (data: WalletFormData) => {
    //after confirm show the authenticate modal

    if (FoundUser === undefined) {
      sendMoneyStore.SearchUser(data.search, (errorMessage) => {
        setError('search', {
          type: 'manual',
          message: errorMessage,
        })
      })
    } else {
      sendMoneyStore.ConfirmSubmit(data.amount)
    }
  }

  const renderMaxSendMessage = () => {
    return (
      <FormInfo style={{marginBottom: 0}}>
        {I18n.t('SendMoney.MaxBalance0')}
        <Text style={highlightText}>{FormatCentsToEuro(wallet.Balance)}</Text>
        {I18n.t('SendMoney.MaxBalance1')}
      </FormInfo>
    )
  }

  const renderSearchForm = () => {
    if (FoundUser !== undefined) {
      return null
    }
    return (
      <>
        {renderMaxSendMessage()}
        <MyInput
          formObject={{} as WalletFormData}
          name="search"
          label={I18n.t('SendMoney.Form.Fields.Search.Label')}
          autofocus={true}
          control={control}
          validation={{
            errors,
            rules: {
              required: i18n.t('SendMoney.Form.Fields.Search.Errors.Required'),
            },
          }}
          inputProps={{
            autoCapitalize: 'none',
            autoCompleteType: 'off',
            autoCorrect: false,
            onSubmitEditing: () => handleSubmit((data) => onSubmit(data))(),
          }}
        />
        <MyFooter>
          <MyFooterTile isLast>
            <MyButton
              isLoading={IsSubmitting}
              title={I18n.t('SendMoney.Form.SearchAction')}
              onPress={handleSubmit(onSubmit)}
            />
          </MyFooterTile>
        </MyFooter>
      </>
    )
  }

  const renderSendMoneyForm = () => {
    if (FoundUser === undefined) {
      return null
    }
    return (
      <>
        {renderMaxSendMessage()}
        <MyLabelValue label="account" value={FoundUser.Name} />
        <EuroTextInput
          formObject={{} as WalletFormData}
          name="amount"
          label={I18n.t('SendMoney.Form.Fields.Amount.Label')}
          control={control}
          onSubmitEditing={() => {
            handleSubmit((data) => onSubmit(data))()
          }}
          validation={{
            errors,
            rules: {
              required: I18n.t('SendMoney.Form.Fields.Amount.Errors.Required'),
              min: {
                value: 1,
                message: I18n.t('SendMoney.Form.Fields.Amount.Errors.MinMax', {
                  min: FormatCentsToEuro(1),
                  max: FormatCentsToEuro(wallet.Balance),
                }),
              },
              max: {
                value: wallet.Balance,
                message: I18n.t('SendMoney.Form.Fields.Amount.Errors.MinMax', {
                  min: FormatCentsToEuro(100),
                  max: FormatCentsToEuro(wallet.Balance),
                }),
              },
            },
          }}
        />
        <MyInput
          formObject={{} as WalletFormData}
          name="description"
          label={I18n.t('SendMoney.Form.Fields.Description.Label')}
          control={control}
          onTextChange={(text) => sendMoneyStore.SetDescription(text)}
          inputProps={{
            autoCapitalize: 'none',
          }}
        />
        <MyFooter>
          <MyFooterTile isLast>
            <MyButton
              isLoading={IsSubmitting}
              title={i18n.t('SendMoney.Form.Action')}
              onPress={handleSubmit(onSubmit)}
            />
          </MyFooterTile>
        </MyFooter>
      </>
    )
  }

  return (
    <>
      {renderSearchForm()}
      {renderSendMoneyForm()}
    </>
  )
}

export default observer(SearchTab)
