import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Badge, Text} from 'react-native-elements'
import Style, {sizes} from '../../Style'
interface IProps {
  title: string
  value: string | number
}

const TextBadge: React.FC<IProps> = ({title, value}) => {
  const {badgeStyle, flexRow} = Style
  return (
    <View
      style={[
        flexRow,
        {
          justifyContent: 'space-between',
          paddingHorizontal: sizes.padding.md,
          paddingVertical: sizes.padding.sm,
        },
      ]}>
      <Text>{title}</Text>
      <Badge
        value={`${value}`}
        badgeStyle={badgeStyle}
        textStyle={{fontSize: 14}}
      />
    </View>
  )
}

export default TextBadge

const styles = StyleSheet.create({})
