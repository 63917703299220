export enum AuthenticationMethod {
  Pincode = 0,
  FingerPrint = 1,
}

export enum FieldTypes {
  FIRSTNAME = 100,
  INITIALS = 101,
  INFIX = 102,
  LASTNAME = 103,
  GENDER = 104,
  DATEOFBIRTH = 105,
  STREETNAME = 106,
  STREETNUMBER = 107,
  ZIPCODE = 108,
  CITY = 109,
  COUNTRY = 110,
  PHONENUMBER = 111,
  CARDNUMBER = 123,
  MANAGEMONEYFUNCTIONSDISABLED = 285,
  IDPERSONALSTAMM = 291,
  AUSWEISNR = 292,
  WERK = 293,
  UNTERNEHMEN = 294,
  ANREDE = 295,
  TITLE = 296,
  VORNAME = 297,
  NACHNAME = 298,
  STRAßE = 299,
  LAND = 300,
  UNTERN_NAME = 301,
  GRUPPE = 302,
  POSITION = 303,
}

export enum UserIdentifierTypes {
  MUID,
  /// <summary>
  /// Uid of a mifare/desfire token
  /// </summary>
  UID,
  /// <summary>
  /// Static user guid
  /// </summary>
  GUID,
  /// <summary>
  /// Generated request identifiers. Usually expires after a period of time or after being used.
  /// </summary>
  //RID,
  /// <summary>
  /// QRID
  /// </summary>
  QRID,
  /// <summary>
  /// Blockchain address
  /// </summary>
  //BLOCKCHAIN,
  /// <summary>
  /// Unique Email Address
  /// </summary>
  EMAIL,
  /// <summary>
  /// Mobile Number
  /// </summary>
  MOBILE,
  /// <summary>
  /// Mybility Cardnumber
  /// </summary>
  MYBILITY_CARDNUMBER,
}

export const LogLevel = {
  Debug: 'debug',
  Info: 'info',
  Warn: 'warn',
  Error: 'error',
}

export enum AppLinkTypes {
  RegisterConfirm,
  ConfirmChangeEmail,
  ResetPassword,
  ConfirmPaymentOrder, //Charge
  EMandateResult,
}

export enum TokenStates {
  Active,
  Blocked,
  Expired,
}

// export enum QrActions{
//   OpenOrder
// }

// Qrcodes die de Topr Consumer leest (zoals voor table orders)
export type QrCodeTypes = 'Table' | 'Ticket'

export enum ConsumerSubscriptionItemTypes {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
  Trips = 5,
  Unlimited = 6,
}

export enum PaymentOrderStatusTypes {
  Refunded = -5,
  ProcessFailed = -4,
  PaymentFailed = -3,
  PaymentExpired = -2,
  PaymentCanceled = -1,
  WaitingForPayment = 0,
  PaymentComplete = 1,
  Processed = 2,
}

export enum ChargeSuccessReturn {
  WalletDetails,
  OrderPayment,
}
