import React from 'react'
import {View, StyleSheet, Text, StyleProp, ViewStyle} from 'react-native'
import Style from '../../Style'
interface IProps {
  label: string
  value: string
  style?: ViewStyle
  isVertical?: boolean
}

const MyLabelValueHorizontal: React.FC<IProps> = ({
  label,
  value,
  style,
  isVertical,
}) => {
  const {labelStyle, flexRow, justifySpaceBetween} = Style
  //const myStyle ={...style}

  const myStyle = [style]

  if (!isVertical) {
    myStyle.push(flexRow, justifySpaceBetween)
  }
  return (
    <View style={myStyle}>
      <Text style={labelStyle}>{label}</Text>
      <Text>{value}</Text>
    </View>
  )
}

export default MyLabelValueHorizontal

MyLabelValueHorizontal.defaultProps = {
  isVertical: false,
}
