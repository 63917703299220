import React from 'react'
import {StyleSheet, View} from 'react-native'
import {Card, Icon} from 'react-native-elements'
import {MyButton, MyModal} from '../../../Components'
import {navigate} from '../../../Helpers/NavigationHelper'
import i18n from '../../../i18n'
import {sizes} from '../../../Style/index'

interface IProps {
  isVisible: boolean
  onClose: () => void
}

const AddMenu: React.FC<IProps> = ({onClose, isVisible}) => {
  return (
    <MyModal closeOnBackPress visible={isVisible} onClose={onClose}>
      <View
        style={{
          paddingHorizontal: sizes.padding.md,
        }}>
        {/* <Text style={modalMessage}>Toevoegen</Text> */}
        <Card.Title>{i18n.t('Common.Add')}</Card.Title>
        <MyButton
          title={i18n.t('Dashboard.AddLocation')}
          isBold={true}
          buttonStyle={[styles.ctaButton]}
          onPress={() => {
            navigate('locations')
            onClose()
          }}
          icon={
            <View style={styles.iconContainer}>
              <Icon name="location-pin" type="entypo" size={30} color="white" />
            </View>
          }
        />
        <MyButton
          title={i18n.t('Dashboard.AddToken')}
          buttonStyle={[styles.ctaButton]}
          isBold
          onPress={() => {
            navigate('addToken')
            onClose()
          }}
          icon={
            <View style={styles.iconContainer}>
              <Icon name="credit-card" type="font-awesome" color="white" />
            </View>
          }
        />
      </View>
    </MyModal>
  )
}

export default AddMenu

const styles = StyleSheet.create({
  ctaButton: {
    paddingVertical: sizes.padding.md,
    marginBottom: sizes.padding.md,
  },

  iconContainer: {
    backgroundColor: '#00000040',
    height: 40,
    width: 40,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
})
