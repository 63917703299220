import AsyncStorage from '@react-native-community/async-storage'
import {makeAutoObservable} from 'mobx'
import {AsyncTrunk} from 'mobx-sync'
import {AuthenticationMethod} from '../Models/Client/Enums'
import {
  ClientToken,
  IAuthenticateUserResponse,
  IUserSTO,
} from '../Models/Server'
import {IMyPlacedOrder} from '../Models/Client'
import {IMyOrderRequestLine, IRestaurant} from '../Models/InzziiApi'

export class PersistedStore {
  public IsHydrated = false
  public IsFirstRun = true

  public ParentUserToken?: IAuthenticateUserResponse = undefined
  public UserToken?: IAuthenticateUserResponse = undefined //could be parent ,could be child usertoken

  public ClientToken?: ClientToken = undefined
  public AuthenticationMethod?: AuthenticationMethod = undefined
  public Pincode?: string = undefined
  public DeviceId: string = ''
  public UsePincode = true

  public PaymentOrderGuid?: string = undefined

  public MainUser?: IUserSTO = undefined
  public ChildUsers: IUserSTO[] = []

  private SetInitialState() {
    this.ParentUserToken = undefined
    this.UserToken = undefined
    this.ClientToken = undefined
    this.AuthenticationMethod = undefined
    this.Pincode = undefined
    this.DeviceId = ''
    this.MainUser = undefined
    this.PaymentOrderGuid = undefined
    this.ChildUsers = []
  }

  public get IsChildUserActive(): boolean {
    return this.ParentUserToken !== this.UserToken
  }

  public SetMainUser(user: IUserSTO, usePincode: boolean = true) {
    this.MainUser = user
    this.UsePincode = usePincode
  }

  public SetChildUsers(childUsers: IUserSTO[]) {
    this.ChildUsers = childUsers
  }

  public SetUserToken(userToken?: IAuthenticateUserResponse) {
    this.UserToken = userToken
  }

  public SwitchBackToParentUserToken() {
    this.UserToken = this.ParentUserToken
  }

  public SetParentUserToken(userToken?: IAuthenticateUserResponse) {
    this.ParentUserToken = userToken
    this.UserToken = userToken
  }

  public SetClientToken(clientToken?: ClientToken) {
    this.ClientToken = clientToken
  }

  public SetAuthenticationMethodPincode(pincode?: string) {
    this.Pincode = pincode
    this.AuthenticationMethod = AuthenticationMethod.Pincode
  }

  public SetAuthenticationMethodFingerPrint() {
    this.AuthenticationMethod = AuthenticationMethod.FingerPrint
    this.Pincode = undefined
  }

  public SetPaymentOrderGuid(paymentOrderGuid?: string) {
    this.PaymentOrderGuid = paymentOrderGuid
  }

  constructor() {
    makeAutoObservable(this)
  }

  public SetHydrated() {
    this.IsHydrated = true
  }

  public FirstRunCheck() {
    if (!this.IsFirstRun) {
      return
    }

    //reset persistedStore
    this.SetInitialState()
    this.IsFirstRun = false
  }
}

export const persistedStore = new PersistedStore()

const trunk = new AsyncTrunk(persistedStore, {storage: AsyncStorage})
trunk.init().then(() => {
  //when 1st run on devices ,which had Topr Wallet 1.0 ==> reset store
  persistedStore.FirstRunCheck()
  persistedStore.SetHydrated()
})
