import {makeAutoObservable} from 'mobx'
import {Platform} from 'react-native'
import NfcManager, {MyTagEvent, NfcTech} from 'react-native-nfc-manager'
import {onTagScannedEvent} from '../../../Helpers/NfcHelpers'
import i18n from '../../../i18n'
import {addTokenUidstore} from '../../../Screens/Token/AddToken/Components/AddTokenUid/Store'

export default class Store {
  constructor() {
    makeAutoObservable(this)
  }

  // Pre-step, call this before any NFC operations
  private async startNfc() {
    await NfcManager.start()
  }
  private readTag = async (onTagScanned: onTagScannedEvent) => {
    let tag: MyTagEvent | null = null

    try {
      await NfcManager.requestTechnology([NfcTech.Ndef])

      tag = (await NfcManager.getTag()) as MyTagEvent

      if (tag === null) {
        //show alert

        addTokenUidstore.ShowAlertMessage({
          type: 'error',
          message: i18n.t('AddTokenUid.Form.Uid.Errors.NotFound'),
        })
        return
      }

      // if (tag !== null) {
      //   tag.ndefStatus = await NfcManager.ndefHandler.getNdefStatus()
      // }

      if (Platform.OS === 'ios') {
        await NfcManager.setAlertMessageIOS(i18n.t('AddTokenUid.CardScanned'))
      }

      onTagScanned(tag)
    } catch (ex) {
      // for tag reading, we don't actually need to show any error
      console.log(ex)
    } finally {
      console.log('NfcManager.cancelTechnologyRequest()')
      NfcManager.cancelTechnologyRequest()
    }

    return tag
  }
  public async Initialize(onTagScanned: onTagScannedEvent) {
    await this.startNfc()

    this.readTag(onTagScanned)
  }

  public Unmount() {
    NfcManager.cancelTechnologyRequest()
  }
}
export const store = new Store()
