import {makeAutoObservable, runInAction} from 'mobx'
//import Pages from '../../Models/Strapi/Pages'
import ToprUmbraco from '../../Services/Topr.Umbraco'
import {IPageViewModel, UmbracoPages} from '../../Services/Topr.Umbraco/Models'

export default class Store {
  public IsLoading = true
  //public Page?: Page = undefined
  public Page?: IPageViewModel = undefined

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize() {
    this.IsLoading = true

    ToprUmbraco.GetPage(UmbracoPages.Home)
      .then((page) => {
        runInAction(() => {
          this.Page = page
        })
      })
      .catch((error: string) => {
        console.log(error)
      })
      .finally(() => {
        setTimeout(() => {
          runInAction(() => (this.IsLoading = false))
        }, 200) //weird component not mounted
      })
  }

  public GetDownload() {
    this.IsLoading = true

    ToprUmbraco.GetPage(UmbracoPages.Download)
      .then((page) => {
        runInAction(() => (this.Page = page))
      })
      .catch((error: string) => {
        console.log(error)
      })
      .finally(() => {
        setTimeout(() => {
          runInAction(() => (this.IsLoading = false))
        }, 200)
      })
  }
}
export const homeStore = new Store()
