import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {ICurrencyChargeMethodSTO, ICurrencySTO} from '../../Models/Server'
import {PaymentMethodTypes} from '../../Models/Server/Wallet/ICurrencyChargeMethodSTO'
import {axiosAuthClient} from './Axios/AxiosAuthClient'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  GetConsumerCurrencies: (merchantGuid: string) => {
    return new Promise<ICurrencySTO[]>((resolve, reject) => {
      const url = `api/v1/merchant/${merchantGuid}/currencies/consumer`
      axiosAuthClient
        .get(url)
        .then((response: AxiosResponse<ICurrencySTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetPaymentMethod: (
    currencyGuid: string,
    paymentMethodType: PaymentMethodTypes,
  ) => {
    return new Promise<ICurrencyChargeMethodSTO>((resolve, reject) => {
      const url = `api/v1/currency/${currencyGuid}/paymentmethod/${paymentMethodType}`
      axiosAuthUser
        .get(
          `api/v1/currency/${currencyGuid}/paymentmethod/${paymentMethodType}`,
        )
        .then((response: AxiosResponse<ICurrencyChargeMethodSTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
