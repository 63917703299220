import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {StyleSheet, View} from 'react-native'
import {Text} from 'react-native-elements'
import {
  Container,
  MyButton,
  MyQrCode,
  SubmitHeader,
  ToprScreenLoader,
} from '../../../Components'
import {FormatSeconds} from '../../../Helpers/DateHelpers'
import i18n from '../../../i18n'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style, {colors, deviceWidth, sizes} from '../../../Style'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import {qrCodeStore} from './Store'

interface IProps extends StackScreenProps<MainScreensList> {}

const QrCode: React.FC<IProps> = ({navigation: {goBack}, route}) => {
  useEffect(() => {
    myLog.warn('QrCode')
    return qrCodeStore.Initialize()
  }, [null])

  const {IsLoading, RequestIdentifier, TimeOutSeconds} = qrCodeStore
  const {centerXYContainer} = Style
  const size = deviceWidth - 100
  return (
    <>
      <ToprScreenLoader isLoading={IsLoading}>
        <Container keyboardAware={false} headerPadding>
          <View style={[centerXYContainer]}>
            {RequestIdentifier !== undefined && (
              <MyQrCode value={RequestIdentifier.Identifier} />
            )}

            <View style={style.timerContainer}>
              <Text>{FormatSeconds(TimeOutSeconds)}</Text>
              <MyButton
                icon={{
                  name: 'refresh',
                  type: 'font-awesome',
                  color: colors.main,
                }}
                disabled={IsLoading}
                onPress={() => qrCodeStore.GetCrCode()}
                buttonStyle={style.button}
                type="outline"
              />
            </View>
          </View>
        </Container>
        <SubmitHeader
          title={i18n.t('Common.QrCode')}
          onBackPress={() => {
            dashboardStore.Initialize()
            goBack()
          }}
        />
      </ToprScreenLoader>
    </>
  )
}
export default observer(QrCode)

const style = StyleSheet.create({
  timerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: sizes.padding.md,
  },
  button: {
    marginLeft: sizes.padding.md,
  },
})
