import React, {useEffect} from 'react'
import {View, StyleSheet} from 'react-native'
import {Card} from 'react-native-elements'

import {useForm} from 'react-hook-form'

import {store} from '../Store'
import {observer} from 'mobx-react'
import {
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  MyModal,
} from '../../../../Components'
import i18n from '../../../../i18n'
import {sizes} from '../../../../Style'

interface IProps {}

export type MyFormData = {
  description: string
}

const SetDescriptionModal: React.FC<IProps> = () => {
  const {SetDescriptionTokenModalIsVisible, SelectedToken} = store

  useEffect(() => {
    reset({description: SelectedToken?.Description})
  }, [SelectedToken])
  const {control, handleSubmit, errors, reset} = useForm<MyFormData>()

  const onSubmit = (data: MyFormData) => {
    store.SetDescription(data.description)
  }

  return (
    <MyModal
      onClose={() => store.ToggleSetDescriptionTokenModalIsVisible()}
      visible={SetDescriptionTokenModalIsVisible}
      position="center">
      <Card.Title>{i18n.t('SetDescriptionModal.Title')}</Card.Title>
      <View>
        <MyInput
          formObject={{} as MyFormData}
          name="description"
          autofocus={true}
          control={control}
          inputProps={{
            onSubmitEditing: () => {
              handleSubmit((data) => onSubmit(data))()
            },
            maxLength: 100,
          }}
          validation={{
            errors,
            rules: {
              required: i18n.t(
                'SetDescriptionModal.Form.DescriptionField.Errors.Required',
              ),
            },
          }}
          label={i18n.t('SetDescriptionModal.Form.DescriptionField.Label')}
        />
      </View>
      <MyFooter>
        <MyFooterTile>
          <MyButton
            type="outline"
            onPress={() => {
              store.ToggleSetDescriptionTokenModalIsVisible()
            }}
            style={{marginRight: sizes.padding.sm}}
            title={i18n.t('Common.Cancel')}
          />
        </MyFooterTile>
        <MyFooterTile isLast>
          <MyButton
            isLoading={store.IsSubmitting}
            title={i18n.t('SetDescriptionModal.Form.Action')}
            onPress={handleSubmit(onSubmit)}
          />
        </MyFooterTile>
      </MyFooter>
    </MyModal>
  )
}

export default observer(SetDescriptionModal)

const styles = StyleSheet.create({})
