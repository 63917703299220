import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch, Json2Url} from '../../Helpers/ServiceHelpers'
import {ILocationSTO} from '../../Models/Server'
import {axiosAuthClient} from './Axios/AxiosAuthClient'

export default {
  NearbyLocationsV2: (
    Latitude: number,
    Longitude: number,
    Radius: number,
    Take: number,
  ) => {
    return new Promise<ILocationSTO[]>((resolve, reject) => {
      const data = {
        Latitude,
        Longitude,
        Radius,
        Take,
      }
      const queryStringParams = Json2Url(data)

      const url = `api/v1/locations/nearby?${queryStringParams}`

      axiosAuthClient
        .get(url)
        .then((response: AxiosResponse<ILocationSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
