import React from 'react'
import {View} from 'react-native'
import Style from '../../Style'
interface IProps {}

const FormFooter: React.FC<IProps> = ({children}) => {
  const {formFooter} = Style
  return <View style={formFooter}>{children}</View>
}

export default FormFooter
