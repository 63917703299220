import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {
  Container,
  Content,
  NfcScanner,
  SubmitHeader,
  ToprScreenLoader,
} from '../../../../../Components'
import {goBack} from '../../../../../Helpers/NavigationHelper'
import {myLog} from '../../../../../Services/Client/LogService'
import i18n from '../../../../../i18n'
import {addTokenUidstore} from './Store'

interface IProps {}

const AddTokenUid: React.FC<IProps> = () => {
  useEffect(() => {
    myLog.warn('AddTokenUid')
    addTokenUidstore.Initialize()
  }, [null])

  const {IsSubmitting, AlertStatusMessage} = addTokenUidstore

  return (
    <ToprScreenLoader isLoading={IsSubmitting}>
      <Container>
        <Content headerMargin>
          <NfcScanner
            label={i18n.t('AddTokenUid.Form.Uid.Label')}
            onTagScanned={(tag) => {
              addTokenUidstore.ActivateWithUid(tag)
            }}
            alertStatusMessage={AlertStatusMessage}
          />
        </Content>
        <SubmitHeader onBackPress={goBack} />
        {/* <SubmitHeaderButton
              isLoading={IsSubmitting}
              disabled={!formState.isValid}
              title={i18n.t('AddToken.Header')}
              onPress={handleSubmit(onSubmit)}
            /> */}
      </Container>
    </ToprScreenLoader>
  )
}
export default observer(AddTokenUid)
