import React from 'react'
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native'
import {Text} from 'react-native-elements'

import Style, {colors} from '../../Style'
import MyLoader from '../Loader/MyLoader'
interface IProps extends TouchableOpacityProps {
  title?: string
  isLoading?: boolean
}

const FullFooterButton: React.FC<IProps> = ({
  children,
  title,
  isLoading,
  disabled,
  ...otherProps
}) => {
  const renderContent = () => {
    if (isLoading) {
      return (
        <View style={{width: 100}}>
          <MyLoader isLoading={isLoading} />
        </View>
      )
    }

    if (title) {
      return <Text style={Style.fullFooterText}>{title}</Text>
    }

    return children
  }
  return (
    <TouchableOpacity
      style={[
        style.cartButtonContainer,
        disabled && {backgroundColor: colors.lightGrey},
      ]}
      disabled={disabled}
      {...otherProps}>
      {renderContent()}
    </TouchableOpacity>
  )
}

export default FullFooterButton

const style = StyleSheet.create({
  cartButtonContainer: {
    height: 50,
    backgroundColor: colors.main,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
