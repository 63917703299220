import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Icon} from 'react-native-elements'
import {MyButton} from '../../../Components'
import {navigate} from '../../../Helpers/NavigationHelper'
import I18n from '../../../i18n'
import Style, {sizes} from '../../../Style'
interface IProps {
  wallets: number
  tokens: number
}

const CallToActions: React.FC<IProps> = ({wallets, tokens}) => {
  if (wallets > 0 && tokens > 0) {
    return null
  }

  const renderAddWalletCta = () => {
    if (wallets > 0) {
      return null
    }
    return (
      <MyButton
        title={I18n.t('Dashboard.AddLocation')}
        buttonStyle={[styles.ctaButton]}
        onPress={() => navigate('locations')}
        icon={
          <View style={styles.iconContainer}>
            <Icon name="location-pin" type="entypo" size={30} color="white" />
          </View>
        }
      />
    )
  }
  const renderAddTokenCta = () => {
    if (tokens > 0) {
      return null
    }

    return (
      <MyButton
        onPress={() => navigate('addToken')}
        title={I18n.t('Dashboard.AddToken')}
        buttonStyle={styles.ctaButton}
        icon={
          <View style={styles.iconContainer}>
            <Icon
              name="credit-card"
              type="font-awesome"
              size={20}
              color="white"
            />
          </View>
        }
      />
    )
  }

  return (
    <View
      style={{
        paddingHorizontal: sizes.padding.md,
      }}>
      {renderAddWalletCta()}
      {renderAddTokenCta()}
    </View>
  )
}

export default CallToActions

const styles = StyleSheet.create({
  ctaButton: {
    marginTop: sizes.padding.md,
    paddingVertical: sizes.padding.md,
  },

  iconContainer: {
    backgroundColor: '#00000040',
    height: 40,
    width: 40,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
})
