import {makeAutoObservable, runInAction} from 'mobx'
import {Animated, ScrollView} from 'react-native'
import {goBack, goBackSteps} from '../../../Helpers/NavigationHelper'
import {GetOrderLineAmount} from '../../../Helpers/OrderHelpers'
import {Geti18NMessage} from '../../../Helpers/ServiceHelpers'
import {FormatToEuro} from '../../../Helpers/TextHelpers'
import i18n from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {ToprError} from '../../../Models/Client'
import {
  IArticle,
  ICondimentGroup,
  IDepartment,
  IInzziUser,
  IMyOrderRequestLine,
  IOrderHistory,
  IRestaurant,
} from '../../../Models/InzziiApi'
import {DeliveryType} from '../../../Models/InzziiApi/Enums'
import {IOrder} from '../../../Models/InzziiApi/IOrder'
import ArticleController from '../../../Services/Inzii.Api/ArticleController'
import RestaurantController from '../../../Services/Inzii.Api/RestaurantController'
import InziiUserController from '../../../Services/Inzii.Api/UserController'
import {deviceWidth} from '../../../Style'

export default class Store {
  public HEADER_MAX_HEIGHT = 300
  public TABBAR_HEIGHT = 55

  public ScrollY = new Animated.Value(0)

  public SearchMode = false

  public Isloading = true
  public Restaurant?: IRestaurant = undefined
  public Departments: IDepartment[] = []
  public Articles: IArticle[] = []
  public AllArticles: IArticle[] = []
  public User?: IInzziUser = undefined

  public TableNumber = ''
  public VSamUid = ''
  public DeliveryType: DeliveryType = DeliveryType.Table
  public BranchofficeId = ''
  private DepartmentName?: string = null

  public OrderRequestLines: IMyOrderRequestLine[] = []

  public OrderHistory: IOrderHistory[] = []
  public Order?: IOrder = undefined

  public get TotalProducts() {
    return this.OrderRequestLines.map((x) => x.quantity).reduce(
      (acc, currentValue) => acc + currentValue,
      0,
    )
  }

  public get TotalAmountOrderFormatted() {
    return this.TotalAmountOrder
    //return FormatToEuro(this.TotalAmountOrder)
  }

  public get TotalAmountOrder() {
    let result = 0
    this.OrderRequestLines.forEach(
      (orderLine) => (result += GetOrderLineAmount(orderLine)),
    )

    return result
  }

  public get FilteredDepartments() {
    return this.Departments.filter(
      (x) =>
        this.Articles.findIndex((a) => a.departmentNumber === x.number) > -1,
    )
  }

  public get SortedOrderHistory() {
    return this.OrderHistory.slice().sort((a, b) => {
      if (new Date(a.orderDate) > new Date(b.orderDate)) {
        return -1
      } else {
        return 1
      }
    })
  }

  public DepartmentXScroll: any = {}

  public VisibleDepartmentName = ''

  private lastScrollX = 0
  public Search = ''

  constructor() {
    makeAutoObservable(this)
  }

  public InitializeTableOrder(
    branchofficeId: string,
    deliveryType: DeliveryType,
    tableNumber: string,
    vsamUid: string,
    departmentName?: string,
  ) {
    this.BranchofficeId = branchofficeId
    this.DeliveryType = deliveryType
    this.TableNumber = tableNumber
    this.VSamUid = vsamUid
    this.DepartmentName = departmentName
  }

  public Initialize() {
    this.Isloading = true
    this.DepartmentXScroll = {}
    this.OrderRequestLines = []
    this.User = undefined

    if (persistedStore.MainUser === undefined || this.BranchofficeId === '') {
      goBackSteps(2)
      return
    }

    this.Isloading = true
    InziiUserController.ToprUser(persistedStore.MainUser)
      .then((user) => {
        this.User = user
        this.GetRestautantData(this.BranchofficeId)
      })
      .catch((toprError: ToprError) => {
        const errorMessage = Geti18NMessage(toprError)
        appUiStore.ShowToast({message: errorMessage})
        goBackSteps(2)
      })
  }

  public InitializePlacedOrders() {
    this.OrderHistory = []
    this.User = undefined
    this.Order = undefined
    if (persistedStore.MainUser === undefined) {
      goBackSteps(2)
      return
    }

    this.Isloading = true
    InziiUserController.ToprUser(persistedStore.MainUser)
      .then((user) => {
        runInAction(() => {
          this.User = user
        })
        this.GetOrderHistory()
      })
      .catch((toprError: ToprError) => {
        const errorMessage = Geti18NMessage(toprError)
        appUiStore.ShowToast({message: errorMessage})
        goBackSteps(2)
      })
  }

  public GetOrderHistory() {
    if (this.User !== undefined) {
      InziiUserController.GetOrderHistory(this.User.accessToken)
        .then((orderHistory) => {
          runInAction(() => {
            this.OrderHistory = orderHistory
            this.Isloading = false
          })
        })
        .catch((toprError: ToprError) => {
          const errorMessage = Geti18NMessage(toprError)
          appUiStore.ShowToast({message: errorMessage})
          goBackSteps(2)
        })
    }
  }

  public GetOrder(orderId: string) {
    this.Order = undefined
    if (this.User !== undefined) {
      InziiUserController.GetOrder(this.User.accessToken, orderId)
        .then((order) => {
          runInAction(() => {
            this.Order = order
            this.Isloading = false
          })
        })
        .catch((toprError: ToprError) => {
          const errorMessage = Geti18NMessage(toprError)
          appUiStore.ShowToast({message: errorMessage})
          goBackSteps(2)
        })
    }
  }

  public async GetRestautantData(branchofficeId: string) {
    const allResults = await Promise.all([
      RestaurantController.GetRestaurant(branchofficeId),
      ArticleController.GetDepartments(branchofficeId),
      ArticleController.GetArticles(branchofficeId),
    ]).catch((toprError: ToprError) => {
      const errorMessage = Geti18NMessage(toprError)
      appUiStore.ShowToast({message: errorMessage})
      goBackSteps(2)
    })

    if (Array.isArray(allResults)) {
      runInAction(() => {
        this.Isloading = false
        this.Restaurant = allResults[0]

        if (this.DepartmentName !== null && this.DepartmentName != undefined) {
          // show only the departmentName from Pos.DepartmentName
          this.Departments = allResults[1].filter(
            (x) => x.name === this.DepartmentName,
          )
        } else {
          //show all departments
          this.Departments = allResults[1]
        }

        this.Articles = allResults[2]
        this.AllArticles = allResults[2]
      })
    }
  }

  public SetSearch(search: string) {
    if (search !== '') {
      this.Search = search

      this.Articles = this.AllArticles.filter(
        (x) =>
          x.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1,
      )
    } else {
      this.Search = ''
      this.Articles = [...this.AllArticles]
    }
  }

  public SetDepartmentScroll(departmentName: string, x: number) {
    this.DepartmentXScroll[departmentName] = x
  }

  public SetVisibleDepartment(
    departmentName: string,
    departmentScrollViewRef: React.RefObject<ScrollView>,
  ) {
    this.VisibleDepartmentName = departmentName

    if (departmentScrollViewRef.current) {
      //last scroll
      const newX = this.DepartmentXScroll[departmentName]
      const scrollDistance = this.lastScrollX - newX
      const horizontalSpacing = 20

      if (scrollDistance !== 0) {
        const toRight = this.lastScrollX - newX < 0

        //only scroll right when out of view on the right
        if (toRight && deviceWidth < newX + horizontalSpacing) {
          departmentScrollViewRef.current.scrollTo({
            x: newX,
          })
        } else if (!toRight) {
          //awlways scroll left
          departmentScrollViewRef.current.scrollTo({
            x: newX,
          })
        }

        this.lastScrollX = newX
      }
    }
  }

  private IsValidArticle(
    orderRequestLine: IMyOrderRequestLine,
    condimentGroups: ICondimentGroup[],
  ) {
    if (condimentGroups && condimentGroups.length > 0) {
      let result = true

      condimentGroups.forEach((cg) => {
        if (cg.isMeetConditions) {
          //check min
          if (cg.minimumCondiments > 0) {
            //check minimumCondiments
            if (
              orderRequestLine.linkedOrderLines === undefined ||
              orderRequestLine.linkedOrderLines.filter(
                (x) => x.parentNumber == cg.number,
              ).length < cg.minimumCondiments
            ) {
              result = false

              appUiStore.ShowToast({
                message: i18n.t('OrderOnline.MinErrorMessage', {
                  min: cg.minimumCondiments,
                  type: cg.name,
                }),
              })
              return
            }
          }
        }
      })

      return result
    } else {
      //no validate
      return true
    }
  }

  public AddArticle(
    orderRequestLine: IMyOrderRequestLine,
    condimentGroups: ICondimentGroup[],
  ) {
    if (this.IsValidArticle(orderRequestLine, condimentGroups)) {
      //check if there existing orderRequestLines

      const existingOrderLineIndex = this.OrderRequestLines.findIndex(
        (x) => x.random === orderRequestLine.random,
      )

      if (existingOrderLineIndex === -1) {
        this.OrderRequestLines.push(orderRequestLine)
      } else {
        const existingOrderLine = this.OrderRequestLines[existingOrderLineIndex]

        if (
          existingOrderLine.linkedOrderLines === undefined ||
          existingOrderLine.linkedOrderLines.length === 0
        ) {
          //orderline without condiments
          //update quantity
          existingOrderLine.quantity += orderRequestLine.quantity
          this.OrderRequestLines[existingOrderLineIndex] = existingOrderLine
        } else {
          //orderline has condiments, always add a new row
          this.OrderRequestLines.push(orderRequestLine)
        }
      }
      goBack()
    }
  }

  public PlusArticle(orderRequestLine: IMyOrderRequestLine) {
    orderRequestLine.quantity++
  }

  public MinusArticle(orderRequestLine: IMyOrderRequestLine) {
    if (orderRequestLine.quantity > 1) {
      orderRequestLine.quantity--
    } else if (orderRequestLine.quantity === 1) {
      const index = this.OrderRequestLines.findIndex(
        (x) => x.random === orderRequestLine.random,
      )
      this.OrderRequestLines.splice(index, 1)
    }
  }

  public ToggleSearchMode(searchMode: boolean) {
    this.SearchMode = searchMode
  }
}
export const orderOnlineStore = new Store()
