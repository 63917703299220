import {StackScreenProps} from '@react-navigation/stack'
import I18n from 'i18n-js'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {FlatList, StyleSheet, Text} from 'react-native'
import {
  Container,
  Content,
  ImageCard,
  SubmitHeader,
  ToprScreenLoader,
} from '../../Components'
import {StockImageLayout} from '../../Components/Web'
import {GetRandomKey} from '../../Helpers/UiHelpers'
import i18n from '../../i18n'
import AppImages from '../../Images/AppImages'
import {persistedStore} from '../../Mobx'
import {MainScreensList} from '../../Navigation'
import {myLog} from '../../Services/Client/LogService'
import Style from '../../Style'
import {orderOnlineStore} from '../Location/OrderOnline/Store'
import PlacedOrderCard from './Components/PlacedOrderCard'
import {dashboardStore} from '../Dashboard/Dashboard/Store'

interface IProps extends StackScreenProps<MainScreensList> {}

const PlacedOrders: React.FC<IProps> = ({navigation: {goBack, navigate}}) => {
  useEffect(() => {
    myLog.warn('PlacedOrders')
    orderOnlineStore.InitializePlacedOrders()
  }, [null])
  const {SortedOrderHistory, Isloading} = orderOnlineStore
  const {
    defaultFlatListWhite,
    flatListContentStyle,
    flatListContentStyleStock,
  } = Style

  return (
    <>
      <ToprScreenLoader isLoading={Isloading}>
        <Container keyboardAware={false}>
          <StockImageLayout>
            <Content headerMargin padder={false} style={{flex: 1}}>
              <FlatList
                style={[defaultFlatListWhite]}
                ListEmptyComponent={
                  <ImageCard
                    image={AppImages.Background.Waiter}
                    text={I18n.t('PlacedOrders.NoOrders')}
                  />
                }
                contentContainerStyle={[flatListContentStyleStock]}
                data={SortedOrderHistory}
                //data={[SortedOrderHistory[0]]}
                renderItem={({item}) => (
                  <PlacedOrderCard
                    onPress={() => {
                      navigate('placedOrderDetails', {orderHistory: item})
                    }}
                    orderHistory={item}
                  />
                )}
                keyExtractor={() => {
                  return GetRandomKey()
                }}
              />
            </Content>

            <SubmitHeader
              onBackPress={() => {
                //dashboardStore.GetWallets()
                goBack()
              }}>
              <Text>{i18n.t('PlacedOrders.Header')}</Text>
            </SubmitHeader>
          </StockImageLayout>
        </Container>
      </ToprScreenLoader>
    </>
  )
}

export default observer(PlacedOrders)

const styles = StyleSheet.create({})
