import React from 'react'
import {View, StyleSheet} from 'react-native'
import Style, {colors, sizes} from '../../Style'
interface IProps {}

const MyListItem: React.FC<IProps> = ({children}) => {
  return (
    <View
      style={{
        padding: sizes.padding.md,
        borderBottomColor: colors.lightGrey,
        borderBottomWidth: 1,
      }}>
      {children}
    </View>
  )
}

export default MyListItem

const styles = StyleSheet.create({})
