import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {Icon, ListItem} from 'react-native-elements'
import {Container, MyHeader, ProfileButton} from '../../../Components'
import i18n from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import ChangePictureModal from './Components/ChangePictureModal'

interface IProps extends StackScreenProps<MainScreensList> {}

const Account: React.FC<IProps> = ({navigation: {navigate, goBack}}) => {
  useEffect(() => {
    myLog.warn('Account')
  }, [null])

  const [pictureModalVisible, setPictureModalVisible] = useState(false)

  const {User} = dashboardStore
  const {MainUser} = persistedStore

  const isChildUser = MainUser?.MainUserGuid !== User?.MainUserGuid

  if (User === undefined) {
    return null
  }

  const renderCancelUser = () => {
    if (!isChildUser) {
      return null
    }

    return (
      <ListItem
        topDivider
        onPress={() => {
          navigate('cancelAccount')
        }}>
        <Icon name="trash" type="font-awesome" />
        <ListItem.Content>
          <ListItem.Title>{i18n.t('CancelAccount.Header')}</ListItem.Title>
        </ListItem.Content>
      </ListItem>
    )
  }

  const renderParentUserItems = () => {
    if (isChildUser) {
      return null
    }

    return (
      <>
        <ListItem onPress={() => navigate('changeEmail')}>
          <Icon name="edit" type="font-awesome" />
          <ListItem.Content>
            <ListItem.Title>{i18n.t('Account.ChangeEmail')}</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem onPress={() => navigate('changePassword')}>
          <Icon name="key" type="font-awesome" />
          <ListItem.Content>
            <ListItem.Title>{i18n.t('Account.ChangePassword')}</ListItem.Title>
          </ListItem.Content>
        </ListItem>
      </>
    )
  }

  return (
    <>
      <MyHeader onBackPress={goBack} title={i18n.t('Account.Header')} />
      <Container>
        <ListItem>
          <ProfileButton user={User} />
          <ListItem.Content>
            <ListItem.Title>{User.Name}</ListItem.Title>
            {User.EmailAddress && (
              <ListItem.Subtitle>{User.EmailAddress}</ListItem.Subtitle>
            )}
          </ListItem.Content>
        </ListItem>
        <ListItem topDivider onPress={() => navigate('changeName')}>
          <Icon name="edit" type="font-awesome" />
          <ListItem.Content>
            <ListItem.Title>{i18n.t('Account.ChangeName')}</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        {renderParentUserItems()}
        {/* <ListItem onPress={() => setPictureModalVisible(true)}>
          <Icon name="image" type="font-awesome" />
          <ListItem.Content>
            <ListItem.Title>
              {i18n.t('Account.SetProfilePicture')}
            </ListItem.Title>
          </ListItem.Content>
        </ListItem> */}

        {renderCancelUser()}
        <ChangePictureModal
          onClose={() => setPictureModalVisible(false)}
          visible={pictureModalVisible}
        />
      </Container>
    </>
  )
}
export default observer(Account)
