import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'
import {View, Text} from 'react-native'
import {SceneMap, TabBar, TabView} from 'react-native-tab-view'
import {
  Container,
  Content,
  ImageHeader,
  SubmitHeader,
} from '../../../Components'
import {GetFileUrl} from '../../../Helpers/UiHelpers'
import i18n from '../../../i18n'
import {MainScreensList} from '../../../Navigation'
import Style, {colors, tabViewLayout} from '../../../Style'
import PaymentTab from './Components/PaymentTab'
import LocationTab from './Components/LocationTab'
import {MyTab, StockImageLayout} from '../../../Components/Web'
import {Tab} from 'react-native-elements'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {store} from './Store'
import AppConfig from '../../../Config/AppConfig'
import {observer} from 'mobx-react'
import SendMoneyHeader from '../../Wallet/SendMoney/Components/SendMoneyHeader'

interface IProps extends StackScreenProps<MainScreensList, 'locationDetails'> {}

const LocationDetails: React.FC<IProps> = ({navigation: {goBack}, route}) => {
  const location = route.params.location

  const [index, setIndex] = React.useState(0)

  console.log(location)

  const renderTabViews = () => {
    if (index === 1) {
      return <LocationTab location={location} />
    }

    return <PaymentTab location={location} />
  }

  return (
    <StockImageLayout imageUrl={GetFileUrl(location.ImageFileGuid)}>
      <Container>
        <Content headerMargin padder={false}>
          <MyTab
            tabs={[
              i18n.t('LocationDetails.Tabs.Payment'),
              i18n.t('LocationDetails.Tabs.Location'),
            ]}
            onChange={(index) => {
              setIndex(index)
            }}
          />
          {renderTabViews()}
        </Content>
        <SubmitHeader onBackPress={goBack} title={location.Name} />
      </Container>
    </StockImageLayout>
  )
}

export default observer(LocationDetails)
