import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {Button, Icon, Text} from 'react-native-elements'
import {StackScreenProps} from '@react-navigation/stack'
import {
  FormHeader,
  Content,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  Container,
  ImageHeader,
  MyHeader,
  ToprScreenLoader,
} from '../../../../Components'
import i18n from '../../../../i18n'
import Style, {sizes, colors} from '../../../../Style'
import {myLog} from '../../../../Services/Client/LogService'
import {OrderOnlineList} from '..'
import {articleStore} from './Store'
import CondimentGroup from './Components/CondimentGroup'
import {StyleSheet, View} from 'react-native'
import {FormatToEuro} from '../../../../Helpers/TextHelpers'
import {orderOnlineStore} from '../Store'
import {GetOrderLineAmountFormatted} from '../../../../Helpers/OrderHelpers'

interface IProps extends StackScreenProps<OrderOnlineList, 'article'> {}

const Article: React.FC<IProps> = ({navigation: {goBack, navigate}, route}) => {
  const article = route.params.article
  useEffect(() => {
    myLog.warn('Article')
    articleStore.Initialize(article)
  }, [null])

  const {imageHeaderTitle, amountStyle, flex1} = Style
  const {CondimentGroups, OrderRequestLine, Isloading} = articleStore

  if (OrderRequestLine === undefined) {
    return null
  }

  const renderHeader = () => {
    if (article.imageUrl) {
      return (
        <ImageHeader
          imageSource={{
            uri: article.imageUrl,
          }}
          onBackPress={() => goBack()}>
          <Text style={imageHeaderTitle}>{article.name}</Text>
        </ImageHeader>
      )
    }
    return <MyHeader title={article.name} onBackPress={goBack} />
  }

  const renderSuplements = () => {
    return CondimentGroups.map((x, key) => (
      <CondimentGroup key={key} condimentGroup={x} />
    ))
  }

  const renderFooter = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: '#fff',
          paddingHorizontal: sizes.padding.md,
          paddingVertical: sizes.padding.sm,
        }}>
        <View style={flex1}>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              borderColor: colors.grey,
              borderWidth: 1,
              borderRadius: 3,
              justifyContent: 'space-between',
              paddingVertical: 8,
              paddingHorizontal: 8,
            }}>
            <Icon
              name="minus"
              type="antdesign"
              onPress={() => articleStore.Minus()}
              size={20}
            />
            <Text
              style={{
                width: 40,
                textAlign: 'center',
                fontSize: 16,
                fontWeight: 'bold',
                color: colors.main,
              }}>
              {OrderRequestLine.quantity}
            </Text>
            <Icon
              //reverse
              borderRadius={0}
              name="plus"
              type="antdesign"
              //color={colors.main}
              onPress={() => articleStore.Plus()}
              size={20}
            />
          </View>
        </View>
        <View style={{width: 10}} />
        <View style={flex1}>
          <MyButton
            //title={i18n.t('OrderOnline.Add')}
            title={GetOrderLineAmountFormatted(OrderRequestLine)}
            onPress={() => {
              orderOnlineStore.AddArticle(OrderRequestLine, CondimentGroups)
            }}
          />
        </View>
      </View>
    )
  }

  return (
    <>
      <ToprScreenLoader isLoading={Isloading}>
        <Container>
          {renderHeader()}
          <Content>{renderSuplements()}</Content>
        </Container>
        {renderFooter()}
      </ToprScreenLoader>
    </>
  )
}
export default observer(Article)
