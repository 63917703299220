import I18n from 'i18n-js'
import {observer} from 'mobx-react'
import React from 'react'
import {useForm} from 'react-hook-form'
import {Text, View} from 'react-native'
import {
  Content,
  EuroTextInput,
  FormInfo,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  MySelectList,
} from '../../../../Components'

import {FormatCentsToEuro} from '../../../../Helpers/TextHelpers'
import i18n from '../../../../i18n'
import {IUserSTO, IWalletSTO} from '../../../../Models/Server'
import Style, {colors} from '../../../../Style'
import {sendMoneyStore, WalletFormData} from '../Store'

interface IProps {
  wallet: IWalletSTO
}

const WalletTab: React.FC<IProps> = ({wallet}) => {
  const {flexRow, highlightText} = Style

  const {control, handleSubmit, errors, setError} = useForm<WalletFormData>()
  const {OtherUsers, SelectedOtherUser, IsSubmitting} = sendMoneyStore
  const onSubmit = (data: WalletFormData) => {
    //after confirm show the authenticate modal
    if (SelectedOtherUser === undefined) {
      setError('amount', {
        type: 'manual',
        message: i18n.t('SendMoney.Form.Fields.OtherWallet.Error'),
      })

      return
    }
    sendMoneyStore.ConfirmSubmit(data.amount)
  }

  const renderMaxSendMessage = () => {
    return (
      <FormInfo>
        {i18n.t('SendMoney.MaxBalance0')}
        <Text style={highlightText}>{FormatCentsToEuro(wallet.Balance)}</Text>
        {i18n.t('SendMoney.MaxBalance1')}
      </FormInfo>
    )
  }

  return (
    <>
      <Content>
        {renderMaxSendMessage()}
        <MySelectList
          label={i18n.t('SendMoney.Form.Fields.OtherWallet.Label')}
          options={OtherUsers}
          selectedOption={SelectedOtherUser}
          onSelect={(option: IUserSTO) =>
            sendMoneyStore.SetSelectedOtherUser(option)
          }
          isItemSelected={(a: IUserSTO, b: IUserSTO) => {
            return a.MainUserGuid === b.MainUserGuid
          }}
          renderListItem={(user: IUserSTO, isHighLighted: boolean) => {
            return (
              <View style={flexRow}>
                <Text style={[isHighLighted && {color: colors.main}]}>
                  {user.Name}
                </Text>
              </View>
            )
          }}></MySelectList>
        <EuroTextInput
          formObject={{} as WalletFormData}
          name="amount"
          label={i18n.t('SendMoney.Form.Fields.Amount.Label')}
          control={control}
          onSubmitEditing={() => {
            handleSubmit((data) => onSubmit(data))()
          }}
          validation={{
            errors,
            rules: {
              required: i18n.t('SendMoney.Form.Fields.Amount.Errors.Required'),
              min: {
                value: 1,
                message: i18n.t('SendMoney.Form.Fields.Amount.Errors.MinMax', {
                  min: FormatCentsToEuro(1),
                  max: FormatCentsToEuro(wallet.Balance),
                }),
              },
              max: {
                value: wallet.Balance,
                message: i18n.t('SendMoney.Form.Fields.Amount.Errors.MinMax', {
                  min: FormatCentsToEuro(100),
                  max: FormatCentsToEuro(wallet.Balance),
                }),
              },
            },
          }}
        />
        <MyInput
          formObject={{} as WalletFormData}
          name="description"
          label={i18n.t('SendMoney.Form.Fields.Description.Label')}
          control={control}
        />
        <MyFooter>
          <MyFooterTile isLast>
            <MyButton
              isLoading={IsSubmitting}
              title={i18n.t('SendMoney.Form.Action')}
              onPress={handleSubmit(onSubmit)}
            />
          </MyFooterTile>
        </MyFooter>
      </Content>
    </>
  )
}

export default observer(WalletTab)
