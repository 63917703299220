import nl from './locales/nl'
import en from './locales/en'
import * as Localization from 'expo-localization'
import 'intl' //intl polyfill
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/de'
import 'intl/locale-data/jsonp/nl'
import 'intl/locale-data/jsonp/fr'

import deDE from 'date-fns/locale/de'
import enGb from 'date-fns/locale/en-GB'
import nlNl from 'date-fns/locale/nl'

import i18n from 'i18n-js'
i18n.fallbacks = true
i18n.defaultLocale = 'en' //should be en when en lang is added

i18n.translations = {
  nl,
  en,
}

i18n.locale = Localization.locale
export const primaryLanguage: string = i18n.locale.substr(0, 2)

const GetCurrentDateLocale = () => {
  switch (primaryLanguage) {
    case 'nl':
      return nlNl
    // case 'de':
    //   return deDE
    case 'en':
    default:
      return enGb
  }
}

export const currentLocale = GetCurrentDateLocale()

export default i18n
