import {StackNavigationProp} from '@react-navigation/stack'
import React, {useEffect} from 'react'
import {Keyboard, Text} from 'react-native'
import {useForm} from 'react-hook-form'
import {
  Container,
  Content,
  FormHeader,
  FormInfo,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import AppConfig from '../../../Config/AppConfig'
import i18n from '../../../i18n'
import {store} from '../Store'

import {myLog} from '../../../Services/Client/LogService'
import {RegisterSteps} from '../Register'
import {RegisterFormData} from '../Store'
import {sizes} from '../../../Style'
import {goBack} from '../../../Helpers/NavigationHelper'
import {StockImageLayout} from '../../../Components/Web'
import {appUiStore} from '../../../Mobx/AppUiStore'

interface IProps {
  navigation: StackNavigationProp<RegisterSteps>
}

const NameStep: React.FC<IProps> = ({navigation: {navigate}}) => {
  const {control, handleSubmit, errors, setValue, formState} =
    useForm<RegisterFormData>({
      mode: 'onChange',
    })

  useEffect(() => {
    myLog.info('NameStep')
    store.Initialize()

    //navigate('termsPdf')

    if (AppConfig.IsDebugMode) {
      setValue('name', 'Rdr test')
    }
  }, [store])

  const onSubmit = (data: RegisterFormData) => {
    store.SetFormData('name', data.name)
    navigate('email')
  }

  return (
    <>
      <Container>
        <StockImageLayout>
          <Content headerMargin>
            <FormHeader>{i18n.t('Register.NameStep.Header')}</FormHeader>
            <FormInfo>{i18n.t('Register.NameStep.Info')}</FormInfo>
            <MyInput
              formObject={{} as RegisterFormData}
              name="name"
              autofocus={true}
              label={i18n.t('Register.NameStep.Field.Label')}
              control={control}
              inputProps={{
                onSubmitEditing: () => {
                  handleSubmit((data) => onSubmit(data))()
                },
              }}
              validation={{
                errors,
                rules: {
                  required: i18n.t('Register.NameStep.Field.Errors.Required'),
                },
              }}
            />
          </Content>
          <SubmitHeader title={i18n.t('Register.Header')} onBackPress={goBack}>
            <SubmitHeaderButton
              disabled={!formState.isValid}
              title={i18n.t('Common.Continue')}
              onPress={handleSubmit(onSubmit)}
            />
          </SubmitHeader>
        </StockImageLayout>
      </Container>
    </>
  )
}

export default NameStep
