import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {goBack} from '../../../Helpers/NavigationHelper'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {FormatCurrency} from '../../../Helpers/TextHelpers'
import {IsChildUserActive} from '../../../Helpers/UserHelper'
import i18n from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {MyErrorMessage, ToprError} from '../../../Models/Client'
import {INameIdSTO, IUserSTO, IWalletSTO} from '../../../Models/Server'
import TransactionController from '../../../Services/Topr.Core/TransactionController'
import UserController from '../../../Services/Topr.Core/UserController'
import WalletController from '../../../Services/Topr.Core/WalletController'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import {walletDetailsStore} from '../Details/Store'

export type WalletFormData = {
  amount: number // cents
  description: string
  search: string
}

export default class Store {
  public IsLoading = true
  public IsSubmitting = false
  public IsSearching = false
  public SelectedTabIndex = 0
  public Description = ''

  //child users tab
  public OtherUsers: IUserSTO[] = []
  public SelectedOtherUser?: IUserSTO = undefined
  public Wallet?: IWalletSTO = undefined

  public CurrencyGuid?: string = undefined

  public FoundUser?: INameIdSTO<string> = undefined
  constructor() {
    makeAutoObservable(this)
  }

  public Initialize(currencyGuid: string) {
    this.IsLoading = true
    this.SelectedOtherUser = undefined
    this.FoundUser = undefined
    this.SelectedTabIndex = 0
    this.Description = ''
    this.CurrencyGuid = currencyGuid
    this.Wallet = undefined
    this.GetWallet(currencyGuid)

    this.OtherUsers = []
    this.GetOtherUsers()
  }

  public GetWallet(currencyGuid: string) {
    this.IsLoading = true

    WalletController.GetWallet(currencyGuid)
      .then((wallet) => {
        runInAction(() => {
          this.IsLoading = false
          this.Wallet = wallet
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public SetSelectedTabIndex(index: number) {
    this.SelectedTabIndex = index
  }

  public SetDescription(description: string) {
    this.Description = description
  }

  private GetOtherUsers() {
    const {ChildUsers, MainUser} = persistedStore

    console.log(
      //ChildUsers.map((x) => x.Name),
      IsChildUserActive(),
    )

    if (ChildUsers.length > 0) {
      if (IsChildUserActive()) {
        const {User} = dashboardStore //active (child)user
        this.OtherUsers.push(MainUser as IUserSTO) //add parent
        this.OtherUsers.push(
          ...ChildUsers.filter((x) => x.MainUserGuid !== User?.MainUserGuid),
        )
      } else {
        this.OtherUsers.push(...ChildUsers)
      }

      this.SelectedOtherUser = this.OtherUsers[0]
    }
  }

  public ConfirmSubmit(amount: number) {
    const id =
      this.FoundUser != undefined
        ? this.FoundUser.Id
        : this.SelectedOtherUser?.MainUserGuid
    const name =
      this.FoundUser != undefined
        ? this.FoundUser.Name
        : this.SelectedOtherUser?.Name

    appUiStore.ShowAuthenticateConfirm({
      OnOk: () => sendMoneyStore.SendMoney(id!, amount, this.Description),
      Message: i18n.t('SendMoney.Form.AuthenticateAction', {
        amount: FormatCurrency(amount, this.Wallet?.Currency),
        name,
      }),
    })
  }

  public SendMoney(toUserGuid: string, amount: number, description: String) {
    this.IsSubmitting = true
    DoServiceCall(() => {
      this.IsSubmitting = true
      TransactionController.SendMoney(
        this.Wallet?.Currency.CurrencyGuid,
        amount,
        toUserGuid,
        description,
      )
        .then((response) => {
          runInAction(() => {
            this.IsSubmitting = false
            if (this.Wallet) {
              const name =
                this.FoundUser === undefined
                  ? this.SelectedOtherUser?.Name
                  : this.FoundUser.Name
              walletDetailsStore.Initialize(this.Wallet.Currency.CurrencyGuid)
              dashboardStore.GetWallets()

              appUiStore.ShowAlert({
                type: 'success',
                message: i18n.t('SendMoney.Form.Success.Message', {
                  amount: FormatCurrency(amount, this.Wallet?.Currency),
                  name,
                }),
                title: i18n.t('SendMoney.Form.Success.Title'),
                additionalCloseAction: () => goBack(),
              })
            }
          })
        })
        .catch((toprError: ToprError) => {
          SetFriendlyMessage(
            toprError,
            (errorMessage: string) => {
              //
              appUiStore.ShowAlert({
                type: 'error',
                message: errorMessage,
                title: i18n.t('SendMoney.Form.ServerErrors.Title'),
              })
            },
            [
              new MyErrorMessage(
                'CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED',
                'SendMoney.Form.ServerErrors.Title',
                'SendMoney.Form.ServerErrors.CURRENCY_PAYMENT_METHOD_NOT_SUPPORTED',
              ),
              new MyErrorMessage(
                'LOYALTYWALLET_SENDMONEY_SAME_USER',
                'SendMoney.Form.ServerErrors.Title',
                'SendMoney.Form.ServerErrors.LOYALTYWALLET_SENDMONEY_SAME_USER',
              ),
            ],
          )
          runInAction(() => {
            this.IsSubmitting = false
          })
        })
    })
  }

  public SearchUser(search: string, onError: (errorMessage: string) => void) {
    this.IsSubmitting = true
    DoServiceCall(() => {
      this.IsSubmitting = true
      UserController.Search(search)
        .then((user) => {
          runInAction(() => {
            this.IsSubmitting = false
            this.FoundUser = user
          })
        })
        .catch((toprError: ToprError) => {
          SetFriendlyMessage(toprError, onError, [
            new MyErrorMessage(
              'USER_NOT_FOUND',
              'SendMoney.Form.ServerErrors.Title',
              'SendMoney.Form.ServerErrors.USER_NOT_FOUND',
              //null,
              //'warning'
            ),
          ])
          runInAction(() => {
            this.IsSubmitting = false
          })
        })
    })
  }

  public SetSelectedOtherUser(user: IUserSTO) {
    this.SelectedOtherUser = user
  }

  public ClearFoundUser() {
    this.FoundUser = undefined
  }
}

export const sendMoneyStore = new Store()
