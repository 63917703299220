import React from 'react'
import {Animated, Image, ImageURISource, StyleSheet, View} from 'react-native'
import {Avatar, Button, Icon, Text} from 'react-native-elements'
import {GetImageSource} from '../../Helpers/UiHelpers'
import {IRestaurant} from '../../Models/InzziiApi'
import {orderOnlineStore} from '../../Screens/Location/OrderOnline/Store'
import Style, {sizes, colors, openSansLight} from '../../Style'
import AppImages from '../../Images/AppImages'
interface IProps {
  restaurant?: IRestaurant
  onBackPress: () => void
  isAbsolute?: boolean
}

const OrderHeader: React.FC<IProps> = ({
  onBackPress,
  isAbsolute,
  restaurant,
}) => {
  if (restaurant === undefined) {
    return null
  }

  const {imageHeaderBackdrop} = Style

  const {
    headerContainer,
    backgroundImage,
    titleStyle,
    logoContainer,
    subTitleStyle,
  } = style

  //https://mybackoffice.blob.core.windows.net/inzzii/images/logo/3472/ImbissKantine.png

  return (
    // <Animated.View
    //   style={[headerContainer, {transform: [{translateY: headerTranslateY}]}]}>

    <View
      style={[
        headerContainer,
        // isAbsolute && {position: 'absolute', top: 0, left: 0, right: 0},
      ]}>
      <Image
        source={GetImageSource(
          restaurant.backgroundUrl,
          AppImages.Background.OrderBg,
        )}
        resizeMode="cover"
        style={backgroundImage}
      />

      <View style={[imageHeaderBackdrop]}>
        <View
          style={{
            paddingTop: sizes.padding.md,
          }}>
          <Icon
            name="arrow-left"
            type="feather"
            color="#fff"
            containerStyle={{
              width: 50,
              height: 50,
            }}
            onPress={onBackPress}
          />
        </View>
      </View>

      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          flex: 1,
        }}>
        <Avatar
          size="xlarge"
          containerStyle={logoContainer}
          imageProps={{resizeMode: 'contain'}}
          source={GetImageSource(restaurant.logoUrl, AppImages.Logo.Short)}
        />
      </View>
      <View
        style={{
          backgroundColor: '#fff',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: sizes.padding.md,
          borderBottomColor: colors.lightGrey,
          borderBottomWidth: 1,
          flex: 1,
        }}>
        <View>
          <Text style={titleStyle}>{restaurant.name}</Text>
          <Text
            style={
              subTitleStyle
            }>{`${restaurant.streetAddress}, ${restaurant.city}`}</Text>
        </View>
      </View>
    </View>
  )
}

export default OrderHeader

OrderHeader.defaultProps = {
  isAbsolute: true,
}

const style = StyleSheet.create({
  backgroundImage: {
    //height: 170,
    flex: 2,
    width: 'auto',
  },
  logoContainer: {
    padding: 20,
    marginTop: -120,
    backgroundColor: '#fff',
    borderColor: colors.lightGrey,
    borderWidth: 1,
  },
  logoImage: {
    width: 80,
    height: 80,
  },
  titleStyle: {
    fontSize: 20,
    marginBottom: sizes.padding.sm,
    ...openSansLight,
  },
  subTitleStyle: {
    //fontSize: 20,
    marginBottom: sizes.padding.sm,
    ...openSansLight,
  },

  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#62d1bc',
    overflow: 'hidden',
    height: orderOnlineStore.HEADER_MAX_HEIGHT,
  },

  headerContainer: {
    backgroundColor: '#fff',

    height: orderOnlineStore.HEADER_MAX_HEIGHT,
  },
})
