import React from 'react'
import {TouchableOpacity, View, Image} from 'react-native'
import {Badge, Card, Icon, Text} from 'react-native-elements'

import {FormatCurrency, FormatDistance} from '../../Helpers/TextHelpers'
import {GetFileUrl, GetLocalizedImageUrl} from '../../Helpers/UiHelpers'
import {IGroupedCoupon, ILocationSTO, IWalletSTO} from '../../Models/Server'
import Style, {sizes} from '../../Style'
import {colors} from '../../Style/index'

interface IProps {
  location: ILocationSTO
  onPress: () => void
}
const LocationCard: React.FC<IProps> = ({location, onPress}) => {
  const {cardFooter, flexRow, flex1, cardFooterColumn} = Style

  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          marginTop: sizes.padding.md,
          marginHorizontal: sizes.padding.md,
          flexDirection: 'row',
          flex: 1,
          backgroundColor: '#fff',
          borderWidth: 1,
          borderColor: '#E5E5E5',
        }}>
        <Image
          source={{
            uri: GetFileUrl(location.ImageFileGuid),
          }}
          style={{width: 100, height: 100}}
        />
        <Card.Divider />
        <View style={[flexRow, {flex: 1}]}>
          <View style={[cardFooterColumn, flex1]}>
            <Text>{location.Name}</Text>
            <Text style={{color: '#cecece', fontSize: 14}}>
              {FormatDistance(location.Distance)}
            </Text>
          </View>
          <View style={[cardFooter]}>
            <Icon name="chevron-thin-right" type="entypo" />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default LocationCard
