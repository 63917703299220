export default {
  Background: {
    OrderBg: require('../../assets/rest-bg.jpg'),
    Waiter: require('../../assets/waiter1.jpg'),
  },
  Logo: {
    ToprCallOutBg: require('../Config/Tenants/Topr/Images/topr-callout-bg.png'),
    ToprWallet: require('../Config/Tenants/Topr/Images/topr-wallet-logo.jpg'),
    Square: require('../Config/Tenants/Topr/Images/logoSquare.png'),
    Short: require('../Config/Tenants/Topr/Images/logo.png'),
  },
  AppButtons:{
    AppStore :require('../../assets/appstore.png'),
    PlayStore :require('../../assets/playstore.png')
  }
}
