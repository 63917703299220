import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {DoServiceCall, Geti18NMessage} from '../../../../Helpers/ServiceHelpers'
import {myLog} from '../../../../Services/Client/LogService'
import i18n from '../../../../i18n'
import {
  IArticle,
  ICondiment,
  ICondimentGroup,
  IOrderRequestLine,
  OrderLineType,
} from '../../../../Models/InzziiApi'
import ArticleController from '../../../../Services/Inzii.Api/ArticleController'
import {orderOnlineStore} from '../Store'
import {ToprError} from '../../../../Models/Client'
import {appUiStore} from '../../../../Mobx/AppUiStore'
import {goBack} from '../../../../Helpers/NavigationHelper'
import {IMyOrderRequestLine} from '../../../../Models/InzziiApi'

export default class Store {
  public Isloading = true
  //public Amount = 1
  public OrderRequestLine?: IMyOrderRequestLine = undefined

  public CondimentGroups: ICondimentGroup[] = []

  //private Article?: IArticle = undefined

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize(article: IArticle) {
    this.Isloading = true
    //this.Article = article

    this.OrderRequestLine = {
      type: OrderLineType.Plu,
      quantity: 1,
      random: article.number,
      price: article.price,
      name: article.name,
    }

    ArticleController.GetCondimentGroups(
      orderOnlineStore.BranchofficeId,
      article.pluId,
    )
      .then((condimentGroups) => {
        runInAction(() => {
          this.Isloading = false
          this.CondimentGroups = condimentGroups
        })
      })
      .catch((toprError: ToprError) => {
        const errorMessage = Geti18NMessage(toprError)
        appUiStore.ShowToast({message: errorMessage})
        goBack()
      })
  }

  public Minus() {
    if (this.OrderRequestLine && this.OrderRequestLine.quantity > 1) {
      this.OrderRequestLine.quantity--
    }
  }

  public Plus() {
    if (this.OrderRequestLine) {
      this.OrderRequestLine.quantity++
    }
  }

  public IsValidMaxCondimentGroup(condimentGroup: ICondimentGroup) {
    if (this.OrderRequestLine === undefined) {
      return false
    }

    if (!condimentGroup.isMeetConditions) {
      //novalidate
      return true
    }

    //linkedOrderLine.parentNumber --> parent condimentGroup, zo dat je kan check of een max of min is bereikt voor deze condimentGroup
    if (this.OrderRequestLine.linkedOrderLines) {
      if (
        this.OrderRequestLine.linkedOrderLines.filter(
          (x) => x.parentNumber === condimentGroup.number,
        ).length < condimentGroup.maximumCondiments
      ) {
        return true
      } else {
        appUiStore.ShowToast({
          message: i18n.t('OrderOnline.MaxErrorMessage', {
            max: condimentGroup.maximumCondiments,
            type: condimentGroup.name,
          }),
        })
        return false
      }
    }

    return true
  }

  public IsCondimentRadioSelected(condiment: ICondiment) {
    if (this.OrderRequestLine && this.OrderRequestLine.linkedOrderLines) {
      const foundOrderLine = this.OrderRequestLine.linkedOrderLines.find(
        (x) => x.random === condiment.number,
      )

      return foundOrderLine !== undefined
    }

    return false
  }

  public ToggleCondimentRadio(
    condiment: ICondiment,
    condimentGroup: ICondimentGroup,
  ) {
    if (this.OrderRequestLine) {
      const type: OrderLineType = condiment.isSupplement
        ? OrderLineType.ModifierWithPrice
        : OrderLineType.Modifier
      const linkedOrderLine: IMyOrderRequestLine = {
        type,
        quantity: 1,
        random: condiment.number,
        price: condiment.price,
        name: condiment.name,
        parentNumber: condimentGroup.number,
      }

      if (this.OrderRequestLine.linkedOrderLines === undefined) {
        this.OrderRequestLine.linkedOrderLines = []
      }

      const index = this.OrderRequestLine.linkedOrderLines.findIndex(
        (x) => x.parentNumber == condimentGroup.number,
      )

      if (index > -1) {
        this.OrderRequestLine.linkedOrderLines.splice(index, 1, linkedOrderLine)
      } else {
        //first select, add
        this.OrderRequestLine.linkedOrderLines.push(linkedOrderLine)
      }
    }
  }

  //checkbox check
  public AddCondimentChecked(
    condiment: ICondiment,
    condimentGroup: ICondimentGroup,
  ) {
    if (this.OrderRequestLine) {
      const type: OrderLineType = condiment.isSupplement
        ? OrderLineType.ModifierWithPrice
        : OrderLineType.Modifier
      const linkedOrderLine: IMyOrderRequestLine = {
        type,
        quantity: 1,
        random: condiment.number,
        price: condiment.price,
        name: condiment.name,
        parentNumber: condimentGroup.number,
      }

      //console.log('addcondiment', linkedOrderLine)

      if (this.OrderRequestLine.linkedOrderLines === undefined) {
        this.OrderRequestLine.linkedOrderLines = []
      }

      this.OrderRequestLine.linkedOrderLines.push(linkedOrderLine)
    }
  }

  public RemoveCondimentChecked(condiment: ICondiment) {
    if (
      this.OrderRequestLine &&
      this.OrderRequestLine.linkedOrderLines !== undefined
    ) {
      const index = this.OrderRequestLine.linkedOrderLines.findIndex(
        (x) => x.random === condiment.number,
      )
      if (index > -1) {
        this.OrderRequestLine.linkedOrderLines.splice(index, 1)
        //console.log('remove ', this.OrderRequestLine.linkedOrderLines.length)
      }
    }
  }
}
export const articleStore = new Store()
