import React from 'react'
import {Image, View} from 'react-native'
import Style from '../../Style'
import Dot from './Dot'

interface IProps {
  isLoading: boolean
  bordered?: boolean
}
const MyLoader: React.FC<IProps> = ({isLoading, bordered}) => {
  if (!isLoading) {
    return null
  }

  const {myLoader} = Style

  const renderDots = () => {
    const dots: React.ReactNode[] = []
    const count = 3
    const duration = 400

    for (let index = 0; index < count; index++) {
      //start when prev dot is finished fadein
      const delayStart = index * duration
      dots.push(
        <Dot
          key={index}
          duration={duration}
          delayFadeOut={1000}
          delayStart={delayStart}
          size="small"
          bordered={bordered}
        />,
      )
    }

    return dots
  }

  return <View style={myLoader}>{renderDots()}</View>
}

export default MyLoader

MyLoader.defaultProps = {
  isLoading: false,
  bordered: false,
}
