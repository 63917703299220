import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Icon, Text} from 'react-native-elements'
import {GetOrderLineAmountFormatted} from '../../../../../Helpers/OrderHelpers'
import i18n from '../../../../../i18n'
import {IMyOrderRequestLine} from '../../../../../Models/InzziiApi'
import Style, {colors, sizes} from '../../../../../Style'
import IconButton from './IconButton'
interface IProps {
  totalAmountFormatted: string
}

const TotalRow: React.FC<IProps> = ({totalAmountFormatted}) => {
  const {container, totalText, amountText} = styles

  return (
    <View style={container}>
      <Text style={totalText}>{i18n.t('Common.Total')}</Text>
      <Text style={amountText}>{totalAmountFormatted}</Text>
    </View>
  )
}

export default TotalRow

const styles = StyleSheet.create({
  container: {
    paddingTop: sizes.padding.md,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: sizes.padding.sm,
    marginBottom: sizes.padding.md,
  },
  totalText: {
    fontWeight: 'bold',
  },
  amountText: {
    width: 70,
    textAlign: 'right',
    fontWeight: 'bold',
  },
})
