import {isAfter} from 'date-fns'
import React from 'react'
import {useForm} from 'react-hook-form'
import {StyleSheet} from 'react-native'
import {Card} from 'react-native-elements'
import {MyButton, MyFooter, MyFooterTile, MyModal} from '../../../../Components'
import MyDatePicker from '../../../../Components/Form/MyDatePicker'
import {sizes} from '../../../../Style'
import i18n from '../../../../i18n'

export interface IProps {
  onClose: () => void
  onOk: (fromDate: Date, tillDate: Date) => void
  isVisible: boolean
}

interface IFormValues {
  fromDate: Date | null
  tillDate: Date
}

const ReportModal: React.FC<IProps> = ({onClose, isVisible, onOk}) => {
  const today = new Date()

  const {control, handleSubmit, errors, getValues, setValue, watch} =
    useForm<IFormValues>({
      defaultValues: {
        //fromDate: null,
        //tillDate: new Date(new Date().toDateString()), //date with no time
        fromDate: new Date(today.getFullYear(), today.getMonth(), 1), //1 1 oct
        tillDate: new Date(today.toDateString()), //date with no time
      },
    })

  const renderContent = () => {
    const fromDate = watch('fromDate')
    const tillDate = watch('tillDate')

    return (
      <>
        <div className="columns">
          <div className="column">
            <MyDatePicker
              formObject={{} as IFormValues}
              name="fromDate"
              selectsStart
              startDate={fromDate}
              endDate={tillDate}
              label={i18n.t('WalletDetails.ReportModal.Fields.FromDate.Label')}
              control={control}
              validation={{
                errors,
                rules: {
                  required: i18n.t(
                    'WalletDetails.ReportModal.Fields.FromDate.Errors.Required',
                  ),
                },
              }}
            />
          </div>
          <div className="column">
            <MyDatePicker
              formObject={{} as IFormValues}
              selectsEnd
              startDate={fromDate}
              endDate={tillDate}
              name="tillDate"
              label={i18n.t('WalletDetails.ReportModal.Fields.TillDate.Label')}
              control={control}
              validation={{
                errors,
                rules: {
                  required: i18n.t(
                    'WalletDetails.ReportModal.Fields.TillDate.Errors.Required',
                  ),
                  validate: {
                    checkPincode: (tillDate: Date) => {
                      const values = getValues()
                      return (
                        isAfter(tillDate, values.fromDate as Date) ||
                        i18n.t(
                          'WalletDetails.ReportModal.Fields.TillDate.Errors.Equality',
                        )
                      )
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </>
    )
  }

  const onSubmit = (data: IFormValues) => {
    onOk(data.fromDate as Date, data.tillDate)
    onClose()
  }

  return (
    <MyModal onClose={onClose} visible={isVisible} position="bottom">
      <Card.Title style={{paddingHorizontal: sizes.padding.md}}>
        {i18n.t('WalletDetails.ReportModal.Header')}
      </Card.Title>
      {renderContent()}

      <MyFooter>
        <MyFooterTile>
          <MyButton
            type="outline"
            onPress={() => {
              onClose()
            }}
            style={{marginRight: sizes.padding.sm}}
            title={i18n.t('Common.Cancel')}
          />
        </MyFooterTile>
        <MyFooterTile isLast>
          <MyButton
            onPress={() => {
              handleSubmit((data) => onSubmit(data))()
            }}
            title={i18n.t('Common.Ok')}
          />
        </MyFooterTile>
      </MyFooter>
    </MyModal>
  )
}

export default ReportModal

const styles = StyleSheet.create({})
