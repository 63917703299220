import {AppLinkTypes} from '../Enums'
export class AppLink {
  public Url: string
  public Parameters: string[] = []
  public DeepLink: AppLinkTypes

  constructor(url: string, deepLink: AppLinkTypes) {
    this.Url = url
    this.DeepLink = deepLink
  }
}
