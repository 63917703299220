import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {DoServiceCall, Geti18NMessage} from '../../../../Helpers/ServiceHelpers'
import {myLog} from '../../../../Services/Client/LogService'
import i18n from '../../../../i18n'
import RestaurantController from '../../../../Services/Inzii.Api/RestaurantController'
import {orderOnlineStore} from '../Store'
//import {DeliveryType, PaymentGateway} from '../../../../Models/InzziiApi/Enums'
import {
  IIPaymentMethod,
  IOrderResult,
  PaymentGateway,
} from '../../../../Models/InzziiApi'
import {ToprError} from '../../../../Models/Client'
import {appUiStore} from '../../../../Mobx/AppUiStore'
import {goBack, navigate} from '../../../../Helpers/NavigationHelper'
import {DeliveryType} from '../../../../Models/InzziiApi/Enums'
import {NotImplemented} from '../../../../Helpers/UiHelpers'
import {StackNavigationProp} from '@react-navigation/stack'
import {OrderOnlineList} from '..'
import {persistedStore} from '../../../../Mobx'
import {placeholder} from 'i18n-js'

// const paymentMethods: IIPaymentMethod[] = [
//   {
//     gateway: 0,
//     description: i18n.t(`OrderOnline.PaymentMethods.PayOnDelivery`),
//     image: 'https://www.inzzii.com/Images/cash.png',
//     systemName: 'PayOnDelivery',
//   },
//   {
//     gateway: 1, //pending table order, set paymentcomplete later when payment is confirmed
//     description: i18n.t(`OrderOnline.PaymentMethods.ToprWallet`),
//     image:
//       'https://www.toprwallet.nl/Content/tenants/topr/images/logo-topr-sq.jpg',
//     systemName: 'ToprWallet',
//   },
// ]

const toprPaymentMethod: IIPaymentMethod = {
  gateway: 1, //pending table order, set paymentcomplete later when payment is confirmed
  description: i18n.t(`OrderOnline.PaymentMethods.ToprWallet`),
  image:
    'https://www.toprwallet.nl/Content/tenants/topr/images/logo-topr-sq.jpg',
  systemName: 'ToprWallet',
}

export default class Store {
  public IsLoading = false
  public IsSubmitting = false

  //public PaymentMethods: IIPaymentMethod[] = paymentMethods
  //public SelectedPaymentMethod?: IIPaymentMethod = paymentMethods[0]

  public PaymentMethods: IIPaymentMethod[] = []
  public SelectedPaymentMethod?: IIPaymentMethod = undefined
  private Navigation?: StackNavigationProp<OrderOnlineList> = undefined

  constructor() {
    makeAutoObservable(this)
  }

  private InitPaymentMethods(paymentMethods: IIPaymentMethod[]) {
    //i18n.t(`OrderOnline.PaymentMethods.PayOnDelivery`)
    //localize
    paymentMethods.forEach((x) => {
      if (x.gateway === 0) {
        x.description = i18n.t(`OrderOnline.PaymentMethods.PayOnDelivery`)
        x.systemName = 'PayOnDelivery'
      }
    })

    paymentMethods.push(toprPaymentMethod)

    return paymentMethods
  }

  public Initialize(navigation: StackNavigationProp<OrderOnlineList>) {
    this.IsLoading = true
    this.IsSubmitting = false
    this.Navigation = navigation
    RestaurantController.GetPaymentMethods(
      orderOnlineStore.BranchofficeId,
      DeliveryType.Table,
    )
      .then((paymentMethods) => {
        runInAction(() => {
          this.IsLoading = false
          this.PaymentMethods = this.InitPaymentMethods(paymentMethods)

          if (paymentMethods.length > 0) {
            this.SelectedPaymentMethod = paymentMethods[0]
          }

          //if only 1 PaymentMethod, go straight to the next step
          if (this.PaymentMethods.length === 1) {
            this.HandleCheckOut('')
          }
        })
      })
      .catch((toprError: ToprError) => {
        const errorMessage = Geti18NMessage(toprError)
        appUiStore.ShowToast({message: errorMessage, type: 'warning'})
        goBack()
      })
  }

  SetSelectedPaymentMethod(selectedPaymentMethod: IIPaymentMethod) {
    this.SelectedPaymentMethod = selectedPaymentMethod
  }

  public HandleCheckOut(remarks: string) {
    if (this.SelectedPaymentMethod && orderOnlineStore.User) {
      this.IsSubmitting = true
      switch (orderOnlineStore.DeliveryType) {
        case DeliveryType.Table:
          this.TableOrder(remarks)
          break
        case DeliveryType.Pickup:
          NotImplemented()
          break
        case DeliveryType.Deliver:
          NotImplemented()
          break
        default:
          appUiStore.ShowAlert({
            message: i18n.t(
              'OrderOnline.CheckoutForm.ServerErrors.INVALID_DELIVERY_TYPE',
              {deliveryType: orderOnlineStore.DeliveryType},
            ),
            type: 'error',
            title: i18n.t('OrderOnline.CheckoutForm.ServerErrors.Title'),
          })
          break
      }
    }
  }

  private PostTableOrderCreated(gateway: number, orderResult: IOrderResult) {
    //console.log('gateway', gateway, orderResult)

    switch (gateway) {
      case PaymentGateway.PayOnDelivery:
        if (orderOnlineStore.Restaurant) {
          const newOrder = persistedStore.AddPlacedOrder(
            orderResult.orderId,
            orderResult.orderNumber.toString(),
            orderOnlineStore.Restaurant,
            orderOnlineStore.OrderRequestLines,
            orderResult.totalAmount,
            orderOnlineStore.TableNumber,
          )

          //Payment by table, show message
          appUiStore.ShowAlert({
            message: i18n.t('OrderOnline.CheckoutForm.Success.Message', {
              orderNumber: orderResult.orderNumber,
            }),
            type: 'success',
            title: i18n.t('OrderOnline.CheckoutForm.Success.Title'),
            additionalCloseAction: () => {
              navigate('dashboard')
              navigate('placedOrderDetails', {placedOrder: newOrder})
            },
          })
        }
        break
      case PaymentGateway.PendingPayment:
        this.Navigation?.navigate('orderPayment', {orderResult})
        break
      default:
        appUiStore.ShowAlert({
          message: i18n.t(
            'OrderOnline.CheckoutForm.ServerErrors.INVALID_GATEWAY',
            {gateway},
          ),
          type: 'error',
          title: i18n.t('OrderOnline.CheckoutForm.ServerErrors.Title'),
        })

        navigate('dashboard')

        this.IsSubmitting = false
        break
    }
  }

  private TableOrder(remarks: string) {
    if (this.SelectedPaymentMethod && orderOnlineStore.User) {
      this.IsSubmitting = true
      RestaurantController.TableOrder(
        orderOnlineStore.User.accessToken,
        orderOnlineStore.BranchofficeId,
        orderOnlineStore.TableNumber,
        orderOnlineStore.OrderRequestLines,
        this.SelectedPaymentMethod.gateway,
        remarks,
      )
        .then((orderResult) => {
          if (this.SelectedPaymentMethod) {
            this.PostTableOrderCreated(
              this.SelectedPaymentMethod.gateway,
              orderResult,
            )
          }
        })
        .catch((toprError: ToprError) => {
          const errorMessage = Geti18NMessage(toprError)

          //GetI18NAlertMessage
          appUiStore.ShowAlert({
            message: errorMessage,
            type: 'error',
            title: i18n.t('OrderOnline.CheckoutForm.ServerErrors.Title'),
          })

          navigate('dashboard')
        })
        .finally(() => {
          runInAction(() => {
            this.IsSubmitting = false
          })
        })
    }
  }
}
export const checkOutStore = new Store()
