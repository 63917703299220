import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {ErrorCodes} from '../../Models/Client'
import {
  IAutomaticChargeSTO,
  ICreateAutomaticChargeRequest,
  IDebtorBank,
  IEditAutomaticChargeStepsRequest,
} from '../../Models/Server'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  Latest: (currencyGuid: string) => {
    return new Promise<IAutomaticChargeSTO | null>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/automaticcharge/${currencyGuid}/latest`)
        .then((response: AxiosResponse<IAutomaticChargeSTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.Code &&
            error.response.data.Code === ErrorCodes.ENTITY_NOT_FOUND_EXCEPTION
          ) {
            resolve(null)
            return
          }

          HandleAxiosCatch(error, reject)
        })
    })
  },
  Insert: (request: ICreateAutomaticChargeRequest) => {
    return new Promise<string>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/automaticcharge/${request.CurrencyGuid}/insert`, request)
        .then((response: AxiosResponse<{RedirectUrl: string}>) => {
          console.log(response.data)
          resolve(response.data.RedirectUrl)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  Edit: (request: ICreateAutomaticChargeRequest) => {
    return new Promise<string>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/automaticcharge/${request.CurrencyGuid}/edit`, request)
        .then((response: AxiosResponse<{RedirectUrl: string}>) => {
          resolve(response.data.RedirectUrl)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  EditSteps: (
    currencyGuid: string,
    request: IEditAutomaticChargeStepsRequest,
  ) => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/automaticcharge/${currencyGuid}/edit/steps`, request)
        .then((response: AxiosResponse) => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  TurnOff: (currencyGuid: string) => {
    return new Promise<string>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/automaticcharge/${currencyGuid}/turnoff`, null)
        .then((response: AxiosResponse<string>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetBanks: (currencyGuid: string) => {
    return new Promise<IDebtorBank[]>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/automaticcharge/${currencyGuid}/banks`)
        .then((response: AxiosResponse<IDebtorBank[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
