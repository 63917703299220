import {makeAutoObservable, runInAction} from 'mobx'
import {persistedStore} from '../../../Mobx'
import {ToprError} from '../../../Models/Client'
import AuthenticationController from '../../../Services/Topr.Core/AuthenticationController'
import UserController from '../../../Services/Topr.Core/UserController'

export default class Store {
  constructor() {
    makeAutoObservable(this)
  }

  public AutomaticLogin(
    tempAdminGuid: string,
    navigate: (screen: string) => void,
  ) {
    AuthenticationController.AutomaticLogin(tempAdminGuid)
      .then(() => {
        UserController.GetChildUsers()
        UserController.GetConsumerUser().then((mainUser) => {
          persistedStore.SetMainUser(mainUser, false)
        })

        //reset form
        navigate('dashboard')
      })
      .catch((toprError: ToprError) => {
        //alert(toprError.Code)
      })
  }
}
export const automaticLoginStore = new Store()
