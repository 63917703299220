import React from 'react'
import ReactMarkdown from 'react-markdown'
import { UmbracoMedia } from '../../../Helpers/UrlHelpers'
import { IImageTextViewModel } from '../../../Services/Topr.Umbraco/Models'
interface IProps {
  data: IImageTextViewModel
}

const ImageText: React.FC<IProps> = ({data}) => {
  const renderContent = () => {
    const reactElements: React.ReactNode[] = []

    reactElements.push(
      <div className="column" key="image">
        <img src={UmbracoMedia(data.ImageUrl)} />
      </div>,
    )
    reactElements.push(
      <div className="column" key="text" dangerouslySetInnerHTML={{__html:data.Text}} />        
    )

    if (data.Right) {
      reactElements.reverse()
    }

    return reactElements
  }
  return (
    <div className="section image-text markdown">
      <div className="columns">{renderContent()}</div>
    </div>
  )
}

export default ImageText
