import {makeAutoObservable, runInAction} from 'mobx'
import {
  DoServiceCall,
  Geti18NMessage,
  SetFriendlyMessage,
} from '../../../../../Helpers/ServiceHelpers'
import {IWalletSTO} from '../../../../../Models/Server'
import {myLog} from '../../../../../Services/Client/LogService'
import I18n from '../../../../../i18n'
import {
  IAlertStatusMessage,
  MyErrorMessage,
  ToprError,
} from '../../../../../Models/Client'
import TokenController from '../../../../../Services/Topr.Core/TokenController'
import i18n from '../../../../../i18n'
import {appUiStore} from '../../../../../Mobx/AppUiStore'
import {store as tokenStore} from '../../../Tokens/Store'
import {goBack} from '../../../../../Helpers/NavigationHelper'
import {MyTagEvent} from 'react-native-nfc-manager'
import {dashboardStore} from '../../../../Dashboard/Dashboard/Store'

export default class Store {
  public IsSubmitting = false
  public AlertStatusMessage?: IAlertStatusMessage = undefined

  public ShowAlertMessage(alertStatusMessage?: IAlertStatusMessage) {
    this.AlertStatusMessage = alertStatusMessage
  }

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize() {
    this.IsSubmitting = false
    this.AlertStatusMessage = undefined
  }

  public ActivateWithUid(tag: MyTagEvent | null) {
    if (tag !== null && tag.id !== undefined) {
      DoServiceCall(() => {
        this.IsSubmitting = true

        TokenController.ActivateUid(tag.id as string)
          .then(() => {
            appUiStore.ShowToast({message: i18n.t('AddTokenUid.Form.Succes')})
            tokenStore.GetTokens()
            dashboardStore.GetWallets()
            goBack()
            runInAction(() => {
              this.IsSubmitting = false
              //this.AlertStatusMessage = undefined
            })
          })
          .catch((toprError: ToprError) => {
            const i18NErrorMessage = Geti18NMessage(toprError, [
              new MyErrorMessage(
                'TOKEN_NOT_FOUND',
                'AddTokenUid.Form.ServerErrors.Title',
                'AddTokenUid.Form.ServerErrors.TOKEN_NOT_FOUND',
                'warning',
              ),
              new MyErrorMessage(
                'TOKEN_ALREADY_ACTIVATED',
                'AddTokenUid.Form.ServerErrors.Title',
                'AddTokenUid.Form.ServerErrors.TOKEN_ALREADY_ACTIVATED',
                'warning',
              ),
            ])
            runInAction(() => {
              this.IsSubmitting = false
              this.AlertStatusMessage = {
                type: 'error',
                message: i18NErrorMessage,
              }
            })
          })
      })
    }
  }
}
export const addTokenUidstore = new Store()
