import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {Text} from 'react-native'
import {Container, Content} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import {GetWalletPagetitle} from '../../../Helpers/TextHelpers'
import i18n from '../../../i18n'
import {persistedStore} from '../../../Mobx/PersistedStore'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import SearchTab from './Components/SearchTab'
import SendMoneyHeader from './Components/SendMoneyHeader'
import SendMoneyTabs from './Components/SendMoneyTabs'
import {sendMoneyStore} from './Store'

interface IProps extends StackScreenProps<MainScreensList, 'sendMoney'> {}

const SendMoney: React.FC<IProps> = ({navigation: {setOptions}, route}) => {
  const currencyGuid = route.params.currencyGuid
  useEffect(() => {
    myLog.warn('SendMoney')
    sendMoneyStore.Initialize(currencyGuid)
  }, [null])

  const {ChildUsers} = persistedStore
  const {Wallet} = sendMoneyStore

  useEffect(() => {
    if (Wallet) {
      setOptions({
        title: GetWalletPagetitle(
          i18n.t('SendMoney.Form.Action'),
          Wallet.Currency.Name,
        ),
      })
    }
  }, [Wallet])

  const renderTabsOrOnlySearch = () => {
    if (Wallet === undefined) {
      return null
    }

    if (ChildUsers.length === 0) {
      return (
        <>
          <Content headerMargin>
            <SearchTab wallet={Wallet} />
          </Content>
          <SendMoneyHeader currencyName={Wallet.Currency.Name} />
        </>
      )
    }

    return <SendMoneyTabs wallet={Wallet} />
  }

  return (
    <>
      <StockImageLayout>{renderTabsOrOnlySearch()}</StockImageLayout>
    </>
  )
}
export default observer(SendMoney)
