import React, {useEffect, useState} from 'react'
import { UmbracoMedia } from '../../../Helpers/UrlHelpers'

import { IMenuItemViewModel, IPageViewModel } from '../../../Services/Topr.Umbraco/Models'

interface IProps {
  page: IPageViewModel
  //mobileMenuActive: boolean
}

const TopMenuView: React.FC<IProps> = ({page}) => {
  const [showShadow, setshowShadow] = useState(false)
  const [mobileMenuActive, setMobileMenuActive] = useState(false)
  const onScroll = () => {
    const scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop
    const offSet = 30

    setshowShadow(scrollTop > offSet)
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  })

  //const {Page, MobileMenuActive} = homeStore
  const cssIsActive = mobileMenuActive ? 'is-active' : ''
  if (page === undefined || page.HomeMenu === undefined) {
    return null
  }

  const renderMenuItems = (menuItems: IMenuItemViewModel[]) => {
    if (menuItems === undefined || menuItems.length === 0) {
      return null
    }

    return menuItems.map((menuItem, key) => {
      if (menuItem.Type === 'Button') {
        return (
          <div className="navbar-item" key={key}>
            <div className="buttons">
              <a className="button download" href={menuItem.Link}>
                <span className="icon">
                  <i className="fas fa-download" aria-hidden="true" />
                </span>
                <span>{menuItem.Name}</span>
              </a>
            </div>
          </div>
        )
      }

      return (
        <a key={key} className="navbar-item" href={menuItem.Link}>
          {menuItem.Name}
        </a>
      )
    })
  }

  const shadowCss = showShadow ? 'shadow' : ''
  return (
    <div className={`fixed ${shadowCss}`}>
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/home">              
              <img src={UmbracoMedia(page.TenantLogo)} /> 
            </a>
            <a
              role="button"
              className="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              onClick={() => setMobileMenuActive(!mobileMenuActive)}>
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div className={`navbar-menu ${cssIsActive}`}>
            <div className="navbar-start">
              {renderMenuItems(page.HomeMenu.MenuItems.filter(x=> !x.Right))}
              {/* <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">Functies</a>
                <div className="navbar-dropdown">
                  <a className="navbar-item">Betalen</a>
                  <a className="navbar-item">Automatisch opwaarderen</a>
                  <a className="navbar-item">Account beheer</a>
                  <a className="navbar-item">Geld sturen</a>                  
                  <a className="navbar-item">Bestel online</a>
                </div>
              </div>*/}
            </div>
            <div className="navbar-end">
              <hr />
              {renderMenuItems(page.HomeMenu.MenuItems.filter(x=> x.Right))}
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default TopMenuView
