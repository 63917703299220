import React from 'react'
import {HelpItem} from '../../../Models/Strapi/Models'
import { IHelpItemViewModel } from '../../../Services/Topr.Umbraco/Models/IHelpItemViewModel'
import HelpItemView from './HelpItemView'
interface IProps {
  helpItems: IHelpItemViewModel[]
}

const HelpItemsView: React.FC<IProps> = ({helpItems}) => {
  //const [showShadow, setshowShadow] = useState(false)

  if (helpItems === undefined || helpItems.length === 0) {
    return null
  }

  return (
    <>
      {helpItems.map((helpItem, key) => (
        <HelpItemView helpItem={helpItem} key={key} />
      ))}
    </>
  )
}

export default HelpItemsView
