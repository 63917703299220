import {intervalToDuration} from 'date-fns'
import format from 'date-fns/format'
import {currentLocale} from '../i18n'

export const GetExpirationDateInMs = (expiresIn: string) => {
  const expiryDateInMs = new Date(expiresIn).valueOf()

  return expiryDateInMs
}

export const FormatDate = (inputDate: number | string | Date) => {
  return format(new Date(inputDate), 'dd-MM-yyyy')
}

//inzzii date miss the 'Z' in the isoString for orderHistory.orderDate
export const FormatInzziiDateString = (inputDate: string) => {
  return format(new Date(inputDate + 'Z'), 'PP HH:mm', {
    locale: currentLocale,
  })
}

export const FormatDateTime = (inputDate: number | string | Date) => {
  return format(new Date(inputDate), 'PP HH:mm', {
    locale: currentLocale,
  })
}

export const FormatSeconds = (seconds: number) => {
  const duration = intervalToDuration({start: 0, end: seconds * 1000})

  const durationSecondsText =
    duration.seconds !== undefined && duration.seconds < 10
      ? `0${duration.seconds}`
      : `${duration.seconds}`
  return `${duration.hours}:${duration.minutes}:${durationSecondsText}`
}

export const DayStage = () => {
  /*
  - Day 10:00 - 1900
- Sunset 19:00 - 22:00  en 06:00 - 10:00
- Night 22:00 - 06:00
  */

  const hours = parseInt(format(new Date(), 'HHmm'))
  //const hours = 1859
  let dayStage = 'Sunset'
  if (hours >= 1000 && hours < 1900) {
    dayStage = 'Day'
  }

  if (hours >= 2200 || hours < 600) {
    dayStage = 'Night'
  }

  return dayStage
}

//https://date-fns.org/v2.2.1/docs/parse
