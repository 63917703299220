import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {OrderOnlineList} from '..'
import {
  Container,
  Content,
  FullFooterButton,
  MyHeader,
} from '../../../../Components'
import i18n from '../../../../i18n'
import {myLog} from '../../../../Services/Client/LogService'
import {orderOnlineStore} from '../Store'
import CartRow from './Components/CartRow'
import TotalRow from './Components/TotalRow'

interface IProps extends StackScreenProps<OrderOnlineList, 'cart'> {}

const Cart: React.FC<IProps> = ({navigation, route}) => {
  useEffect(() => {
    myLog.warn('Cart')
  }, [null])

  const {OrderRequestLines, TotalAmountOrderFormatted} = orderOnlineStore

  return (
    <>
      <Container>
        <MyHeader
          title={i18n.t('OrderOnline.Cart')}
          onBackPress={() => navigation.navigate('articles')}
        />
        <Content>
          {OrderRequestLines.map((orderRequestLine, key) => (
            <CartRow
              key={key}
              plus={() => orderOnlineStore.PlusArticle(orderRequestLine)}
              minus={() => orderOnlineStore.MinusArticle(orderRequestLine)}
              orderRequestLine={orderRequestLine}
            />
          ))}
          <TotalRow totalAmountFormatted={TotalAmountOrderFormatted} />
        </Content>
      </Container>
      <FullFooterButton
        onPress={() => navigation.navigate('orderPayment')}
        title={`${i18n.t('OrderOnline.Pay')} (${TotalAmountOrderFormatted})`}
      />
    </>
  )
}
export default observer(Cart)
