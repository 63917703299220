import {ICurrencySTO} from '../Models/Server/Currency/ICurrencySTO'
import accounting from 'accounting'
import i18n, {primaryLanguage} from '../i18n'
import AppConfig from '../Config/AppConfig'
import {ILocalizedStringSto} from '../Models/Server/LocalizedString/ILocalizedStringSto'
import {IUserSTO} from '../Models/Server'

accounting.settings = {
  currency: {
    symbol: '€', // default currency symbol is '$'
    format: '%s%v', // controls output: %s = symbol, %v = value/number (can be object: see below)
    decimal: ',', // decimal point separator
    thousand: '.', // thousands separator
    precision: 2, // decimal places,
  },
  number: {
    precision: 0, // default precision on numbers is 0
    thousand: ',',
    decimal: '.',
  },
}

export const FormatCurrencyTransactionItems = (
  amount: number | undefined,
  currency: ICurrencySTO | undefined,
) => {
  if (amount === undefined || currency === undefined) {
    return undefined
  }

  let amountText = amount.toString()

  if (currency.IsDecimal) {
    if (currency.Code === 'HBC') {
      amount = amount / 100 //(no 300 for items)
      return amount
    }
    amount = amount / 100
    amountText = amount.toFixed(2).replace('.', ',')
  }

  const prefix = currency.StringFormat.split('{')[0]
  return `${prefix}${amountText}`
}

//parse c# string format
export const FormatCurrency = (
  amount: number | undefined,
  currency: ICurrencySTO | undefined,
) => {
  if (amount === undefined || currency === undefined) {
    return undefined
  }

  let amountText = amount.toString()

  if (currency.IsDecimal) {
    if (currency.Code === 'HBC') {
      amount = Math.round(amount / 300)
      return amount
    }
    amount = amount / 100
    amountText = amount.toFixed(2).replace('.', ',')
  }

  const prefix = currency.StringFormat.split('{')[0]
  return `${prefix}${amountText}`
}

//split initAmount Charge screen into euro and cents
export const CentsToParts = (amountCents?: number) => {
  //return accounting.formatMoney(amountInt / 100)

  if (amountCents !== undefined) {
    const euroValue = amountCents / 100

    const options = {style: 'currency', currency: 'EUR'}
    const numberFormat = new Intl.NumberFormat('nl-NL', options)

    const parts = numberFormat.formatToParts(euroValue)

    const euro = parts.find((x) => x.type === 'integer')?.value
    const cents = parts.find((x) => x.type === 'fraction')?.value

    //return ['0', '00']
    return [euro ?? '0', cents ?? '0']
  }
  return ['0', '00']
}

export const FormatCentsToEuro = (amountInt: number) => {
  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency: 'EUR',
  }).format(amountInt / 100)
}

export const FormatEuro = (amountEuro: number) => {
  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency: 'EUR',
  }).format(amountEuro)
}

export const FormatToEuro = (amount: number) => {
  //return accounting.formatMoney(amount)gradle

  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)
}

export const FormatDistance = (distance: number) => {
  const isKm = distance > 1000
  const unit = isKm ? 'km' : 'm'
  const result = isKm ? distance / 1000 : distance

  return `${result.toFixed(0)} ${unit}`
}

export const FixGuid = (guid: string) => {
  guid = guid.replace('{', '')
  guid = guid.replace('}', '')

  return guid
}

export const GetTenantPagetitle = (pagetitle: string) => {
  return `${pagetitle} | ${AppConfig.Tenant.AppName}`
}

export const GetWalletPagetitle = (pagetitle: string, currencyName: string) => {
  return `${pagetitle} | ${currencyName}`
}

export const GetLocalText = (localizedString?: ILocalizedStringSto) => {
  if (localizedString === null || localizedString === undefined) {
    return ''
  }

  const lsv = localizedString.Values.find(
    (x) => x.Language === primaryLanguage.toUpperCase(),
  )
  if (lsv === undefined) {
    return `lsv ${primaryLanguage} not found`
  }
  return lsv.Value
}

export const GetFirstLetterUser = (user?: IUserSTO) => {
  const letter =
    user?.Name === undefined || user?.Name === null || user?.Name === ''
      ? 'T'
      : user?.Name[0]

  return letter
}
