import React from 'react'
import {StyleSheet, View, ActivityIndicator} from 'react-native'
import {Button, Card, Icon, ListItem, Text} from 'react-native-elements'
import {FormatDate} from '../../../../Helpers/DateHelpers'
import {GetTokenState} from '../../../../Helpers/TokenHelpers'
import i18n from '../../../../i18n'
import {TokenStates} from '../../../../Models/Client/Enums'
import {ITokenSTO} from '../../../../Models/Server'
import Style, {colors, sizes} from '../../../../Style'
import {store} from '../Store'
import TokenIcon from './TokenIcon'

interface IProps {
  token: ITokenSTO
  loadingTokenTokenGUID: string
}

const TokenCard: React.FC<IProps> = ({token, loadingTokenTokenGUID}) => {
  const {flexRow, flex1} = Style
  const {titleStyle, iconRow} = styles

  const renderState = () => {
    const tokenState = GetTokenState(token)

    switch (tokenState) {
      case TokenStates.Blocked:
        return (
          <View style={iconRow}>
            <TokenIcon tokenState={tokenState} />
            <ListItem.Subtitle>
              {i18n.t('Tokens.TokenState.Blocked')}
            </ListItem.Subtitle>
          </View>
        )
      case TokenStates.Expired:
        return (
          <View style={iconRow}>
            <TokenIcon tokenState={tokenState} />
            <ListItem.Subtitle>
              {i18n.t('Tokens.TokenState.Expired')}
            </ListItem.Subtitle>
          </View>
        )
      case TokenStates.Active:
      default:
        return (
          <View style={iconRow}>
            <TokenIcon tokenState={tokenState} />
            <ListItem.Subtitle>
              {i18n.t('Tokens.TokenState.Active')}
            </ListItem.Subtitle>
          </View>
        )
    }
  }

  const renderButton = () => {
    if (loadingTokenTokenGUID === token.TokenGUID) {
      return <ActivityIndicator size="small" color={colors.main} />
    }
    return (
      <Button
        titleStyle={titleStyle}
        icon={<Icon name="cogs" type="font-awesome" size={15} color="white" />}
        onPress={() => store.ShowTokenMenu(token)}
      />
    )
  }

  return (
    <Card>
      <View
        style={[
          flexRow,
          {
            justifyContent: 'space-between',
            paddingVertical: sizes.padding.sm,
          },
        ]}>
        <View style={[flexRow, {alignItems: 'center'}]}>
          <Icon
            name="credit-card"
            type="entypo"
            style={{marginRight: sizes.padding.sm}}
          />
          <Text
            style={{
              fontWeight: '600',
            }}>
            {token.Description} ({token.ActivationCode})
          </Text>
        </View>
        {renderButton()}
      </View>

      <Card.Divider />

      <View
        style={[
          flexRow,
          {justifyContent: 'space-between', alignItems: 'center'},
        ]}>
        <View>{renderState()}</View>
        <View>
          <Text>{i18n.t('Tokens.ValidThru')}</Text>
          <ListItem.Subtitle>
            {FormatDate(token.ValidThru as string)}
          </ListItem.Subtitle>
        </View>
      </View>
    </Card>
  )
}

export default TokenCard

const styles = StyleSheet.create({
  titleStyle: {
    fontSize: 10,
    paddingVertical: 0,
  },
  iconRow: {
    flexDirection: 'row',
  },
})
