import {makeAutoObservable, runInAction} from 'mobx'
import {DoServiceCall} from '../../../Helpers/ServiceHelpers'
import {ICurrencySTO, ILocationSTO, IWalletSTO} from '../../../Models/Server'
import {myLog} from '../../../Services/Client/LogService'
import I18n from '../../../i18n'
import {ToprError} from '../../../Models/Client'
import CurrencyController from '../../../Services/Topr.Core/CurrencyController'
import MerchantController from '../../../Services/Topr.Core/MerchantController'
import {navigate} from '../../../Helpers/NavigationHelper'

import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import {appUiStore} from '../../../Mobx/AppUiStore'

class Store {
  public IsCurrenciesLoading = true
  public IsSubmitting = false
  public Currencies: ICurrencySTO[] = []

  constructor() {
    makeAutoObservable(this)
  }

  public GetConsumerCurrencies(merchantGuid: string) {
    DoServiceCall(() => {
      this.IsCurrenciesLoading = true
      CurrencyController.GetConsumerCurrencies(merchantGuid)
        .then((currencies) => {
          runInAction(() => {
            this.Currencies = currencies
            this.IsCurrenciesLoading = false
          })
        })
        .catch(() => {
          runInAction(() => {
            this.IsCurrenciesLoading = false
          })
        })
    })
  }

  public JoinMerchant(location: ILocationSTO) {
    DoServiceCall(() => {
      this.IsSubmitting = true

      //   setTimeout(() => {
      //     runInAction(() => {
      //       this.IsSubmitting = false
      //       dashboardStore.GetWallets()
      //       navigate('Dashboard')
      //       ShowToast(
      //         I18n.t('LocationDetails.AddLocation.Success', {
      //           locationName: location.Name,
      //         }),
      //       )
      //     })
      //   }, 1000)

      MerchantController.Join(location.MerchantGuid)
        .then(() => {
          runInAction(() => {
            this.IsSubmitting = false
          })
          dashboardStore.GetWallets()
          navigate('dashboard')
          appUiStore.ShowToast({
            message: I18n.t('LocationDetails.AddLocation.Success', {
              locationName: location.Name,
            }),
          })
        })
        .catch(() => {
          runInAction(() => {
            this.IsSubmitting = false
          })
        })
    })
  }
}
export const store = new Store()
