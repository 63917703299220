import {makeAutoObservable, runInAction} from 'mobx'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {ISavingCardSummarySTO} from '../../../Models/Server'
import SavingcardController from '../../../Services/Topr.Core/SavingcardController'

export default class Store {
  public IsLoading = true
  public SavingCardSummaries: ISavingCardSummarySTO[] = []

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize() {
    this.IsLoading = true

    SavingcardController.GetSavingCardSummaries()
      .then((savingCardSummaries) => {
        runInAction(() => {
          this.SavingCardSummaries = savingCardSummaries
          this.IsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }
}
export const savingCardSummaryStore = new Store()
