import {NavigationContainer} from '@react-navigation/native'
import {createStackNavigator} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React from 'react'
import {MySafeAreaView} from '../Components'
import {navigationRef} from '../Helpers/NavigationHelper'
import {GetTenantPagetitle} from '../Helpers/TextHelpers'
import i18n from '../i18n'
import {persistedStore} from '../Mobx'
import {IMyPlacedOrder} from '../Models/Client'
import {DeliveryType} from '../Models/InzziiApi/Enums'
import {
  ICouponSummarySTO,
  ILocationSTO,
  ISavingCardSummarySTO,
  IUserEventSto,
  IWalletSTO,
} from '../Models/Server'
import {
  Account,
  AddToken,
  CancelAccount,
  ChangeEmail,
  ChangeEmailConfirmationSent,
  ChangeName,
  ChangePassword,
  Charge,
  ChoosePicture,
  ConfirmChangeEmail,
  CouponDetails,
  CouponsSummary,
  DashboardIndex,
  ForgotPassword,
  LocationDetails,
  Locations,
  Login,
  OrderOnline,
  QrCode,
  Register,
  RegisterConfirm,
  ResetPassword,
  SavingCardDetails,
  SavingCardsSummary,
  SendMoney,
  TakePicture,
  Tokens,
  UserCouponsSummary,
  UserSavingCardsSummary,
  WalletDetails,
  Withdraw,
  PlacedOrderDetails,
  PlacedOrders,
  Home,
  Download,
  Help,
  AutomaticCharge,
  AutomaticLogin,
  SubscriptionDetails,
  TicketDetails,
} from '../Screens/index.web'
import Test from '../Screens/Test/Test'

import LinkingConfiguration from './LinkingConfiguration'

export type MainScreensListWeb = {
  home: undefined
  automaticLogin: {tempAdminGuid: string}
  download: undefined
  help: undefined
  login: undefined
  register: undefined
  confirm: {userGuid: string}
  dashboard: undefined
  locations: undefined
  locationDetails: {location: ILocationSTO}
  addToken: undefined
  walletDetails: {currencyGuid: string}
  charge: {wallet: IWalletSTO}
  sendMoney: {currencyGuid: string}
  withdraw: {wallet: IWalletSTO}
  automaticCharge: {currencyGuid: string}
  tokens: undefined
  account: undefined
  cancelAccount: undefined
  changeName: undefined
  changePassword: undefined
  changeEmail: undefined
  changeEmailConfirmationSent: {email: string}
  confirmChangeEmail: {requestIdentifier: string}
  takePicture: undefined
  choosePicture: undefined
  qrCode: undefined
  forgotPassword: undefined
  resetPassword: {requestIdentifier: string}
  couponDetails: {userCouponGuid: string}
  savingCardDetails: {userSavingCardGuid: string}
  ticketDetails: {userEvent: IUserEventSto}

  test: undefined
  couponsSummary: undefined
  userCouponsSummary: {couponSummary: ICouponSummarySTO}
  savingCardsSummary: undefined
  userSavingCardsSummary: {savingCardSummary: ISavingCardSummarySTO}
  orderOnline: undefined
  //scanner: undefined
  placedOrders: undefined
  placedOrderDetails: {placedOrder: IMyPlacedOrder}
  confirmPaymentOrder: {status: string; currencyGuid: string}
  subscriptionDetails: {userSubscriptionId: string}
}

const Stack = createStackNavigator<MainScreensListWeb>()

interface IProps {}

const Navigation: React.FC<IProps> = ({children}) => {
  const {UserToken} = persistedStore
  return (
    <NavigationContainer
      ref={navigationRef as any}
      linking={LinkingConfiguration}>
      <Stack.Navigator headerMode="none">
        {UserToken === undefined ? (
          <>
            {/* <Stack.Screen name="test" component={Test} /> */}
            <Stack.Screen
              name="home"
              component={Home}
              options={{title: GetTenantPagetitle('Home')}}
            />
            <Stack.Screen name="automaticLogin" component={AutomaticLogin} />
            <Stack.Screen
              name="download"
              component={Download}
              options={{title: GetTenantPagetitle('Download')}}
            />
            <Stack.Screen
              name="help"
              component={Help}
              options={{title: GetTenantPagetitle('Help')}}
            />
            <Stack.Screen
              name="login"
              component={Login}
              options={{
                title: GetTenantPagetitle(i18n.t('Login.Form.Action')),
              }}
            />
            <Stack.Screen
              name="register"
              component={Register}
              options={{
                title: GetTenantPagetitle(i18n.t('Register.Form.Action')),
              }}
            />
            <Stack.Screen
              name="confirm"
              component={RegisterConfirm}
              options={{
                title: GetTenantPagetitle(i18n.t('RegisterConfirm.Header')),
              }}
            />
            <Stack.Screen
              name="forgotPassword"
              component={ForgotPassword}
              options={{
                title: GetTenantPagetitle(i18n.t('ForgotPassword.Header')),
              }}
            />
            <Stack.Screen
              name="resetPassword"
              component={ResetPassword}
              options={{
                title: GetTenantPagetitle(i18n.t('ResetPassword.Header')),
              }}
            />
            <Stack.Screen
              name="locations"
              component={Locations}
              options={{
                title: GetTenantPagetitle(i18n.t('Locations.Header')),
              }}
            />
            <Stack.Screen name="locationDetails" component={LocationDetails} />
          </>
        ) : (
          <>
            <Stack.Screen name="dashboard" component={DashboardIndex} />
            <Stack.Screen name="automaticLogin" component={AutomaticLogin} />
            <Stack.Screen
              name="help"
              component={Help}
              options={{title: GetTenantPagetitle('Help')}}
            />
            <Stack.Screen
              name="locations"
              component={Locations}
              options={{
                title: GetTenantPagetitle(i18n.t('Locations.Header')),
              }}
            />

            <Stack.Screen
              name="locationDetails"
              component={LocationDetails}
              options={{
                title: GetTenantPagetitle(i18n.t('Locations.Header')),
              }}
            />
            <Stack.Screen
              name="addToken"
              component={AddToken}
              options={{
                title: GetTenantPagetitle(i18n.t('AddToken.Header')),
              }}
            />
            <Stack.Screen
              name="walletDetails"
              component={WalletDetails}
              options={{
                title: GetTenantPagetitle(i18n.t('Common.Wallet')),
              }}
            />
            <Stack.Screen
              name="charge"
              component={Charge}
              options={{
                title: GetTenantPagetitle(i18n.t('Charge.Form.Action')),
              }}
            />
            <Stack.Screen
              name="automaticCharge"
              component={AutomaticCharge}
              options={{
                title: GetTenantPagetitle(
                  i18n.t('WalletDetails.AutomaticCharge'),
                ),
              }}
            />
            <Stack.Screen
              name="sendMoney"
              component={SendMoney}
              options={{
                title: GetTenantPagetitle(i18n.t('SendMoney.Form.Action')),
              }}
            />
            <Stack.Screen
              name="withdraw"
              component={Withdraw}
              options={{
                title: GetTenantPagetitle(i18n.t('Withdraw.Form.Action')),
              }}
            />
            <Stack.Screen
              name="tokens"
              component={Tokens}
              options={{
                title: GetTenantPagetitle(i18n.t('Tokens.Header')),
              }}
            />
            <Stack.Screen
              name="account"
              component={Account}
              options={{
                title: GetTenantPagetitle(i18n.t('Account.Header')),
              }}
            />
            <Stack.Screen
              name="cancelAccount"
              component={CancelAccount}
              options={{
                title: GetTenantPagetitle(i18n.t('CancelAccount.Header')),
              }}
            />
            <Stack.Screen
              name="changeName"
              component={ChangeName}
              options={{
                title: GetTenantPagetitle(i18n.t('ChangeName.Header')),
              }}
            />
            <Stack.Screen
              name="changePassword"
              component={ChangePassword}
              options={{
                title: GetTenantPagetitle(i18n.t('ChangePassword.Header')),
              }}
            />
            <Stack.Screen
              name="changeEmail"
              component={ChangeEmail}
              options={{
                title: GetTenantPagetitle(i18n.t('ChangeEmail.Header')),
              }}
            />
            <Stack.Screen
              name="changeEmailConfirmationSent"
              component={ChangeEmailConfirmationSent}
            />
            <Stack.Screen
              name="confirmChangeEmail"
              component={ConfirmChangeEmail}
            />
            <Stack.Screen name="takePicture" component={TakePicture} />
            <Stack.Screen name="choosePicture" component={ChoosePicture} />
            <Stack.Screen name="qrCode" component={QrCode} />
            <Stack.Screen name="couponDetails" component={CouponDetails} />
            <Stack.Screen
              name="savingCardDetails"
              component={SavingCardDetails}
            />
            <Stack.Screen
              name="subscriptionDetails"
              component={SubscriptionDetails}
            />

            <Stack.Screen
              name="couponsSummary"
              component={CouponsSummary}
              options={{
                title: GetTenantPagetitle(i18n.t('CouponsSummary.Header')),
              }}
            />
            <Stack.Screen
              name="userCouponsSummary"
              component={UserCouponsSummary}
              options={{
                title: GetTenantPagetitle(i18n.t('CouponsSummary.Header')),
              }}
            />
            <Stack.Screen
              name="savingCardsSummary"
              component={SavingCardsSummary}
              options={{
                title: GetTenantPagetitle(i18n.t('SavingCardsSummary.Header')),
              }}
            />
            <Stack.Screen
              name="userSavingCardsSummary"
              component={UserSavingCardsSummary}
            />
            <Stack.Screen
              name="orderOnline"
              component={OrderOnline}
              options={{
                title: GetTenantPagetitle(i18n.t('OrderOnline.Header')),
              }}
            />
            {/* <Stack.Screen name="scanner" component={Scanner} /> */}
            <Stack.Screen name="placedOrders" component={PlacedOrders} />
            <Stack.Screen
              name="placedOrderDetails"
              component={PlacedOrderDetails}
            />
            <Stack.Screen name="ticketDetails" component={TicketDetails} />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default observer(Navigation)
