import {StackScreenProps} from '@react-navigation/stack'
import {placeholder} from 'i18n-js'
import {observer, Observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {View, StyleSheet, FlatList} from 'react-native'
import {Text} from 'react-native-elements'
import {ScrollView} from 'react-native-gesture-handler'
import {
  Container,
  Content,
  MyHeader,
  OrderHeader,
  ToprScreenLoader,
} from '../../Components'
import {FormatDateTime, FormatInzziiDateString} from '../../Helpers/DateHelpers'
import {FormatToEuro} from '../../Helpers/TextHelpers'
import {GetRandomKey} from '../../Helpers/UiHelpers'
import i18n from '../../i18n'
import {MainScreensList} from '../../Navigation'
import {myLog} from '../../Services/Client/LogService'
import Style, {sizes} from '../../Style'
import TotalRow from '../Location/OrderOnline/Cart/Components/TotalRow'
import {orderOnlineStore} from '../Location/OrderOnline/Store'
import TransactionRow from '../Wallet/Details/Components/TransactionRow'
import {walletDetailsStore} from '../Wallet/Details/Store'
import OrderRow from './Components/OrderRow'
interface IProps {}

//placedOrderDetails
interface IProps
  extends StackScreenProps<MainScreensList, 'placedOrderDetails'> {}
const PlacedOrderDetails: React.FC<IProps> = ({
  navigation: {goBack},
  route,
}) => {
  const orderHistory = route.params.orderHistory
  useEffect(() => {
    myLog.warn('PlacedOrderDetails')
    orderOnlineStore.GetOrder(orderHistory.orderId)
  }, [orderHistory])
  const {
    flatListHeader,

    flex1,
    textWhite,
  } = Style
  const {Order, Isloading} = orderOnlineStore
  const renderOrderRows = () => {
    if (Order === undefined) {
      return null
    }

    return (
      <>
        <View style={flatListHeader}>
          <View>
            <Text style={[textWhite, {paddingBottom: sizes.padding.sm}]}>
              {i18n.t('PlacedOrderDetails.Receipt')}
            </Text>

            <View style={styles.headerRow}>
              <Text style={styles.title}>
                {i18n.t('PlacedOrderDetails.OrderNumber')}
              </Text>
              <Text>{Order.orderNumber}</Text>
            </View>
            {Order.tableNumber && (
              <View style={styles.headerRow}>
                <Text style={styles.title}>
                  {i18n.t('PlacedOrders.Table')}:
                </Text>
                <Text>{Order.tableNumber}</Text>
              </View>
            )}

            <View style={styles.headerRow}>
              <Text style={styles.title}>
                {i18n.t('PlacedOrderDetails.Date')}
              </Text>
              {/* this date does have the right isoString ?!? */}
              <Text>{FormatDateTime(Order.orderDateTime)}</Text>
            </View>
          </View>
        </View>
        <View style={[flex1]}>
          <Content style={{backgroundColor: '#fff'}}>
            {Order.orderLines.map((orderRequestLine, key) => (
              <OrderRow key={key} orderRequestLine={orderRequestLine} />
            ))}
            <TotalRow totalAmountFormatted={FormatToEuro(Order.totalAmount)} />
          </Content>
        </View>
      </>
    )
  }
  return (
    <Container keyboardAware={false}>
      <ScrollView>
        <MyHeader
          onBackPress={() => {
            goBack()
          }}
          title={`${i18n.t('PlacedOrderDetails.OrderNumber')} ${
            orderHistory.orderNumber
          }`}
        />
        <ToprScreenLoader isLoading={Isloading}>
          {renderOrderRows()}
        </ToprScreenLoader>
      </ScrollView>
    </Container>
  )
}

export default observer(PlacedOrderDetails)

const styles = StyleSheet.create({
  headerRow: {
    alignItems: 'center',
    //justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  title: {
    paddingRight: sizes.padding.sm,
  },
})
