import {Platform} from 'react-native'
import NfcManager, {MyTagEvent} from 'react-native-nfc-manager'

export type onTagScannedEvent = (tag: MyTagEvent | null) => void

export const IsNfcSupportedAndEnabled = () => {
  return new Promise<boolean>((resolve, reject) => {
    if (Platform.OS === 'web') {
      resolve(false)
    }

    NfcManager.isSupported()
      .then((supported: boolean) => {
        if (supported && Platform.OS === 'android') {
          NfcManager.isEnabled()
            .then((isEnabled: boolean) => {
              resolve(isEnabled)
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve(supported)
        }
      })
      .catch((error) => reject(error))
  })
}
