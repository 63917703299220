import {observer} from 'mobx-react'
import React from 'react'
//import {ShowToast} from '../../../Helpers/UiHelpers'
import I18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import CheckPincodeModal from './Pincode/CheckPincodeModal'
interface IProps {}

const AuthenticateModal: React.FC<IProps> = ({children}) => {
  const {AuthenticateAction} = appUiStore

  if (AuthenticateAction === undefined) {
    return null
  }

  return (
    <CheckPincodeModal
      onClose={() => appUiStore.CloseAuthenticateConfirm()}
      onValidPin={() => AuthenticateAction.OnOk()}
      pinMessage={I18n.t('AuthenticateAction.Pin', {
        message: AuthenticateAction.Message,
      })}
    />
  )
}

export default observer(AuthenticateModal)
