import AppConfig from '../Config/AppConfig'

import * as Linking from 'expo-linking'
import {AppLinkTypes} from '../Models/Client/Enums'
import {AppLink} from '../Models/Client/AppLinks/AppLink'
import {myLog} from '../Services/Client/LogService'
import Url from 'url-parse'
import {Platform} from 'react-native'
import AppLinks, {
  //getAppLink,
  getAppLinkByType,
} from '../Models/Client/AppLinks/AppLinks'
import {primaryLanguage} from '../i18n'

export const GetDeepLinkBaseUrl = () => {
  const baseUrl =
    Platform.OS === 'web' //|| __DEV__
      ? Linking.makeUrl('/')
      : AppConfig.Tenant.BaseDeepLink

  return baseUrl
}

//wallet/details/:currencyGuid/:action/:status
//wallet/details/a63e57dc-42ba-4635-9a3f-059087fe9a68/emandateResult/status
//wallet/details/a63e57dc-42ba-4635-9a3f-059087fe9a68/confirmPaymentOrder/status

//wallet/details/{0}/confirmPaymentOrder
///wallet/details/1234/confirmPaymentOrder/

export const CreateAppLinkUrl = (type: AppLinkTypes, idParam?: string) => {
  const baseUrl = GetDeepLinkBaseUrl()

  const appLink = getAppLinkByType(type)
  if (appLink === undefined) {
    throw new Error('Invalid deeplink')
  }

  if (idParam !== undefined) {
    appLink.Url = appLink.Url.replace('{0}', `${encodeURIComponent(idParam)}`)
  }

  return `${baseUrl}${appLink.Url}/`
}

export const GetAppLinkFromUrl = (url: string): AppLink | undefined => {
  const parsedUrl = Url(url, true)

  //.substring(1) remove first /
  const pathname: string = parsedUrl.pathname.substring(1)
  const splittedPath: string[] = pathname.split('/')
  if (splittedPath.length < 2) {
    return undefined
  }

  const controller: string = splittedPath[0]
  const action: string = splittedPath[1]
  const parameters = splittedPath.slice(2)

  //console.log('url to check', pathname, splittedPath, parameters)
  let appLink = AppLinks.find((appLink) =>
    pathname.toLocaleLowerCase().startsWith(appLink.Url.toLowerCase()),
  )

  //pathname
  // wallet/details/a63e57dc-42ba-4635-9a3f-059087 fe9a68/confirmPaymentOrder/PAYMENT_CANCELED

  //splitted path
  //["wallet", "details", "a63e57dc-42ba-4635-9a3f-059087fe9a68", "confirmPaymentOrder", "PAYMENT_CANCELED"]

  //parameters
  //["a63e57dc-42ba-4635-9a3f-059087fe9a68", "confirmPaymentOrder", "PAYMENT_CANCELED"]

  if (appLink === undefined) {
    if (parameters.length < 2) {
      return undefined
    }

    //try to skip the id param: dus skip param[0] a63e57dc-42ba-4635-9a3f-059087fe9a68
    //wallet/details/a63e57dc-42ba-4635-9a3f-059087fe9a68/emandateResult/status
    //wallet/details/a63e57dc-42ba-4635-9a3f-059087fe9a68/confirmPaymentOrder/status

    //wallet/details/a63e57dc-42ba-4635-9a3f-059087fe9a68/confirmPaymentOrder/PAYMENT_CANCELED
    if (parameters.length > 1) {
      const urlZonderId = `${controller}/${action}/${parameters[1]}`

      //vergelijk urlZonderId tegen applink zonder {0}
      //applink: wallet/details/{0}/emandateResult --> wallet/details/emandateResult
      //urlZonderId: wallet/details/emandateResult

      //vind de juiste applink (emandate of confirmPaymentOrder -->deze hebben hetzelde startsWith --> wallet/details)
      appLink = AppLinks.find((appLink) =>
        appLink.Url.replace('{0}/', '')
          .toLocaleLowerCase()
          .startsWith(urlZonderId.toLowerCase()),
      )

      //console.log('try skip id', urlZonderId, AppLinks)
    }
  }

  if (appLink !== undefined && parameters.length > 0) {
    appLink.Parameters = parameters
  }

  return appLink
}

export const umbracoApiUrl = (url: string) => {
  let result = `${AppConfig.Service.CmsUrl}/umbraco/Api${url}`

  return result
}

export const UmbracoMedia = (url: string) => {
  return `${AppConfig.Service.CmsUrl}${url}`
}

//from /file/file/{tenant.SystemName}
export const GetTenantPdfWeb = () => {
  return `/file/${AppConfig.Tenant.SystemName}/terms-and-conditions-${primaryLanguage}.pdf`
}
