import React from 'react'
import {ListItem} from 'react-native-elements'
import {MyModal, ProfileButton} from '../../../../Components'
import i18n from '../../../../i18n'
import {persistedStore} from '../../../../Mobx'
import {IUserSTO} from '../../../../Models/Server'
import {dashboardStore} from '../../../Dashboard/Dashboard/Store'
//import {store} from './Store'
interface IProps {
  onClose: () => void
  isVisible: boolean
  onUserSelected: (user: IUserSTO) => void
}

const OtherUsersModal: React.FC<IProps> = ({
  onClose,
  isVisible,
  onUserSelected,
}) => {
  const {ChildUsers, MainUser} = persistedStore
  const {User} = dashboardStore

  const isChildUserSelected = MainUser?.MainUserGuid !== User?.MainUserGuid

  const childUsersWithoutActiveUser = ChildUsers.filter(
    (x) => x.MainUserGuid !== User?.MainUserGuid,
  )

  const renderParentMenuItem = () => {
    if (!isChildUserSelected || MainUser === undefined) {
      return null
    }

    return (
      <ListItem
        onPress={() => {
          //store.SwitchBackToParent()
          onUserSelected(MainUser)
          onClose()
        }}>
        <ProfileButton user={MainUser} />
        <ListItem.Content>
          <ListItem.Title>{MainUser.Name}</ListItem.Title>
        </ListItem.Content>
      </ListItem>
    )
  }

  return (
    <MyModal onClose={onClose} visible={isVisible} closeOnBackPress={true}>
      <ListItem>
        <ListItem.Content style={{alignItems: 'center'}}>
          <ListItem.Title>
            {i18n.t('Tokens.TokenMenu.SwitchUserAction.Title')}
          </ListItem.Title>
        </ListItem.Content>
      </ListItem>
      {childUsersWithoutActiveUser.map((childUser, key) => {
        return (
          <ListItem
            key={key}
            onPress={() => {
              onUserSelected(childUser)
              onClose()
            }}>
            <ProfileButton user={childUser} />
            <ListItem.Content>
              <ListItem.Title>{childUser.Name}</ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )
      })}
      {renderParentMenuItem()}
    </MyModal>
  )
}

export default OtherUsersModal
