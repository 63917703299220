import React from 'react'
import {Icon} from 'react-native-elements'
import {TokenStates} from '../../../../Models/Client/Enums'
import {colors, sizes} from '../../../../Style'
interface IProps {
  tokenState: TokenStates
}

const TokenIcon: React.FC<IProps> = ({tokenState}) => {
  switch (tokenState) {
    case TokenStates.Blocked:
      return (
        <Icon
          name="ban"
          type="font-awesome"
          style={{marginRight: sizes.padding.sm}}
          color={colors.darkRed}
        />
      )
    case TokenStates.Expired:
      return (
        <Icon
          name="clock-o"
          type="font-awesome"
          style={{marginRight: sizes.padding.sm}}
          color={colors.lightGrey}
        />
      )
    case TokenStates.Active:
    default:
      return (
        <Icon
          name="check"
          type="font-awesome"
          style={{marginRight: sizes.padding.sm}}
          color={colors.main}
        />
      )
  }
}

export default TokenIcon
