import React, {ReactNode} from 'react'
import {View, StyleSheet} from 'react-native'
import AppImages from '../../Images/AppImages'
import PageLoader from './PageLoader/PageLoader'

interface IProps {
  title?: string
  leftContentCenter: ReactNode
  leftContentBottom?: ReactNode
  noPadding?: boolean
}

const LeftColumnLayout: React.FC<IProps> = ({
  title,
  children,
  leftContentCenter,
  leftContentBottom,
  noPadding,
}) => {
  const renderLeftBottom = () => {
    if (leftContentBottom === undefined) {
      return null
    }

    return <div className="py-4">{leftContentBottom}</div>
  }

  const renderTitle = () => {
    if (title === undefined) {
      return null
    }

    return (
      <div className="header py-4 px-4">
        <h1 className="title is-4">{title}</h1>
      </div>
    )
  }

  const noPaddingCss = noPadding ? 'no-padding' : ''

  return (
    <div className="webroot">
      <PageLoader />
      <div className="container">
        <div className="columns left">
          <div className="column left-col">
            <div className="is-flex is-flex-direction-column is-align-items-start my-4">
              <img
                className="logo is-flex-desktop"
                src={AppImages.Logo.ToprWallet}
              />
              <img
                className="short is-hidden-desktop"
                src={AppImages.Logo.Short}
              />
            </div>
            <div className="menu">{leftContentCenter}</div>
            {renderLeftBottom()}
          </div>
          <div className={`column main ${noPaddingCss}`}>
            {renderTitle()}
            {/* <div className="v-scroll">{children}</div> */}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftColumnLayout

LeftColumnLayout.defaultProps = {
  noPadding: false,
}
