import React from 'react'
import {
  TouchableOpacity,
  View,
  Image,
  ImageStyle,
  StyleSheet,
} from 'react-native'
import {Badge, Card, Icon, Text} from 'react-native-elements'

import {FormatCurrency} from '../../Helpers/TextHelpers'
import {GetFileUrl, GetLocalizedImageUrl} from '../../Helpers/UiHelpers'
import i18n from '../../i18n'
import {
  ISavingCardSummarySTO,
  IGroupedCoupon,
  IWalletSTO,
} from '../../Models/Server'
import Style, {fullWidthHeightAbs, sizes} from '../../Style'
import {colors} from '../../Style/index'
import TextBadge from '../Labels/TextBadge'
import CardFooter from './CardFooter'

interface IProps {
  savingSummary: ISavingCardSummarySTO
  onPress: () => void
}
const SavingCardSummaryCard: React.FC<IProps> = ({savingSummary, onPress}) => {
  const imageGuid = savingSummary.SavingCard.ImageGuid
  const {backgroundImageStyle} = Style

  return (
    <TouchableOpacity onPress={onPress}>
      <Card containerStyle={{padding: 0}}>
        <View>
          <Image
            source={{
              uri: GetFileUrl(imageGuid),
            }}
            style={backgroundImageStyle as ImageStyle}
          />
          <TextBadge
            title={i18n.t('SavingCardsSummary.NotCompleted')}
            value={savingSummary.NotCompleted}
          />
          <TextBadge
            title={i18n.t('SavingCardsSummary.Completed')}
            value={savingSummary.Completed}
          />
          <TextBadge
            title={i18n.t('Common.Total')}
            value={savingSummary.Total}
          />
        </View>
        <CardFooter title={savingSummary.SavingCard.Title} />
      </Card>
    </TouchableOpacity>
  )
}

export default SavingCardSummaryCard
