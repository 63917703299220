import React from 'react'
import {observer} from 'mobx-react'
import {Platform, View} from 'react-native'
import {Text} from 'react-native-elements'
import AddTokenActivationCode from './Components/AddTokenActivationCode'
import {useEffect} from 'react'

import {IsNfcSupportedAndEnabled} from '../../../Helpers/NfcHelpers'
import {store} from './Store'
import AddTokenUid from './Components/AddTokenUid/AddTokenUid'
import {MyHeader} from '../../../Components'
import i18n from '../../../i18n'
import {StackNavigationProp} from '@react-navigation/stack'
import {MainScreensList} from '../../../Navigation'

interface IProps {
  navigation: StackNavigationProp<MainScreensList>
}

const AddToken: React.FC<IProps> = ({navigation: {goBack}}) => {
  useEffect(() => {
    //https://github.com/whitedogg13/react-native-nfc-manager/blob/HEAD/example/AppV2Mifare.js
    //NfcManager.start()
    store.Initialize()

    return () => store.InitialState()
  }, [null])

  const {IsLoading, HasNfcEnabled} = store

  if (IsLoading) {
    return null
  }

  const renderAddTokenScreens = () => {
    if (!HasNfcEnabled) {
      return <AddTokenActivationCode />
    } else {
      return <AddTokenUid />
    }
  }

  return (
    <>
      {/* <MyHeader onBackPress={goBack} title={i18n.t('AddToken.Header')} /> */}
      {renderAddTokenScreens()}
    </>
  )
}

export default observer(AddToken)
