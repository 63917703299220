import React, {useState} from 'react'
import {View, StyleSheet} from 'react-native'
import {CheckBox} from 'react-native-elements'
import {FormatToEuro} from '../../../../../Helpers/TextHelpers'
import {ICondiment, ICondimentGroup} from '../../../../../Models/InzziiApi'
import Style from '../../../../../Style'
import {articleStore} from '../Store'
interface IProps {
  condiment: ICondiment

  onRadioPress: () => void
}

const CondimentRadioButton: React.FC<IProps> = ({condiment, onRadioPress}) => {
  //const {container} = styles
  const checked = articleStore.IsCondimentRadioSelected(condiment)
  const title = `${condiment.name} (${FormatToEuro(condiment.price)})`
  return (
    <CheckBox
      title={title}
      iconType="ionicon"
      checkedIcon="radio-button-on"
      uncheckedIcon="radio-button-off"
      checked={checked}
      containerStyle={{flex: 1}}
      onPress={() => {
        onRadioPress()
      }}
    />
  )
}

export default CondimentRadioButton

const styles = StyleSheet.create({
  // container: {
  //   flexDirection: 'row',
  // },
})
