import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Text} from 'react-native-elements'
import {
  Container,
  Content,
  EuroTextInput,
  FormInfo,
  MyInput,
  SubmitHeader,
  SubmitHeaderButton,
  ToprScreenLoader,
} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import AppConfig from '../../../Config/AppConfig'
import {
  FormatCentsToEuro,
  GetWalletPagetitle,
} from '../../../Helpers/TextHelpers'
import i18n from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style, {colors} from '../../../Style'
import {WithdrawFormData, withdrawStore} from './Store'

const iban = require('iban')

interface IProps extends StackScreenProps<MainScreensList, 'withdraw'> {}

const Withdraw: React.FC<IProps> = ({
  navigation: {goBack, setOptions},
  route,
}) => {
  const wallet = route.params.wallet
  const {noteText, highlightText} = Style
  useEffect(() => {
    myLog.warn('Withdraw')
    withdrawStore.Initialize(wallet.Currency.CurrencyGuid)

    if (
      persistedStore.MainUser !== undefined &&
      persistedStore.MainUser.EmailAddress
    ) {
      setTimeout(() => {
        setValue('email', persistedStore.MainUser?.EmailAddress)
      }, 300)
    }

    if (AppConfig.IsDebugMode) {
      //setValue('email', 'r.de.ruijter@mybility.nl')
      // setTimeout(() => {
      //   // setValue('name', 'Ralph de Ruijter')
      //   // setValue('iban', 'NL76INGB0003346 704')
      // }, 300)
    }
  }, [null])

  useEffect(() => {
    if (wallet) {
      setOptions({
        title: GetWalletPagetitle(
          i18n.t('Withdraw.Form.Action'),
          wallet.Currency.Name,
        ),
      })
    }
  }, [wallet])

  const {control, handleSubmit, errors, setValue, formState} =
    useForm<WithdrawFormData>({
      mode: 'onChange',
    })

  //console.log('disabled', !formState.isValid)
  const {
    IsSubmitting,
    IsLoading,
    WithdrawInfo,
    MaxWithdrawAmount,
    WithdrawCosts,
  } = withdrawStore
  const onSubmit = (data: WithdrawFormData) => {
    const amountWithoutCosts = FormatCentsToEuro(data.amount - WithdrawCosts)

    appUiStore.ShowConfirm(
      i18n.t('Withdraw.Form.ConfirmMessage', {
        amountWithoutCosts,
        amount: FormatCentsToEuro(data.amount),
        transactionCosts: FormatCentsToEuro(WithdrawCosts),
      }),
      () => {
        //after confirm show the authenticate modal
        appUiStore.ShowAuthenticateConfirm({
          OnOk: () =>
            withdrawStore.Withdraw(wallet.Currency.CurrencyGuid, data),
          Message: i18n.t('Withdraw.Form.AuthenticateAction', {
            amountWithoutCosts,
          }),
        })
      },
    )
  }

  const renderMaxWithdrawMessage = () => {
    return (
      <FormInfo>
        {i18n.t('Withdraw.MaxBalance0')}
        <Text style={highlightText}>
          {FormatCentsToEuro(MaxWithdrawAmount)}
        </Text>
        {i18n.t('Withdraw.MaxBalance1')}
      </FormInfo>
    )
  }

  const renderSubmit = () => {
    return (
      <SubmitHeaderButton
        isLoading={IsSubmitting}
        title={i18n.t('Withdraw.Form.Action')}
        onPress={handleSubmit(onSubmit)}
      />
    )
  }
  const renderTransactionCosts = () => {
    return (
      <Text style={noteText}>
        {i18n.t('Withdraw.Form.ChargeCostsMessage')}
        <Text style={{color: colors.main, fontSize: 12}}>
          {FormatCentsToEuro(WithdrawCosts)}
        </Text>
        .
      </Text>
    )
  }

  const renderForm = () => {
    if (
      WithdrawInfo === undefined ||
      WithdrawInfo.HasAutomatichChargeWithdrawn
    ) {
      return null
    }

    return (
      <>
        {renderMaxWithdrawMessage()}
        <EuroTextInput
          formObject={{} as WithdrawFormData}
          name="amount"
          label={i18n.t('Withdraw.Form.Fields.Amount.Label')}
          control={control}
          onSubmitEditing={() => {
            handleSubmit((data) => onSubmit(data))()
          }}
          validation={{
            errors,
            rules: {
              required: i18n.t('Withdraw.Form.Fields.Amount.Errors.Required'),
              min: {
                value: 100,
                message: i18n.t('Withdraw.Form.Fields.Amount.Errors.MinMax', {
                  min: FormatCentsToEuro(100),
                  max: FormatCentsToEuro(MaxWithdrawAmount),
                }),
              },
              max: {
                value: MaxWithdrawAmount,
                message: i18n.t('Withdraw.Form.Fields.Amount.Errors.MinMax', {
                  min: FormatCentsToEuro(100),
                  max: FormatCentsToEuro(MaxWithdrawAmount),
                }),
              },
            },
          }}
        />
        {renderTransactionCosts()}
        <MyInput
          formObject={{} as WithdrawFormData}
          name="iban"
          label={i18n.t('Withdraw.Form.Fields.Iban.Label')}
          control={control}
          inputProps={{
            autoCapitalize: 'none',
          }}
          validation={{
            errors,
            rules: {
              required: i18n.t('Withdraw.Form.Fields.Iban.Errors.Required'),
              validate: {
                positive: (value: string) => {
                  //remove all spaces
                  const valueNoSpace = value.replace(/ /g, '')
                  //check if space where removed
                  if (valueNoSpace.length < value.length) {
                    return 'voer een geldige iban in'
                  }

                  return iban.isValid(value) || 'voer een geldige iban in'
                },
              },
            },
          }}
        />
        <MyInput
          formObject={{} as WithdrawFormData}
          name="name"
          label={i18n.t('Withdraw.Form.Fields.Name.Label')}
          control={control}
          inputProps={{
            autoCapitalize: 'none',
          }}
          validation={{
            errors,
            rules: {
              required: i18n.t('Withdraw.Form.Fields.Name.Errors.Required'),
            },
          }}
        />
        <MyInput
          formObject={{} as WithdrawFormData}
          name="email"
          label={i18n.t('Register.EmailStep.Field.Label')}
          control={control}
          inputProps={{
            autoCapitalize: 'none',
            keyboardType: 'email-address',
            onEndEditing: () => {
              handleSubmit((data) => onSubmit(data))()
            },
          }}
          validation={{
            errors,
            rules: {
              required: i18n.t('Register.EmailStep.Field.Errors.Required'),
              pattern: {
                value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
                message: i18n.t('Register.EmailStep.Field.Errors.Regex'),
              },
            },
          }}
        />
      </>
    )
  }

  const renderAutomaticChargeCanceledMessage = () => {
    if (
      WithdrawInfo === undefined ||
      !WithdrawInfo.HasAutomatichChargeWithdrawn
    ) {
      return null
    }

    return (
      <FormInfo>
        {i18n.t('Withdraw.Form.AutomaticChargeCanceledMessage', {
          currencyName: wallet.Currency.Name,
        })}
      </FormInfo>
    )
  }

  return (
    <>
      <Container keyboardAware={!IsLoading}>
        <StockImageLayout isLoading={IsLoading}>
          <ToprScreenLoader isWhite isLoading={IsLoading}>
            <Content headerMargin>
              {renderForm()}
              {renderAutomaticChargeCanceledMessage()}
            </Content>
            <SubmitHeader onBackPress={goBack}>
              <Text>{wallet.Currency.Name}</Text>
              {renderSubmit()}
            </SubmitHeader>
          </ToprScreenLoader>
        </StockImageLayout>
      </Container>
    </>
  )
}
export default observer(Withdraw)
