import {action, makeObservable, observable, runInAction} from 'mobx'

import {persistedStore} from '.'
import {ToastTimeout} from '../Components/Modal/MyToast'
import {syncOptions} from '../Helpers/CodepushHelpers'
import {
  IAlertMessage,
  IAuthenticateAction,
  IConfirmMessage,
  IToastMessage,
} from '../Models/Client'

export default class AppUiStore {
  public IsConnected: boolean = false
  public NetworkStatefinished: boolean = false
  public AlertMessage?: IAlertMessage = undefined
  public ConfirmMessage?: IConfirmMessage = undefined
  public AuthenticateAction?: IAuthenticateAction = undefined
  public ToastMessage?: IToastMessage = undefined

  constructor() {
    //makeAutoObservable(this)
    makeObservable(this, {
      IsConnected: observable,
      NetworkStatefinished: observable,
      AlertMessage: observable,
      ConfirmMessage: observable,
      AuthenticateAction: observable,
      ToastMessage: observable,
      ShowAuthenticateConfirm: action,
      CloseAuthenticateConfirm: action,
      SetIsConnected: action,
      ResetPincode: action,
      Logout: action,
      ShowAlert: action,
      CloseAlert: action,
      ShowConfirm: action,
      CloseConfirm: action,
      ShowToast: action,
    })
  }

  public ShowAuthenticateConfirm(authenticateAction: IAuthenticateAction) {
    this.AuthenticateAction = authenticateAction
  }

  public CloseAuthenticateConfirm() {
    this.AuthenticateAction = undefined
  }

  public SetIsConnected(isConnected: boolean) {
    this.NetworkStatefinished = true
    this.IsConnected = isConnected
  }

  public ResetPincode() {
    persistedStore.SetAuthenticationMethodPincode(undefined)
    this.Logout()
  }

  public Logout() {
    persistedStore.SetUserToken(undefined)
    persistedStore.SetClientToken(undefined)
  }

  public ShowAlert(alertMessage: IAlertMessage) {
    this.AlertMessage = alertMessage
  }

  public CloseAlert() {
    this.AlertMessage = undefined
  }

  public ShowConfirm(
    message: string,
    onOk: () => void,
    additionalText?: string,
  ) {
    this.ConfirmMessage = {
      Message: message,
      onOk,
      AdditionalText: additionalText,
    }
  }

  public CloseConfirm() {
    this.ConfirmMessage = undefined
  }

  public ShowToast(message: IToastMessage) {
    this.ToastMessage = message

    if (message && message.type === undefined) {
      message.type = 'normal'
    }

    setTimeout(() => {
      runInAction(() => {
        this.ToastMessage = undefined
      })
    }, ToastTimeout)
  }
}

export const appUiStore = new AppUiStore()
