import {StackNavigationProp} from '@react-navigation/stack'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {View} from 'react-native'
import {
  Container,
  Content,
  FormHeader,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import i18n from '../../../i18n'
import {persistedStore} from '../../../Mobx'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style from '../../../Style'

interface IProps {}

type FormData = {
  pincode: string
  confirmPincode: string
}
interface IProps {
  navigation: StackNavigationProp<MainScreensList>
}

const SetPincode: React.FC<IProps> = ({navigation: {navigate}}) => {
  useEffect(() => {
    myLog.info('Pincode')
  }, [null])

  const {control, handleSubmit, errors, getValues, formState} =
    useForm<FormData>({mode: 'onChange'})

  const onSubmit = (data: FormData) => {
    persistedStore.SetAuthenticationMethodPincode(data.pincode)
  }

  const {pincodeStyle} = Style

  return (
    <>
      <Container>
        <Content headerMargin>
          <MyInput
            formObject={{} as FormData}
            name="pincode"
            label={i18n.t('Pincode.Form.Fields.Pincode.Label')}
            control={control}
            autofocus={true}
            inputProps={{
              maxLength: 4,
              keyboardType: 'numeric',
              secureTextEntry: true,
              style: pincodeStyle,
            }}
            validation={{
              errors,
              rules: {
                required: i18n.t('Pincode.Form.Fields.Pincode.Errors.Required'),
                minLength: {
                  value: 4,
                  message: i18n.t(
                    'Pincode.Form.Fields.Pincode.Errors.MinLength',
                  ),
                },
                pattern: {
                  value: /[0-9]{4}/,
                  message: i18n.t('Pincode.Form.Fields.Pincode.Errors.Regex'),
                },
              },
            }}
          />
          <MyInput
            formObject={{} as FormData}
            name="confirmPincode"
            label={i18n.t('Pincode.Form.Fields.ConfirmPincode.Label')}
            control={control}
            inputProps={{
              maxLength: 4,
              keyboardType: 'numeric',
              secureTextEntry: true,
              style: pincodeStyle,
              onEndEditing: () => handleSubmit((data) => onSubmit(data))(),
            }}
            validation={{
              errors,
              rules: {
                required: i18n.t(
                  'Pincode.Form.Fields.ConfirmPincode.Errors.Required',
                ),
                minLength: {
                  value: 4,
                  message: i18n.t(
                    'Pincode.Form.Fields.ConfirmPincode.Errors.MinLength',
                  ),
                },
                pattern: {
                  value: /[0-9]{4}/,
                  message: i18n.t(
                    'Pincode.Form.Fields.ConfirmPincode.Errors.Regex',
                  ),
                },
                validate: {
                  equality: (confirmPincode: string) => {
                    const formData = getValues()

                    return (
                      confirmPincode === formData.pincode ||
                      i18n.t(
                        'Pincode.Form.Fields.ConfirmPincode.Errors.Equality',
                      )
                    )
                  },
                },
              },
            }}
          />
        </Content>
      </Container>
      <SubmitHeader>
        <SubmitHeaderButton
          disabled={!formState.isValid}
          //isLoading={IsSubmitting}
          title={i18n.t('Pincode.Form.Action')}
          onPress={handleSubmit(onSubmit)}
        />
      </SubmitHeader>
    </>
  )
}

export default SetPincode
