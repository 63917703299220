import {ErrorMessage} from '@hookform/error-message'
import React, {PropsWithChildren, useState} from 'react'
import {Controller} from 'react-hook-form'
import {View} from 'react-native'
import {Text} from 'react-native-elements'
import Style, {sizes} from '../../Style'

import nl from 'date-fns/locale/nl'
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('nl', nl)

interface IProps<T>
  extends Pick<
    ReactDatePickerProps,
    'selectsStart' | 'selectsEnd' | 'startDate' | 'endDate'
  > {
  name: keyof T
  label: string
  control: any
  validation?: {
    errors: any
    rules: any
    defaultValue?: string
  }
  formObject: T
  onDateChange?: (text: Date | null) => void
}

const MyDatePicker = <ObjectType,>(
  props: PropsWithChildren<IProps<ObjectType>>,
) => {
  const {inputContainer, labelStyle, errorMessage} = Style
  const {
    name,
    label,
    control,
    validation,
    onDateChange,
    selectsStart,
    selectsEnd,
    startDate,
    endDate,
  } = props

  const rules = validation !== undefined ? validation.rules : {}
  const errors = validation !== undefined ? validation.errors : {}

  const defaultValue =
    validation !== undefined && validation.defaultValue !== undefined
      ? validation.defaultValue
      : ''

  return (
    <Controller
      control={control}
      name={name.toString()}
      rules={rules}
      defaultValue={defaultValue}
      render={(
        {onChange, onBlur, value, name, ref},
        {invalid, isTouched, isDirty},
      ) => {
        //console.log(name, value, startDate, endDate)

        //const myStartDate = sele

        return (
          <View>
            <Text style={labelStyle}>{label}</Text>
            <DatePicker
              locale="nl"
              dateFormat="dd-MM-yyyy"
              placeholderText="dd-mm-jjjj"
              showMonthDropdown
              showYearDropdown
              selected={value}
              selectsStart={selectsStart}
              selectsEnd={selectsEnd}
              startDate={startDate}
              endDate={endDate}
              onBlur={onBlur}
              isClearable
              onChange={(date: Date | null) => {
                onChange(date)
                if (onDateChange) {
                  onDateChange(date)
                }
              }}
            />
            <ErrorMessage
              errors={errors}
              name={name.toString()}
              render={({message}) => (
                <Text style={errorMessage}>{message}</Text>
              )}
            />
          </View>
        )
      }}
    />
  )
}

export default MyDatePicker
