import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {StackScreenProps} from '@react-navigation/stack'
import {MainScreensList} from '../../../../Navigation'
import {
  FormHeader,
  Content,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  Container,
  MyHeader,
  FullFooterButton,
  MySelectList,
  ToprScreenLoader,
} from '../../../../Components'
import i18n from '../../../../i18n'
import Style, {colors, sizes} from '../../../../Style'
import {myLog} from '../../../../Services/Client/LogService'
import {OrderOnlineList} from '..'
import {checkOutStore} from './Store'
import {IIPaymentMethod} from '../../../../Models/InzziiApi'
import {View, Image} from 'react-native'
import {Text} from 'react-native-elements'
import {useForm} from 'react-hook-form'
import {orderOnlineStore} from '../Store'

interface IProps extends StackScreenProps<OrderOnlineList, 'orderPayment'> {}

type MyFormData = {
  remarks: string
}

const CheckOut: React.FC<IProps> = ({navigation}) => {
  const {TotalAmountOrderFormatted} = orderOnlineStore
  useEffect(() => {
    myLog.warn('OrderCheckout')
    checkOutStore.Initialize(navigation)
  }, [null])

  const {pickerRow, pickerItemImage, pickerText} = Style

  const {IsLoading, PaymentMethods, SelectedPaymentMethod, IsSubmitting} =
    checkOutStore

  const {control, handleSubmit, errors, setError} = useForm<MyFormData>()

  const onSubmit = (data: MyFormData) => {
    if (SelectedPaymentMethod) {
      checkOutStore.HandleCheckOut(data.remarks)
      // appUiStore.ShowAuthenticateConfirm({
      //   OnOk: () => {
      //     store.Topup(
      //       SelectedChargeMethod.Guid,
      //       data.amount,
      //       (errorMessage: string) => {
      //         setError('amount', {
      //           type: 'manual',
      //           message: errorMessage,
      //         })
      //       },
      //     )
      //   },
      //   Message: I18n.t('Charge.Form.AuthenticateAction', {
      //     amount: FormatCentsToEuro(data.amount),
      //   }),
      // })
    }
  }
  return (
    <ToprScreenLoader isLoading={IsLoading}>
      <Container>
        <MyHeader
          title={i18n.t('OrderOnline.Checkout')}
          onBackPress={() => navigation.goBack()}
        />
        <Content>
          <MySelectList
            label={i18n.t(
              'OrderOnline.CheckoutForm.Fields.PaymentMethod.Label',
            )}
            options={PaymentMethods}
            selectedOption={SelectedPaymentMethod}
            onSelect={(option: IIPaymentMethod) =>
              checkOutStore.SetSelectedPaymentMethod(option)
            }
            isItemSelected={(a: IIPaymentMethod, b: IIPaymentMethod) => {
              return a.gateway === b.gateway
            }}
            renderListItem={(
              paymentMethod: IIPaymentMethod,
              isHighLighted: boolean,
            ) => {
              return (
                <View style={pickerRow}>
                  <View
                    style={{
                      padding: sizes.padding.sm,
                      borderWidth: 1,
                      borderColor: colors.grey,
                    }}>
                    <Image
                      style={pickerItemImage}
                      source={{uri: paymentMethod.image}}
                    />
                  </View>
                  <Text
                    style={[isHighLighted && {color: colors.main}, pickerText]}>
                    {paymentMethod.description}
                  </Text>
                </View>
              )
            }}
          />
          <MyInput
            formObject={{} as MyFormData}
            name="remarks"
            label={i18n.t('OrderOnline.CheckoutForm.Fields.Remarks.Label')}
            control={control}
            multiline={true}
            numberOfLines={4}
          />
          <Text>
            Door op 'Bevestigen' te klikken, ga je akkoord met ons
            Privacyverklaring
          </Text>
        </Content>
      </Container>
      <FullFooterButton
        isLoading={IsSubmitting}
        onPress={handleSubmit(onSubmit)}
        title={`${i18n.t('Common.Confirm')} (${TotalAmountOrderFormatted})`}
      />
    </ToprScreenLoader>
  )
}
export default observer(CheckOut)
