import {LinkingOptions, NavigatorScreenParams} from '@react-navigation/native'
//import {StackScreenProps, StackScreenProps} from '@react-navigation/stack'
import {GetDeepLinkBaseUrl} from '../Helpers/UrlHelpers'
import {DashboardScreensList} from '../Screens/Dashboard/Dashboard/DashboardWeb'

//also used to map web  url path and parameters to MainScreensList in
// Navigation/index.ts

//deeplinks (used in app) always follow this pattern: controller/action/parameters
//eg:
//    register/confirm/:userGuid
//    email/confirmChange/:requestIdentifier
//    wallet/confirmPaymentOrder/:currencyGuid/:status

type RootStackParamList = {
  RoundUps: undefined
  Account: undefined
  dashboard: {
    path: 'dashboard'
    screens: NavigatorScreenParams<DashboardScreensList>
  }
}

const linkingOptions: LinkingOptions = {
  enabled: true,
  prefixes: [GetDeepLinkBaseUrl()],

  config: {
    screens: {
      register: {
        path: 'register',
      },
      confirm: {
        path: 'register/confirm/:userGuid',
      },
      confirmChangeEmail: {
        path: 'email/confirmChange/:requestIdentifier',
      },
      resetPassword: {
        path: 'user/resetPassword/:requestIdentifier',
      },

      //web
      automaticLogin: {
        //linked by from manager.mybility.nl //'Login as user'
        path: 'User/AutomaticLogin/:tempAdminGuid',
      },
      confirmPaymentOrder: {
        path: 'wallet/confirmPaymentOrder/:currencyGuid/:status',
      },
      walletDetails: {
        path: 'wallet/details/:currencyGuid/:action/:status',
      },
      sendMoney: {
        path: 'wallet/send/:currencyGuid',
      },
      automaticCharge: {
        path: 'wallet/automaticcharge/:currencyGuid',
      },
      couponDetails: {
        path: 'coupon/details/:userCouponGuid',
      },
      savingCardDetails: {
        path: 'savingcard/details/:userSavingCardGuid',
      },
      couponsSummary: {
        path: 'couponsSummary',
      },
      login: {
        path: 'login',
      },
      locations: {
        path: 'locations',
      },
      download: {
        path: 'download',
      },
      help: {
        path: 'help',
      },
      // tokens: {
      //   path: 'tokens',
      // },
      forgotPassword: {
        path: 'forgotPassword',
      },
      dashboard: {
        path: 'dashboard',
        screens: {
          help: {path: 'help'},
          main: {path: 'main'},
        },
      },
      // account: {
      //   path: 'account',
      // },
      // dashboard: {
      //   path: 'dashboard/sd',
      // },
      //NotFound: '*',
    },
  },
}

export default linkingOptions
