import React from 'react'
import {View, ViewStyle} from 'react-native'
import Style from '../../Style'
interface IProps {
  isLast?: boolean
  horizontal?: boolean
}

const MyFooterTile: React.FC<IProps> = ({children, isLast, horizontal}) => {
  const style: ViewStyle[] = [Style.flex1]
  if (!isLast) {
    style.push({marginRight: 10})
  }

  if (horizontal) {
    style.push({flexDirection: 'row', alignItems: 'center'})
  }

  return <View style={style}>{children}</View>
}

export default MyFooterTile

MyFooterTile.defaultProps = {
  isLast: false,
  horizontal: false,
}
