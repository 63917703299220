import React from 'react'
import {View, StyleSheet, TouchableOpacityProps} from 'react-native'
import {Icon, IconProps} from 'react-native-elements'
import {TouchableOpacity} from 'react-native-gesture-handler'

import {colors, sizes} from '../../../../../Style'

interface IProps extends TouchableOpacityProps {
  iconProps: IconProps
}

const IconButton: React.FC<IProps> = ({iconProps, style, ...otherProps}) => {
  return (
    <TouchableOpacity style={[styles.button, style]} {...otherProps}>
      <Icon
        color={colors.main}
        //name="minus"
        //type="ant-design"
        size={20}
        {...iconProps}
      />
    </TouchableOpacity>
  )
}

export default IconButton

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: sizes.padding.sm,
    paddingVertical: 1,
    borderWidth: 1,
    borderColor: colors.lightGrey,
  },
})
