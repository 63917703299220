import React from 'react'
import {View} from 'react-native'
import {colors, sizes} from '../../Style'

interface IProps {
  isGreyBackground?: boolean
  centerContent?: boolean
  paddingBottom?: boolean
}

const MyFooter: React.FC<IProps> = ({
  children,
  isGreyBackground,
  centerContent,
  paddingBottom,
}) => {
  const backgroundColor = isGreyBackground ? colors.containerBg : '#fff'

  return (
    <View
      style={[
        {
          backgroundColor,
          paddingVertical: paddingBottom ? sizes.padding.sm : 0,
          flexDirection: 'row',
          justifyContent: centerContent ? 'center' : 'space-between',
          alignItems: centerContent ? 'center' : 'baseline',
        },
      ]}>
      {children}
    </View>
  )
}

export default MyFooter

MyFooter.defaultProps = {
  isGreyBackground: false,
  centerContent: false,
  paddingBottom: true,
}
