import React from 'react'
import {View} from 'react-native'
import {
  FormHeader,
  MyButton,
  Content,
  MyFooter,
  MyFooterTile,
} from '../../../../Components'
import {GetDirections} from '../../../../Helpers/LocationHelper'
import I18n from '../../../../i18n'
import {ILocationSTO} from '../../../../Models/Server'
import Style, {sizes} from '../../../../Style'
import {Text} from 'react-native-elements'

interface IProps {
  location: ILocationSTO
}

const LocationTab: React.FC<IProps> = ({location}) => {
  const {textNote} = Style
  return (
    <>
      <Content>
        <FormHeader>{I18n.t('LocationDetails.Address')}</FormHeader>
        <View style={{marginBottom: sizes.padding.md}}>
          <Text style={textNote}>{I18n.t('LocationDetails.Street')}</Text>
          <Text>
            {location.StreetName} {location.StreetNumber}
          </Text>
        </View>
        <View style={{marginBottom: sizes.padding.md}}>
          <Text>{I18n.t('LocationDetails.City')}</Text>
          <Text>{location.City}</Text>
        </View>
      </Content>
      <MyFooter>
        <MyFooterTile isLast>
          <MyButton
            title={I18n.t('LocationDetails.Route')}
            onPress={() => {
              GetDirections(location)
            }}
          />
        </MyFooterTile>
      </MyFooter>
    </>
  )
}

export default LocationTab
