import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {
  Container,
  Content,
  FormInfo,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import MyInput from '../../../Components/Form/MyInput'
import {StockImageLayout} from '../../../Components/Web'
import AppConfig from '../../../Config/AppConfig'
import {MainScreensList} from '../../../Navigation'
import i18n from '../../../i18n'
import Store, {MyFormData} from './Store'

const store = new Store()

interface IProps extends StackScreenProps<MainScreensList, 'login'> {}

const Login: React.FC<IProps> = ({navigation: {navigate}, route: {params}}) => {
  const {control, handleSubmit, errors, setError, setValue, formState} =
    useForm<MyFormData>({mode: 'onChange'})

  useEffect(() => {
    store.Initialize()

    if (AppConfig.IsDebugMode) {
      //setValue('email', 'r.de.ruijter@mybility.nl')
      setValue('email', 'superdopey@live.nl')
    }

    //return linkingStore.Initialize()
  }, [store])

  const {IsSubmitting} = store

  const onSubmit = (data: MyFormData) => {
    store.ForgotPassword(data.email)
  }

  return (
    <>
      <Container>
        <StockImageLayout>
          <Content headerMargin>
            <FormInfo>{i18n.t('ForgotPassword.Info')}</FormInfo>
            <MyInput
              formObject={{} as MyFormData}
              name="email"
              control={control}
              inputProps={{
                autoCapitalize: 'none',
                keyboardType: 'email-address',
                autoCompleteType: 'off',
              }}
              validation={{
                errors,
                rules: {
                  required: i18n.t(
                    'ForgotPassword.Form.EmailField.Errors.Required',
                  ),
                },
              }}
              label={i18n.t('ForgotPassword.Form.EmailField.Label')}
            />
          </Content>
          <SubmitHeader onBackPress={() => navigate('login')}>
            <SubmitHeaderButton
              disabled={!formState.isValid}
              isLoading={IsSubmitting}
              title={i18n.t('ForgotPassword.Form.Action')}
              onPress={handleSubmit(onSubmit)}
            />
          </SubmitHeader>
        </StockImageLayout>
      </Container>
    </>
  )
}

export default observer(Login)
