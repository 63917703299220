import {Badge, Icon, ListItem, Text} from 'react-native-elements'
import React from 'react'
import {View} from 'react-native'
import {FormatDate, FormatDateTime} from '../../../../Helpers/DateHelpers'
import {FormatCurrency} from '../../../../Helpers/TextHelpers'
import {
  ITransactionSTO,
  IUserCouponSummarySTO,
  IUserSavingCardSTO,
} from '../../../../Models/Server'
import Style, {colors, sizes} from '../../../../Style'
import i18n from '../../../../i18n'
import {navigate} from '../../../../Helpers/NavigationHelper'
interface IProps {
  userSavingCard: IUserSavingCardSTO
}

const UserSavingCardRow: React.FC<IProps> = ({userSavingCard}) => {
  const {noteText, badgeStyle} = Style

  const getIconAndText = () => {
    //is complete?
    if (userSavingCard.Processed !== null) {
      return (
        <>
          <Icon
            type="font-awesome"
            name="check"
            iconStyle={{marginRight: sizes.padding.sm, color: 'green'}}
          />
          <Text style={noteText}>{i18n.t('Loyalty.Status.Completed')}</Text>
        </>
      )
    }

    const now = new Date(new Date().toISOString())
    const validFrom = userSavingCard.SavingCard.ValidFrom
      ? new Date(userSavingCard.SavingCard.ValidFrom)
      : null
    const validTill = userSavingCard.SavingCard.ValidTill
      ? new Date(userSavingCard.SavingCard.ValidTill)
      : null
    if (validFrom === null || (validFrom && validFrom < now)) {
      if (validTill === null || (validTill && validTill > now)) {
        return (
          <>
            <Icon
              type="font-awesome"
              name="exchange"
              iconStyle={{marginRight: sizes.padding.sm}}
            />
            <Text style={noteText}>{i18n.t('Loyalty.Status.Active')}</Text>
          </>
        )
      }

      if (validTill && validTill < now) {
        return (
          <>
            <Icon
              type="font-awesome"
              name="ban"
              iconStyle={{marginRight: sizes.padding.sm, color: 'red'}}
            />
            <Text style={noteText}>{i18n.t('Loyalty.Status.Expired')}</Text>
          </>
        )
      }
    }

    return (
      <>
        <Icon
          type="font-awesome"
          name="clock"
          iconStyle={{marginRight: sizes.padding.sm}}
        />
        <Text style={noteText}>{i18n.t('Loyalty.Status.NotActive')}</Text>
      </>
    )
  }

  /*
  const renderRedeemedRow = () => {
    if (userCouponSummary.Redeemed === null) {
      return null
    }

    return (
      <View style={{flexDirection: 'row', paddingTop: sizes.padding.sm}}>
        <View style={{paddingRight: sizes.padding.md, flex: 1}}>
          <Text>{i18n.t('Common.Redeemed')}</Text>
          <Text style={noteText}>
            {FormatDateTime(userCouponSummary.Redeemed as string)}
          </Text>
        </View>
        <View style={{paddingRight: sizes.padding.md, flex: 1}}>
          <Text>{i18n.t('Common.Location')}</Text>
          <Text style={noteText}>{userCouponSummary.RedeemedLocation}</Text>
        </View>
      </View>
    )
  }
*/
  return (
    <ListItem
      bottomDivider
      onPress={() =>
        navigate('savingCardDetails', {
          userSavingCardGuid: userSavingCard.UserSavingCardGuid,
        })
      }>
      <View style={{flex: 1}}>
        <View style={{flexDirection: 'row'}}>
          <View style={{paddingRight: sizes.padding.md, flex: 1}}>
            <Text>{i18n.t('Common.Received')}</Text>
            <Text style={noteText}>
              {FormatDate(userSavingCard.Created as string)}
            </Text>
          </View>
          <View style={{paddingRight: sizes.padding.md, flex: 1}}>
            <Text>{i18n.t('Common.Status')}</Text>
            <View style={{flexDirection: 'row'}}>{getIconAndText()}</View>
          </View>
        </View>
        <View style={{alignItems: 'flex-start'}}>
          <Text>{i18n.t('Common.Stamps')}</Text>
          <Badge
            value={`${userSavingCard.StampCount}/${userSavingCard.SavingCard.StampCount}`}
            badgeStyle={[badgeStyle, {marginTop: sizes.padding.sm}]}
            //textStyle={{fontSize: 24}}
          />
        </View>
      </View>

      <ListItem.Chevron />
    </ListItem>
  )
}

export default UserSavingCardRow
