import {makeAutoObservable, runInAction} from 'mobx'
import {goBack} from '../../../Helpers/NavigationHelper'
import {GetI18NAlertMessage} from '../../../Helpers/UiHelpers'
import i18n from '../../../i18n'
import {appUiStore} from '../../../Mobx/AppUiStore'
import ToprError from '../../../Models/Client/ErrorHandling/ToprError'
import UserController from '../../../Services/Topr.Core/UserController'

export default class Store {
  public IsSubmitting = false
  private RequestIdentifier: string = ''
  constructor() {
    makeAutoObservable(this)
  }

  Initialize(requestIdentifier: string) {
    this.IsSubmitting = false
    this.RequestIdentifier = requestIdentifier
  }

  ResetPassword(password: string) {
    this.IsSubmitting = true
    UserController.ResetPassword(this.RequestIdentifier, password)
      .then(() => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowToast({message: i18n.t('ResetPassword.Form.Succes')})
        goBack()
      })
      .catch((toprError: ToprError) => {
        const alert = GetI18NAlertMessage(
          'ResetPassword.Form.ServerErrors',
          toprError.Code,
        )

        appUiStore.ShowAlert(alert)
        runInAction(() => (this.IsSubmitting = false))
      })
  }
}
export const resetPasswordStore = new Store()
