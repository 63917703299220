import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import {TableQrObject} from '../../../Models/Client'
import {
  IGroupedCoupon,
  IUserCouponSTO,
  IUserEventSto,
  IUserFieldSTO,
  IUserSavingCardSTO,
  IUserSTO,
  IWalletSTO,
} from '../../../Models/Server'
import {IUserSubscriptionSto} from '../../../Models/Server/ConsumerSubscription/IUserSubscriptionSto'
import CouponController from '../../../Services/Topr.Core/CouponController'
import EventController from '../../../Services/Topr.Core/EventController'
import SavingcardController from '../../../Services/Topr.Core/SavingcardController'
import SubscriptionController from '../../../Services/Topr.Core/SubscriptionController'
import UserController from '../../../Services/Topr.Core/UserController'
import WalletController from '../../../Services/Topr.Core/WalletController'
import UmbracoController from '../../../Services/Topr.Umbraco'
import {ISystemMessageViewModel} from '../../../Services/Topr.Umbraco/Models'
import {store as tokenStore} from '../../Token/Tokens/Store'

export default class Store {
  public IsAuthenticationMethodCheckLoading = true
  public IsWalletLoading = true
  public IsCouponsLoading = true
  public IsSavingcardsLoading = true
  public IsUserLoading = true
  public IsUserMenuVisible = false
  public IsAddMenuVisible = false

  public Wallets: IWalletSTO[] = []
  public UserCoupons: IUserCouponSTO[] = []
  public UserSavingCards: IUserSavingCardSTO[] = []
  public UserSubscriptions: IUserSubscriptionSto[] = []
  public IsUserSubscriptionsLoading = false

  public UserEvents: IUserEventSto[] = []
  public IsUserEventsLoading = false

  public User?: IUserSTO = undefined
  public UserFields: IUserFieldSTO[] = []
  public SystemMessages: ISystemMessageViewModel[] = []

  public get GroupedCoupons(): IGroupedCoupon[] {
    return this.UserCoupons.reduce(
      (groupedCoupons: IGroupedCoupon[], userCoupon: IUserCouponSTO) => {
        const index = groupedCoupons.findIndex(
          (x) => x.Coupon.Guid === userCoupon.Coupon.Guid,
        )
        if (index === -1) {
          const newCouponGrouping: IGroupedCoupon = {
            Coupon: userCoupon.Coupon,
            UserCoupons: [userCoupon],
          }
          groupedCoupons.push(newCouponGrouping)
        } else {
          groupedCoupons[index].UserCoupons.push(userCoupon)
        }

        return groupedCoupons
      },
      [],
    )
  }

  constructor() {
    //makeAutoObservable(this)
    makeObservable(this, {
      IsAuthenticationMethodCheckLoading: observable,
      IsWalletLoading: observable,
      IsCouponsLoading: observable,
      IsSavingcardsLoading: observable,
      IsUserLoading: observable,
      IsUserMenuVisible: observable,
      IsAddMenuVisible: observable,
      Wallets: observable,
      UserCoupons: observable,
      UserSavingCards: observable,
      User: observable,
      UserFields: observable,
      UserSubscriptions: observable,
      IsUserSubscriptionsLoading: observable,
      UserEvents: observable,
      IsUserEventsLoading: observable,
      GroupedCoupons: computed,
      Initialize: action,
      SetIsAuthenticationMethodCheckLoading: action,
      GetWallets: action,
      GetUserCoupons: action,
      GetUserSavingCards: action,
      GetUser: action,
      ToggleUserMenu: action,
      ToggleAddMenu: action,
      GetUserSubscriptions: action,
      GetUserEvents: action,
    })
  }

  public Initialize() {
    this.GetUser()
    this.GetWallets()
    this.GetUserCoupons()
    this.GetUserSavingCards()
    this.GetUserSubscriptions()
    this.GetUserEvents()
    tokenStore.GetTokens()

    //example table qr json
    // const test = new TableQrObject('2', 'VSAM7A76231E41')
    // console.log(JSON.stringify(test))
  }

  public SetIsAuthenticationMethodCheckLoading(value: boolean) {
    this.IsAuthenticationMethodCheckLoading = value
  }

  private GetActiveSystemMessages() {
    this.SystemMessages = []
    UmbracoController.GetActiveSystemMessages()
      .then((messages) => {
        this.SystemMessages = messages
      })
      .catch(() => {})
  }

  public GetWallets() {
    this.Wallets = []
    this.IsWalletLoading = true
    this.GetActiveSystemMessages()

    WalletController.GetWallets()
      .then((wallets) => {
        runInAction(() => {
          this.Wallets = wallets.slice().sort((a, b) => {
            if (a.Modified === null || a.Modified === undefined) {
              return 1
            }

            if (b.Modified === null || b.Modified === undefined) {
              return -1
            }

            if (a.Modified > b.Modified) {
              return -1
            } else if (a.Modified < b.Modified) {
              return 1
            }

            return 0
          })

          this.IsWalletLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsWalletLoading = false
        })
      })
  }

  public GetUserCoupons() {
    this.UserCoupons = []
    this.IsCouponsLoading = true

    CouponController.RedeemableUserCoupons()
      .then((userCoupons) => {
        runInAction(() => {
          this.UserCoupons = userCoupons.slice().sort((a, b) => {
            if (a.Created < b.Created) {
              return 1
            }
            if (a.Created > b.Created) {
              return -1
            }
            return 0
          })
          this.UserCoupons = userCoupons
          this.IsCouponsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsCouponsLoading = false
        })
      })
  }

  public GetUserSavingCards() {
    this.IsSavingcardsLoading = true
    this.UserSavingCards = []
    SavingcardController.ActiveUserSavingCards()
      .then((userSavingCards) => {
        runInAction(() => {
          //workaround :only display active cards. Must be changed on server
          this.UserSavingCards = userSavingCards.filter(
            (x) => x.StampCount < x.SavingCard.StampCount,
          )
          this.IsSavingcardsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsSavingcardsLoading = false
        })
      })
  }

  public GetUserSubscriptions() {
    this.UserSubscriptions = []

    this.IsSavingcardsLoading = true
    SubscriptionController.GetUserSubscriptions()
      .then((usersubscriptions) => {
        runInAction(() => {
          //workaround :only display active cards. Must be changed on server
          this.UserSubscriptions = usersubscriptions
          this.IsUserSubscriptionsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsUserSubscriptionsLoading = false
        })
      })
  }

  public GetUserEvents() {
    this.IsUserEventsLoading = true
    this.UserEvents = []
    EventController.GetUserEvents()
      .then((userEvents) => {
        runInAction(() => {
          this.UserEvents = userEvents
          this.IsUserEventsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsUserEventsLoading = false
        })
      })
  }

  public GetUser() {
    this.IsUserLoading = true
    this.User = undefined
    UserController.GetConsumerUser()
      .then((user) => {
        runInAction(() => {
          this.User = user
          this.IsUserLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsUserLoading = false
        })
      })
  }

  public ToggleUserMenu(isVisible: boolean) {
    this.IsUserMenuVisible = isVisible
  }

  public ToggleAddMenu(isVisible: boolean) {
    this.IsAddMenuVisible = isVisible
  }
}

export const dashboardStore = new Store()
