import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {ITokenSTO} from '../../Models/Server'
import {axiosAuthUser} from './Axios/AxiosAuthUser'
import {FixGuid} from '../../Helpers/TextHelpers'

export default {
  GetTokens: () => {
    return new Promise<ITokenSTO[]>((resolve, reject) => {
      const url = `api/v1/tokens/consumer`
      axiosAuthUser
        .get(url)
        .then((response: AxiosResponse<ITokenSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },

  ActivateWithCode: (activationCode: string) => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/token/activate/code/${activationCode}`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  ActivateUid: (uid: string) => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/token/activate/uid/${uid}`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  SetDescription: (tokenGuid: string, description: string) => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/token/description/${tokenGuid}`, description)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  Block: (tokenGuid: string) => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/token/${tokenGuid}/block`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  Unblock: (tokenGuid: string) => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/token/${tokenGuid}/unblock`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  SwitchTokenUser: (tokenGuid: string, userGuid: string) => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/token/switch/${tokenGuid}/${userGuid}`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
