import {makeAutoObservable, runInAction} from 'mobx'
import {DoServiceCall, SetFriendlyMessage} from '../../Helpers/ServiceHelpers'
import {GetI18NAlertMessage, GetRandStockImage} from '../../Helpers/UiHelpers'
import i18n from '../../i18n'
import {appUiStore} from '../../Mobx/AppUiStore'
import {IAlertMessage, MyErrorMessage} from '../../Models/Client'
import ToprError from '../../Models/Client/ErrorHandling/ToprError'
import {myLog} from '../../Services/Client/LogService'
import RegisterController from '../../Services/Topr.Core/RegisterController'

export type RegisterFormData = {
  name: string
  email: string
  password: string
  acceptTerms: boolean
}

const initialForm: RegisterFormData = {
  name: '',
  email: '',
  password: '',
  acceptTerms: false,
}

class Store {
  public IsSubmitting = false
  public ConfirmationSent = false
  public Email?: string = undefined
  public FormData: RegisterFormData = initialForm
  public IsConfirming = false

  public ResultMessage?: IAlertMessage = undefined

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize() {
    this.InitialState()
  }

  public InitialState() {
    this.IsSubmitting = false
    this.Email = undefined
    this.ConfirmationSent = false
    this.FormData = initialForm
    this.IsConfirming = true
  }

  public SetFormData<FormKey extends keyof RegisterFormData>(
    key: FormKey,
    value: RegisterFormData[FormKey],
  ) {
    this.FormData[key] = value
  }

  public CheckEmail(
    navigate: () => void,
    onError: (errorMessage: string) => void,
  ) {
    DoServiceCall(() => {
      this.IsSubmitting = true

      RegisterController.EmailExists(this.FormData.email!)
        .then(() => {
          runInAction(() => {
            this.IsSubmitting = false
          })
          navigate()
        })
        .catch((toprError: ToprError) => {
          SetFriendlyMessage(toprError, onError, [
            new MyErrorMessage(
              'USER_EMAIL_NOT_UNIQUE',
              'Register.Form.ServerErrors.Title',
              'Register.Form.ServerErrors.USER_EMAIL_NOT_UNIQUE',
              'warning',
              {
                email: this.FormData.email!,
              },
            ),
          ])

          runInAction(() => {
            this.IsSubmitting = false
          })
        })
    })
  }

  public RegisterConsumer(onError: (errorMessage: string) => void) {
    //myLog.info(this.FormData)

    DoServiceCall(() => {
      myLog.info('register', this.FormData)
      this.IsSubmitting = true

      //test
      // setTimeout(() => {
      //   runInAction(() => {
      //     console.log('dummy submit')
      //     this.IsSubmitting = false
      //     this.ConfirmationSent = true
      //   })
      // }, 100)

      RegisterController.RegisterConsumer(
        this.FormData.name,
        this.FormData.email,
        this.FormData.password,
        this.FormData.password,
      )
        .then(() => {
          //this.InitialState()
          runInAction(() => {
            this.IsSubmitting = false
            this.ConfirmationSent = true
            //navigateConfirmationSent()
          })
        })
        .catch((toprError: ToprError) => {
          SetFriendlyMessage(toprError, onError, [
            new MyErrorMessage(
              'USER_EMAIL_NOT_UNIQUE',
              'Register.Form.ServerErrors.Title',
              'Register.Form.ServerErrors.USER_EMAIL_NOT_UNIQUE',
              'warning',
              {
                email: this.FormData.email!,
              },
            ),
          ])
          runInAction(() => {
            this.IsSubmitting = false
          })
        })
    })
  }

  //confirm

  public InitializeConfirm(userGuid: string) {
    this.InitialState()

    this.RegisterConfirmConsumer(userGuid)

    // setTimeout(() => {
    //   runInAction(() => {
    //     this.IsConfirming = false
    //     this.IsConfirmSucces = true
    //   })
    // }, 1000)
  }

  public RegisterConfirmConsumer(userGuid: string) {
    DoServiceCall(() => {
      //2) valid email, do the actual register now
      RegisterController.RegisterConfirmConsumer(userGuid)
        .then(() => {
          runInAction(() => {
            this.IsConfirming = false
            this.ResultMessage = {
              title: i18n.t('RegisterConfirm.Header'),
              message: i18n.t('RegisterConfirm.ConfirmSuccess'),
            }
          })
        })
        .catch((toprError: ToprError) => {
          runInAction(() => {
            this.IsConfirming = false
            this.ResultMessage = GetI18NAlertMessage(
              'RegisterConfirm.ServerErrors',
              toprError.Code,
            )
          })
        })
    })
  }
}

export const store = new Store()
