import {TokenStates} from '../Models/Client/Enums'
import {ITokenSTO} from '../Models/Server/Token/ITokenSTO'

export const GetTokenState = (token: ITokenSTO): TokenStates => {
  if (token.IsBlocked) {
    return TokenStates.Blocked
  }

  if (new Date(token.ValidThru) < new Date()) {
    return TokenStates.Expired
  }

  return TokenStates.Active
}

const compareDateDesc = (a: string | Date | null, b: string | Date | null) => {
  if (a === null || b === null) {
    return -1
  }

  if (a < b) {
    return 1
  }
  if (a > b) {
    return -1
  }
  return 0
}

export const sortTokens = (tokens: ITokenSTO[]) => {
  const sortedTokens = tokens.slice().sort((a, b) => {
    const stateA = GetTokenState(a)
    const stateB = GetTokenState(b)

    //first sort by state then by activated
    return stateA - stateB || compareDateDesc(a.Activated, b.Activated)
    // return a.city.localeCompare(b.city) || b.price - a.price;
  })
  return sortedTokens
}
