import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {FixGuid} from '../../Helpers/TextHelpers'
import {ISavingCardSummarySTO, IUserSavingCardSTO} from '../../Models/Server'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  ActiveUserSavingCards: () => {
    return new Promise<IUserSavingCardSTO[]>((resolve, reject) => {
      const url = 'api/v1/usersavingcards/active/consumer'
      axiosAuthUser
        .get(url)
        .then((response: AxiosResponse<IUserSavingCardSTO[]>) => {
          if (response.data) {
            response.data.forEach((userSavingCard) => {
              userSavingCard.UserSavingCardGuid = FixGuid(
                userSavingCard.UserSavingCardGuid,
              )
            })
          }
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetUserSavingCard: (userSavingCardGuid: string) => {
    return new Promise<IUserSavingCardSTO>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/usersavingcard/${userSavingCardGuid}`)
        .then((response: AxiosResponse<IUserSavingCardSTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetUserSavingCards: (savingCardGuid: string, skip: number, take: number) => {
    return new Promise<IUserSavingCardSTO[]>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/usersavingcards/${savingCardGuid}/${skip}/${take}`)
        .then((response: AxiosResponse<IUserSavingCardSTO[]>) => {
          if (response.data) {
            response.data.forEach((userSavingCard) => {
              userSavingCard.UserSavingCardGuid = FixGuid(
                userSavingCard.UserSavingCardGuid,
              )
            })
          }
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetSavingCardSummaries: () => {
    return new Promise<ISavingCardSummarySTO[]>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/savingcards/summary`)
        .then((response: AxiosResponse<ISavingCardSummarySTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
