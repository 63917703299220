import React from 'react'
import {View, StyleSheet} from 'react-native'

interface IProps {}

const TakePicture: React.FC<IProps> = ({children}) => {
  return <View>{children}</View>
}

export default TakePicture

const styles = StyleSheet.create({})

// import React, {useState, useEffect} from 'react'
// import {Button, Image, View, Platform} from 'react-native'
// import * as ImagePicker from 'expo-image-picker'
// import Constants from 'expo-constants'
// import {
//   Container,
//   Content,
//   MyButton,
//   MyFooter,
//   MyFooterTile,
//   SubmitHeader,
//   SubmitHeaderButton,
// } from '../../../Components'
// import {goBack} from '../../../Helpers/NavigationHelper'
// import i18n from '../../../i18n'
// import Style, {deviceWidth, sizes} from '../../../Style'
// import {changeProfilePictureStore, imagePickerOptions} from './Store'
// import {observer} from 'mobx-react'
// import {ImageInfo} from 'expo-image-picker/build/ImagePicker.types'
// import {appUiStore} from '../../../Mobx/AppUiStore'

// interface IProps {}

// const TakePicture: React.FC<IProps> = ({}) => {
//   const [imageInfo, setImage] = useState<ImageInfo | undefined>(undefined)
//   const {IsSubmitting} = changeProfilePictureStore
//   const {centerXYContainer} = Style
//   useEffect(() => {
//     ;(async () => {
//       if (Platform.OS !== 'web') {
//         const {status} = await ImagePicker.requestCameraPermissionsAsync()
//         if (status !== 'granted') {
//           appUiStore.ShowToast({
//             message: i18n.t('ChangeProfilePicture.Permissions.Camera'),
//           })

//           return
//         }

//         pickImage()
//       }
//     })()
//   }, [])

//   const pickImage = async () => {
//     let result = await ImagePicker.launchCameraAsync(imagePickerOptions)

//     if (!result.cancelled) {
//       setImage(result as ImageInfo)
//     }
//   }

//   if (imageInfo === undefined) {
//     return null
//   }

//   return (
//     <>
//       <Container>
//         <View style={[centerXYContainer, {marginTop: 60}]}>
//           {imageInfo !== undefined && (
//             <Image
//               source={{uri: imageInfo.uri}}
//               style={{
//                 width: deviceWidth,
//                 height: deviceWidth,
//                 marginBottom: sizes.padding.md,
//               }}
//             />
//           )}
//           <MyButton
//             title={i18n.t('ChangeProfilePicture.Form.Again')}
//             onPress={pickImage}
//             type="outline"
//           />
//         </View>
//       </Container>
//       <SubmitHeader
//         title={i18n.t('ChangeProfilePicture.TakePictureHeader')}
//         onBackPress={goBack}>
//         <SubmitHeaderButton
//           disabled={imageInfo === undefined}
//           isLoading={IsSubmitting}
//           title={i18n.t('ChangeProfilePicture.Form.Action')}
//           onPress={() => {
//             if (imageInfo) {
//               changeProfilePictureStore.SavePicture(imageInfo.base64!)
//             }
//           }}
//         />
//       </SubmitHeader>
//     </>
//   )
// }

// //export default ImagePickerExample
// export default observer(TakePicture)
