import React from 'react'
import {ScrollView, View, ViewProps, ViewStyle} from 'react-native'
import Style, {deviceHeight} from '../../Style'
interface IProps extends ViewProps {
  padder?: boolean
  headerMargin?: boolean
  style?: ViewStyle
}

const Content: React.FC<IProps> = ({
  children,
  padder,
  style,
  headerMargin,
  ...otherProp
}) => {
  const {content, containerBg} = Style

  const styles: ViewStyle[] = [content]

  if (!padder) {
    styles.push({paddingHorizontal: 0, paddingTop: 0})
  }

  if (headerMargin) {
    styles.push({marginTop: 60})
  }

  if (style !== undefined) {
    styles.push(style)
  }

  //styles.push({backgroundColor: 'green'})

  return (
    <View {...otherProp} style={styles}>
      {children}
    </View>
  )
  //return <ScrollView style={styles}>{children}</ScrollView>
}

export default Content

Content.defaultProps = {
  padder: true,
  headerMargin: false,
}
