import React from 'react'
import {View} from 'react-native'
import {Card, Text} from 'react-native-elements'
import I18n from '../../i18n'
import Style from '../../Style'
import MyButton from '../Buttons/MyButton'
import MyFooter from '../Form/MyFooter'
import MyFooterTile from '../Form/MyFooterTile'
import MyModal from './MyModal'
// import Style from '../../Styles/Style'
// import ToprButton from '../Topr/ToprButton'
// import ToprModal from './ToprModal'
import {observer} from 'mobx-react'
import {appUiStore} from '../../Mobx/AppUiStore'
import MyAlertIcon from '../Form/MyAlertIcon'

interface IProps {
  //alert?: IAlertMessage
  //closeClicked: () => void
}

const AlertModal: React.FC<IProps> = () => {
  const {AlertMessage} = appUiStore

  if (AlertMessage === undefined) {
    return null
  }

  const {alignItemsCenter, textCenter} = Style

  const close = () => {
    appUiStore.CloseAlert()
    if (AlertMessage.additionalCloseAction) {
      AlertMessage.additionalCloseAction()
    }
  }

  return (
    <MyModal onClose={close} closeOnBackPress visible={alert !== undefined}>
      <Card.Title>{AlertMessage.title}</Card.Title>
      <View style={alignItemsCenter}>
        <MyAlertIcon type={AlertMessage.type} />
        <Text style={textCenter}>{AlertMessage.message}</Text>
      </View>
      <MyFooter>
        <MyFooterTile isLast>
          <MyButton title={I18n.t('Common.Ok')} onPress={close} />
        </MyFooterTile>
      </MyFooter>
    </MyModal>
  )
}

export default observer(AlertModal)
