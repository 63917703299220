import {StackScreenProps} from '@react-navigation/stack'
import 'bulma/sass/grid/columns.sass'
import 'bulma/sass/helpers/visibility.sass'
import 'bulma/sass/utilities/_all.sass'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {
  Container,
  Content,
  FormFooter,
  FormHeader,
  LinkButton,
  MyButton,
  SubmitHeader,
  VersionLabel,
} from '../../Components'
import MyInput from '../../Components/Form/MyInput'
import {StockImageLayout} from '../../Components/Web'
import AppConfig from '../../Config/AppConfig'
import i18n from '../../i18n'
import {MainScreensListWeb} from '../../Navigation/index.web'
import {linkingStore} from '../../Navigation/LinkingStore'
import {sizes} from '../../Style/index'
import '../../Style/style.web.scss'
import Store, {MyFormData} from './Store.web'

const store = new Store()

interface IProps extends StackScreenProps<MainScreensListWeb, 'login'> {}

const Login: React.FC<IProps> = ({
  navigation: {navigate, goBack},
  route: {params},
}) => {
  const {control, handleSubmit, errors, setError, setValue} =
    useForm<MyFormData>()

  useEffect(() => {
    if (AppConfig.IsDebugMode) {
      //setValue('email', 'r.de.ruijter@mybility.nl')
      setValue('email', 'superdopey@live.nl')
      //setValue('email', 'jordi@sctelstar.nl') //veel kind portemonnee's
      setValue('password', '')

      // const appLink = GetAppLink('http://localhost:19006/register/confirm/hmmm')
      // console.log('applink', appLink)
    }

    return linkingStore.Initialize()
  }, [store])

  const {IsSubmitting} = store

  const onSubmit = (data: MyFormData) => {
    store.AuthenticateConsumerUser(data, (errorMessage: string) => {
      setError('password', {
        type: 'manual',
        message: errorMessage,
      })
    })
  }

  return (
    <>
      <Container>
        <StockImageLayout>
          <Content headerMargin>
            <FormHeader>{i18n.t('Login.Header')}</FormHeader>
            <MyInput
              formObject={{} as MyFormData}
              name="email"
              control={control}
              inputProps={{
                autoCapitalize: 'none',
                keyboardType: 'email-address',
              }}
              autofocus={true}
              validation={{
                errors,
                rules: {
                  required: i18n.t('Login.Form.Fields.Email.Errors.Required'),
                },
              }}
              label={i18n.t('Login.Form.Fields.Email.Label')}
            />
            <MyInput
              formObject={{} as MyFormData}
              name="password"
              label={i18n.t('Login.Form.Fields.Password.Label')}
              control={control}
              inputProps={{
                secureTextEntry: true,
                autoCapitalize: 'none',
                onSubmitEditing: () => handleSubmit((data) => onSubmit(data))(),
              }}
              validation={{
                errors,
                rules: {
                  required: i18n.t(
                    'Login.Form.Fields.Password.Errors.Required',
                  ),
                },
              }}
            />
            <FormFooter>
              <MyButton
                isLoading={IsSubmitting}
                title={i18n.t('Login.Form.Action')}
                onPress={handleSubmit(onSubmit)}
              />
              <LinkButton
                text={i18n.t('Login.ForgotPassword')}
                onPress={() => navigate('forgotPassword')}
                style={{marginVertical: sizes.padding.md}}
              />
              <LinkButton
                text={i18n.t('Login.CreateAccount')}
                onPress={() => navigate('register')}
              />
            </FormFooter>
            <VersionLabel />
          </Content>
          <SubmitHeader onBackPress={() => navigate('home')} />
        </StockImageLayout>
      </Container>
    </>
  )
}

export default observer(Login)
