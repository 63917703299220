import axios, {AxiosError, AxiosResponse} from 'axios'
import AppConfig from '../../Config/AppConfig'
import {HandleInziiCatch} from '../../Helpers/ServiceHelpers'
import {IInzziUser, IOrderHistory, IOrderResult} from '../../Models/InzziiApi'
import {IOrder} from '../../Models/InzziiApi/IOrder'
import {IUserSTO} from '../../Models/Server'

export default {
  ToprUser: (toprUser: IUserSTO) => {
    return new Promise<IInzziUser>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/User/ToprUser`
      //inzzii api gives error on emails with 1 letter like r.de.ruijter@mybility.nl

      //hack
      let firstPart = toprUser.EmailAddress.split('@')[0]
      const secondPart = toprUser.EmailAddress.split('@')[1]

      if (firstPart.indexOf('.') > -1) {
        firstPart = firstPart.split('.').join('')
      }
      const email = `${firstPart}@${secondPart}`

      const data = {
        emailAddress: email,
        password: '12345',
        source: 'Topr',
        firstName: toprUser.Name,
        lastName: toprUser.Name,
        phoneNumber: '0',
        country: 'nl',
      }

      axios
        .post(url, data)
        .then((response: AxiosResponse<IInzziUser>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          console.log(
            'axios error',
            error.response?.status,
            error.response?.statusText,
            error.message,
          )
          HandleInziiCatch(error, reject)
        })
    })
  },
  GetOrderHistory: (accessToken: string) => {
    return new Promise<IOrderHistory[]>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/Order/OrderHistory`

      const bearerAuthorization = `Bearer ${accessToken}`
      const headers = {
        'Content-type': 'application/json',
        Authorization: bearerAuthorization,
      }

      axios
        .get(url, {
          headers: headers,
        })
        .then((response: AxiosResponse<IOrderHistory[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleInziiCatch(error, reject)
        })
    })
  },
  GetOrder: (accessToken: string, orderId: string) => {
    return new Promise<IOrder>((resolve, reject) => {
      const url = `${AppConfig.Service.BaseUrl}inzzii/Order/Order/${orderId}`

      const bearerAuthorization = `Bearer ${accessToken}`
      const headers = {
        'Content-type': 'application/json',
        Authorization: bearerAuthorization,
      }

      axios
        .get(url, {
          headers: headers,
        })
        .then((response: AxiosResponse<IOrder>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleInziiCatch(error, reject)
        })
    })
  },
}
