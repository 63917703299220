import {makeAutoObservable, runInAction} from 'mobx'
import {goBack} from '../../../Helpers/NavigationHelper'
import {DoServiceCall} from '../../../Helpers/ServiceHelpers'
import {IUserCouponSTO, IUserSavingCardSTO} from '../../../Models/Server'
import SavingcardController from '../../../Services/Topr.Core/SavingcardController'

export default class Store {
  public IsLoading = true

  public UserSavingCard?: IUserSavingCardSTO = undefined

  constructor() {
    makeAutoObservable(this)
  }

  public GetUserSavingCard(userSavingCardGuid: string) {
    this.IsLoading = true
    SavingcardController.GetUserSavingCard(userSavingCardGuid)
      .then((userSavingCard) => {
        runInAction(() => {
          this.UserSavingCard = userSavingCard
          this.IsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }
}
export const savingCardDetailsStore = new Store()
