import 'bulma/css/bulma.min.css'
import {observer} from 'mobx-react'
import * as React from 'react'
import {useEffect, useRef} from 'react'
import {AppState, AppStateStatus, View} from 'react-native'
import {AddAccountModal, SystemMessageCard} from '../../../Components'
import ProfileButtonEmail from '../../../Components/Buttons/ProfileButtonEmail'
import {LeftColumnLayout} from '../../../Components/Web'
import {navigate} from '../../../Helpers/NavigationHelper'
import i18n from '../../../i18n'
import {linkingStore} from '../../../Navigation/LinkingStore'
import {myLog} from '../../../Services/Client/LogService'
import '../../../Style/style.web.scss'
import {AddMenu} from '../Components'
import ChildUsersModal from '../Components/UserMenu/ChildUsersModal'
import {userMenuStore} from '../Components/UserMenu/Store'
import UserMenu from '../Components/UserMenu/UserMenu'
import CouponsScroll from '../Components/Web/CouponsScroll.web'
import SavingCardsScroll from '../Components/Web/SavingCardsScroll.web'
import SideMenu from '../Components/Web/SideMenu.web'
import WalletsScroll from '../Components/Web/WalletsScroll.web'
import {dashboardStore} from './Store'

//interface IProps extends StackScreenProps<MainScreensList, 'dashboard'> {}
import {createStackNavigator} from '@react-navigation/stack'
import Main from './Screens/Main'
import Help from './Screens/Help'
import {GetTenantPagetitle} from '../../../Helpers/TextHelpers'

export type DashboardScreensList = {
  main: undefined
  help: undefined
}

const Stack = createStackNavigator<DashboardScreensList>()
const DashboardWeb: React.FC = () => {
  const appState = useRef(AppState.currentState)

  const _handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      dashboardStore.Initialize()
    }

    appState.current = nextAppState
  }
  useEffect(() => {
    myLog.warn('Dashboard web')

    AppState.addEventListener('change', _handleAppStateChange)
    dashboardStore.Initialize()
    return linkingStore.Initialize()
  }, [dashboardStore])

  const {User, IsUserMenuVisible, IsAddMenuVisible, SystemMessages} =
    dashboardStore

  return (
    <>
      <LeftColumnLayout
        title={i18n.t('Common.Dashboard')}
        leftContentCenter={<SideMenu />}
        leftContentBottom={
          <>
            <ProfileButtonEmail
              user={User}
              onPress={() => {
                dashboardStore.ToggleUserMenu(true)
              }}
            />
          </>
        }>
        <Stack.Navigator
          headerMode="none"
          screenOptions={{cardStyle: {backgroundColor: '#fff'}}}>
          <Stack.Screen
            name="main"
            component={Main}
            options={{title: GetTenantPagetitle('Dashboard')}}
          />
          <Stack.Screen
            name="help"
            component={Help}
            options={{title: GetTenantPagetitle('Help')}}
          />
        </Stack.Navigator>
      </LeftColumnLayout>

      <UserMenu
        isVisible={IsUserMenuVisible}
        onClose={() => dashboardStore.ToggleUserMenu(false)}
      />
      <AddMenu
        isVisible={IsAddMenuVisible}
        onClose={() => dashboardStore.ToggleAddMenu(false)}
      />
      <AddAccountModal />
      <ChildUsersModal
        isVisible={userMenuStore.ShowShildUsers}
        onClose={() => userMenuStore.ToggleChildMenu()}
      />
    </>
  )
}

export default observer(DashboardWeb)
