import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Card, Icon, ListItem, Text} from 'react-native-elements'
import {FormatDateTime} from '../../Helpers/DateHelpers'
import i18n from '../../i18n'
import {IUserCouponSTO} from '../../Models/Server'
import Style, {sizes, colors} from '../../Style'
import TextRow from '../Layout/TextRow'
import TransparentCardHeader from './TransparentCardHeader'
import ValidityCard from './ValidityCard'
interface IProps {
  userCoupon?: IUserCouponSTO
}

const CouponInfoCard: React.FC<IProps> = ({userCoupon}) => {
  if (userCoupon === undefined) {
    return null
  }

  return (
    <>
      <ValidityCard
        validFrom={userCoupon.ValidFrom}
        validTill={userCoupon.ValidTill}
      />
      <TransparentCardHeader>
        {i18n.t('Common.Description')}
      </TransparentCardHeader>
      <Card containerStyle={{marginTop: 0}}>
        <Text>{userCoupon.Coupon.Description}</Text>
      </Card>
    </>
  )
}

export default CouponInfoCard

const styles = StyleSheet.create({})
