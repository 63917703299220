//import Geolocation, {GeoCoordinates} from 'react-native-geolocation-service'
import getDirections from 'react-native-google-maps-directions'

import * as Location from 'expo-location'
import {LocationObject} from 'expo-location'
import {ILocationSTO} from '../Models/Server'

export const GetDirections = (location: ILocationSTO) => {
  GetCurrentLocation().then((myLocation) => {
    const data = {
      source: {
        latitude: myLocation.coords.latitude,
        longitude: myLocation.coords.longitude,
        // latitude: 51.826688,
        // longitude: 4.4302336,
      },
      destination: {
        latitude: location.Latitude,
        longitude: location.Longitude,
      },
      params: [
        {
          key: 'travelmode',
          value: 'driving', // may be "walking", "bicycling" or "transit" as well
        },
      ],
    }

    getDirections(data)
  })
}

export const GetCurrentLocation = () => {
  return new Promise<LocationObject>((resolve, reject) => {
    Location.getCurrentPositionAsync({})
      .then((location: LocationObject) => {
        resolve(location)
      })
      .catch(() => {
        // no coords, still resolve but with location of Mybility

        resolve({
          coords: {
            latitude: 51.826688,
            longitude: 4.4302336,
            accuracy: 1,
            altitude: 1,
            altitudeAccuracy: 1,
            heading: 1,
            speed: 1,
          },
          timestamp: new Date().getUTCDate(),
        })
      })
  })
}
