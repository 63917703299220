import {AxiosError, AxiosResponse} from 'axios'
import AppConfig from '../../Config/AppConfig'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {CreateAppLinkUrl} from '../../Helpers/UrlHelpers'
import {persistedStore} from '../../Mobx'
import {
  AppLinkTypes,
  FieldTypes,
  UserIdentifierTypes,
} from '../../Models/Client/Enums'
import {
  INameIdSTO,
  IRequestIdentifier,
  IUserFieldSTO,
  IUserSTO,
} from '../../Models/Server'
import {axiosAuthClient} from './Axios/AxiosAuthClient'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  GetConsumerUser: () => {
    return new Promise<IUserSTO>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/user/consumer`)
        .then((response: AxiosResponse<IUserSTO>) => {
          resolve(response.data)
        })
    })
  },
  GetUserFields: (fields: FieldTypes[]) => {
    return new Promise<IUserFieldSTO[]>((resolve, reject) => {
      const fieldsQs = fields
        .map((n, index) => `Fields=${FieldTypes[n].toString()}`)
        .join('&')

      let url = `api/v1/userfields?${fieldsQs}`

      axiosAuthUser
        .get(url)
        .then((response: AxiosResponse<IUserFieldSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  SetUserFields: (userFields: IUserFieldSTO[]) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        Fields: userFields,
      }

      axiosAuthUser
        .post('api/v1/userfields', data)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  SetName: (name: string, userGuid?: string) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        UserIdentifierType: UserIdentifierTypes.GUID,
        UserIdentifier: userGuid,
        Name: name,
      }

      axiosAuthUser
        .post('api/v1/user/name', data)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  StartChangeEmail: (email: string) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        Email: email,
        ConfirmChangeEmailUrl: CreateAppLinkUrl(
          AppLinkTypes.ConfirmChangeEmail,
        ),
      }

      axiosAuthUser
        .post('api/v1/user/email', data)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  ConfirmChangeEmail: (requestIdentifier: string) => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/user/email/confirm/${requestIdentifier}`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  ValidatePassword: (password: string, userGuid?: string) => {
    return new Promise<boolean>((resolve, reject) => {
      const data = {
        UserIdentifierType: UserIdentifierTypes.GUID,
        UserIdentifier: userGuid,
        Name: password,
      }
      axiosAuthUser
        .post(`api/v1/user/validate/password/${password}`, data)
        .then((response: AxiosResponse<boolean>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  SetPassword: (password: string, userGuid?: string) => {
    return new Promise<boolean>((resolve, reject) => {
      const data = {
        UserIdentifierType: UserIdentifierTypes.GUID,
        UserIdentifier: userGuid,
        Password: password,
      }

      axiosAuthUser
        .post(`api/v1/user/password`, data)
        .then((response: AxiosResponse<boolean>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  SaveUserPicture: (base64: string) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        PictureData: base64,
      }

      axiosAuthUser
        .post(`api/v1/userpicture`, data)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetChildUsers: () => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .get('api/v1/user/children/consumer')
        .then((response: AxiosResponse<IUserSTO[]>) => {
          persistedStore.SetChildUsers(response.data)
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  AddChildUser: (name: string) => {
    return new Promise<string>((resolve, reject) => {
      const data = {Name: name}
      axiosAuthUser
        .post(`api/v1/user/child`, data)
        .then((response: AxiosResponse<string>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  CancelChildUser: (mainUserGuid: string, childUserGuid: String) => {
    return new Promise<void>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/user/child/cancel/${mainUserGuid}/${childUserGuid}`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  Search: (search: string) => {
    return new Promise<INameIdSTO<string>>((resolve, reject) => {
      axiosAuthUser
        .post(`api/v1/user/search/${search}`, null)
        .then((response: AxiosResponse<INameIdSTO<string>>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  CreateRequestIdentifier: () => {
    return new Promise<IRequestIdentifier>((resolve, reject) => {
      axiosAuthUser
        .post('api/v1/user/requestidentifier', null)
        .then((response: AxiosResponse<IRequestIdentifier>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  ForgotPassword: (email: string) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        Email: email,
        ResetPasswordUrl: CreateAppLinkUrl(AppLinkTypes.ResetPassword),
      }
      const url = 'api/v1/user/forgotpassword'
      axiosAuthClient
        .post(url, data)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  ResetPassword: (requestIdentifier: string, password: string) => {
    return new Promise<void>((resolve, reject) => {
      const data = {
        RequestIdentifier: requestIdentifier,
        Password: password,
      }
      const url = 'api/v1/user/resetpassword'

      axiosAuthClient
        .post(`${AppConfig.Service.BaseUrl}${url}`, data)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
