import React from 'react'
import {TouchableOpacity, View, Image, ActivityIndicator} from 'react-native'
import {Card, Icon, ListItem, Text} from 'react-native-elements'
import AppConfig from '../../Config/AppConfig'

import {FormatCurrency} from '../../Helpers/TextHelpers'
import {GetLocalizedImageUrl} from '../../Helpers/UiHelpers'
import {IWalletSTO} from '../../Models/Server'
import Style, {sizes} from '../../Style'

import {colors} from '../../Style/index'
import Badge from '../Web/Badge.web'
import CardTitle from '../Web/CardTitle.web'

interface IProps {
  wallet: IWalletSTO
  onPress: () => void
}
const WalletCardWeb: React.FC<IProps> = ({wallet, onPress}) => {
  return (
    <div className="card-tile shadow" onClick={onPress}>
      <div
        className="card-img"
        style={{
          backgroundImage: `url(${GetLocalizedImageUrl(
            wallet.Currency.AppPhotoLocalizedImageGUID,
          )})`,
        }}></div>
      <div className="card-footer px-2 ">
        <Badge>{FormatCurrency(wallet.Balance, wallet.Currency)}</Badge>
        <CardTitle>{wallet.Currency.Name}</CardTitle>
        <Icon name="chevron-thin-right" type="entypo" />
      </div>
    </div>
  )
}

export default WalletCardWeb
