import {observer} from 'mobx-react'
import React from 'react'
import {View, StyleSheet} from 'react-native'
import {SystemMessageCard} from '../../../../Components'
import {} from '../../Components'
import CouponsScroll from '../../Components/Web/CouponsScroll.web'
import SavingCardsScroll from '../../Components/Web/SavingCardsScroll.web'
import WalletsScroll from '../../Components/Web/WalletsScroll.web'
import {dashboardStore} from '../Store.web'

interface IProps {}

const Main: React.FC<IProps> = ({children}) => {
  const {SystemMessages} = dashboardStore

  return (
    <>
      <div className="v-scroll">
        <SystemMessageCard systemMessages={SystemMessages} />
        <WalletsScroll />
        <CouponsScroll />
        <SavingCardsScroll />
      </div>
    </>
  )
}

export default observer(Main)
