import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Icon} from 'react-native-elements'
import {MyButton} from '../../../../Components'
import {navigate} from '../../../../Helpers/NavigationHelper'
import i18n from '../../../../i18n'
import {sizes, boldFontWeight} from '../../../../Style'
import {dashboardStore} from '../../Dashboard/Store.web'
import SideMenuItem from './SideMenuItem.web'

interface IProps {}

const SideMenu: React.FC<IProps> = ({children}) => {
  return (
    <>
      <ul>
        <SideMenuItem
          name="dashboard"
          type="materialicons"
          onClick={() => navigate('main')}>
          {i18n.t('Common.Dashboard')}
        </SideMenuItem>
        <SideMenuItem
          name="tags"
          type="font-awesome"
          onClick={() => navigate('couponsSummary')}>
          {i18n.t('Common.Coupons')}
        </SideMenuItem>
        <SideMenuItem
          name="piggy-bank"
          type="font-awesome-5"
          onClick={() => navigate('savingCardsSummary')}>
          {i18n.t('Common.SavingCards')}
        </SideMenuItem>
        <SideMenuItem
          name="credit-card"
          type="font-awesome-5"
          onClick={() => navigate('tokens')}>
          {i18n.t('Common.Cards')}
        </SideMenuItem>
        <SideMenuItem
          name="help-circle"
          type="feather"
          onClick={() => navigate('help')}>
          {i18n.t('Common.Help')}
        </SideMenuItem>
        {/* <SideMenuItem
          name="receipt"
          type="font-awesome-5"
          onClick={() => navigate('placedOrders')}>
          {i18n.t('Common.Orders')}
        </SideMenuItem> */}
      </ul>
      <div className="is-hidden-touch">
        <MyButton
          title={i18n.t('Common.Add')}
          isBold
          buttonStyle={{borderRadius: 20}}
          containerStyle={{marginTop: sizes.padding.lg}}
          onPress={() => {
            dashboardStore.ToggleAddMenu(true)
          }}
        />
      </div>
      <div className="is-hidden-desktop">
        <MyButton
          icon={<Icon name="plus" type="ant-design" color="#fff" />}
          buttonStyle={{borderRadius: 20}}
          containerStyle={{marginTop: sizes.padding.lg}}
          onPress={() => {
            dashboardStore.ToggleAddMenu(true)
          }}
        />
      </div>
    </>
  )
}

export default SideMenu
