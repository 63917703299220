export default {
  Amst: require('../../assets/stock/amst.jpg'),
  Amst2: require('../../assets/stock/amst2.jpg'),
  Rotonde: require('../../assets/stock/rotonde.jpg'),
  Rott: require('../../assets/stock/rott.jpg'),
  Balloons:
    'https://images.pexels.com/photos/670061/pexels-photo-670061.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  Canyon:
    'https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg?auto=compress&cs=tinysrgb&h=750&w=1260',
  CanyonLightning:
    'https://images.pexels.com/photos/3244513/pexels-photo-3244513.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  City:
    'https://images.pexels.com/photos/2246476/pexels-photo-2246476.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  Lake:
    'https://images.pexels.com/photos/2080960/pexels-photo-2080960.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  CityNight:
    'https://images.pexels.com/photos/3586966/pexels-photo-3586966.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  StarryNight:
    'https://images.pexels.com/photos/3274903/pexels-photo-3274903.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  StarryNight2:
    'https://images.pexels.com/photos/1252873/pexels-photo-1252873.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
}
