import {StackHeaderProps, StackNavigationProp} from '@react-navigation/stack'
import React from 'react'
import {View, StyleSheet, TouchableOpacity} from 'react-native'
import {Icon, Text} from 'react-native-elements'
import i18n from '../../i18n'
import {MainScreensList} from '../../Navigation'
import Style, {colors, sizes} from '../../Style'
//interface IProps extends StackHeaderProps {}

interface IProps {
  onBackPress: () => void
  title: string
}

const MyHeader: React.FC<IProps> = ({onBackPress, title}) => {
  const {headerContainer, backButton, titleStyle} = styles

  // const {options} = scene.descriptor
  // const title =
  //   options.headerTitle !== undefined
  //     ? options.headerTitle
  //     : options.title !== undefined
  //     ? options.title
  //     : scene.route.name

  // console.log('MyHeader', mode)

  return (
    <View style={headerContainer}>
      <Text style={titleStyle}>{title}</Text>
      <Icon
        name="arrow-left"
        type="feather"
        //color="#fff"
        containerStyle={[
          backButton,
          {
            width: 50,
            height: 50,
            marginLeft: sizes.padding.md,
          },
        ]}
        onPress={onBackPress}
      />
    </View>
  )
}

export default MyHeader

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    paddingHorizontal: 10,
    paddingVertical: sizes.padding.md,
    alignItems: 'center',
    justifyContent: 'center',

    //borderBottomColor: '#f0f0f0',
    borderBottomColor: colors.lightGrey,
    borderBottomWidth: 1,
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
  },
  titleStyle: {
    fontSize: 17,
    fontWeight: '600',
  },
})
