import React, {ReactNode} from 'react'
import {TextStyle, View, ViewStyle} from 'react-native'
import Style, {colors, sizes} from '../../Style'
import {Card, Text, Icon} from 'react-native-elements'
import {IAlertStatusMessage} from '../../Models/Client'

interface IProps {
  alertMessage?: IAlertStatusMessage
}

const AlertCard: React.FC<IProps> = ({alertMessage}) => {
  if (alertMessage === undefined) {
    return null
  }

  const {type, message} = alertMessage

  const getStyle = (): {
    container: ViewStyle
    textStyle: TextStyle
    iconStyle: TextStyle
  } => {
    switch (type) {
      case 'error':
        return {
          container: {
            backgroundColor: '#f8d7da',
            borderColor: '#f5c6cb',
            marginBottom: sizes.padding.md,
          },
          iconStyle: {color: '#721c24', width: 40},
          textStyle: {color: '#721c24'},
        }
      case 'warning':
        return {
          container: {
            backgroundColor: '#fff3cd',
            borderColor: '#ffeeba',
            marginBottom: sizes.padding.md,
          },
          iconStyle: {color: '#856404', width: 40},
          textStyle: {color: '#856404'},
        }

      default:
        return {
          container: {
            backgroundColor: '#d4edda',
            borderColor: '#c3e6cb',
            marginBottom: sizes.padding.md,
          },
          iconStyle: {color: '#155724', width: 40},
          textStyle: {color: '#155724'},
        }
    }
  }
  const style = getStyle()
  const {flexRow, centerRowY} = Style

  const getIcon = (): ReactNode => {
    switch (type) {
      case 'error':
        return (
          <Icon iconStyle={style.iconStyle} name="close" type="antdesign" />
        )
      case 'warning':
        return (
          <Icon iconStyle={style.iconStyle} name="warning" type="antdesign" />
        )

      default:
        return (
          <Icon iconStyle={style.iconStyle} name="check" type="antdesign" />
        )
    }
  }

  return (
    <Card containerStyle={[{marginHorizontal: 0}, style.container]}>
      {/* <Card.Title>{title}</Card.Title> */}
      <View style={[flexRow, centerRowY]}>
        {getIcon()}
        <Text style={style.textStyle}>{message}</Text>
      </View>
    </Card>
  )
}

export default AlertCard
