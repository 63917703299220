import {makeAutoObservable, runInAction} from 'mobx'
import {IUserCouponSummarySTO} from '../../../Models/Server'
import CouponController from '../../../Services/Topr.Core/CouponController'

export default class Store {
  public IsLoading = true
  public CouponGuid = ''
  public UserCouponSummaries: IUserCouponSummarySTO[] = []
  private Skip = 0
  public Take = 20
  public HasMore = true
  public HasMoreLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  public Initialize(couponGuid: string) {
    this.IsLoading = true
    this.CouponGuid = couponGuid
    this.GetUserCouponSummaries(true)
  }

  private GetUserCouponSummaries(reload: boolean) {
    this.HasMoreLoading = true

    if (reload) {
      this.Skip = 0
    }

    CouponController.GetUserCouponSummaries(
      this.CouponGuid,
      this.Skip,
      this.Take,
    )
      .then((userCouponSummaries) => {
        runInAction(() => {
          if (reload) {
            this.UserCouponSummaries = userCouponSummaries
          } else {
            this.UserCouponSummaries.push(...userCouponSummaries)
          }
          this.IsLoading = false
          this.HasMoreLoading = false
          this.HasMore = userCouponSummaries.length === this.Take
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public NextPage() {
    if (!this.HasMoreLoading && this.HasMore) {
      this.Skip += this.Take
      console.log('nextPage', this.Skip)
      this.GetUserCouponSummaries(false)
    }
  }
}
export const userCouponSummaryStore = new Store()
