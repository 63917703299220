// import * as ImagePicker from 'expo-image-picker'
// import {ImagePickerOptions} from 'expo-image-picker'
import {makeAutoObservable, runInAction} from 'mobx'
import {goBack} from '../../../Helpers/NavigationHelper'
import {SetFriendlyMessage} from '../../../Helpers/ServiceHelpers'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {ToprError} from '../../../Models/Client'
import UserController from '../../../Services/Topr.Core/UserController'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'

// export const imagePickerOptions: ImagePickerOptions = {
//   mediaTypes: ImagePicker.MediaTypeOptions.All,
//   allowsEditing: true,
//   aspect: [1, 1],
//   quality: 0.4,
//   base64: true,
// }

export default class Store {
  public IsSubmitting = false
  constructor() {
    makeAutoObservable(this)
  }

  public SavePicture(data: string) {
    this.IsSubmitting = true
    UserController.SaveUserPicture(data)
      .then(() => {
        dashboardStore.GetUser()
        goBack()
        runInAction(() => (this.IsSubmitting = false))
      })
      .catch((error: ToprError) => {
        SetFriendlyMessage(error, (message) => {
          appUiStore.ShowToast({message})
        })
        runInAction(() => (this.IsSubmitting = false))
      })
  }
}
export const changeProfilePictureStore = new Store()
