import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Text} from 'react-native'
import {Card} from 'react-native-elements'
import i18n from '../../../../i18n'
import {persistedStore} from '../../../../Mobx'
import Style, {sizes} from '../../../../Style'
import MyButton from '../../../Buttons/MyButton'
import MyFooter from '../../../Form/MyFooter'
import MyFooterTile from '../../../Form/MyFooterTile'
import MyInput from '../../../Form/MyInput'
import MyModal from '../../MyModal'

interface IProps {
  onClose: () => void
  onValidPin: () => void
  pinMessage: string
}

type FormData = {
  pincode: string
  confirmPincode: string
}

const CheckPincodeModal: React.FC<IProps> = ({
  onClose,
  onValidPin,
  pinMessage,
}) => {
  useEffect(() => {
    if (!persistedStore.UsePincode) {
      //adminused automaticLogin, do not use Pincode modal
      onValidPin()
    }
  }, [null])

  if (!persistedStore.UsePincode) {
    return null
  }

  const {control, handleSubmit, errors} = useForm<FormData>()

  const onSubmit = (data: FormData) => {
    onValidPin()
    onClose()
  }

  const {pincodeStyle} = Style
  return (
    <MyModal onClose={onClose} visible={true} position="center">
      <Card.Title>{i18n.t('AuthenticateAction.Title')}</Card.Title>
      {/* <Content> */}
      <Text
        style={{
          marginBottom: sizes.padding.md,
        }}>
        {pinMessage}
      </Text>
      <MyInput
        formObject={{} as FormData}
        name="pincode"
        label={i18n.t('Pincode.Form.Fields.Pincode.Label')}
        control={control}
        autofocus
        inputProps={{
          maxLength: 4,
          keyboardType: 'numeric',
          secureTextEntry: true,
          style: pincodeStyle,
          onSubmitEditing: () => handleSubmit((data) => onSubmit(data))(),
        }}
        validation={{
          errors,
          rules: {
            required: i18n.t('Pincode.Form.Fields.Pincode.Errors.Required'),
            validate: {
              checkPincode: (pincode: string) => {
                return (
                  persistedStore.Pincode === pincode ||
                  i18n.t('Pincode.InvalidMessage')
                )
              },
            },
          },
        }}
      />
      {/* </Content> */}
      <MyFooter>
        <MyFooterTile>
          <MyButton
            type="outline"
            title={i18n.t('Common.Cancel')}
            onPress={() => {
              onClose()
            }}
          />
        </MyFooterTile>
        <MyFooterTile isLast>
          <MyButton
            title={i18n.t('Common.Ok')}
            onPress={handleSubmit(onSubmit)}
          />
        </MyFooterTile>
      </MyFooter>
    </MyModal>
  )
}

export default CheckPincodeModal
