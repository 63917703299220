import React, {Children} from 'react'
import {View} from 'react-native'
import {ListItem} from 'react-native-elements'
import Style, {sizes} from '../../Style'
import ToprConfig from '../../Config/Tenants/Topr/ToprConfig'
interface IProps {
  iconButtonLeft?: boolean
}

const FooterBar: React.FC<IProps> = ({children, iconButtonLeft}) => {
  const {dropShadow} = Style
  return (
    <View
      style={[
        {
          backgroundColor: ToprConfig.MainColor,
          height: 60,
          borderRadius: 30,
          marginHorizontal: sizes.padding.sm,
          paddingHorizontal: 25,
          paddingLeft: iconButtonLeft ? 0 : 25,
          marginBottom: 20,
          opacity: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        },
        dropShadow,
      ]}>
      {children}
    </View>
  )
}

export default FooterBar
FooterBar.defaultProps = {
  iconButtonLeft: true,
}
