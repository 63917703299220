import React from 'react'
import {View, StyleSheet, Platform} from 'react-native'
import Style, {sizes, deviceWidth} from '../../Style'
import QRCodeSvg from 'react-native-qrcode-svg'
import QRCodeWeb from 'react-qr-code'

interface IProps {
  value: string
}

const MyQrCode: React.FC<IProps> = ({value}) => {
  const renderWebOrNative = () => {
    if (Platform.OS === 'web') {
      return <QRCodeWeb value={value} />
    }

    return <QRCodeSvg value={value} size={size} />
  }

  const size = deviceWidth - 100
  return <View style={style.qrCodeBorder}>{renderWebOrNative()}</View>
}

export default MyQrCode

const style = StyleSheet.create({
  qrCodeBorder: {
    borderColor: '#000',
    borderWidth: 1,
    padding: sizes.padding.md,
    backgroundColor: '#fff',
  },
})
