import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Card, ListItem} from 'react-native-elements'
import {FormatDate, FormatDateTime} from '../../Helpers/DateHelpers'
import i18n from '../../i18n'
import Style from '../../Style'
import TextRow from '../Layout/TextRow'
import TransparentCardHeader from './TransparentCardHeader'
interface IProps {
  validFrom: Date | string | null | undefined
  validTill: Date | string | null | undefined
}

const ValidityCard: React.FC<IProps> = ({validFrom, validTill}) => {
  const renderDate = (
    label: string,
    date: Date | string | null | undefined,
  ) => {
    if (date === null || date === undefined) {
      return null
    }

    return <TextRow label={label} value={FormatDate(date as string)} />
  }

  const renderValidity = () => {
    if (
      (validFrom === null || validFrom === undefined) &&
      (validTill === null || validTill === undefined)
    ) {
      return (
        <ListItem.Subtitle>{i18n.t('Common.ValidAlways')}</ListItem.Subtitle>
      )
    }

    return (
      <View>
        {renderDate(i18n.t('Common.ValidFrom'), validFrom)}
        {renderDate(i18n.t('Common.ValidUntill'), validTill)}
      </View>
    )
  }

  return (
    <>
      <TransparentCardHeader>{i18n.t('Common.Valid')}</TransparentCardHeader>
      <Card>{renderValidity()}</Card>
    </>
  )
}

export default ValidityCard

const styles = StyleSheet.create({})
