import React, {useState} from 'react'
import {View, StyleSheet} from 'react-native'
import {CheckBox} from 'react-native-elements'
import {FormatToEuro} from '../../../../../Helpers/TextHelpers'
import {ICondiment, ICondimentGroup} from '../../../../../Models/InzziiApi'
import Style from '../../../../../Style'
import {articleStore} from '../Store'
interface IProps {
  condiment: ICondiment
  condimentGroup: ICondimentGroup
  onCheckChanged: (checked: boolean) => void
}

const CondimentCheckBox: React.FC<IProps> = ({
  onCheckChanged,
  condiment,
  condimentGroup,
}) => {
  const [checked, setChecked] = useState(false)
  const title = `${condiment.name} (${FormatToEuro(condiment.price)})`
  return (
    <CheckBox
      title={title}
      checked={checked}
      containerStyle={{flex: 1}}
      onPress={() => {
        if (checked === false) {
          //can check ? (max not reached yet)
          if (articleStore.IsValidMaxCondimentGroup(condimentGroup)) {
            setChecked(!checked)
            onCheckChanged(!checked)
          }
        } else {
          setChecked(!checked)
          onCheckChanged(!checked)
        }
      }}
    />
  )
}

export default CondimentCheckBox
