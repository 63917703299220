import React from 'react'
import {View, StyleSheet} from 'react-native'
import {Text} from 'react-native-elements'
import Style, {colors, sizes} from '../../Style'
interface IProps {}

const TransparentCardHeader: React.FC<IProps> = ({children}) => {
  return (
    <Text
      style={{
        textAlign: 'center',
        color: colors.darkGrey,
        paddingBottom: sizes.padding.sm,
        paddingTop: sizes.padding.md,
      }}>
      {children}
    </Text>
  )
}

export default TransparentCardHeader

const styles = StyleSheet.create({})
