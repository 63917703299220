import React from 'react'
import {
  Image,
  LayoutChangeEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import {Card, ListItem, Text} from 'react-native-elements'
import {FormatToEuro} from '../../../../../Helpers/TextHelpers'
import {IArticle} from '../../../../../Models/InzziiApi'
import Style, {colors, sizes} from '../../../../../Style'

interface IProps {
  article: IArticle
  onPress: () => void
}

export class ArticleCard extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  renderImage() {
    const {article} = this.props

    if (article.imageUrl) {
      return (
        <>
          <Image
            source={{
              uri: article.imageUrl,
            }}
            resizeMode="cover"
            style={{height: sizes.image.tileImageHeight}}
          />
        </>
      )
    }

    return null
  }

  render() {
    const {article, onPress} = this.props

    const {title, price} = Style

    return (
      <TouchableOpacity onPress={onPress}>
        <Card
          containerStyle={{
            padding: 0,
            marginTop: 0,
            marginBottom: sizes.padding.md,
          }}>
          {this.renderImage()}
          <ListItem>
            <ListItem.Content>
              <Text style={title}>{article.name}</Text>
              {article.description !== '' && (
                <ListItem.Subtitle style={{fontStyle: 'italic'}}>
                  {article.description}
                </ListItem.Subtitle>
              )}
              <Text style={price}>{article.price}</Text>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
        </Card>
      </TouchableOpacity>
    )
  }
}

export default React.memo(ArticleCard)
