import * as Linking from 'expo-linking'
import {makeAutoObservable, runInAction} from 'mobx'
import {Platform} from 'react-native'
import {navigate} from '../Helpers/NavigationHelper'
import {GetAppLinkFromUrl} from '../Helpers/UrlHelpers'
import {appUiStore} from '../Mobx/AppUiStore'
import {AppLinkTypes} from '../Models/Client/Enums'
import {AlertType} from '../Models/Client/ErrorHandling/AlertType'
import {chargeStore} from '../Screens/Wallet/Charge/Store'
import {myLog} from '../Services/Client/LogService'
import i18n from '../i18n'

export default class Store {
  private Url = ''
  public IsProcessing = true
  constructor() {
    makeAutoObservable(this)
  }

  public Initialize() {
    if (Platform.OS !== 'web') {
      this.IsProcessing = true
      this.CheckInitialUrl()
      return this.SetUrlListener()
    }

    return () => {}
  }

  private CheckInitialUrl() {
    //when app was closed and is opened from other app
    Linking.getInitialURL()
      .then((url) => {
        if (url && this.Url !== url) {
          //myLog.info('Initial DeepLink Url', url)
          this.ProcessUrl(url)
          runInAction(() => {
            this.Url = url // save url  to check for new Initial links
          })
        }
      })
      .catch((err) => myLog.error('processUrl: An error occurred', err))
  }

  private SetUrlListener() {
    //when app was in background and is opened from other app
    Linking.addEventListener('url', (event) => this.HandleUrlListener(event))

    return () => {
      Linking.removeEventListener('url', (event) =>
        this.HandleUrlListener(event),
      )
    }
  }

  private HandleUrlListener(event: {url: string}) {
    this.ProcessUrl(event.url)
  }

  public HandlePaymentOrderStatus(status: string, currencyGuid: string) {
    let title = i18n.t(`Charge.Form.Success.Title`)
    let type: AlertType = 'success'
    let message = i18n.t(`Charge.Form.Success.Message`)

    chargeStore.SetCurrencyGuid(currencyGuid)

    if (status === 'PAYMENT_COMPLETE') {
      chargeStore.InitCheckPaymentOrder()
    } else {
      title = i18n.t(`Charge.Form.ServerErrors.Title`)
      message = i18n.t(`Charge.Form.ServerErrors.${status}`)
      type = 'warning'

      appUiStore.ShowAlert({
        type,
        message,
        title,
      })
    }

    //success
    //check payment OrderId
  }

  private HandleEmandateResult(eMandateStatus: string) {
    const getAlertType = (): AlertType => {
      switch (eMandateStatus) {
        case 'EMANDATE_SUCCESS':
          return 'success'
        case 'EMANDATE_PENDING':
        case 'EMANDATE_CANCELLED':
        case 'EMANDATE_EXPIRED':
          return 'warning'

        default:
          return 'error'
      }
    }

    appUiStore.ShowAlert({
      type: getAlertType(),
      message: i18n.t(`AutomaticCharge.Form.EMandateResults.${eMandateStatus}`),
      title: i18n.t('AutomaticCharge.Form.EMandateResults.Title'),
    })
  }

  public ProcessUrl(url: string) {
    this.IsProcessing = true
    const appLink = GetAppLinkFromUrl(url)

    //console.log('ProcessUrl', url)
    if (appLink !== undefined) {
      switch (appLink.DeepLink) {
        case AppLinkTypes.RegisterConfirm:
          const userGuid: string = appLink.Parameters[0]
          navigate('confirm', {userGuid})

          break
        case AppLinkTypes.ConfirmChangeEmail:
          const requestIdentifier: string = appLink.Parameters[0]
          navigate('confirmChangeEmail', {requestIdentifier})
          break
        case AppLinkTypes.ResetPassword:
          const resetPasswordRequestIdentifier: string = appLink.Parameters[0]
          navigate('resetPassword', {
            requestIdentifier: resetPasswordRequestIdentifier,
          })
          break
        case AppLinkTypes.ConfirmPaymentOrder: //confirm charge
          const currencyGuid: string = appLink.Parameters[0]
          //const action: string = appLink.Parameters[1] //confirmPaymentOrder
          const status: string = appLink.Parameters[2]
          this.HandlePaymentOrderStatus(status, currencyGuid)
          break
        case AppLinkTypes.EMandateResult: //confirm charge
          const eMandateStatus: string = appLink.Parameters[2]
          this.HandleEmandateResult(eMandateStatus)
          break
        default:
          myLog.warn('Unknown AppLink', url)
          break
      }
    }

    this.IsProcessing = false
  }
}
export const linkingStore = new Store()
