import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'

import React, {useEffect} from 'react'

import {persistedStore} from '../../Mobx/PersistedStore'
import {MainScreensList} from '../../Navigation'
import {myLog} from '../../Services/Client/LogService'
import Dashboard from './Dashboard/index'
import SetPincode from './SetPincode/SetPincode'

interface IProps extends StackScreenProps<MainScreensList> {}

const DashboardIndex: React.FC<IProps> = ({navigation}) => {
  useEffect(() => {
    myLog.info('Dashboard Index')
  }, [null])

  const {Pincode} = persistedStore

  if (Pincode === undefined) {
    return <SetPincode navigation={navigation} />
  }

  return <Dashboard />
}

export default observer(DashboardIndex)
