import {IMyOrderRequestLine} from '../Models/InzziiApi'
import {FormatToEuro} from './TextHelpers'

export const GetOrderLineAmount = (orderRequestLine: IMyOrderRequestLine) => {
  let value = 0

  if (orderRequestLine) {
    value = orderRequestLine.price
    if (orderRequestLine.linkedOrderLines) {
      orderRequestLine.linkedOrderLines.forEach((linkedOrderLine) => {
        //price nullable?
        value += linkedOrderLine.price * linkedOrderLine.quantity
      })
    }
    return value * orderRequestLine.quantity
  }

  return value
}

export const GetOrderLineAmountFormatted = (
  orderRequestLine: IMyOrderRequestLine,
) => {
  //return FormatToEuro(GetOrderLineAmount(orderRequestLine))
  return GetOrderLineAmount(orderRequestLine)
}

export const GetTotalOrderAmount = (
  orderRequestLines: IMyOrderRequestLine[],
) => {
  let result = 0
  orderRequestLines.forEach(
    (orderLine) => (result += GetOrderLineAmount(orderLine)),
  )

  return result
}

export const GetTotalOrderAmountFormatted = (
  orderRequestLines: IMyOrderRequestLine[],
) => {
  return FormatToEuro(GetTotalOrderAmount(orderRequestLines))
}
