import {useNavigation} from '@react-navigation/native'
import {EventEmitter} from 'expo-location'
import {observer} from 'mobx-react'
import React, {useRef, useState} from 'react'
import {
  Animated,
  ScrollView,
  SectionList,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'
import {Text} from 'react-native-elements'
import {OrderNavigationProp} from '../..'
import {OrderHeader} from '../../../../../Components'
import {goBack, navigate} from '../../../../../Helpers/NavigationHelper'
import {GetRandomKey} from '../../../../../Helpers/UiHelpers'
import i18n from '../../../../../i18n'
import Style, {sizes} from '../../../../../Style'
import {orderOnlineStore} from '../../Store'
import ArticleCard from './ArticleCard'
import CategorySelector from './CategorySelector'

interface IProps {}

const AnimatedSectionList = Animated.createAnimatedComponent(SectionList)

const CategorySectionList: React.FC<IProps> = ({children}) => {
  const {defaultFlatListWhite, flatListContentStyle} = Style
  const {FilteredDepartments, Articles, ScrollY, SearchMode, Restaurant} =
    orderOnlineStore

  const sectionListRef = useRef<SectionList>(null)
  const departmentScrollViewRef = useRef<ScrollView>(null)

  const navigation = useNavigation<OrderNavigationProp>()

  const sectionListData = FilteredDepartments.map((department) => {
    const articlesbyDepartment = Articles.filter(
      (x) => x.departmentNumber === department.number,
    )

    return {
      title: department.name,
      data: articlesbyDepartment,
    }
  })

  //const

  const departmentsTitles = sectionListData.map((x) => x.title)

  return (
    <>
      <AnimatedSectionList
        stickySectionHeadersEnabled={false}
        style={[defaultFlatListWhite]}
        ref={sectionListRef}
        contentContainerStyle={[flatListContentStyle]}
        bounces={false}
        onScroll={Animated.event(
          [{nativeEvent: {contentOffset: {y: ScrollY}}}],
          {useNativeDriver: true},
        )}
        scrollEventThrottle={5}
        sections={sectionListData}
        renderSectionHeader={(event: any) => {
          const {
            section: {title},
          } = event

          const index = departmentsTitles.findIndex((x) => x === title)
          const firstElementStyle: ViewStyle =
            index === 0 && !SearchMode
              ? {
                  paddingTop: orderOnlineStore.TABBAR_HEIGHT,
                }
              : {paddingTop: sizes.padding.sm}

          const header = () => {
            if (index > 0 || SearchMode) {
              return null
            }

            return (
              <>
                <OrderHeader
                  restaurant={Restaurant}
                  onBackPress={() => {
                    navigate('dashboard')
                  }}
                  isAbsolute={false}
                />
              </>
            )
          }

          return (
            <>
              {header()}
              <Text
                h4
                h4Style={[
                  firstElementStyle,
                  {
                    paddingHorizontal: sizes.padding.md,
                    paddingBottom: sizes.padding.sm,
                  },
                ]}>
                {title}
              </Text>
            </>
          )
        }}
        onViewableItemsChanged={({viewableItems, changed}) => {
          if (viewableItems && viewableItems.length > 0) {
            orderOnlineStore.SetVisibleDepartment(
              viewableItems[0].section.title,
              departmentScrollViewRef,
            )
          }
        }}
        viewabilityConfig={{
          itemVisiblePercentThreshold: 50, //means if 50% of the item is visible
        }}
        renderItem={({item}) => {
          return (
            <>
              <ArticleCard
                article={item}
                onPress={() => {
                  navigation.navigate('article', {article: item})
                }}
              />
            </>
          )
        }}
        ListEmptyComponent={<Text>{i18n.t('OrderOnline.NoArticles')}</Text>}
        keyExtractor={() => {
          return GetRandomKey()
        }}></AnimatedSectionList>
      <CategorySelector
        departments={departmentsTitles}
        sectionListRef={sectionListRef}
        departmentScrollViewRef={departmentScrollViewRef}
      />
    </>
  )
}

export default observer(CategorySectionList)

const styles = StyleSheet.create({})
