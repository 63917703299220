import {makeAutoObservable, runInAction} from 'mobx'
import {Animated} from 'react-native'

export default class Store {
  //public ScrollY = 0

  public HEADER_MAX_HEIGHT = 55

  public ScrollY = new Animated.Value(0)
  public ScrollYValue = 0

  constructor() {
    makeAutoObservable(this)
  }

  public SetScrollY(y: number) {
    this.ScrollY.setValue(y)
    this.ScrollYValue = y
    Animated.event([this.ScrollY], {
      useNativeDriver: true,
    })
  }

  public ResetScrollYAnimated() {
    if (this.ScrollYValue > 0) {
      Animated.timing(this.ScrollY, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start()
      this.ScrollYValue = 0
    }
  }
}
export const dashboardHeaderStore = new Store()
