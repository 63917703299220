import React from 'react'
import {View, StyleSheet} from 'react-native'
import MyButton from './MyButton'
import Style from '../../Style'
import {IMyButtonProps} from './MyButton'
interface IProps extends IMyButtonProps {}

const SubmitHeaderButton: React.FC<IProps> = ({...props}) => {
  const {flexEnd} = Style
  return (
    <View style={flexEnd}>
      <MyButton {...props} />
    </View>
  )
}
export default SubmitHeaderButton
