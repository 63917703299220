import {StackScreenProps} from '@react-navigation/stack'
import React, {useEffect} from 'react'
import {View, StyleSheet} from 'react-native'
import {Text} from 'react-native-elements'
import {MainScreensList} from '../../../Navigation'
import Style from '../../../Style'
import {changeEmailStore} from './Store'
import {myLog} from '../../../Services/Client/LogService'
import {ResultCard, ToprScreenLoader} from '../../../Components'
import i18n from '../../../i18n'
import {observer} from 'mobx-react'
interface IProps
  extends StackScreenProps<MainScreensList, 'confirmChangeEmail'> {}
{
}

const ConfirmChangeEmail: React.FC<IProps> = ({
  navigation: {navigate},
  route,
}) => {
  useEffect(() => {
    //navigate('Pincode')
    const {requestIdentifier} = route.params
    myLog.info(`ConfirmChangeEmail ${requestIdentifier}`)

    changeEmailStore.ConfirmChangeEmail(requestIdentifier)
  }, [changeEmailStore])

  const {IsConfirming, ResultMessage} = changeEmailStore
  const renderResult = () => {
    if (ResultMessage === undefined) {
      return null
    }

    return (
      <ResultCard
        title={ResultMessage.title}
        button={{
          text: i18n.t('Common.BackToDashboard'),
          action: () => navigate('dashboard'),
        }}>
        <Text>{ResultMessage.message}</Text>
      </ResultCard>
    )
  }

  return (
    <ToprScreenLoader isLoading={IsConfirming}>
      {renderResult()}
    </ToprScreenLoader>
  )
}

export default observer(ConfirmChangeEmail)
