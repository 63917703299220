import React from 'react'
import {Icon} from 'react-native-elements'
import {GetFileUrl} from '../../Helpers/UiHelpers'
import {IUserSavingCardSTO} from '../../Models/Server'
import Badge from '../Web/Badge.web'
import CardTitle from '../Web/CardTitle.web'

interface IProps {
  userSavingCard: IUserSavingCardSTO
  onPress: () => void
}
const UserSavingsCardWeb: React.FC<IProps> = ({userSavingCard, onPress}) => {
  return (
    <div className="card-tile shadow" onClick={onPress}>
      <div
        className="card-img"
        style={{
          backgroundImage: `url(${GetFileUrl(
            userSavingCard.SavingCard.ImageGuid,
          )})`,
        }}></div>
      <div className="card-footer px-2">
        <Badge>{`${userSavingCard.StampCount}/${userSavingCard.SavingCard.StampCount}`}</Badge>
        <CardTitle>{userSavingCard.SavingCard.Title}</CardTitle>
        <Icon name="chevron-thin-right" type="entypo" />
      </div>
    </div>
  )
}

export default UserSavingsCardWeb
