import {Platform} from 'react-native'
import {deviceWidth} from '../Style'

/*
show app interface:
 - When  android or ios
 - When Web  and the width is mobile (<768)
*/
export const IsWebAndMobileOrApp = () => {
  return Platform.OS !== 'web' || (Platform.OS === 'web' && deviceWidth < 769)
}
