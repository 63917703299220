import React from 'react'
import {ListItem} from 'react-native-elements'
import {MyModal, ProfileButton} from '../../../../Components'
import i18n from '../../../../i18n'
import {persistedStore} from '../../../../Mobx'
import {dashboardStore} from '../../Dashboard/Store'
import {userMenuStore} from './Store'
interface IProps {
  onClose: () => void
  isVisible: boolean
}

const ChildUsersModal: React.FC<IProps> = ({onClose, isVisible}) => {
  const {ChildUsers, MainUser} = persistedStore
  const {User} = dashboardStore
  const isChildUser = MainUser?.MainUserGuid !== User?.MainUserGuid

  const childUsersWithoutActiveUser = ChildUsers.filter(
    (x) => x.MainUserGuid !== User?.MainUserGuid,
  )

  const renderParentMenuItem = () => {
    if (!isChildUser || MainUser === undefined) {
      return null
    }

    return (
      //   <MyButton
      //     text={MainUser.Name}
      //     block
      //     onPress={() => {
      //       store.SwitchBackToParent()
      //     }}
      //   />
      <ListItem
        onPress={() => {
          userMenuStore.SwitchBackToParent()
          onClose()
        }}>
        <ProfileButton user={MainUser} />
        <ListItem.Content>
          <ListItem.Title>{MainUser.Name}</ListItem.Title>
        </ListItem.Content>
      </ListItem>
    )
  }

  return (
    <MyModal onClose={onClose} visible={isVisible} closeOnBackPress={true}>
      <ListItem>
        <ListItem.Content style={{alignItems: 'center'}}>
          <ListItem.Title>{i18n.t('Common.Accounts')}</ListItem.Title>
        </ListItem.Content>
      </ListItem>
      {childUsersWithoutActiveUser.map((childUser, key) => {
        return (
          <ListItem
            key={key}
            onPress={() => {
              userMenuStore.SwitchToChildUser(childUser.MainUserGuid as string)
              onClose()
            }}>
            <ProfileButton user={childUser} />
            <ListItem.Content>
              <ListItem.Title>{childUser.Name}</ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )
      })}
      {renderParentMenuItem()}
    </MyModal>
  )
}

export default ChildUsersModal
