import {StackScreenProps} from '@react-navigation/stack'
import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {View} from 'react-native'
import {Badge, Text} from 'react-native-elements'
import {
  Container,
  Content,
  CouponInfoCard,
  ImageHeader,
  SavingCardInfoCard,
  StampsCard,
  SubmitHeader,
  ToprScreenLoader,
} from '../../../Components'
import {StockImageLayout} from '../../../Components/Web'
import {GetFileUrl, GetLocalizedImageUrl} from '../../../Helpers/UiHelpers'
import {MainScreensList} from '../../../Navigation'
import {myLog} from '../../../Services/Client/LogService'
import Style from '../../../Style'
import {savingCardDetailsStore} from './Store'

interface IProps
  extends StackScreenProps<MainScreensList, 'savingCardDetails'> {}

const SubscriptionDetails: React.FC<IProps> = ({
  navigation: {goBack},
  route,
}) => {
  return <>todo web</>
  // const userSavingCardGuid = route.params.userSavingCardGuid
  // useEffect(() => {
  //   myLog.info(`SavingCardDetails`)
  //   savingCardDetailsStore.GetUserSavingCard(userSavingCardGuid)
  // }, [null])

  // const {IsLoading, UserSavingCard} = savingCardDetailsStore
  // const {imageHeaderTitle, badgeStyle} = Style
  // const imageUrl = GetFileUrl(UserSavingCard?.SavingCard.ImageGuid)
  // return (
  //   <Container isGreyBackground>
  //     <StockImageLayout imageUrl={imageUrl} isLoading={IsLoading}>
  //       <Content headerMargin padder={false}>
  //         <img src={imageUrl} className="is-hidden-tablet" />
  //         <SavingCardInfoCard userSavingCard={UserSavingCard} />
  //         <StampsCard userSavingCard={UserSavingCard} />
  //       </Content>
  //       <SubmitHeader onBackPress={goBack}>
  //         {`${UserSavingCard?.SavingCard.Title} ${UserSavingCard?.StampCount}/${UserSavingCard?.SavingCard.StampCount}`}
  //       </SubmitHeader>
  //     </StockImageLayout>
  //   </Container>
  // )
}
export default observer(SubscriptionDetails)
