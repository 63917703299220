import React from 'react'
import {Image, TouchableOpacity} from 'react-native'
import {Badge, Card, ListItem, Text} from 'react-native-elements'

import Style, {sizes} from '../../Style'

interface IProps {
  title: string
  badgeText?: string
  imageUrl: string
  onPress: () => void
  isLast?: boolean
}
const ImageTextCard: React.FC<IProps> = ({
  title,
  badgeText,
  imageUrl,
  onPress,
  isLast,
}) => {
  const {badgeStyle} = Style

  const renderBadge = () => {
    if (badgeText === undefined) {
      return null
    }

    return (
      <Badge
        value={badgeText}
        badgeStyle={badgeStyle}
        textStyle={{fontSize: 14, fontWeight: '300'}}
      />
    )
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        marginBottom: isLast ? 90 : 0,
      }}>
      <Card containerStyle={{padding: 0}}>
        <Image
          source={{
            uri: imageUrl,
          }}
          style={{height: sizes.image.tileImageHeight}}
        />
        <ListItem>
          <ListItem.Content>
            <Text style={Style.title}>{title}</Text>
          </ListItem.Content>
          {renderBadge()}
          <ListItem.Chevron size={26} />
        </ListItem>
      </Card>
    </TouchableOpacity>
  )
}

export default ImageTextCard

ImageTextCard.defaultProps = {
  isLast: false,
}
