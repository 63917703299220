import React from 'react'
import {View, StyleSheet} from 'react-native'
import {ListItem, Text} from 'react-native-elements'
import Style from '../../Style'
interface IProps {
  label: string
  value: string
}

const TextRow: React.FC<IProps> = ({label, value}) => {
  const {textRowContainer} = Style
  return (
    <View style={textRowContainer}>
      <Text>{label}</Text>
      <ListItem.Subtitle>{value}</ListItem.Subtitle>
    </View>
  )
}

export default TextRow

const styles = StyleSheet.create({})
