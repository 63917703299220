import {AxiosError, AxiosResponse} from 'axios'
import {HandleAxiosCatch} from '../../Helpers/ServiceHelpers'
import {IUserSubscriptionSto} from '../../Models/Server/ConsumerSubscription/IUserSubscriptionSto'
import {axiosAuthUser} from './Axios/AxiosAuthUser'

export default {
  GetUserSubscriptions: () => {
    return new Promise<IUserSubscriptionSto[]>((resolve, reject) => {
      const url = 'api/v1/user/subscriptions'
      axiosAuthUser
        .get(url)
        .then((response: AxiosResponse<IUserSubscriptionSto[]>) => {
          if (response.data) {
          }
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
  GetUserSubscription: (userSubscriptionId: string) => {
    return new Promise<IUserSubscriptionSto>((resolve, reject) => {
      axiosAuthUser
        .get(`api/v1/user/subscription/${userSubscriptionId}`)
        .then((response: AxiosResponse<IUserSubscriptionSto>) => {
          if (response.data) {
          }
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          HandleAxiosCatch(error, reject)
        })
    })
  },
}
