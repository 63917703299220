import React from 'react'
import {Text, TouchableOpacity, View} from 'react-native'
import Style, {colors} from '../../Style'
interface IProps {
  onPress: () => void
}

const TextButton: React.FC<IProps> = ({children, onPress}) => {
  return (
    <Text onPress={onPress} style={{color: colors.main}}>
      {children}
    </Text>
  )
}

export default TextButton
