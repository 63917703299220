import React from 'react'
import {View} from 'react-native'
import {Card} from 'react-native-elements'
import Style, {sizes} from '../../Style'
import MyButton from '../Buttons/MyButton'

interface IProps {
  title: string
  button?: {
    text: string
    action: () => void
  }
}

const ResultCard: React.FC<IProps> = ({children, title, button}) => {
  const renderButton = () => {
    if (button === undefined) {
      return null
    }

    return (
      <MyButton
        title={button.text}
        onPress={button.action}
        buttonStyle={{marginTop: sizes.padding.md}}
      />
    )
  }

  return (
    <Card
    //containerStyle={{marginTop: sizes.padding.lg}}
    >
      <Card.Title>{title}</Card.Title>
      <View>
        {children}
        {renderButton()}
      </View>
    </Card>
  )
}

export default ResultCard
