import {StackNavigationProp} from '@react-navigation/stack'

import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {
  FormHeader,
  FormInfo,
  MyButton,
  Content,
  MyFooter,
  MyFooterTile,
  MyInput,
  Container,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import AppConfig from '../../../Config/AppConfig'
import {goBack} from '../../../Helpers/NavigationHelper'

import {store} from '../Store'
import {myLog} from '../../../Services/Client/LogService'
import {RegisterSteps} from '../Register'
import {RegisterFormData} from '../Store'
import {Keyboard} from 'react-native'
import i18n from '../../../i18n'
import {StockImageLayout} from '../../../Components/Web'
import {appUiStore} from '../../../Mobx/AppUiStore'
interface IProps {
  navigation: StackNavigationProp<RegisterSteps>
}

const PasswordStep: React.FC<IProps> = ({navigation: {navigate}}) => {
  const {control, handleSubmit, errors, setValue, formState} =
    useForm<RegisterFormData>({
      mode: 'onChange',
    })

  useEffect(() => {
    myLog.info('PasswordStep')
    if (AppConfig.IsDebugMode) {
      setValue('password', 'sdopey01')
    }
  }, [store])

  const onSubmit = (data: RegisterFormData) => {
    store.SetFormData('password', data.password)
    navigate('terms')
  }

  return (
    <>
      <Container>
        <StockImageLayout>
          <Content headerMargin>
            <FormHeader>{i18n.t('Register.PasswordStep.Header')}</FormHeader>
            <FormInfo>{i18n.t('Register.PasswordStep.Info')}</FormInfo>
            <MyInput
              formObject={{} as RegisterFormData}
              name="password"
              autofocus={true}
              label={i18n.t('Register.PasswordStep.Field.Label')}
              control={control}
              inputProps={{
                autoCapitalize: 'none',
                secureTextEntry: true,
                onSubmitEditing: () => {
                  handleSubmit((data) => onSubmit(data))()
                },
              }}
              validation={{
                errors,
                rules: {
                  required: i18n.t(
                    'Register.PasswordStep.Field.Errors.Required',
                  ),
                  minLength: {
                    value: 6,
                    message: i18n.t(
                      'Register.PasswordStep.Field.Errors.MinLength',
                    ),
                  },
                },
              }}
            />
          </Content>
          <SubmitHeader title={i18n.t('Register.Header')} onBackPress={goBack}>
            <SubmitHeaderButton
              disabled={!formState.isValid}
              title={i18n.t('Common.Continue')}
              onPress={handleSubmit(onSubmit)}
            />
          </SubmitHeader>
        </StockImageLayout>
      </Container>
    </>
  )
}

export default PasswordStep
//simple email regex
//https://stackoverflow.com/questions/742451/what-is-the-simplest-regular-expression-to-validate-emails-to-not-accept-them-bl
