import {makeAutoObservable, runInAction} from 'mobx'
import {
  DoServiceCall,
  SetFriendlyMessage,
} from '../../../Helpers/ServiceHelpers'
import {myLog} from '../../../Services/Client/LogService'
import i18n from '../../../i18n'
import UserController from '../../../Services/Topr.Core/UserController'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'
import ToprError from '../../../Models/Client/ErrorHandling/ToprError'
import {appUiStore} from '../../../Mobx/AppUiStore'
import {goBack} from '../../../Helpers/NavigationHelper'
import {MyFormData} from './ChangePassword'

export default class Store {
  public IsSubmitting = false
  constructor() {
    makeAutoObservable(this)
  }

  ChangePassword(password: string) {
    this.IsSubmitting = true
    UserController.SetPassword(password, dashboardStore.User?.MainUserGuid)
      .then(() => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowToast({message: i18n.t('ChangePassword.Form.Succes')})
        goBack()
      })
      .catch((error: ToprError) => {
        SetFriendlyMessage(error, (message) => {
          appUiStore.ShowToast({message})
        })
        runInAction(() => (this.IsSubmitting = false))
      })
  }

  ValidatePassword(password: string) {
    return UserController.ValidatePassword(
      password,
      dashboardStore.User?.MainUserGuid,
    )
  }
}
export const changePasswordStore = new Store()
