import React from 'react'
import {View} from 'react-native'
import {Icon, Text} from 'react-native-elements'
interface IProps {
  onBackPress?: () => void
  title?: string
}

const SubmitHeader: React.FC<IProps> = ({onBackPress, children, title}) => {
  const renderBackButton = () => {
    if (onBackPress === undefined) {
      return null
    }
    return (
      <Icon
        name="arrow-left"
        type="feather"
        containerStyle={{
          width: 50,
          height: 40,
          justifyContent: 'center',
        }}
        onPress={onBackPress}
      />
    )
  }

  const renderTitle = () => {
    if (title === undefined) {
      return null
    }

    return (
      <Text
        style={{
          fontSize: 17,
          fontWeight: '600',
        }}>
        {title}
      </Text>
    )
  }
  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        paddingVertical: 10,
        paddingRight: 10,
        backgroundColor: '#fff',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomColor: '#000',
        borderBottomWidth: 1,
      }}>
      {renderBackButton()}
      {renderTitle()}
      {children}
    </View>
  )
}

export default SubmitHeader
