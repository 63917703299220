import React, {useEffect} from 'react'
import {onTagScannedEvent} from '../../../Helpers/NfcHelpers'
import {IAlertStatusMessage} from '../../../Models/Client'
import AlertCard from '../../Cards/AlertCard'
import FormInfo from '../../Form/FormInfo'
import {store} from './Store'
import NfcManager from 'react-native-nfc-manager'
interface IProps {
  label: string
  onTagScanned: onTagScannedEvent
  alertStatusMessage?: IAlertStatusMessage
}

const NfcScanner: React.FC<IProps> = ({
  label,
  onTagScanned,
  alertStatusMessage,
}) => {
  useEffect(() => {
    store.Initialize(onTagScanned)

    return () => {
      store.Unmount()
    }
  }, [null])

  return (
    <>
      <FormInfo>{label}</FormInfo>
      <AlertCard alertMessage={alertStatusMessage} />
    </>
  )
}

export default NfcScanner
