import React from 'react'
import CardTitle from '../Web/CardTitle.web'
interface IProps {
  imageUrl: string
  text: string
}

const ImageCard: React.FC<IProps> = ({imageUrl, text}) => {
  return (
    <div className="card-tile shadow no-cursor">
      <div
        className="card-img"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}></div>
      <div className="card-footer px-2">
        <CardTitle>{text}</CardTitle>
      </div>
    </div>
  )
}

export default ImageCard
