import {logger} from 'react-native-logs'

import {ansiColorConsoleSync} from 'react-native-logs/dist/transports/ansiColorConsoleSync'
import AppConfig from '../../Config/AppConfig'

const defaultConfig = {
  severity: AppConfig.Service.LogLevel,
  //transport: colorConsoleSync,
  transport: ansiColorConsoleSync,
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
}

export const myLog = logger.createLogger(defaultConfig)
// declare class MylogTyped {
//   [key: string]: (msg: string) => void
// }

//export const myLog:MylogTyped = logger.createLogger(defaultConfig)
