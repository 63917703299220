import {observer} from 'mobx-react'
import React from 'react'
import WalletCardWeb from '../../../../Components/Cards/WalletCardWeb'
import {HorizontalScroll} from '../../../../Components/Web'
import {navigate} from '../../../../Helpers/NavigationHelper'
import i18n from '../../../../i18n'
import {dashboardStore} from '../../Dashboard/Store.web'
import ToprRowLoader from './ToprRowLoader.web'

interface IProps {}

const WalletsScroll: React.FC<IProps> = ({children}) => {
  const {Wallets, IsWalletLoading} = dashboardStore

  const renderWalletCards = () => {
    return Wallets.map((wallet, key) => (
      <WalletCardWeb
        key={key}
        onPress={() => {
          navigate('walletDetails', {
            currencyGuid: wallet.Currency.CurrencyGuid,
          })
        }}
        wallet={wallet}
      />
    ))
  }

  if (IsWalletLoading) {
    return <ToprRowLoader />
  }

  return (
    <>
      <h1 className="title is-4 pt-4">{i18n.t('Common.Pay')}</h1>
      <HorizontalScroll>{renderWalletCards()}</HorizontalScroll>
    </>
  )
}

export default observer(WalletsScroll)
