import { StackScreenProps } from '@react-navigation/stack'
import 'bulma/css/bulma.min.css'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ToprScreenLoader } from '../../Components'
import { Container } from '../../Components/Web'
import Redirect from '../../Components/Web/Redirect'
import { ContentElements } from '../../Components/Web/Strapi'
import { MainScreensListWeb } from '../../Navigation/index.web'
import '../../Style/strapi.scss'
import '../../Style/style.web.scss'
import TopMenuView from './Components/TopMenu'
import { homeStore } from './Store'
import './style.scss'


interface IProps extends StackScreenProps<MainScreensListWeb, 'home'> {}

const Home: React.FC<IProps> = ({children, navigation}) => {
  useEffect(() => {
    homeStore.GetDownload()
  }, [homeStore])

  const {IsLoading, Page} = homeStore

  if (IsLoading) {
    //return loader
    return <ToprScreenLoader isLoading={IsLoading} isWhite />
  }

  //if strapi fails go to login
  if (Page === undefined) {
    return <Redirect routeName="login" navigation={navigation} />
  }

  return (
    <>
      <TopMenuView page={Page} />
      <Container isWhite>
        <ContentElements contentElements={Page.Content} />
      </Container>
    </>
  )
}

export default observer(Home)
