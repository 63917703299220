import React, {CSSProperties} from 'react'
interface IProps {
  transparent?: boolean
  visible?: boolean
}

const MyWebModal: React.FC<IProps> = ({children, transparent, visible}) => {
  let style = {...myStyle}
  style.backgroundColor = transparent ? 'transparent' : '#ffffff'

  if (!visible) {
    return null
  }

  return (
    <div
      style={style}
      className="myWebModal"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}>
      {children}
    </div>
  )
}

export default MyWebModal

MyWebModal.defaultProps = {
  transparent: false,
  visible: true,
}

//backdrop
const myStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9999,
}
