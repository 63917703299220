import {makeAutoObservable, runInAction} from 'mobx'
import ToprUmbraco from '../../../Services/Topr.Umbraco'
import {IHelpItemViewModel} from '../../../Services/Topr.Umbraco/Models/IHelpItemViewModel'
import {IHelpViewModel} from '../../../Services/Topr.Umbraco/Models/IHelpViewModel'

export default class Store {
  public IsLoading = true
  public Page?: IHelpViewModel = undefined
  public Search: string = ''
  public FilteredHelpItems: IHelpItemViewModel[] = []

  constructor() {
    makeAutoObservable(this)
  }

  public GetHelp() {
    this.IsLoading = true

    ToprUmbraco.GetHelpPage()
      .then(async (page: IHelpViewModel) => {
        this.Page = page
        this.FilteredHelpItems = this.Page.HelpItems
      })
      .catch((error: string) => {
        //console.log(`GetHelpPage  failed`, error)
      })
      .finally(() => {
        runInAction(() => (this.IsLoading = false))
      })
  }

  public SetSearch(search: string) {
    this.Search = search
    if (this.Page !== undefined) {
      if (search.trim() === '') {
        this.FilteredHelpItems = this.Page.HelpItems

        return
      }

      this.FilteredHelpItems = this.Page.HelpItems.filter((helpDeskItem) => {
        const hasAnswer =
          helpDeskItem.Answer.toLowerCase().indexOf(search.toLowerCase()) > -1
        const hasQuestion =
          helpDeskItem.Question.toLowerCase().indexOf(search.toLowerCase()) > -1

        return hasAnswer || hasQuestion
      })
    }
  }
}
export const helpStore = new Store()
