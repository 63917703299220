import React, {PropsWithChildren, useState} from 'react'
import {Controller} from 'react-hook-form'
import {StyleSheet, TouchableOpacity, View, ViewStyle} from 'react-native'
import {Icon, Input, InputProps} from 'react-native-elements'
import Style, {colors, sizes} from '../../Style'
import {useEffect} from 'react'
interface IProps<T> {
  name: keyof T
  label: string
  control: any
  validation?: {
    errors: any
    rules: any
    defaultValue?: string
  }
  formObject: T
  inputProps?: InputProps
  autofocus?: boolean
  multiline?: boolean
  numberOfLines?: number
  onTextChange?: (text: string) => void
}

const MyInput = <ObjectType,>(props: PropsWithChildren<IProps<ObjectType>>) => {
  const inputRef = React.useRef<Input>(null)

  const {inputContainer, labelStyle} = Style
  const {
    name,
    label,
    control,
    validation,
    inputProps,
    autofocus,
    multiline,
    numberOfLines,
    onTextChange,
  } = props
  useEffect(() => {
    if (autofocus) {
      inputRef.current.input.focus()
    }
  }, [null])

  const [secureTextEntryState, setSecureTextEntryState] = useState<boolean>(
    inputProps?.secureTextEntry !== undefined
      ? inputProps?.secureTextEntry
      : false,
  )

  const rules = validation !== undefined ? validation.rules : {}
  //const errors = validation !== undefined ? validation.errors : {}
  const errorsMessage =
    validation !== undefined && validation.errors[name] !== undefined
      ? validation.errors[name].message
      : undefined

  const defaultValue =
    validation !== undefined && validation.defaultValue !== undefined
      ? validation.defaultValue
      : ''

  const renderPasswordToggle = () => {
    const {rightContainer, centerXY} = Style
    if (inputProps?.secureTextEntry !== undefined) {
      const iconColor = !secureTextEntryState ? colors.main : colors.lightGrey
      return (
        <View style={[rightContainer, centerXY]}>
          <TouchableOpacity
            style={{paddingHorizontal: sizes.padding.md, paddingVertical: -10}}
            onPress={() => {
              setSecureTextEntryState(!secureTextEntryState)
            }}>
            <Icon name="eye" type="entypo" color={iconColor} />
          </TouchableOpacity>
        </View>
      )
    }

    return null
  }

  return (
    <Controller
      control={control}
      name={name.toString()}
      rules={rules}
      defaultValue={defaultValue}
      render={(
        {onChange, onBlur, value, name, ref},
        {invalid, isTouched, isDirty},
      ) => {
        return (
          <View>
            <Input
              inputContainerStyle={inputContainer}
              containerStyle={{paddingHorizontal: 0}}
              labelStyle={labelStyle}
              ref={inputRef}
              label={label}
              value={value}
              errorMessage={errorsMessage}
              onBlur={onBlur}
              onChangeText={(value) => {
                onChange(value)

                if (onTextChange) {
                  onTextChange(value)
                }
              }}
              {...inputProps}
              secureTextEntry={secureTextEntryState}
              multiline={multiline}
              numberOfLines={numberOfLines}
            />
            {renderPasswordToggle()}
          </View>
        )
      }}
    />
  )
}

export default MyInput
