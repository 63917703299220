import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {StackScreenProps} from '@react-navigation/stack'
import {MainScreensList} from '../../../Navigation'
import {
  FormHeader,
  Content,
  MyButton,
  MyFooter,
  MyFooterTile,
  MyInput,
  Container,
  SubmitHeader,
  SubmitHeaderButton,
} from '../../../Components'
import i18n from '../../../i18n'
import {sizes} from '../../../Style'
import {myLog} from '../../../Services/Client/LogService'
import {changeNameStore} from './Store'
import {useForm} from 'react-hook-form'
import {dashboardStore} from '../../Dashboard/Dashboard/Store'

interface IProps extends StackScreenProps<MainScreensList> {}

export type MyFormData = {
  name: string
}

const ChangeName: React.FC<IProps> = ({navigation: {goBack}, route}) => {
  const {
    control,
    handleSubmit,
    errors,
    formState,
    setValue,
    trigger,
  } = useForm<MyFormData>({mode: 'onChange'})

  useEffect(() => {
    myLog.warn('ChangeName')

    setValue('name', dashboardStore.User?.Name)
    trigger('name')
  }, [null])

  const {IsSubmitting} = changeNameStore

  const onSubmit = (data: MyFormData) => {
    changeNameStore.ChangeName(data.name)
  }
  return (
    <>
      <Container keyboardAware>
        <Content headerMargin>
          <MyInput
            formObject={{} as MyFormData}
            name="name"
            control={control}
            autofocus={true}
            inputProps={{
              onSubmitEditing: () => {
                handleSubmit((data) => onSubmit(data))()
              },
            }}
            validation={{
              errors,
              rules: {
                required: i18n.t('ChangeName.Form.NameField.Errors.Required'),
              },
            }}
            label={i18n.t('ChangeName.Form.NameField.Label')}
          />
        </Content>
      </Container>
      <SubmitHeader onBackPress={goBack}>
        <SubmitHeaderButton
          disabled={!formState.isValid}
          isLoading={IsSubmitting}
          title={i18n.t('ChangeName.Form.Action')}
          onPress={handleSubmit(onSubmit)}
        />
      </SubmitHeader>
    </>
  )
}
export default observer(ChangeName)
